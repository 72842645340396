import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { isSome } from '../../../config/Maybe';
import {
  useLazyGetFoldersWithTagsByProductGroupIdQuery,
  useLazyGetRootContentWithTagsByProductGroupIdQuery,
  useLazyGetRootFoldersAndContentQuery,
} from '../../../services/prodGroupsEndpoints';
import { useLazyGetUngroupedTagsByProductGroupIdQuery } from '../../../services/tagEndpoints';
import { useLazyGetTagGroupsByProductGroupIdQuery } from '../../../services/tagGroupEndpoints';
import { addTags, setTags } from '../../../store/tagSlice';
import { setTagGroups } from '../../../store/tagGroupSlice';
import {
  FolderOrContentItem,
  ItemType,
} from '../../../objects/FolderOrContentItem';
import axios from 'axios';
import { getBaseUrl } from '../../../services/vidualsApi';
import {
  addFolderOrContentToTree,
  selectContentsInTree,
  selectFoldersInTree,
} from '../../../store/treeItemSlice';
import { useLazyGetQTemplatesByProductGroupIdQuery } from '../../../services/qTemplateEndpoints';
import { Customizations } from '../../../objects/Customizations';
import { useGetQuestionnaireByProdGroupQuery } from '../../../services/questionnaireEndpoints';
import { EndUserQuestionnaire } from './EndUserQuestionnaire';
import { useEndUserContextContext } from '../../../contexts/EndUserContext';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface QuestionnaireWrapperProps {
  customizations?: Customizations;
}

export function QuestionnaireWrapper({
  customizations,
}: QuestionnaireWrapperProps): JSX.Element {
  const { productGroup } = useEndUserContextContext();
  const dispatch = useAppDispatch();
  const [fetchUngroupedTags] = useLazyGetUngroupedTagsByProductGroupIdQuery();
  const [fetchTagGroupsByProdGroup, tagGroupResult] =
    useLazyGetTagGroupsByProductGroupIdQuery();
  const [fetchQTemplatesByProdGroup] =
    useLazyGetQTemplatesByProductGroupIdQuery();
  const [fetchRootFoldersAndContents, rootFoldersAndContentResult] =
    useLazyGetRootFoldersAndContentQuery();
  const [getFolderWithTags] = useLazyGetFoldersWithTagsByProductGroupIdQuery();
  const [getContentsWithTags] =
    useLazyGetRootContentWithTagsByProductGroupIdQuery();
  const currentFolder = useAppSelector(selectFoldersInTree);
  const currentContent = useAppSelector(selectContentsInTree);

  const { data: questionnaire } = useGetQuestionnaireByProdGroupQuery(
    productGroup ? productGroup.id : skipToken
  );

  useEffect(() => {
    if (productGroup) {
      dispatch(setTags([]));
      dispatch(setTagGroups([]));
      fetchUngroupedTags(productGroup.id);
      fetchTagGroupsByProdGroup(productGroup.id);
      if (currentFolder.length > 0 || currentContent.length > 0) {
        if (
          isSome(currentFolder[0]) &&
          currentFolder[0].productGroupId !== productGroup.id
        ) {
          fetchRootFoldersAndContents(productGroup.id);
        } else if (
          isSome(currentContent[0]) &&
          currentContent[0].productGroupId !== productGroup.id
        ) {
          fetchRootFoldersAndContents(productGroup.id);
        }
      } else {
        fetchRootFoldersAndContents(productGroup.id);
      }
      fetchQTemplatesByProdGroup(productGroup.id);
      getFolderWithTags(productGroup.id);
      getContentsWithTags(productGroup.id);
    }
  }, [productGroup]);

  useEffect(() => {
    if (productGroup) {
      (async function () {
        if (
          isSome(rootFoldersAndContentResult.data) &&
          rootFoldersAndContentResult.data.length > 0 &&
          !rootFoldersAndContentResult.isLoading
        ) {
          await Promise.all(
            rootFoldersAndContentResult.data.map(
              async (folderOrContent): Promise<void> => {
                if (folderOrContent.type === ItemType.Folder) {
                  return await fetchChildrenOfFolder(
                    folderOrContent.item.id,
                    productGroup.id
                  );
                }
              }
            )
          );
        }
      })()
        .then((res) => console.log(res)) // Uneccesary logs, but the linter wont let me igonre the promise, not sure what to do
        .catch((err) => console.log(err));
    }
  }, [rootFoldersAndContentResult]);

  useEffect(() => {
    (async function () {
      if (
        isSome(tagGroupResult.data) &&
        tagGroupResult.data.length > 0 &&
        !tagGroupResult.isLoading &&
        !tagGroupResult.isFetching
      ) {
        await Promise.all(
          tagGroupResult.data.map(async (group): Promise<void> => {
            await fetchTagsInGroup(group.id);
          })
        );
      }
    })()
      .then((res) => console.log(res)) // Uneccesary logs, but the linter wont let me igonre the promise, not sure what to do
      .catch((err) => console.log(err));
  }, [tagGroupResult]);

  const fetchChildrenOfFolder = async (
    folderId: string,
    pgroupId: string
  ): Promise<void> => {
    const url = `${getBaseUrl()}/productGroups/${pgroupId}/foldersAndContentInFolder/${folderId}`;
    const response = await axios.get(url);
    dispatch(addFolderOrContentToTree(response.data));
    const data = response.data as FolderOrContentItem[];
    await Promise.all(
      data.map(async (folderOrContent: FolderOrContentItem): Promise<void> => {
        if (folderOrContent.type === ItemType.Folder) {
          return await fetchChildrenOfFolder(folderOrContent.item.id, pgroupId);
        }
      })
    );
  };

  const fetchTagsInGroup = async (groupId: string): Promise<void> => {
    const url = `${getBaseUrl()}/tags/tagGroup/${groupId}`;
    const response = await axios.get(url);
    dispatch(addTags(response.data));
  };

  return (
    <>
      {isSome(questionnaire) && (
        <EndUserQuestionnaire
          customizations={customizations}
          questionnaire={questionnaire}
        />
      )}
    </>
  );
}
