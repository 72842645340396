import { useState } from 'react';
import Logo from '../../images/logo.svg';
import './LoginPage.scss';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import {
  Flex,
  FormLabel,
  Stack,
  Container,
  VStack,
  Heading,
  Text,
  Input,
  Image,
  Link,
  Button,
  InputGroup,
  InputRightElement,
  IconButton,
  createIcon,
} from '@chakra-ui/react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useFirebase } from '../../contexts/firebase';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../config/Routes';

export const LoginPage = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const app = useFirebase();
  const { register, handleSubmit, watch } = useForm();
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();

  const signInWithEmailPassword = (email: string, password: string): void => {
    app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push(Routes.FOLDERS_AND_CONTENT);
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setErrorMessage(
            'User not found. Please enter a valid email address.'
          );
        } else if (error.code === 'auth/wrong-password') {
          setErrorMessage(
            'The login credentials do not match. Please try again.'
          );
        }
        setShowErrorMessage(true);
      });
  };

  const sendPasswordResetEmail = (email: string): void => {
    setShowErrorMessage(false);
    if (email.trim() === '') {
      setErrorMessage(`Please enter your email address`);
      setShowErrorMessage(true);
    } else {
      firebase
        .auth(app)
        .sendPasswordResetEmail(email)
        .then(() => {
          console.info('Password reset email sent to:', email);
          setErrorMessage(`Password reset email sent to: ${email}`);
          setShowErrorMessage(true);
        })
        .catch((error) => {
          setErrorMessage(`Error sending password reset email to: ${email}`);
          setShowErrorMessage(true);
          console.error('Error sending password reset email', error);
          throw error;
        });
    }
  };

  const onSubmit = (data: Record<string, string>): void => {
    setShowErrorMessage(false);
    signInWithEmailPassword(data.email, data.password);
  };
  const handleClick = (): void => setShowPass(!showPass);

  const email = watch('email');

  const ClosedEyeIcon = createIcon({
    displayName: 'closedEyeIcon',
    viewBox: '0 0 22 20',
    path: (
      <path
        d='M12.875 16.825C12.2569 16.9419 11.6291 17.0005 11 17C6.522 17 2.732 14.057 1.457 10C1.80027 8.9081 2.32899 7.88346 3.02 6.971M8.878 7.879C9.44066 7.31634 10.2038 7.00025 10.9995 7.00025C11.7952 7.00025 12.5583 7.31634 13.121 7.879C13.6837 8.44166 13.9998 9.20478 13.9998 10.0005C13.9998 10.7962 13.6837 11.5593 13.121 12.122M8.878 7.879L13.121 12.122M8.878 7.879L13.12 12.12M13.121 12.122L16.412 15.412M8.88 7.88L5.59 4.59M5.59 4.59L2 1M5.59 4.59C7.20236 3.54957 9.0811 2.9974 11 3C15.478 3 19.268 5.943 20.543 10C19.839 12.2305 18.3774 14.1446 16.411 15.411M5.59 4.59L16.411 15.411M16.411 15.411L20 19'
        fillOpacity='0'
        stroke='#71717A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    ),
  });

  return (
    <Flex
      bg='gray.50'
      w='100%'
      h='100vh'
      maxW='100%'
      maxH='100%'
      justifyContent='center'
    >
      <VStack>
        <Image id='logo' src={Logo} alt='viduals logo' pt={5} mb='20%' />
        {/* later change this to position fixed? */}
        <Container
          w={515}
          h={428}
          p={30}
          boxShadow={
            '0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)'
          }
          borderRadius={4}
          bg='white'
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing='20px'>
              <Heading as='h2' size='lg'>
                Login
              </Heading>
              {showErrorMessage && (
                <Text variant='error-box'>{errorMessage}</Text>
              )}
              {/* <TextInput placeholder={"Email"} value= /> */}
              <Stack display='flex' w='100%'>
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    placeholder='Email'
                    {...register('email', {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                </InputGroup>
              </Stack>
              <Stack display='flex' w='100%'>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    {...register('password', {
                      required: true,
                    })}
                    pr='4.5rem'
                    type={showPass ? 'text' : 'password'}
                    placeholder='Password'
                  />
                  <InputRightElement width='4.5rem'>
                    {showPass ? (
                      <Button
                        onClick={handleClick}
                        border='none'
                        color={'gray.500'}
                        width='4.5rem'
                      >
                        <FontAwesomeIcon
                          aria-label='Hide password'
                          className={classNames.faIcon}
                          icon={faEye}
                        />
                      </Button>
                    ) : (
                      <IconButton
                        aria-label='Show password'
                        onClick={handleClick}
                        icon={<ClosedEyeIcon />}
                        border='none'
                        width='4.5rem'
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>
              <Link
                onClick={() => sendPasswordResetEmail(email)}
                color='viduals.greenDarker'
                fontWeight={500}
              >
                Forgot password?
              </Link>
              <Input type='submit' value='Login' fontWeight={500} />
            </VStack>
          </form>
        </Container>
      </VStack>
    </Flex>
  );
};

const classNames = {
  faIcon: 'faIcon',
};
