import { HStack, Text } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { FolderIconNoColor } from '../../config/icons';
import { isSome } from '../../config/Maybe';
import { Folder } from '../../objects/Folder';

interface SystemFolderItemProps {
  onClick: () => void;
  folder: Folder;
  color: string | undefined;
}

export const SystemFolderItem = ({
  onClick,
  folder,
  color,
}: SystemFolderItemProps): JSX.Element => {
  const displayColor = isSome(color) ? '#' + String(color) : appColors.GRAY400;

  return (
    <HStack w='100%' h='100%'>
      <HStack>
        <FolderIconNoColor w={6} stroke={displayColor} />
        <Text
          onClick={onClick}
          className={classNames.content}
          maxW='100%'
          isTruncated
        >
          {folder.name}
        </Text>
      </HStack>
    </HStack>
  );
};

const classNames = {
  content: 'content',
  icon: 'icon',
  faIcon: 'faIcon',
};
