import { Text, Tr, Td, IconButton } from '@chakra-ui/react';
import { Organization } from '../../objects/Organization';
import { useAppDispatch } from '../../store/hooks';
import { setOrgToEdit } from '../../store/modalFormSlice';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { setCurrentOrganization } from '../../store/organizationSlice';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../config/Routes';

interface OrgItemProps {
  org: Organization;
  onClick: () => void;
}

export const OrgItem = ({ org, onClick }: OrgItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const setOrgAndNavigate = (organization: Organization): void => {
    dispatch(setCurrentOrganization(organization));
    history.push(Routes.PRODUCT_GROUPS);
  };

  return (
    <Tr>
      <Td>
        <span
          onClick={() => {
            dispatch(setOrgToEdit(org));
            onClick();
          }}
          className='content'
        >
          {org.name}
        </span>
      </Td>
      <Td>{org.urlExtension}</Td>
      <Td>{org.users?.length ?? 0}</Td>
      <Td>
        <Text noOfLines={2}>{org.notes}</Text>
      </Td>
      <Td>
        <IconButton
          aria-label='go to organization'
          border='none'
          icon={<ChevronRightIcon color='viduals.greenDarker' w={7} h={7} />}
          onClick={() => setOrgAndNavigate(org)}
        />
      </Td>
    </Tr>
  );
};
