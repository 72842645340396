import './QuestionnairePage.scss';
import { Box, VStack } from '@chakra-ui/react';
import { QuestionnaireTemplateOption } from '../../objects/Questions/QuestionnaireTemplateOption';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectQTemplateById } from '../../store/qTemplateSlice';
import { useEffect } from 'react';
import { isSome } from '../../config/Maybe';
import { useGetRootQuestionsForTemplateOptionQuery } from '../../services/questionnaireEndpoints';
import { FolderOrContentQuestion } from '../../objects/Questions/FolderOrContentQuestion';
import { ItemType } from '../../objects/FolderOrContentItem';
import { ContentOrFolderQuestionCard } from './QuestionCards/ContentOrFolderQuestionCard';
import {
  addRootQuestionsToConfigure,
  selectRootQuestionsToConfigureByTemplateOptionId,
  setCurrentQuestionnaireRootContent,
  updateRootQuestion,
} from '../../store/questionnaireConfigurationSlice';
import {
  selectFoldersWithTags,
  selectContentsWithTags,
} from '../../store/foldersAndContentsWithTagsSlice';
import {
  getNecessaryContentQuestions,
  getNecessaryFolderQuestions,
} from '../../objects/Questions/Questionnaire';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

interface ContentFolderQuestionCardListProps {
  templateOption: QuestionnaireTemplateOption;
}

export const ContentFolderQuestionCardList = ({
  templateOption,
}: ContentFolderQuestionCardListProps): JSX.Element => {
  const pGroup = useAppSelector(selectCurrentProductGroup);
  const dispatch = useAppDispatch();
  const questionsToAsk = useAppSelector((state) =>
    selectRootQuestionsToConfigureByTemplateOptionId(state, templateOption.id)
  );
  const foldersWithTags = useAppSelector(selectFoldersWithTags);
  const contentsWithTags = useAppSelector(selectContentsWithTags);

  const { data: savedRootQuestions, isLoading: savedRootQuestionsLoading } =
    useGetRootQuestionsForTemplateOptionQuery(templateOption.id);
  const qTemplate = useAppSelector((state) =>
    selectQTemplateById(state, templateOption.questionnaireTemplateId)
  );

  useEffect(() => {
    if (
      isSome(qTemplate) &&
      isSome(foldersWithTags) &&
      isSome(contentsWithTags) &&
      isSome(savedRootQuestions) &&
      isSome(pGroup) &&
      !savedRootQuestionsLoading
    ) {
      // add questions that are not yet saved for contents and folders
      const necessaryContentQuestions = getNecessaryContentQuestions(
        qTemplate.qTemplateContents,
        contentsWithTags,
        templateOption,
        pGroup
      );

      const necessaryFolderQuestions = getNecessaryFolderQuestions(
        qTemplate.qTemplateFolders,
        foldersWithTags,
        templateOption,
        pGroup
      );

      const questionsThatShouldBeAsked: FolderOrContentQuestion[] = [
        ...necessaryContentQuestions,
        ...necessaryFolderQuestions,
      ].sort((a, b) => (a.indexWithinParent ?? 0) - (b.indexWithinParent ?? 0));

      const newAndExistingQuestionsToAsk = questionsThatShouldBeAsked.map(
        (question) => {
          const existingQuestion = savedRootQuestions.find(
            (sQuestion) =>
              sQuestion.folderOrContentId === question.folderOrContentId &&
              sQuestion.templateQuestionnaireOptionId ===
                question.templateQuestionnaireOptionId
          );
          if (isSome(existingQuestion) && !question.mandatory) {
            return existingQuestion;
          }
          return question;
        }
      );
      const newQuestions = newAndExistingQuestionsToAsk.filter(
        (newQuestion) =>
          !questionsToAsk.some(
            (existingQuestion) =>
              newQuestion.folderOrContentId ===
                existingQuestion.folderOrContentId &&
              newQuestion.templateQuestionnaireOptionId ===
                existingQuestion.templateQuestionnaireOptionId
          )
      );
      dispatch(addRootQuestionsToConfigure(newQuestions));
    }
  }, [
    foldersWithTags,
    contentsWithTags,
    qTemplate,
    savedRootQuestions,
    templateOption,
    pGroup,
  ]);

  const updateQuestion = (
    text: string,
    helpText: string,
    itemId: string,
    imageUrl: string | null | undefined,
    file: File | string | undefined
  ): void => {
    const updatedQuestion = questionsToAsk.find(
      (q) => q.folderOrContentId === itemId
    );
    if (isSome(updatedQuestion)) {
      let objectUrl: string | undefined = '';
      let objectName: string | undefined = '';
      if (typeof file === 'string') {
        objectUrl = file;
        objectName = updatedQuestion.imageFileObjectName;
      } else if (isSome(file)) {
        objectUrl = URL.createObjectURL(file as Blob);
        objectName = file.name;
      } else {
        objectUrl = undefined;
        objectName = undefined;
      }

      dispatch(
        updateRootQuestion({
          ...updatedQuestion,
          text,
          helpText,
          imageUrl,
          imageFileObjectUrl: objectUrl,
          imageFileObjectName: objectName,
        })
      );
    }
  };

  const setRootItem = (question: FolderOrContentQuestion): void => {
    if (question.type === ItemType.Folder && isSome(foldersWithTags)) {
      const rootItem = foldersWithTags.find(
        (folder) => folder.id === question.folderOrContentId
      );
      if (isSome(rootItem)) {
        dispatch(
          setCurrentQuestionnaireRootContent({
            item: rootItem,
            type: ItemType.Folder,
          })
        );
      } else {
        dispatch(setCurrentQuestionnaireRootContent(undefined));
      }
    } else if (question.type === ItemType.Content && isSome(contentsWithTags)) {
      const rootItem = contentsWithTags.find(
        (content) => content.id === question.folderOrContentId
      );
      if (isSome(rootItem)) {
        dispatch(
          setCurrentQuestionnaireRootContent({
            item: rootItem,
            type: ItemType.Content,
          })
        );
      } else {
        dispatch(setCurrentQuestionnaireRootContent(undefined));
      }
    }
  };

  return (
    <Box h='100%' mb={5} minW='400px' overflowY={'scroll'}>
      <VStack mb={5} w='400px'>
        {questionsToAsk.map((question) => (
          <Box
            key={`root question card ${question.folderOrContentId}, ${question.templateQuestionnaireOptionId}`}
            id={`content-folder-question-card-${question.folderOrContentId}-${question.templateQuestionnaireOptionId}`}
          >
            <ContentOrFolderQuestionCard
              question={question}
              updateQuestion={updateQuestion}
              setRootItem={setRootItem}
            />
          </Box>
        ))}
      </VStack>
    </Box>
  );
};
