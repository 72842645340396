import {
  HStack,
  VStack,
  Text,
  Radio,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  RadioGroup,
  Divider,
} from '@chakra-ui/react';
import '../Popup.scss';
import { SelectionMarkOptions, TemplateTag } from '../../../objects/Template';
import { Tag } from '../../../objects/Tag';
import { TagGroup } from '../../../objects/TagGroup';
import { isSome } from '../../../config/Maybe';
import { appColors } from '../../../config/constants';
import { TemplateSettingsRadioLabels } from './TemplateSettingsRadioLabels';

interface TemplateAvailableTagListRedesignProps {
  tags: Tag[];
  selectionTags: TemplateTag[];
  setTagSelection: (tagId: string, option: SelectionMarkOptions) => void;
}

export const TemplateAvailableTagListRedesign = ({
  selectionTags,
  setTagSelection,
  tags,
}: TemplateAvailableTagListRedesignProps): JSX.Element => {
  const availableTagGroups: TagGroup[] = [];
  const ungroupedTags: Tag[] = [];
  tags.forEach((tag) => {
    if (isSome(tag.tagGroup)) {
      const exists = availableTagGroups.some(
        (group) => group.id === tag.tagGroup?.id
      );
      if (!exists) {
        availableTagGroups.push({
          ...tag.tagGroup,
          tags: tags.filter((t) => t.tagGroup?.id === tag.tagGroup?.id),
        });
      }
    } else {
      ungroupedTags.push(tag);
    }
  });

  return (
    <VStack w='100%' h='100%' justifyContent='flex-start' spacing={0}>
      {tags.length > 0 && (
        <TemplateSettingsRadioLabels
          title={
            <Heading
              as='h4'
              size='md'
              px='10px'
              height={'fit-content'}
              mb='10px'
            >
              Tags
            </Heading>
          }
        />
      )}
      {availableTagGroups
        .sort((a: TagGroup, b: TagGroup) => {
          return a.indexWithinParent - b.indexWithinParent;
        })
        .map((group) => {
          return (
            <Accordion
              allowToggle
              w='100%'
              key={`template tagGroup option: ${group.id}`}
              spacing={0}
              // This defaults the first (only) element to open
              defaultIndex={[0]}
            >
              <AccordionItem w='100%'>
                <AccordionButton
                  w='100%'
                  h='52px'
                  backgroundColor={appColors.GRAY_LIGHT}
                  px='10px'
                  alignContent='center'
                  justifyContent='space-between'
                  display='flex'
                  color={'gray.500'}
                  border='none'
                >
                  <Text>{group.name}</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel w='100%' padding='0px'>
                  {group.tags.map((tag) => {
                    const selectionOption = selectionTags.find(
                      (st) => st.tagId === tag.id
                    );
                    return (
                      <HStack
                        borderTop={'1px solid #d4d4d8'}
                        key={`template tag option: ${tag.id}`}
                        w='100%'
                        h='52px'
                        px='10px'
                        justifyContent='space-between'
                      >
                        <HStack>
                          <Text>{tag.name}</Text>
                        </HStack>
                        <RadioGroup
                          h='100%'
                          w='200px'
                          value={selectionOption?.selection ?? ''}
                        >
                          <HStack
                            h='100%'
                            px='10px'
                            w='100%'
                            justifyContent='space-between'
                          >
                            <Radio
                              colorScheme='green'
                              value={SelectionMarkOptions.Preselected}
                              onClick={() =>
                                setTagSelection(
                                  tag.id,
                                  SelectionMarkOptions.Preselected
                                )
                              }
                            />
                            <Radio
                              colorScheme='green'
                              value={SelectionMarkOptions.Available}
                              onClick={() =>
                                setTagSelection(
                                  tag.id,
                                  SelectionMarkOptions.Available
                                )
                              }
                            />
                            <Divider orientation='vertical' />
                            <Radio
                              colorScheme='green'
                              value={SelectionMarkOptions.Unavailable}
                              onClick={() =>
                                setTagSelection(
                                  tag.id,
                                  SelectionMarkOptions.Unavailable
                                )
                              }
                            />
                          </HStack>
                        </RadioGroup>
                      </HStack>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })}
      {ungroupedTags.length > 0 && (
        <Accordion
          allowToggle
          w='100%'
          spacing={0}
          // This defaults the first (only) element to open
          defaultIndex={[0]}
        >
          <AccordionItem w='100%'>
            <AccordionButton
              w='100%'
              h='52px'
              backgroundColor={appColors.GRAY_LIGHT}
              px='10px'
              alignContent='center'
              justifyContent='space-between'
              display='flex'
              color={'gray.500'}
              border='none'
            >
              <Text>Ungrouped Tags</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel w='100%' padding='0px'>
              {ungroupedTags.map((tag) => {
                const selectionOption = selectionTags.find(
                  (st) => st.tagId === tag.id
                );
                return (
                  <HStack
                    borderTop={'1px solid #d4d4d8'}
                    key={`template ungrouped tag option: ${tag.id}`}
                    w='100%'
                    h='52px'
                    px='10px'
                    justifyContent='space-between'
                  >
                    <HStack>
                      <Text>{tag.name}</Text>
                    </HStack>
                    <RadioGroup
                      h='100%'
                      w='200px'
                      value={selectionOption?.selection ?? ''}
                    >
                      <HStack
                        h='100%'
                        px='10px'
                        w='100%'
                        justifyContent='space-between'
                      >
                        <Radio
                          colorScheme='green'
                          value={SelectionMarkOptions.Preselected}
                          onClick={() =>
                            setTagSelection(
                              tag.id,
                              SelectionMarkOptions.Preselected
                            )
                          }
                        />
                        <Radio
                          colorScheme='green'
                          value={SelectionMarkOptions.Available}
                          onClick={() =>
                            setTagSelection(
                              tag.id,
                              SelectionMarkOptions.Available
                            )
                          }
                        />
                        <Divider orientation='vertical' />
                        <Radio
                          colorScheme='green'
                          value={SelectionMarkOptions.Unavailable}
                          onClick={() =>
                            setTagSelection(
                              tag.id,
                              SelectionMarkOptions.Unavailable
                            )
                          }
                        />
                      </HStack>
                    </RadioGroup>
                  </HStack>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </VStack>
  );
};
