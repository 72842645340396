/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FoldersOrContentWithTags } from '../objects/FolderOrContentItem';
import { FolderOrContentQuestion } from '../objects/Questions/FolderOrContentQuestion';
import { Questionnaire } from '../objects/Questions/Questionnaire';
import { QuestionnaireTemplateOption } from '../objects/Questions/QuestionnaireTemplateOption';
import { ITagGroupQuestion } from '../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../objects/Questions/UngroupedTagQuestion';
import { RootState } from './store';

export const questionnaireAnswers = createSlice({
  name: 'questionnaireAnswers',
  initialState: {
    selectedTemplateOption: undefined as
      | QuestionnaireTemplateOption
      | undefined,
    questionnaire: undefined as Questionnaire | undefined,
    currentRootContent: undefined as FoldersOrContentWithTags | undefined,
    rootQuestionsToAnswer: [] as FolderOrContentQuestion[],
    tagGroupQuestionsToAnswer: [] as ITagGroupQuestion[],
    ungroupedTagQuestionsToAnswer: [] as IUngroupedTagQuestion[],
  },
  reducers: {
    setQTemplateOption: (
      state,
      {
        payload: option,
      }: PayloadAction<QuestionnaireTemplateOption | undefined>
    ) => {
      state.selectedTemplateOption = option;
    },
    setquestionnaire: (
      state,
      { payload: questionnaire }: PayloadAction<Questionnaire | undefined>
    ) => {
      state.questionnaire = questionnaire;
    },
    setCurrentQuestionnaireRootContent: (
      state,
      {
        payload: folderOrContentWithTags,
      }: PayloadAction<FoldersOrContentWithTags | undefined>
    ) => {
      state.currentRootContent = folderOrContentWithTags;
    },
    setRootQuestionsToAnswer: (
      state,
      { payload: questions }: PayloadAction<FolderOrContentQuestion[]>
    ) => {
      state.rootQuestionsToAnswer = questions;
    },
    setTagGroupQuestionsToAnswer: (
      state,
      { payload: questions }: PayloadAction<ITagGroupQuestion[]>
    ) => {
      state.tagGroupQuestionsToAnswer = questions;
    },
    setUngroupedTagQuestionsToAnswer: (
      state,
      { payload: questions }: PayloadAction<IUngroupedTagQuestion[]>
    ) => {
      state.ungroupedTagQuestionsToAnswer = questions;
    },
    answerRootQuestion: (
      state,
      { payload: updatedQuestion }: PayloadAction<FolderOrContentQuestion>
    ) => {
      // We key off tqoId and fcId since some of these could have undefined ids
      return {
        ...state,
        rootQuestionsToAnswer: state.rootQuestionsToAnswer.map((question) =>
          question.folderOrContentId === updatedQuestion.folderOrContentId &&
          question.templateQuestionnaireOptionId ===
            updatedQuestion.templateQuestionnaireOptionId
            ? updatedQuestion
            : question
        ),
      };
    },
    answerGroupQuestion: (
      state,
      { payload: updatedQuestion }: PayloadAction<ITagGroupQuestion>
    ) => {
      // We key off tqoId and gId since some of these could have undefined ids
      return {
        ...state,
        tagGroupQuestionsToAnswer: state.tagGroupQuestionsToAnswer.map(
          (question) =>
            question.tagGroupId === updatedQuestion.tagGroupId &&
            question.templateQuestionnaireOptionId ===
              updatedQuestion.templateQuestionnaireOptionId
              ? updatedQuestion
              : question
        ),
      };
    },
    answerUngroupedQuestion: (
      state,
      { payload: updatedQuestion }: PayloadAction<IUngroupedTagQuestion>
    ) => {
      // We key off tqoId and tId since some of these could have undefined ids
      return {
        ...state,
        ungroupedTagQuestionsToAnswer: state.ungroupedTagQuestionsToAnswer.map(
          (question) =>
            question.tagId === updatedQuestion.tagId &&
            question.templateQuestionnaireOptionId ===
              updatedQuestion.templateQuestionnaireOptionId
              ? updatedQuestion
              : question
        ),
      };
    },
  },
});

export const selectQuestionnaireSelectedTemplateOption = (
  state: RootState
): QuestionnaireTemplateOption | undefined =>
  state.questionnaireAnswers.selectedTemplateOption;

export const selectCurrentQuestionnaireRootContent = (
  state: RootState
): FoldersOrContentWithTags | undefined =>
  state.questionnaireAnswers.currentRootContent;

export const selectquestionnaire = (
  state: RootState
): Questionnaire | undefined => state.questionnaireAnswers.questionnaire;

export const selectRootQuestionsToAnswerByTemplateOptionId = (
  state: RootState,
  templateOptionId: string
): FolderOrContentQuestion[] => {
  return state.questionnaireAnswers.rootQuestionsToAnswer.filter(
    (question) => question.templateQuestionnaireOptionId === templateOptionId
  );
};

export const selectAllRootQuestionsToAnswer = (
  state: RootState
): FolderOrContentQuestion[] => {
  return state.questionnaireAnswers.rootQuestionsToAnswer;
};

export const selectTagGroupQuestionsToAnswerByTemplateOptionId = (
  state: RootState,
  templateOptionId: string
): ITagGroupQuestion[] => {
  return state.questionnaireAnswers.tagGroupQuestionsToAnswer.filter(
    (question) => question.templateQuestionnaireOptionId === templateOptionId
  );
};

export const selectAllTagGroupQuestionsToAnswer = (
  state: RootState
): ITagGroupQuestion[] => {
  return state.questionnaireAnswers.tagGroupQuestionsToAnswer;
};

export const selectUngroupedTagQuestionsToAnswerByTemplateOptionId = (
  state: RootState,
  templateOptionId: string
): IUngroupedTagQuestion[] => {
  return state.questionnaireAnswers.ungroupedTagQuestionsToAnswer.filter(
    (question) => question.templateQuestionnaireOptionId === templateOptionId
  );
};

export const selectAllUngroupedTagQuestionsToAnswer = (
  state: RootState
): IUngroupedTagQuestion[] => {
  return state.questionnaireAnswers.ungroupedTagQuestionsToAnswer;
};

export const {
  setCurrentQuestionnaireRootContent,
  setQTemplateOption,
  setquestionnaire,
  setRootQuestionsToAnswer,
  setUngroupedTagQuestionsToAnswer,
  setTagGroupQuestionsToAnswer,
  answerRootQuestion,
  answerGroupQuestion,
  answerUngroupedQuestion,
} = questionnaireAnswers.actions;

export default questionnaireAnswers.reducer;
