import axios from 'axios';
import { isSome } from './Maybe';
import { OptionalProdGroup } from '../objects/ProdGroup';
import { getBaseUrl } from '../services/vidualsApi';
import firebase from 'firebase/app';

export async function getPresignedUrl(
  currentUser: firebase.User | null,
  selectedProductGroup: OptionalProdGroup,
  file: File
): Promise<string> {
  const token: firebase.auth.IdTokenResult | undefined =
    await currentUser?.getIdTokenResult();

  if (isSome(token?.token) && isSome(selectedProductGroup)) {
    const headers = {
      Authorization: `Bearer ${token?.token ?? ''}`,
    };
    const url = `${getBaseUrl()}/presign/generate-put`;
    // grab a string to add a key prefix for aws
    // see https://docs.aws.amazon.com/AmazonS3/latest/userguide/optimizing-performance.html
    const prefix = file.name;
    const putUrl = await axios.post(
      url,
      {
        key: `${selectedProductGroup.id}/${prefix}/${file.name}`,
        contentType: file.type,
      },
      { headers }
    );
    return putUrl.data;
  } else throw new Error('Error in generating upload URL');
}

export async function uploadFile(
  currentUser: firebase.User | null,
  selectedProductGroup: OptionalProdGroup,
  file: File
): Promise<void> {
  const putUrl = await getPresignedUrl(currentUser, selectedProductGroup, file);

  await axios.put(putUrl, file, {
    headers: { 'Content-Type': file.type },
  });
}
