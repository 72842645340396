import { Flex, Box, HStack, Heading, Text } from '@chakra-ui/react';
import { FileList } from './FileList';
import { System } from '../../objects/System';
import { isSome } from '../../config/Maybe';
import { appValues } from '../../config/constants';
import { Customizations } from '../../objects/Customizations';

interface SidebarProps {
  system?: System;
  customizations: Customizations | null | undefined;
}

export const Sidebar = ({
  system,
  customizations,
}: SidebarProps): JSX.Element => {
  return (
    <Flex
      pos='sticky'
      left='0'
      h='100%'
      w={appValues.END_USER_SIDEBAR_WIDTH}
      maxW='40vw'
      minW={300}
      flexDir='column'
      bg={
        isSome(customizations?.secondaryColor)
          ? '#' + String(customizations?.secondaryColor)
          : 'gray.700'
      }
      color={
        isSome(customizations?.secondaryTextColor)
          ? '#' + String(customizations?.secondaryTextColor)
          : 'gray.200'
      }
      overflowX='hidden'
      overflowY='scroll'
      zIndex={2}
    >
      <HStack pl={'10px'} mt={5} ml={1} alignItems='flex-start' mb={5}>
        <Box>
          <Heading as='h1' size='md' pb={1}>
            {customizations?.customTitle}
          </Heading>
          {customizations?.inclClientName === true && (
            <Text>
              {customizations?.clientNameLabel}
              <span style={{ fontWeight: 500 }}>{system?.clientName}</span>
            </Text>
          )}
          {customizations?.inclSerialNumber === true && (
            <Text>
              {customizations?.serialNumberLabel}
              <span style={{ fontWeight: 500 }}>{system?.serialNumber}</span>
            </Text>
          )}
          {customizations?.inclTemplateName === true && (
            <Text>
              {customizations?.templateNameLabel}
              <span style={{ fontWeight: 500 }}>
                {system?.lastAppliedTemplate}
              </span>
            </Text>
          )}
        </Box>
      </HStack>
      {isSome(system) && (
        <FileList system={system} customizations={customizations} />
      )}
    </Flex>
  );
};
