/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { ContentWithTags } from '../objects/ContentWithTags';
import { FolderOrContentItem } from '../objects/FolderOrContentItem';
import { FolderWithTags } from '../objects/FolderWithTags';
import {
  ProdGroup,
  NewProdGroupParams,
  UpdateProdGroupParams,
} from '../objects/ProdGroup';
import {
  setContentsWithTags,
  setFoldersWithTags,
} from '../store/foldersAndContentsWithTagsSlice';
import {
  setCurrentProductGroup,
  setProductGroups,
  updateProductGroup,
} from '../store/productGroupSlice';
import { setTreeRootFoldersAndContent } from '../store/treeItemSlice';
import {
  ContentsWithTagsList,
  FoldersWithTagsList,
  IOnQueryAPI,
  ProdGroupListCacheId,
  vidualsApi,
} from './vidualsApi';

const updateProductGroupState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;

    dispatch(setProductGroups(data));
    dispatch(setCurrentProductGroup(data[0] ?? null));
  } catch (err) {
    console.trace(err);
  }
};

const updateProductGroupOnState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;

    dispatch(updateProductGroup(data));
  } catch (err) {
    console.trace(err);
  }
};

const setRootFoldersAndContent = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;

    dispatch(setTreeRootFoldersAndContent(data));
  } catch (err) {
    console.trace(err);
  }
};

const setContentsWithTagsState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;

    dispatch(setContentsWithTags(data));
  } catch (err) {
    console.trace(err);
  }
};

const setFoldersWithTagsState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;

    dispatch(setFoldersWithTags(data));
  } catch (err) {
    console.trace(err);
  }
};

export const prodGroupsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    createProdGroup: builder.mutation<ProdGroup, NewProdGroupParams>({
      query: (prodGroupInput) => {
        return {
          url: `productGroups`,
          method: 'POST',
          body: prodGroupInput,
        };
      },
      invalidatesTags: [ProdGroupListCacheId],
    }),
    updateProdGroup: builder.mutation<ProdGroup, UpdateProdGroupParams>({
      query: (prodGroupInput) => {
        return {
          url: `productGroups`,
          method: 'PUT',
          body: prodGroupInput,
        };
      },
      onQueryStarted: updateProductGroupOnState,
      invalidatesTags: [ProdGroupListCacheId],
    }),
    getProdGroups: builder.query<ProdGroup[], string>({
      query: (orgId) => `productGroups/organization/${orgId}`,
      onQueryStarted: updateProductGroupState,
      providesTags: [ProdGroupListCacheId],
    }),
    getFoldersWithTagsByProductGroupId: builder.query<FolderWithTags[], string>(
      {
        query: (pgid) => `productGroups/${pgid}/foldersWithTags`,
        onQueryStarted: setFoldersWithTagsState,
        providesTags: [FoldersWithTagsList],
      }
    ),
    getRootContentWithTagsByProductGroupId: builder.query<
      ContentWithTags[],
      string
    >({
      query: (pgid) => `productGroups/${pgid}/contentWithTags`,
      onQueryStarted: setContentsWithTagsState,
      providesTags: [ContentsWithTagsList],
    }),
    getRootFoldersAndContent: builder.query<FolderOrContentItem[], string>({
      query: (pgid) => `productGroups/${pgid}/rootFoldersAndContent`,
      onQueryStarted: setRootFoldersAndContent,
    }),
    getFoldersAndContentInFolder: builder.query<
      FolderOrContentItem[],
      { pgid: string; fid: string }
    >({
      query: ({ pgid, fid }) =>
        `productGroups/${pgid}/foldersAndContentInFolder/${fid}`,
    }),
    getProductGroupByUrl: builder.query<
      ProdGroup,
      { pgUrl: string; orgUrl: string }
    >({
      query: ({ pgUrl, orgUrl }) =>
        `productGroups/${pgUrl}/organization/${orgUrl}`,
    }),
  }),
});

export const {
  useCreateProdGroupMutation,
  useLazyGetRootFoldersAndContentQuery,
  useLazyGetProdGroupsQuery,
  useGetProductGroupByUrlQuery,
  useLazyGetFoldersWithTagsByProductGroupIdQuery,
  useLazyGetRootContentWithTagsByProductGroupIdQuery,
  useUpdateProdGroupMutation,
} = prodGroupsApi;
