import { SideNavBar } from '../../sharedComponents/sideNavBar/SideNavBar';
import './QuestionnairePage.scss';
import { Box, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { appValues } from '../../config/constants';
import {
  useAddQTemplateOptionMutation,
  useGetQuestionnaireByProdGroupQuery,
} from '../../services/questionnaireEndpoints';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { isSome } from '../../config/Maybe';
import { QuestionnaireCard } from './QuestionCards/QuestionnaireCard';
import { selectQTemplates } from '../../store/qTemplateSlice';
import { InputQuestionnaireTemplateOption } from '../../objects/Questions/QuestionnaireTemplateOption';
import { useEffect } from 'react';
import { QuestionnairePageHeader } from './QuestionnairePageHeader';
import {
  selectCurrentQuestionnaireRootContent,
  selectQuestionnaireTemplateOptionToConfigure,
  selectQuestionnaireToConfigure,
  setQuestionnaireToConfigure,
} from '../../store/questionnaireConfigurationSlice';
import { ContentFolderQuestionCardList } from './ContentFolderQuestionCardList';
import { TagQuestionCardList } from './TagQuestionCardList';

export const QuestionnairePage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [addQTemplateOption] = useAddQTemplateOptionMutation();
  const allQTemplates = useAppSelector(selectQTemplates);
  const templateOptionToConfigure = useAppSelector(
    selectQuestionnaireTemplateOptionToConfigure
  );
  const questionnaireToConfigure = useAppSelector(
    selectQuestionnaireToConfigure
  );
  const currentRootContent = useAppSelector(
    selectCurrentQuestionnaireRootContent
  );
  // const loading = useAppSelector(selectQuestionnaireSaveLoading);

  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const { data: questionnaire } = useGetQuestionnaireByProdGroupQuery(
    selectedProductGroup?.id ?? '',
    {
      skip: !isSome(selectedProductGroup),
    }
  );

  const toggleTemplateChecked = (templateId: string): void => {
    if (isSome(questionnaireToConfigure)) {
      dispatch(
        setQuestionnaireToConfigure({
          ...questionnaireToConfigure,
          questionnaireTemplateOptions:
            questionnaireToConfigure.questionnaireTemplateOptions.map(
              (tOption) => {
                if (tOption.questionnaireTemplateId === templateId) {
                  const available = !tOption.available;
                  return {
                    ...tOption,
                    available,
                  };
                } else {
                  return tOption;
                }
              }
            ),
        })
      );
    }
  };

  const updateInputQuestionnaire = (
    text: string,
    helpText: string,
    imageUrl: string | null | undefined,
    file: File | string | undefined
  ): void => {
    if (isSome(questionnaireToConfigure)) {
      let objectUrl: string | undefined = '';
      let objectName: string | undefined = '';
      if (typeof file === 'string') {
        objectUrl = file;
        objectName = questionnaireToConfigure.imageFileObjectName;
      } else if (isSome(file)) {
        objectUrl = URL.createObjectURL(file as Blob);
        objectName = file.name;
      } else {
        objectUrl = undefined;
        objectName = undefined;
      }
      dispatch(
        setQuestionnaireToConfigure({
          ...questionnaireToConfigure,
          text,
          helpText,
          imageUrl,
          imageFileObjectUrl: objectUrl,
          imageFileObjectName: objectName,
        })
      );
    }
  };

  useEffect(() => {
    if (isSome(questionnaire)) {
      const unusedTemplates = allQTemplates.filter(
        (qTemplate) =>
          !questionnaire.questionnaireTemplateOptions.some(
            (qTO): boolean => qTO.questionnaireTemplateId === qTemplate.id
          )
      );

      const newQTemplateOptions: InputQuestionnaireTemplateOption[] =
        unusedTemplates.map((unusedTemplate) => {
          return {
            questionnaireId: questionnaire.id,
            questionnaireTemplateId: unusedTemplate.id,
            available: false,
          };
        });
      if (newQTemplateOptions.length > 0) {
        addQTemplateOption(newQTemplateOptions)
          .then()
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        dispatch(setQuestionnaireToConfigure(questionnaire));
      }
    }
  }, [allQTemplates, questionnaire]);

  // const xarrowProps = {
  //   color: appColors.GREEN_DARKER,
  //   strokeWidth: 1,
  //   showHead: false,
  // };

  return (
    <Flex h='100vh' w='100vw'>
      <SideNavBar />
      <Flex
        className='main'
        ml={appValues.SIDEBAR_WIDTH}
        w={`calc(100vw - ${appValues.SIDEBAR_WIDTH}px)`}
      >
        <Stack w='100%' h='100%'>
          <QuestionnairePageHeader />
          <Heading as='h1'>Questionnaire</Heading>
          <Text as='h4'>
            Select a template to customize the sequence and wording of the
            questions.
          </Text>
          {isSome(questionnaireToConfigure) && (
            <HStack
              spacing={20}
              alignItems='flex-start'
              w='100%'
              overflowX={'scroll'}
              h='100%'
            >
              <Box id='questionnaire-card'>
                <QuestionnaireCard
                  updateInputQuestionnaire={updateInputQuestionnaire}
                  toggleTemplateChecked={toggleTemplateChecked}
                  questionnaire={questionnaireToConfigure}
                />
              </Box>
              {isSome(templateOptionToConfigure) && (
                <ContentFolderQuestionCardList
                  templateOption={templateOptionToConfigure}
                />
              )}
              {/* {isSome(templateOptionToConfigure) && !loading && (
                <QuestionnaireWarningHandler
                  templateOption={templateOptionToConfigure}
                />
              )} */}
              {/* {questionsToAsk.map((question, index) => {
                return (
                  <Xarrow
                    key={`item: ${question.id ?? ''} index: ${index}`}
                    start={'questionnaire-card'}
                    end={`content-folder-question-card-${question.folderOrContentId}-${question.templateQuestionnaireOptionId}`}
                    startAnchor='right'
                    endAnchor='left'
                    {...xarrowProps}
                  />
                );
              })} */}
              {isSome(templateOptionToConfigure) &&
                isSome(currentRootContent) && (
                  <TagQuestionCardList
                    templateOption={templateOptionToConfigure}
                    activeRootItem={currentRootContent}
                  />
                )}
            </HStack>
          )}
        </Stack>
      </Flex>
    </Flex>
  );
};
