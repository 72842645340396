import { useState } from 'react';
import {
  Box,
  Button,
  Text,
  IconButton,
  Stack,
  HStack,
  Spacer,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Drawer,
  DrawerContent,
  Heading,
  DrawerOverlay,
  DrawerFooter,
  DrawerBody,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { TextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import { isSome } from '../../../config/Maybe';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import {
  selectOrgToEdit,
  selectUserToEdit,
  setUserToEdit,
} from '../../../store/modalFormSlice';
import { User } from '../../../objects/User';
import { DeleteOrgPopup } from './DeleteOrgPopup';
import {
  useAddUsersToOrgMutation,
  useGetUsersForOrgQuery,
  useUpdateOrgMutation,
} from '../../../services/orgEndpoints';
import { UserItem } from '../../orgs/UserItem';

interface EditOrgPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditOrgPopup = ({
  isOpen,
  onClose,
}: EditOrgPopupProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>();
  const [addUsersToOrg] = useAddUsersToOrgMutation();

  const dispatch = useAppDispatch();

  const userToEdit = useAppSelector(selectUserToEdit);
  const orgToEdit = useAppSelector(selectOrgToEdit);

  const { data: orgUsers } = useGetUsersForOrgQuery(
    orgToEdit !== undefined ? orgToEdit.id : ''
  );

  const [updateOrg] = useUpdateOrgMutation();

  const orgNameRegister = register('orgName', {
    required: true,
    value: isSome(orgToEdit) ? orgToEdit.name : '',
  });
  const orgNameText = watch('orgName');
  const orgNotesRegister = register('notes', {
    value: isSome(orgToEdit) ? orgToEdit.notes : '',
  });
  const orgNotesText = watch('notes');

  const onSubmit = async (temporary: any): Promise<void> => {
    if (isSome(orgToEdit)) {
      if (isSome(temporary.userEmail) && isSome(temporary.userName)) {
        await addUsersToOrg({
          ...orgToEdit,
          users: [{ name: temporary.userName, email: temporary.userEmail }],
        });
      }

      const orgValues = {
        name: temporary.orgName,
        notes: temporary.notes,
      };

      const updatedOrgValues = { id: orgToEdit.id, ...orgValues };
      await updateOrg(updatedOrgValues).unwrap();

      reset();
      onClose();
    }
  };

  const [showMemberInput, setShowMemberInput] = useState(false);
  const memberIssue =
    (isSome(errors.userEmail) && errors.userEmail.type === 'required') ||
    (isSome(errors.userName) && errors.userName.type === 'required');

  const {
    isOpen: isOpenDeleteOrgPopup,
    onOpen: onOpenDeleteOrgPopup,
    onClose: onCloseDeleteOrgPopup,
  } = useDisclosure();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size='lg'
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DrawerContent py={5}>
          <DrawerBody>
            <Stack spacing={4}>
              <Heading as='h1' size='md' textAlign='center'>
                Edit an organization
              </Heading>
              <Box>
                <Heading as='h2' size='md' mb={5}>
                  Info
                </Heading>
                <TextInput
                  placeholder='Organization name'
                  register={orgNameRegister}
                  text={orgNameText}
                />
                {isSome(errors.orgName) &&
                  errors.orgName.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter an organization name. Please try again.
                    </Text>
                  )}
                <TextInput
                  placeholder='Notes (optional; for internal purposes only)'
                  register={orgNotesRegister}
                  text={orgNotesText}
                />
              </Box>
              <Box>
                <HStack my={5}>
                  <Heading as='h2' size='md'>
                    Members
                  </Heading>
                  <Spacer />
                  <Button
                    disabled={showMemberInput || isSome(userToEdit)}
                    onClick={() => setShowMemberInput(true)}
                  >
                    Add new member
                  </Button>
                </HStack>
                <Table variant='simple' size='sm'>
                  <Thead bg='gray.100'>
                    <Tr>
                      <Th>Email</Th>
                      <Th>Name</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {orgUsers?.map((user) => {
                      return <UserItem key={user.id} user={user} />;
                    })}
                    {showMemberInput && (
                      <Tr>
                        <Td>
                          <Input
                            {...register('userEmail', {
                              required: true,
                              value: '',
                            })}
                            type='text'
                          />
                        </Td>
                        <Td>
                          <Input
                            {...register('userName', {
                              required: true,
                              value: '',
                            })}
                            type='text'
                          />
                        </Td>
                        <Td>
                          <IconButton
                            aria-label='delete member'
                            textAlign='right'
                            color='red.700'
                            border='none'
                            onClick={() => setShowMemberInput(false)}
                            icon={
                              <FontAwesomeIcon
                                className={(classNames.icon, classNames.faIcon)}
                                icon={faTrashAlt}
                              />
                            }
                          />
                        </Td>
                      </Tr>
                    )}
                    {isSome(userToEdit) && (
                      <Tr>
                        <Td>
                          <Input
                            {...register('userEmail', {
                              required: true,
                              value: userToEdit?.email ?? '',
                            })}
                            type='text'
                          />
                        </Td>
                        <Td>
                          <Input
                            {...register('userName', {
                              required: true,
                              value: userToEdit?.displayName ?? '',
                            })}
                            type='text'
                          />
                        </Td>
                        <Td>
                          <IconButton
                            aria-label='delete member'
                            textAlign='right'
                            color='red.700'
                            border='none'
                            onClick={() =>
                              dispatch(
                                setUserToEdit(undefined as User | undefined)
                              )
                            }
                            icon={
                              <FontAwesomeIcon
                                className={(classNames.icon, classNames.faIcon)}
                                icon={faTrashAlt}
                              />
                            }
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {showMemberInput && memberIssue && (
                  <Text variant={'error'}>
                    You must enter an email and a name to add a member. If you
                    do not wish to add a member, please delete the input.
                  </Text>
                )}
              </Box>
            </Stack>
            <Button
              w='100%'
              px={5}
              mt={5}
              variant='delete'
              onClick={() => {
                onOpenDeleteOrgPopup();
              }}
              leftIcon={
                <FontAwesomeIcon
                  className={(classNames.icon, classNames.faIcon)}
                  icon={faTrashAlt}
                />
              }
            >
              Delete this organization
            </Button>
          </DrawerBody>
          <DrawerFooter justifyContent='space-evenly'>
            <Button
              w='45%'
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Input
              type='submit'
              value='Save'
              w='45%'
              bg='viduals.greenDarker'
              color='white'
              fontWeight={500}
            />
          </DrawerFooter>
        </DrawerContent>
      </form>
      <DeleteOrgPopup
        isOpen={isOpenDeleteOrgPopup}
        onClose={onCloseDeleteOrgPopup}
        onCloseParent={onClose}
      />
    </Drawer>
  );
};
const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
