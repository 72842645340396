export enum SelectionMarkOptions {
  Preselected = 'Preselected',
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export interface TemplateFolder {
  selection: SelectionMarkOptions;
  folderId: string;
}

export interface TemplateContent {
  selection: SelectionMarkOptions;
  contentId: string;
}

export interface TemplateTag {
  selection: SelectionMarkOptions;
  tagId: string;
}

export interface Template {
  id: string;
  name: string;
  imageUrl?: string;
  templateFolders: TemplateFolder[];
  templateContents: TemplateContent[];
  templateTags: TemplateTag[];
}

export interface InputTemplateParams {
  name: string;
  productGroupId: string;
  imageUrl?: string;
}

export interface UpdateTemplateParams extends InputTemplateParams {
  id: string;
}

export interface ITemplateWithFoldersContentsAndTags {
  id: string;
  folders: TemplateFolder[];
  contents: TemplateContent[];
  tags: TemplateTag[];
}
