import { Box, HStack, Image } from '@chakra-ui/react';
import { Customizations } from '../../objects/Customizations';
import { isSome } from '../../config/Maybe';
import { buildFileGetUrlByProdGroup } from '../../config/S3Client';
import { useEndUserContextContext } from '../../contexts/EndUserContext';

interface HeaderLargeProps {
  customizations: Customizations | null | undefined;
}

export const HeaderLarge = ({
  customizations,
}: HeaderLargeProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  const backgroundImage = isSome(customizations)
    ? buildFileGetUrlByProdGroup(
        productGroup,
        customizations?.coverImageName ?? ''
      )
    : '';

  return (
    <HStack
      bg={'var(--vid-customizations-secondary-color)'}
      h='400px'
      w='100%'
      px={10}
      alignItems='flex-start'
      justifyContent='space-between'
      bgImage={backgroundImage}
      bgSize={'cover'}
    >
      <Box>
        <Image
          src={buildFileGetUrlByProdGroup(
            productGroup,
            customizations?.logoImageName ?? ''
          )}
          maxW='30vw'
          minW={250}
        />
      </Box>
    </HStack>
  );
};
