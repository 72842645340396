import { isSome } from '../../../config/Maybe';
import { Customizations } from '../../../objects/Customizations';
import { QuestionnaireTemplateOption } from '../../../objects/Questions/QuestionnaireTemplateOption';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectAllRootQuestionsToAnswer,
  selectAllTagGroupQuestionsToAnswer,
  selectAllUngroupedTagQuestionsToAnswer,
  setRootQuestionsToAnswer,
  setTagGroupQuestionsToAnswer,
  setUngroupedTagQuestionsToAnswer,
} from '../../../store/questionnaireAnswers';
import { selectQTemplateById } from '../../../store/qTemplateSlice';
import {
  selectFoldersWithTags,
  selectContentsWithTags,
} from '../../../store/foldersAndContentsWithTagsSlice';
import {
  useGetRootQuestionsForTemplateOptionQuery,
  useGetTagGroupQuestionsForTemplateOptionQuery,
  useGetUngroupedTagQuestionsForTemplateOptionQuery,
} from '../../../services/questionnaireEndpoints';
import { useEffect, useState } from 'react';
import {
  QTemplate,
  QTemplateOptions,
} from '../../../objects/QuestionnaireTemplate';
import { ContentWithTags } from '../../../objects/ContentWithTags';
import { FolderWithTags } from '../../../objects/FolderWithTags';
import {
  FolderOrContentQuestion,
  RootQuestionWithSubQuestions,
} from '../../../objects/Questions/FolderOrContentQuestion';
import { ItemType } from '../../../objects/FolderOrContentItem';
import { selectTags } from '../../../store/tagSlice';
import { selectTagGroups } from '../../../store/tagGroupSlice';
import { ITagGroupQuestion } from '../../../objects/Questions/TagGroupQuestion';
import { Tag } from '../../../objects/Tag';
import { IUngroupedTagQuestion } from '../../../objects/Questions/UngroupedTagQuestion';
import { QuestionList } from './QuestionList';
import { useEndUserContextContext } from '../../../contexts/EndUserContext';

interface QuestionnaireForTemplateProps {
  customizations?: Customizations;
  qtemplateOption: QuestionnaireTemplateOption;
  pGroupId: string;
}

export const QuestionnaireForTemplate = ({
  customizations,
  qtemplateOption,
  pGroupId,
}: QuestionnaireForTemplateProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  const dispatch = useAppDispatch();
  const qTemplateForOption = useAppSelector((state) =>
    selectQTemplateById(state, qtemplateOption.questionnaireTemplateId)
  );
  const allRootQuestions = useAppSelector(selectAllRootQuestionsToAnswer);
  const allGroupQuestions = useAppSelector(selectAllTagGroupQuestionsToAnswer);
  const allUngroupedQuestions = useAppSelector(
    selectAllUngroupedTagQuestionsToAnswer
  );
  const allTags = useAppSelector(selectTags);
  const allTagGroups = useAppSelector(selectTagGroups);
  const foldersWithTags = useAppSelector(selectFoldersWithTags);
  const contentsWithTags = useAppSelector(selectContentsWithTags);

  const [allSortedQuestions, setAllSortedQuestions] = useState<
    Array<FolderOrContentQuestion | ITagGroupQuestion | IUngroupedTagQuestion>
  >([]);

  const { data: savedGroupQuestions, isLoading: savedGroupQuestionsLoading } =
    useGetTagGroupQuestionsForTemplateOptionQuery(qtemplateOption.id);
  const {
    data: savedUngroupedTagQuestions,
    isLoading: savedTagQuestionsLoading,
  } = useGetUngroupedTagQuestionsForTemplateOptionQuery(qtemplateOption.id);
  const { data: savedRootQuestions, isLoading: savedRootQuestionsLoading } =
    useGetRootQuestionsForTemplateOptionQuery(qtemplateOption.id);

  useEffect(() => {
    if (
      isSome(qTemplateForOption) &&
      isSome(savedRootQuestions) &&
      !savedRootQuestionsLoading
    ) {
      const necessaryContentQuestions = getAllNecessaryContentQuestions(
        qTemplateForOption,
        contentsWithTags
      );

      const necessaryFolderQuestions = getAllNecessaryFolderQuestions(
        qTemplateForOption,
        foldersWithTags
      );

      const allRootQuestions: FolderOrContentQuestion[] = [
        ...necessaryContentQuestions,
        ...necessaryFolderQuestions,
      ].sort((a, b) => (a.indexWithinParent ?? 0) - (b.indexWithinParent ?? 0));

      // We build all necessary questions first in case the template has changed
      // and the user did not update the questionnaire
      const allRootQuestionsForTemplate = substituteSavedQuestions(
        allRootQuestions,
        savedRootQuestions
      );

      dispatch(setRootQuestionsToAnswer(allRootQuestionsForTemplate));
    }
  }, [
    foldersWithTags,
    contentsWithTags,
    qTemplateForOption,
    savedRootQuestions,
    qtemplateOption,
  ]);

  useEffect(() => {
    if (
      isSome(qTemplateForOption) &&
      isSome(savedGroupQuestions) &&
      isSome(savedUngroupedTagQuestions) &&
      !savedGroupQuestionsLoading &&
      !savedTagQuestionsLoading
    ) {
      const optionalTags = getAllOptionalTags(qTemplateForOption);
      const optionalUngroupedTags = optionalTags.filter(
        (tag) => !isSome(tag.tagGroup)
      );
      const optionalGroupedTags = optionalTags.filter((tag) =>
        isSome(tag.tagGroup)
      );

      const allNecessaryTagGroupIds = optionalGroupedTags.map(
        (tag) => tag.tagGroupId
      ) as string[];
      const uniqueGroupIds = [...new Set(allNecessaryTagGroupIds)];

      const necessaryGroups = allTagGroups
        .filter((tg) => uniqueGroupIds.some((tgId) => tgId === tg.id))
        .sort(
          (a, b) => (a.indexWithinParent ?? 0) - (b.indexWithinParent ?? 0)
        );

      const necessaryGroupQuestions = necessaryGroups.map(
        (group): ITagGroupQuestion => {
          const tagOptions = optionalGroupedTags.filter(
            (to) => to.tagGroupId === group.id
          );
          return {
            text: `Which ${group.name} do you have?`,
            helpText: '',
            templateQuestionnaireOptionId: qtemplateOption.id,
            tagGroupId: group.id,
            tagOptions,
          };
        }
      );
      const necessaryUngroupedQuestions = optionalUngroupedTags.map(
        (tag): IUngroupedTagQuestion => {
          return {
            text: `Do you have a(n) ${tag.name}?`,
            helpText: '',
            templateQuestionnaireOptionId: qtemplateOption.id,
            tagId: tag.id,
          };
        }
      );

      const newAndExistingGroupQuestions = substituteSavedGroupQuestions(
        necessaryGroupQuestions,
        savedGroupQuestions
      );
      const newAndExistingUngroupedQuestions =
        substituteSavedUngroupedQuestions(
          necessaryUngroupedQuestions,
          savedUngroupedTagQuestions
        );

      dispatch(setTagGroupQuestionsToAnswer(newAndExistingGroupQuestions));
      dispatch(
        setUngroupedTagQuestionsToAnswer(newAndExistingUngroupedQuestions)
      );
    }
  }, [
    savedGroupQuestions,
    savedUngroupedTagQuestions,
    qtemplateOption,
    qTemplateForOption,
  ]);

  useEffect(() => {
    const usedTagOrGroupIds: string[] = [];
    const rootQuestionsWithSubQuestions = allRootQuestions.map(
      (rootQuestion): RootQuestionWithSubQuestions => {
        if (rootQuestion.type === ItemType.Content) {
          const content = contentsWithTags.find(
            (cwt) => cwt.id === rootQuestion.folderOrContentId
          );
          if (isSome(content)) {
            return {
              ...rootQuestion,
              subQuestions: getSubquestionsForRootQuestion(
                content,
                usedTagOrGroupIds
              ),
            };
          }
        } else {
          const folder = foldersWithTags.find(
            (fwt) => fwt.id === rootQuestion.folderOrContentId
          );
          if (isSome(folder)) {
            return {
              ...rootQuestion,
              subQuestions: getSubquestionsForRootQuestion(
                folder,
                usedTagOrGroupIds
              ),
            };
          }
        }
        throw new Error('Cannot handle question of unkown type');
      }
    );
    const allQuestionsInOrder: Array<
      FolderOrContentQuestion | ITagGroupQuestion | IUngroupedTagQuestion
    > = [];
    rootQuestionsWithSubQuestions.forEach((rq) => {
      if (rq.mandatory !== true) {
        allQuestionsInOrder.push(rq);
      }
      rq.subQuestions.forEach((sq) => {
        if (sq.mandatory !== true) {
          allQuestionsInOrder.push(sq);
        }
      });
    });
    setAllSortedQuestions(allQuestionsInOrder);
  }, [allRootQuestions, allGroupQuestions, allUngroupedQuestions]);

  const getSubquestionsForRootQuestion = (
    item: ContentWithTags | FolderWithTags,
    usedTagOrGroupIds: string[]
  ): Array<ITagGroupQuestion | IUngroupedTagQuestion> => {
    const subQuestions: Array<ITagGroupQuestion | IUngroupedTagQuestion> = [];
    allGroupQuestions.forEach((groupQuestion) => {
      // if the item has a tag belonging to the group,
      // and the group question isn't already assigned elsewhere
      if (
        item.availableTags.some(
          (tag) =>
            groupQuestion.tagOptions?.some((to) => to.id === tag.id) &&
            !usedTagOrGroupIds.includes(groupQuestion.tagGroupId)
        )
      ) {
        // ensure tags in right order
        const groupQuestionToAdd: ITagGroupQuestion = {
          ...groupQuestion,
          tagOptions: [...(groupQuestion.tagOptions ?? [])].sort(
            (tag1, tag2) => tag1.indexWithinParent - tag2.indexWithinParent
          ),
        };
        subQuestions.push(groupQuestionToAdd);
        usedTagOrGroupIds.push(groupQuestion.tagGroupId);
      }
    });
    allUngroupedQuestions.forEach((ungroupedQuestion) => {
      // if the item has a tag belonging to the group,
      // and the group question isn't already assigned elsewhere
      if (
        item.availableTags.some(
          (tag) =>
            ungroupedQuestion.tagId === tag.id &&
            !usedTagOrGroupIds.includes(ungroupedQuestion.tagId)
        )
      ) {
        subQuestions.push(ungroupedQuestion);
        usedTagOrGroupIds.push(ungroupedQuestion.tagId);
      }
    });
    return subQuestions;
  };

  const getAllNecessaryContentQuestions = (
    qTemplate: QTemplate,
    contentsWithTags: ContentWithTags[]
  ): FolderOrContentQuestion[] => {
    return qTemplate.qTemplateContents
      .filter(
        (qtContent) =>
          qtContent.selection === QTemplateOptions.Optional ||
          qtContent.selection === QTemplateOptions.Mandatory
      )
      .map((qtContent): FolderOrContentQuestion => {
        const content = contentsWithTags.find(
          (cwt) => cwt.id === qtContent.contentId
        );
        if (!isSome(content)) {
          return {
            text: `This is an error`,
            helpText: '',
            templateQuestionnaireOptionId: '',
            folderOrContentId: '',
            type: ItemType.Content,
          };
        }
        return {
          text: `Does your ${
            productGroup?.configurationName ?? 'configuration'
          } have a ${content.name}?`,
          helpText: '',
          templateQuestionnaireOptionId: qtemplateOption.id,
          folderOrContentId: content.id,
          type: ItemType.Content,
          indexWithinParent: content.indexWithinParent,
          mandatory: qtContent.selection === QTemplateOptions.Mandatory,
          unsaved: true,
        };
      })
      .filter((question) => isSome(question.indexWithinParent));
  };

  const getAllOptionalTags = (qTemplate: QTemplate): Tag[] => {
    return qTemplate.qTemplateTags
      .filter((qtTag) => qtTag.selection === QTemplateOptions.Optional)
      .map((qtTag): Tag | undefined => {
        return allTags.find((t) => t.id === qtTag.tagId);
      })
      .filter((tag) => isSome(tag)) as Tag[];
  };

  const getAllNecessaryFolderQuestions = (
    qTemplate: QTemplate,
    foldersWithTags: FolderWithTags[]
  ): FolderOrContentQuestion[] => {
    return qTemplate.qTemplateFolders
      .filter(
        (qtFolder) =>
          qtFolder.selection === QTemplateOptions.Optional ||
          qtFolder.selection === QTemplateOptions.Mandatory
      )
      .map((qtFolder): FolderOrContentQuestion => {
        const folder = foldersWithTags.find(
          (fwt) => fwt.id === qtFolder.folderId
        );
        if (!isSome(folder)) {
          return {
            text: `This is an error`,
            helpText: '',
            templateQuestionnaireOptionId: '',
            folderOrContentId: '',
            type: ItemType.Folder,
          };
        }
        return {
          text: `Does your ${
            productGroup?.configurationName ?? 'configuration'
          } have a ${folder.name}?`,
          helpText: '',
          templateQuestionnaireOptionId: qtemplateOption.id,
          folderOrContentId: folder.id,
          type: ItemType.Folder,
          indexWithinParent: folder.indexWithinParent,
          mandatory: qtFolder.selection === QTemplateOptions.Mandatory,
          unsaved: true,
        };
      })
      .filter((question) => isSome(question.indexWithinParent));
  };

  const substituteSavedQuestions = (
    allRootQuestions: FolderOrContentQuestion[],
    savedQuestions: FolderOrContentQuestion[]
  ): FolderOrContentQuestion[] => {
    return allRootQuestions.map((question) => {
      const existingQuestion = savedQuestions.find(
        (sQuestion) =>
          sQuestion.folderOrContentId === question.folderOrContentId &&
          sQuestion.templateQuestionnaireOptionId ===
            question.templateQuestionnaireOptionId
      );
      if (isSome(existingQuestion)) {
        return existingQuestion;
      }
      return question;
    });
  };

  const substituteSavedGroupQuestions = (
    necessaryGroupQuestions: ITagGroupQuestion[],
    savedGroupQuestions: ITagGroupQuestion[]
  ): ITagGroupQuestion[] => {
    return necessaryGroupQuestions.map((question) => {
      const existingQuestion = savedGroupQuestions.find(
        (sQuestion) =>
          sQuestion.tagGroupId === question.tagGroupId &&
          sQuestion.templateQuestionnaireOptionId ===
            question.templateQuestionnaireOptionId
      );
      if (isSome(existingQuestion)) {
        return { ...existingQuestion, tagOptions: question.tagOptions };
      }
      return question;
    });
  };

  const substituteSavedUngroupedQuestions = (
    necessaryUngroupedQuestions: IUngroupedTagQuestion[],
    savedUngroupedTagQuestions: IUngroupedTagQuestion[]
  ): IUngroupedTagQuestion[] => {
    return necessaryUngroupedQuestions.map((question) => {
      const existingQuestion = savedUngroupedTagQuestions.find(
        (sQuestion) =>
          sQuestion.tagId === question.tagId &&
          sQuestion.templateQuestionnaireOptionId ===
            question.templateQuestionnaireOptionId
      );
      if (isSome(existingQuestion)) {
        return existingQuestion;
      }
      return question;
    });
  };

  const updateQuestion = (
    updatedQuestion:
      | FolderOrContentQuestion
      | ITagGroupQuestion
      | IUngroupedTagQuestion
  ): void => {
    setAllSortedQuestions((current) =>
      current.map((item) =>
        item.id === updatedQuestion.id ? updatedQuestion : item
      )
    );
  };

  return (
    <>
      {isSome(qTemplateForOption) && (
        <QuestionList
          customizations={customizations}
          allQuestions={allSortedQuestions}
          updateQuestion={updateQuestion}
          pGroupId={pGroupId}
          qTemplate={qTemplateForOption}
        />
      )}
    </>
  );
};
