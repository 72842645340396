import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Customizations } from '../../../objects/Customizations';
import { useAppDispatch } from '../../../store/hooks';
import { QSystemContentFrame } from './QSystemContentFrame';
import { QSystemContentInfo } from './QSystemContentInfo';
import { setVersionToDisplay } from '../../../store/endUserSlice';

interface QSystemContentViewerProps {
  prodGroupId: string;
  customizations?: Customizations;
}

export const QSystemContentViewer = ({
  prodGroupId,
  customizations,
}: QSystemContentViewerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setVersionToDisplay());
  }, []);

  return (
    <Box mx={10} w='100%' overflowY='hidden'>
      <QSystemContentFrame
        prodGroupId={prodGroupId}
        customizations={customizations}
      />
      <QSystemContentInfo
        prodGroupId={prodGroupId}
        customizations={customizations}
      />
    </Box>
  );
};
