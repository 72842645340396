import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Textarea,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FolderIcon, ImageIcon, PreviewIcon } from '../../../config/icons';
import { isNone, isSome } from '../../../config/Maybe';
import { FoldersOrContentWithTags } from '../../../objects/FolderOrContentItem';
import { ITagGroupQuestion } from '../../../objects/Questions/TagGroupQuestion';
import { TagGroup } from '../../../objects/TagGroup';
import { Tag } from '../../../objects/Tag';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectQuestionnaireTemplateOptionToConfigure,
  updateGroupQuestion,
} from '../../../store/questionnaireConfigurationSlice';
import { selectGroupById } from '../../../store/tagGroupSlice';
import { selectTagsInGroup } from '../../../store/tagSlice';
import { selectOptionalOrMandatoryTagsForQTemplateById } from '../../../store/qTemplateSlice';
import { GroupQuestionTagOption } from './GroupQuestionTagOption';
import { QTemplateOptions } from '../../../objects/QuestionnaireTemplate';
import { useGetUsedQuestionnaireImagesQuery } from '../../../services/questionnaireEndpoints';
import Select from 'react-select';
import { ImagePreview } from '../../../components/ImagePreview';
import { buildFileGetQuestionImageUrlByProdGroup } from '../../../config/S3Client';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';

interface SelectInterface {
  value: string;
  label: string;
}

interface TagGroupQuestionCardProps {
  question: ITagGroupQuestion;
  activeRootItem: FoldersOrContentWithTags;
  groupQuestionsOnSlice: ITagGroupQuestion[];
}

export const TagGroupQuestionCard = ({
  question,
  activeRootItem,
  groupQuestionsOnSlice,
}: TagGroupQuestionCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const templateOptionToConfigure = useAppSelector(
    selectQuestionnaireTemplateOptionToConfigure
  );
  const qTemplateTags = useAppSelector((state) =>
    selectOptionalOrMandatoryTagsForQTemplateById(
      state,
      templateOptionToConfigure?.questionnaireTemplateId ?? ''
    )
  );
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const group: TagGroup | undefined = useAppSelector((state) =>
    selectGroupById(state, question.tagGroupId)
  );
  const tags: Tag[] = useAppSelector((state) =>
    selectTagsInGroup(state, question.tagGroupId)
  );

  const [showHelpInput, setShowHelpInput] = useState<boolean>(
    question.helpText !== ''
  );
  const [shouldAppear, setShouldAppear] = useState<boolean>(false);

  useEffect(() => {
    const groupsIdsToShow = activeRootItem.item.availableTags.map(
      (tag) => tag.tagGroupId
    );
    setShouldAppear(groupsIdsToShow.includes(question.tagGroupId));
  }, [activeRootItem]);

  // useRef hook does not trigger a rerender, so we do it manually.
  const [imageFile, setImageFileState] = useState<File | undefined>(undefined);
  const [imageFileUrl, setImageFileUrl] = useState<string>('');

  useEffect(() => {
    if (isSome(imageFile)) {
      setImageFileUrl(URL.createObjectURL(imageFile));
    } else {
      setImageFileUrl('');
    }
  }, [imageFile]);

  const [selectedExistingFile, setSelectedExistingFile] = useState<string>(
    question.imageUrl ?? ''
  );
  const [fileOptions, setFileOptions] = useState<SelectInterface[]>([]);

  const { data: exsitingImages } = useGetUsedQuestionnaireImagesQuery(
    selectedProductGroup?.id ?? '',
    {
      skip: isNone(selectedProductGroup),
    }
  );

  useEffect(() => {
    if (isSome(exsitingImages)) {
      setFileOptions(
        exsitingImages.map((name) => {
          return { value: name, label: name };
        })
      );
    } else {
      setFileOptions([]);
    }
  }, [exsitingImages]);

  const thumbnailImageRef = useRef<HTMLInputElement>(null);

  const setImageFile = (): void => {
    const file = thumbnailImageRef?.current?.files?.item(0) ?? undefined;
    if (isSome(file) && fileOptions.map((fo) => fo.value).includes(file.name)) {
      window.alert(
        `duplicate file name will overwrite existing file with name:${file.name}`
      );
    }
    updateQuestion(
      question.text,
      question.helpText,
      question.tagGroupId,
      question.imageUrl,
      file
    );

    setImageFileState(file);
  };

  const onSelectFile = async (
    option: SelectInterface | null
  ): Promise<void> => {
    if (isSome(option)) {
      setSelectedExistingFile(option.value);
      updateQuestion(
        question.text,
        question.helpText,
        question.tagGroupId,
        option.value,
        question.imageFileObjectUrl
      );
      removeImageFile();
    } else {
      updateQuestion(
        question.text,
        question.helpText,
        question.tagGroupId,
        '',
        question.imageFileObjectUrl
      );
      setSelectedExistingFile('');
    }
  };

  const removeImageFile = (): void => {
    if (isSome(thumbnailImageRef) && isSome(thumbnailImageRef.current)) {
      thumbnailImageRef.current.files = null;
      thumbnailImageRef.current.value = '';

      setImageFileState(undefined);
    }
    setImageFileUrl('');
  };

  const renderImagePreview = (): JSX.Element => {
    if (isSome(imageFile)) {
      return (
        <ImagePreview
          fileUrl={imageFileUrl}
          imageLabel='Help'
          imageName={imageFile.name}
          removeFunc={() => {
            updateQuestion(
              question.text,
              question.helpText,
              question.tagGroupId,
              question.imageUrl,
              undefined
            );
            removeImageFile();
          }}
        />
      );
    } else if (selectedExistingFile !== '' && selectedProductGroup) {
      const imageUrl = buildFileGetQuestionImageUrlByProdGroup(
        selectedProductGroup.id,
        selectedExistingFile
      );
      return (
        <ImagePreview
          fileUrl={imageUrl}
          imageLabel='Help'
          imageName={selectedExistingFile}
          removeFunc={() => {
            updateQuestion(
              question.text,
              question.helpText,
              question.tagGroupId,
              '',
              question.imageFileObjectUrl
            );
            setSelectedExistingFile('');
          }}
        />
      );
    }
    return <Text>Image could not be rendered</Text>;
  };

  const optionalTags = tags.filter((tag) =>
    qTemplateTags.some(
      (qtt) =>
        qtt.tagId === tag.id && qtt.selection === QTemplateOptions.Optional
    )
  );

  const mandatoryTag = tags.find((tag) =>
    qTemplateTags.some(
      (qtt) =>
        qtt.tagId === tag.id && qtt.selection === QTemplateOptions.Mandatory
    )
  );

  const updateQuestion = (
    text: string,
    helpText: string,
    groupId: string,
    imageUrl: string | null | undefined,
    file: File | string | undefined
  ): void => {
    const updatedQuestion = groupQuestionsOnSlice.find(
      (q) => q.tagGroupId === groupId
    );
    if (isSome(updatedQuestion)) {
      let objectUrl: string | undefined = '';
      let objectName: string | undefined = '';
      if (typeof file === 'string') {
        objectUrl = file;
        objectName = updatedQuestion.imageFileObjectName;
      } else if (isSome(file)) {
        objectUrl = URL.createObjectURL(file as Blob);
        objectName = file.name;
      } else {
        objectUrl = undefined;
        objectName = undefined;
      }
      dispatch(
        updateGroupQuestion({
          ...updatedQuestion,
          text,
          helpText,
          imageUrl,
          imageFileObjectUrl: objectUrl,
          imageFileObjectName: objectName,
        })
      );
    }
  };

  return (
    <Box
      layerStyle='questionnaire-card'
      display={shouldAppear ? 'block' : 'none'}
    >
      {isSome(group) && (
        <Stack>
          {question.mandatory === true && (
            <Heading as='h2' variant='questionnaire'>
              Mandatory {group.name} ({mandatoryTag?.name})
            </Heading>
          )}

          {question.mandatory !== true && (
            <>
              <FormLabel>
                <FolderIcon /> {group.name}
              </FormLabel>
              <Textarea
                variant='questionnaire'
                placeholder={''}
                value={question.text}
                onChange={(e) =>
                  updateQuestion(
                    e.target.value,
                    question.helpText,
                    question.tagGroupId,
                    question.imageUrl,
                    question.imageFileObjectUrl
                  )
                }
              />
              <Stack justifyContent={'space-between'}>
                {isSome(imageFile) || selectedExistingFile !== '' ? (
                  renderImagePreview()
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        thumbnailImageRef?.current?.click();
                      }}
                      leftIcon={<ImageIcon />}
                    >
                      Upload New Help Image
                    </Button>
                    <Box w='100%' my='20px'>
                      <Text>Choose image from exsiting files</Text>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        name='fileName'
                        options={fileOptions}
                        onChange={onSelectFile}
                      />
                    </Box>
                  </>
                )}
                <input
                  onClick={(e) => (e.currentTarget.value = '')}
                  type='file'
                  style={{ display: 'none' }}
                  accept='image/*'
                  ref={thumbnailImageRef}
                  onChange={setImageFile}
                />
                {!showHelpInput && (
                  <Button
                    w='48%'
                    leftIcon={<PreviewIcon className='icon' w={8} h={5} />}
                    onClick={() => setShowHelpInput(!showHelpInput)}
                  >
                    Add help text
                  </Button>
                )}
              </Stack>
              {showHelpInput && (
                <>
                  <FormLabel>Help text</FormLabel>
                  <Textarea
                    variant='questionnaire'
                    placeholder={''}
                    value={question.helpText}
                    onChange={(e) =>
                      updateQuestion(
                        question.text,
                        e.target.value,
                        question.tagGroupId,
                        question.imageUrl,
                        question.imageFileObjectUrl
                      )
                    }
                  />
                </>
              )}
            </>
          )}
          {optionalTags.length > 0 && (
            <>
              <Heading as='h3' variant='questionnaire'>
                {'Options (Tags)'}
              </Heading>
              <Flex w='100%'>
                {optionalTags.map((tag) => (
                  <GroupQuestionTagOption
                    key={`tagOption:${tag.id}`}
                    tag={tag}
                  />
                ))}
              </Flex>
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};
