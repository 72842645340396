/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isSome } from '../config/Maybe';
import { System } from '../objects/System';
import { RootState } from './store';

const initialState = {
  systems: [] as System[],
  systemToConfigure: undefined as System | undefined,
  systemToDuplicate: undefined as System | undefined,
  systemToEdit: undefined as System | undefined,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setSystems: (state, { payload: systems }: PayloadAction<System[]>) => {
      state.systems = systems;
    },
    updateSystem: (
      state,
      { payload: updatedSystem }: PayloadAction<System>
    ) => {
      state.systems = state.systems.map((system) =>
        system.id === updatedSystem.id ? updatedSystem : system
      );

      if (
        isSome(state.systemToConfigure) &&
        state.systemToConfigure.id === updatedSystem.id
      ) {
        state.systemToConfigure = updatedSystem;
      }
    },
    addSystem: (state, { payload: system }: PayloadAction<System>) => {
      state.systems.push(system);
    },
    deleteSystem: (state, { payload: deletedId }: PayloadAction<string>) => {
      return {
        ...state,
        systems: state.systems.filter((system) => system.id !== deletedId),
      };
    },
    setSystemToConfigure: (
      state,
      { payload: systemToConfigure }: PayloadAction<System | undefined>
    ) => {
      state.systemToConfigure = systemToConfigure;
    },
    setSystemToDuplicate: (
      state,
      { payload: systemToDuplicate }: PayloadAction<System | undefined>
    ) => {
      state.systemToDuplicate = systemToDuplicate;
    },
    setSystemToEdit: (
      state,
      { payload: systemToEdit }: PayloadAction<System | undefined>
    ) => {
      state.systemToEdit = systemToEdit;
    },
    resetSystemSlice: (state) => {
      return { ...initialState, systems: state.systems };
    },
  },
});

export const selectSystems = (state: RootState): System[] =>
  state.system.systems;

export const selectSystemToConfigure = (state: RootState): System | undefined =>
  state.system.systemToConfigure;

export const selectSystemToDuplicate = (state: RootState): System | undefined =>
  state.system.systemToDuplicate;

export const selectSystemToEdit = (state: RootState): System | undefined =>
  state.system.systemToEdit;

export const {
  setSystems,
  setSystemToConfigure,
  setSystemToDuplicate,
  setSystemToEdit,
  updateSystem,
  addSystem,
  deleteSystem,
  resetSystemSlice,
} = systemSlice.actions;

export default systemSlice.reducer;
