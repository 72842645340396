import {
  Text,
  Button,
  Box,
  HStack,
  IconButton,
  useDisclosure,
  InputGroup,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import '../Popup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { WarningModal } from '../WarningModal';
import { useAppSelector } from '../../../store/hooks';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { isSome } from '../../../config/Maybe';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  QTemplate,
  UpdateQTemplateParams,
} from '../../../objects/QuestionnaireTemplate';
import {
  useDeleteQTemplateMutation,
  useUpdateQTemplateMutation,
} from '../../../services/qTemplateEndpoints';

interface QTemplateOptionProps {
  qTemplate: QTemplate;
  selected: boolean;
  setSelected: (template: QTemplate | undefined) => void;
}

const nameInputName = 'name';

export const QTemplateOption = ({
  qTemplate,
  selected,
  setSelected,
}: QTemplateOptionProps): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const [deleteQTemplate] = useDeleteQTemplateMutation();
  const [updateQTemplate] = useUpdateQTemplateMutation();
  const [editing, setEditing] = useState<boolean>(false);
  const { register, watch, reset, getValues } = useForm<{ name: string }>();
  watch();

  const name = getValues(nameInputName);

  const onSubmitQTemplate = async (): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const qTemplateToUpdate: UpdateQTemplateParams = {
        id: qTemplate.id,
        name: name,
        productGroupId: selectedProductGroup.id,
      };
      await updateQTemplate(qTemplateToUpdate);
      reset();
      setEditing(false);
    }
  };

  const {
    isOpen: isOpenDeleteConfirmPopup,
    onOpen: onOpenDeleteConfirmPopup,
    onClose: onCloseDeleteConfirmPopup,
  } = useDisclosure();

  const onDelete = async (): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const qTemplateToDelete: UpdateQTemplateParams = {
        id: qTemplate.id,
        name: qTemplate.name,
        productGroupId: selectedProductGroup.id,
      };

      setSelected(undefined);
      await deleteQTemplate(qTemplateToDelete).unwrap();
      onCloseDeleteConfirmPopup();
    }
  };

  return (
    <Box
      w='100%'
      h='40px'
      mt='0px'
      borderBottom={'1px'}
      borderColor={appColors.BORDERGRAY}
    >
      {editing ? (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onSubmitQTemplate();
          }}
        >
          <InputGroup w='100%'>
            <Input
              w='100%'
              type='text'
              autoFocus={true}
              {...register(nameInputName, {
                required: true,
                value: qTemplate.name,
              })}
            />

            <InputRightElement width='4.5rem'>
              <Button
                border='none'
                onClick={() => {
                  reset();
                  setEditing(false);
                }}
              >
                <FontAwesomeIcon
                  height={'100%'}
                  color={appColors.GRAY500}
                  className={(classNames.icon, classNames.faIcon)}
                  icon={faTimes}
                />
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
      ) : (
        <HStack
          h='100%'
          w='100%'
          backgroundColor={selected ? appColors.GRAY_MEDIUM : 'white'}
        >
          <Button
            justifyContent={'space-between'}
            className={(classNames.navItem, classNames.navItemActive)}
            h='100%'
            w='100%'
            onClick={() => setSelected(qTemplate)}
            variant={selected ? 'side-nav-bar-active' : 'side-nav-bar'}
          >
            <Text color={appColors.GRAY500}>{qTemplate.name}</Text>
          </Button>
          {selected && (
            <HStack h='100%'>
              <IconButton
                aria-label='edit template name'
                color={appColors.GREEN_DARKER}
                border='none'
                h='90%'
                onClick={() => setEditing(true)}
                icon={
                  <FontAwesomeIcon
                    className={(classNames.icon, classNames.faIcon)}
                    icon={faPen}
                  />
                }
              />
              <IconButton
                aria-label='delete template'
                color={appColors.RED}
                border='none'
                h='90%'
                onClick={onOpenDeleteConfirmPopup}
                icon={
                  <FontAwesomeIcon
                    className={(classNames.icon, classNames.faIcon)}
                    icon={faTrashAlt}
                  />
                }
              />
            </HStack>
          )}
        </HStack>
      )}
      {isOpenDeleteConfirmPopup && (
        <WarningModal
          isOpen={isOpenDeleteConfirmPopup}
          onClose={onCloseDeleteConfirmPopup}
          title={'Delete this template?'}
          message={`This will not affect ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }s created from the template.`}
          onCancel={onCloseDeleteConfirmPopup}
          onConfirm={async () => await onDelete()}
        />
      )}
    </Box>
  );
};

const classNames = {
  navItem: 'sideBar-item',
  navItemActive: 'sideBar-item-active',
  icon: 'icon',
  faIcon: 'faIcon',
};
