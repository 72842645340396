/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  InputQTemplateParams,
  IQTemplateWithFoldersContentsAndTags,
  QTemplate,
  UpdateQTemplateParams,
} from '../objects/QuestionnaireTemplate';
import {
  addQTemplate,
  deleteQTemplate,
  setQTemplates,
  updateQTemplate,
} from '../store/qTemplateSlice';
import { IOnQueryAPI, QuestionnaireKey, vidualsApi } from './vidualsApi';

const setQTemplatesState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setQTemplates(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateQTemplateOnSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateQTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

const addQTemplateToSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addQTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

const deleteQTemplateFromSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(deleteQTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

export const qTemplatesApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getQTemplatesByProductGroupId: builder.query<QTemplate[], string>({
      query: (pgid) => `questionnaire-templates/productGroup/${pgid}`,
      onQueryStarted: setQTemplatesState,
    }),
    createQTemplate: builder.mutation<QTemplate, InputQTemplateParams>({
      query: (body) => {
        return {
          url: `questionnaire-templates`,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: addQTemplateToSlice,
    }),
    updateQTemplate: builder.mutation<QTemplate, UpdateQTemplateParams>({
      query: (body) => {
        return {
          url: `questionnaire-templates`,
          method: 'PUT',
          body: body,
        };
      },
      onQueryStarted: updateQTemplateOnSlice,
    }),
    setQTemplateSelections: builder.mutation<
      QTemplate,
      IQTemplateWithFoldersContentsAndTags
    >({
      query: (body) => {
        return {
          url: `questionnaire-templates/selections`,
          method: 'PUT',
          body: body,
        };
      },
      onQueryStarted: updateQTemplateOnSlice,
      invalidatesTags: [QuestionnaireKey],
    }),
    deleteQTemplate: builder.mutation<string, UpdateQTemplateParams>({
      query: (body) => {
        return {
          url: `questionnaire-templates`,
          method: 'DELETE',
          body: body,
        };
      },
      onQueryStarted: deleteQTemplateFromSlice,
    }),
  }),
});

export const {
  useLazyGetQTemplatesByProductGroupIdQuery,
  useCreateQTemplateMutation,
  useDeleteQTemplateMutation,
  useUpdateQTemplateMutation,
  useSetQTemplateSelectionsMutation,
} = qTemplatesApi;
