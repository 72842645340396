import { useEffect, useState } from 'react';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import SortableTree, {
  TreeItem,
  changeNodeAtPath,
  ExtendedNodeData,
  getVisibleNodeCount,
} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { Content } from '../../objects/Content';
import { FileTreeItemTypeSchema } from '../../objects/FileTreeItem';
import { VideoItem } from './VideoItem';
import { PdfItem } from './PdfItem';
import { SystemFolderItem } from './SystemFolderItem';
import {
  useGetSystemContentsByIdQuery,
  useGetSystemFoldersChildrenByIdQuery,
} from '../../services/systemEndpoints';
import { isSome } from '../../config/Maybe';
import { Folder } from '../../objects/Folder';
import { System } from '../../objects/System';
import { SearchResults } from './SearchResults';
import { useDebounce } from '../../utilities/hooks';
import { Customizations } from '../../objects/Customizations';
import Fuse from 'fuse.js';
import './EndUserFileList.scss';
import { useAppSelector } from '../../store/hooks';
import { ContentVersion } from '../../objects/ContentVersion';
import { selectVersionToDisplay } from '../../store/endUserSlice';

interface FileListProps {
  system: System;
  customizations: Customizations | null | undefined;
}

export const FileList = ({
  system,
  customizations,
}: FileListProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<TreeItem[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { data: foldersChildren } = useGetSystemFoldersChildrenByIdQuery({
    systemId: system.id,
  });
  const { data: systemContents } = useGetSystemContentsByIdQuery({
    systemId: system.id,
  });
  const currentSelectedVersion: ContentVersion | undefined = useAppSelector(
    selectVersionToDisplay
  );

  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  const contentVersionsTreeItems = (
    contents: Content[] | undefined,
    parentPathName: string
  ): TreeItem[] => {
    if (contents === undefined) {
      return [];
    }

    const contentVersionsItems: TreeItem[] = [];
    contents.forEach((content) => {
      if (isSome(content.contentVersions)) {
        content.contentVersions?.forEach((contentVersion) => {
          if (
            isSome(contentVersion.videoName) &&
            contentVersion.videoName !== ''
          ) {
            contentVersionsItems.push({
              title: (
                <VideoItem
                  contentVersion={contentVersion}
                  content={content}
                  color={customizations?.secondaryTextColor}
                />
              ),
              id: contentVersion.id,
              fileTreeItem: contentVersion,
              content: content,
              name: content.name,
              pathName: parentPathName,
              type: FileTreeItemTypeSchema.Enum.CONTENT_VERSION,
              childAllowed: false,
              children: [],
              indexWithinParent: content.indexWithinParent,
            });
          } else {
            if (
              isSome(contentVersion.pdfName) &&
              contentVersion.pdfName !== ''
            ) {
              contentVersionsItems.push({
                title: (
                  <PdfItem
                    contentVersion={contentVersion}
                    content={content}
                    color={customizations?.secondaryTextColor}
                  />
                ),
                id: contentVersion.id,
                fileTreeItem: contentVersion,
                content: content,
                name: content.name,
                pathName: parentPathName,
                type: FileTreeItemTypeSchema.Enum.CONTENT_VERSION,
                childAllowed: false,
                children: [],
                indexWithinParent: content.indexWithinParent,
              });
            }
          }
        });
      }
    });
    contentVersionsItems.sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });
    return contentVersionsItems;
  };

  const setTreeDataRecursive = (
    parentFolderId: string,
    parentPathName: string
  ): TreeItem[] => {
    const firstFolderChildren: Folder[] =
      foldersChildren?.filter((item) => item.parent.id === parentFolderId) ??
      [];

    const itemsToReturn: TreeItem[] = [];

    firstFolderChildren?.forEach((child) => {
      const contentChildren = contentVersionsTreeItems(
        child.contents as Content[],
        parentPathName + ' > ' + child.name
      );
      const folderChildren = setTreeDataRecursive(
        child.id,
        parentPathName + ' > ' + child.name
      );
      const allChildren = [...folderChildren, ...contentChildren].sort(
        (a, b) => {
          return a.indexWithinParent - b.indexWithinParent;
        }
      );
      if (contentChildren.length > 0 || folderChildren.length > 0) {
        itemsToReturn.push({
          title: (
            <SystemFolderItem
              onClick={() => {
                return true;
              }}
              folder={child}
              color={customizations?.secondaryTextColor}
            />
          ),
          id: child.id,
          fileTreeItem: child,
          name: child.name,
          pathName: parentPathName,
          type: FileTreeItemTypeSchema.Enum.FOLDER,
          childAllowed: true,
          children: allChildren,
          indexWithinParent: child.indexWithinParent,
        });
      }
    });
    itemsToReturn.sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });

    return itemsToReturn;
  };

  const setData = (): void => {
    const finalData: TreeItem[] = [];

    const topLevelFolders = foldersChildren?.filter(
      (item) => item.parent.isRoot
    );

    topLevelFolders?.forEach((topFolder) => {
      const contentChildren = contentVersionsTreeItems(
        topFolder.contents as Content[],
        topFolder.name
      );
      const folderChildren = setTreeDataRecursive(topFolder.id, topFolder.name);
      const allChildren = [...folderChildren, ...contentChildren].sort(
        (a, b) => {
          return a.indexWithinParent - b.indexWithinParent;
        }
      );
      if (contentChildren.length > 0 || folderChildren.length > 0) {
        finalData.push({
          title: (
            <SystemFolderItem
              onClick={() => {
                return true;
              }}
              folder={topFolder}
              color={customizations?.secondaryTextColor}
            />
          ),
          id: topFolder.id,
          fileTreeItem: topFolder,
          name: topFolder.name,
          pathName: '',
          type: FileTreeItemTypeSchema.Enum.FOLDER,
          childAllowed: true,
          children: allChildren,
          indexWithinParent: topFolder.indexWithinParent,
        });
      }
    });

    const rootVersionTreeItems: TreeItem[] = [];
    systemContents?.forEach((rootContent) => {
      const contentItems = contentVersionsTreeItems([rootContent], '');
      if (contentItems.length > 0) {
        rootVersionTreeItems.push(...contentItems);
      }
    });

    finalData.push(...rootVersionTreeItems);

    finalData.sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });

    setTreeData(finalData);
  };

  useEffect(() => {
    setData();
  }, [foldersChildren, systemContents]);

  const toggleNodeExpandIfFolder = (nodeData: ExtendedNodeData): void => {
    const { node, path } = nodeData;
    if (node.children && node.children.length > 0) {
      setTreeData(
        changeNodeAtPath({
          treeData,
          path,
          getNodeKey: (fileTreeNode) => fileTreeNode.treeIndex,
          newNode: { ...node, expanded: !node.expanded },
        })
      );
    }
  };

  const visibleCount = getVisibleNodeCount({ treeData });
  const fullHeightPixels = visibleCount * 42; // count times row height

  return (
    <Box>
      <InputGroup mb={3} ml={2} w='95%'>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon
            color={
              isSome(customizations?.secondaryTextColor)
                ? '#' + String(customizations?.secondaryTextColor)
                : 'gray.200'
            }
          />
        </InputLeftElement>
        <Input
          value={searchTerm}
          color={
            isSome(customizations?.secondaryTextColor)
              ? '#' + String(customizations?.secondaryTextColor)
              : 'gray.200'
          }
          borderWidth={0}
          focusBorderColor={
            isSome(customizations?.buttonColor)
              ? '#' + String(customizations?.buttonColor)
              : 'gray.200'
          }
          placeholder='Search for the name of a video or folder'
          _placeholder={{
            opacity: 0.7,
            color: isSome(customizations?.secondaryTextColor)
              ? '#' + String(customizations?.secondaryTextColor)
              : 'gray.200',
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Box h={fullHeightPixels} w='100%'>
        <SortableTree
          searchQuery={debouncedSearchTerm}
          searchMethod={({ searchQuery, node }) => {
            const fuse = new Fuse([node], {
              threshold: 0.5,
              keys: ['name', 'content.description'],
            });
            const searchResult = fuse.search(searchQuery);
            return searchResult.length > 0;
          }}
          searchFinishCallback={(matches) => {
            setSearchResults(matches.map((node) => node.node));
          }}
          treeData={treeData}
          canDrag={false}
          onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
          theme={FileExplorerTheme}
          rowHeight={42}
          reactVirtualizedListProps={{
            className: 'file-tree-list',
          }}
          style={{
            width: '100%',
            display: debouncedSearchTerm.length === 0 ? '' : 'none',
          }}
          generateNodeProps={(nodeData: ExtendedNodeData) => {
            const { node } = nodeData;
            return {
              className:
                node.id === currentSelectedVersion?.id ||
                node.containsSelected === true
                  ? classNames.currentRow
                  : '',
              onClick: () => toggleNodeExpandIfFolder(nodeData),
            };
          }}
        />
        {debouncedSearchTerm.length !== 0 && (
          <SearchResults
            matches={debouncedSearchTerm.length !== 0 ? searchResults : []}
            color={
              isSome(customizations?.secondaryTextColor)
                ? '#' + String(customizations?.secondaryTextColor)
                : 'gray.200'
            }
            resetSearchTerm={() => setSearchTerm('')}
          />
        )}
      </Box>
    </Box>
  );
};

const classNames = {
  currentRow: 'end-user-file-list-current-row',
};
