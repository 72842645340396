import './FileTree.scss';
import { HStack, Image, Text } from '@chakra-ui/react';
import { useAppDispatch } from '../../store/hooks';
import { Folder } from '../../objects/Folder';
import closedFolder from '../../images/fileIcons/closedFolder.svg';
import { setFolderToEdit } from '../../store/modalFormSlice';
import { isSome } from '../../config/Maybe';

interface FolderItemProps {
  folder: Folder;
  disableOnClick?: boolean;
  overWriteOnClick?: (folder: Folder) => void;
}

export const FolderItem = ({
  folder,
  disableOnClick,
  overWriteOnClick,
}: FolderItemProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <HStack
      w='100%'
      h='100%'
      onClick={
        isSome(overWriteOnClick) ? () => overWriteOnClick(folder) : undefined
      }
    >
      <Image className={classNames.icon} src={closedFolder} />
      <Text
        onClick={() => {
          if (disableOnClick !== true) {
            dispatch(setFolderToEdit(folder));
          }
        }}
        className={classNames.folder}
      >
        {folder.name}
      </Text>
    </HStack>
  );
};

const classNames = {
  folder: 'folder',
  icon: 'icon',
};
