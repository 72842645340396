import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Stack,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react';
import { InputFolderParams } from '../../../objects/Folder';
import { useAppSelector } from '../../../store/hooks';
import { TextInput } from '../../../components/TextInput';
import { useCreateFolderMutation } from '../../../services/folderEndpoints';
import { isSome } from '../../../config/Maybe';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';

interface AddFolderPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddFolderPopup = ({
  isOpen,
  onClose,
}: AddFolderPopupProps): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InputFolderParams>();

  const [createFolder] = useCreateFolderMutation();

  const onSubmit = async (folderToCreate: InputFolderParams): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      folderToCreate.productGroupId = selectedProductGroup.id;
    }
    folderToCreate.parentFolderId = undefined;

    await createFolder(folderToCreate).unwrap();

    reset();
    onClose();
  };

  const registerTextInput = register('name', { required: true });
  const text = watch('name');

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent w='458px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <ModalHeader pt={15}>
              <Heading as='h1' size='lg' textAlign='center'>
                Name your folder
              </Heading>
            </ModalHeader>
            <Stack pt={15}>
              <TextInput
                placeholder='Folder Name'
                register={registerTextInput}
                text={text}
              />
              {isSome(errors.name) && errors.name.type === 'required' && (
                <Text variant={'error'}>
                  You must enter a folder name. Please try again.
                </Text>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack className='space-between width100' pt={30}>
              <Button w='45%' onClick={onClose}>
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='45%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
