import {
  Button,
  Text,
  Stack,
  Link,
  Modal,
  ModalContent,
  Heading,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import { useFirebase } from '../../contexts/firebase';
import { selectCurrentUserData } from '../../store/authSlice';
import { history } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectCurrentOrg,
  selectCurrentUserOrgs,
  setCurrentOrganization,
} from '../../store/organizationSlice';
import { Organization } from '../../objects/Organization';
import { Routes } from '../../config/Routes';
import firebase from 'firebase';

interface ProfilePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ProfilePopup = ({
  isOpen,
  onClose,
}: ProfilePopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const app = useFirebase();
  const userData = useAppSelector(selectCurrentUserData);
  const userOrgs = useAppSelector(selectCurrentUserOrgs);
  const selectedOrg = useAppSelector(selectCurrentOrg);

  const sendPasswordResetEmail = (email: string): void => {
    firebase
      .auth(app)
      .sendPasswordResetEmail(email)
      .then(() => {
        console.info('Password reset email sent to:', email);
        alert(`Password reset email sent to:${email}`);
      })
      .catch((error) => {
        console.error(error);
        alert('Failed to send password reset');
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={30} pt={15}>
            <Stack>
              <Heading as='h1' size='md'>
                {userData?.displayName ?? 'User Name'}
              </Heading>
              <Text color='gray.500'>
                {userData?.email ?? 'email@email.com'}
              </Text>
              {/* <Button>Manage your profile</Button> */}
            </Stack>
            <Stack>
              <Heading as='h2' size='sm'>
                Organizations
              </Heading>
              <Select
                value={selectedOrg?.id}
                onChange={(e) => {
                  const foundOrg = userOrgs.find(
                    (org) => org.id === e.target.value
                  );
                  dispatch(setCurrentOrganization(foundOrg ?? null));
                }}
              >
                {userOrgs.map((org: Organization) => (
                  <option key={`user org option:${org.id}`} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </Select>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent='left'>
          <Stack>
            <Button
              pl={2}
              color='viduals.greenDarker'
              fontWeight={600}
              textDecoration='underline'
              border='none'
              justifyContent='left'
              colorScheme='transparent'
              onClick={async () => {
                await app.auth().signOut();
              }}
            >
              Log out
            </Button>
            <Link
              pl={2}
              color='viduals.greenDarker'
              fontWeight={600}
              textDecoration='underline'
              onClick={() => sendPasswordResetEmail(userData.email)}
            >
              Reset Password
            </Link>
            {userData.superAdmin && (
              <Link
                pl={2}
                color='viduals.greenDarker'
                fontWeight={600}
                textDecoration='underline'
                onClick={() => history.push(Routes.SUPER_ADMIN)}
              >
                Go to Super-Admin Console
              </Link>
            )}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
