import './ContentHeader.scss';
import NewFolder from '../../images/buttonIcons/newFolder.svg';
import NewContent from '../../images/buttonIcons/newContent.svg';
import { Box, HStack, Button, Image, useDisclosure } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { AddContentPopup } from '../modal/AddContentPopup';
import { Content, InputContentParams } from '../../objects/Content';
import { useCreateContentMutation } from '../../services/contentsEndpoints';
import { AddFolderPopup } from '../modal/folder/AddFolderPopup';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { isSome } from '../../config/Maybe';
import { InputContentVersionParams } from '../../objects/ContentVersion';
import { useCreateContentVersionMutation } from '../../services/contentVersionsEndpoints';
import {
  addFailedFileToUpload,
  addFileToUpload,
  addSucceededFileUpload,
  removeFileToUpload,
} from '../../store/toastSlice';
import { uploadFile } from '../../config/fileupload';
import { firebaseApp } from '../../config/firebase.config';

export const ContentHeader = (): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const dispatch = useAppDispatch();

  const [createContent] = useCreateContentMutation();
  const [createContentVersions] = useCreateContentVersionMutation();

  const {
    isOpen: isOpenAddContentPopup,
    onOpen: onOpenAddContentPopup,
    onClose: onCloseAddContentPopup,
  } = useDisclosure();

  const createContentFunc = async (
    content: InputContentParams
  ): Promise<Content> => {
    if (isSome(selectedProductGroup)) {
      content.productGroupId = selectedProductGroup.id;
      return await createContent(content)
        .unwrap()
        .then((response): Content => {
          return response;
        })
        .catch((rejected) => {
          console.error(rejected);
          alert(
            `Failed to create content: ${content.name}, attached variations will not be created`
          );
          throw new Error('Failed to create content');
        });
    } else {
      alert(`Product Group not found, please try again`);
      throw new Error(`Product Group not found, please try again`);
    }
  };

  const createContentVersionsFunc = async (
    contentId: string,
    versions: InputContentVersionParams[]
  ): Promise<void> => {
    const versionsToCreate: InputContentVersionParams[] = [];
    for (const version of versions) {
      if (isSome(version.pdfFile)) {
        try {
          dispatch(addFileToUpload(version.pdfFile.name));
          await uploadFile(
            firebaseApp.auth().currentUser,
            selectedProductGroup,
            version.pdfFile
          );
          version.pdfName = version.pdfFile.name;
          dispatch(addSucceededFileUpload(version.pdfFile.name));
        } catch (err) {
          console.log(err);
          dispatch(addFailedFileToUpload(version.pdfFile.name));
          version.pdfName = undefined;
        } finally {
          dispatch(removeFileToUpload(version.pdfFile.name));
        }
      }

      if (isSome(version.videoFile)) {
        try {
          dispatch(addFileToUpload(version.videoFile.name));
          await uploadFile(
            firebaseApp.auth().currentUser,
            selectedProductGroup,
            version.videoFile
          );
          version.videoName = version.videoFile.name;
          dispatch(addSucceededFileUpload(version.videoFile.name));
        } catch (err) {
          console.log(err);
          dispatch(addFailedFileToUpload(version.videoFile.name));
          version.videoName = undefined;
        } finally {
          dispatch(removeFileToUpload(version.videoFile.name));
        }
      }

      versionsToCreate.push({
        id: version.id ?? undefined,
        contentId,
        tagIds: version.tagIds ?? [],
        videoName: version.videoName,
        pdfName: version.pdfName,
        pdfStartPage: version.pdfStartPage ?? undefined,
      });
    }

    await createContentVersions(versionsToCreate);
  };

  const {
    isOpen: isOpenAddFolderPopup,
    onOpen: onOpenAddFolderPopup,
    onClose: onCloseAddFolderPopup,
  } = useDisclosure();

  return (
    <Box>
      <HStack>
        <Button
          onClick={onOpenAddFolderPopup}
          disabled={!isSome(selectedProductGroup)}
        >
          <Image className={classNames.icon} src={NewFolder} />
          New folder
        </Button>
        <Button
          onClick={onOpenAddContentPopup}
          disabled={!isSome(selectedProductGroup)}
        >
          <Image className={classNames.icon} src={NewContent} />
          New content
        </Button>
      </HStack>
      <AddContentPopup
        createContent={createContentFunc}
        createVersions={createContentVersionsFunc}
        isOpen={isOpenAddContentPopup}
        onClose={onCloseAddContentPopup}
      />
      <AddFolderPopup
        isOpen={isOpenAddFolderPopup}
        onClose={onCloseAddFolderPopup}
      />
    </Box>
  );
};

const classNames = {
  icon: 'icon',
};
