import {
  Button,
  HStack,
  Modal,
  ModalContent,
  ModalFooter,
  ModalBody,
  Heading,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PdfIcon } from '../../config/icons';
import { isSome } from '../../config/Maybe';
import { selectVersionsInTree } from '../../store/treeItemSlice';
import { useAppSelector } from '../../store/hooks';
import Select from 'react-select';

interface SelectInterface {
  value: string;
  label: string;
}

interface PdfInputPopupProps {
  pdfInputRef: React.RefObject<HTMLInputElement>;
  setPdfFileName: (fileName: string) => void;
  isOpen: boolean;
  onClose: () => void;
  pdfsSetToUpload: File[];
  removePdfFile: () => void;
}

export const PdfInputPopup = ({
  onClose,
  pdfInputRef,
  setPdfFileName,
  isOpen,
  pdfsSetToUpload,
  removePdfFile,
}: PdfInputPopupProps): JSX.Element => {
  const existingVersions = useAppSelector(selectVersionsInTree);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [fileOptions, setFileOptions] = useState<SelectInterface[]>([]);

  useEffect(() => {
    const newFileOptions: string[] = [];
    existingVersions.map((version) => {
      if (isSome(version.pdfName)) {
        newFileOptions.push(version.pdfName);
      }
      return version;
    });

    // Checks if input was referencing a pdf file that was added to another input
    // and if that file was removed, also removes this one
    if (
      selectedFile !== '' &&
      !pdfsSetToUpload.some((file) => file.name === selectedFile) &&
      !existingVersions.some((version) => version.pdfName === selectedFile) &&
      !isSome(pdfInputRef?.current?.files?.item(0))
    ) {
      setSelectedFile('');
      removePdfFile();
    }

    // pdfs uploaded to other inputs
    pdfsSetToUpload.map((file) => {
      if (isSome(file.name)) {
        newFileOptions.push(file.name);
      }
      return file;
    });

    const uniqueFileOptions: SelectInterface[] = Array.from(
      new Set(newFileOptions)
    ).map((file) => {
      return { value: file, label: file };
    });
    setFileOptions(uniqueFileOptions);
  }, [existingVersions, pdfsSetToUpload]);

  const onSelectFile = async (
    option: SelectInterface | null
  ): Promise<void> => {
    if (isSome(option)) {
      setSelectedFile(option.value);
    } else {
      setSelectedFile('');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalContent>
        <ModalBody>
          <HStack justifyContent='space-between'>
            <Heading>Choose PDF</Heading>
            <Button
              className={classNames.contentButton}
              onClick={() => {
                pdfInputRef?.current?.click();
                onClose();
              }}
              leftIcon={<PdfIcon w={5} />}
            >
              Upload new PDF
            </Button>
          </HStack>
          <Box w='100%' my='20px'>
            <Select
              isClearable={true}
              isSearchable={true}
              name='fileName'
              options={fileOptions}
              onChange={onSelectFile}
            />
          </Box>
          <HStack className='space-between width100'>
            <Button w='50%' onClick={onClose}>
              Cancel
            </Button>
            <Button
              className='modal-button'
              w='50%'
              onClick={() => {
                setPdfFileName(selectedFile);
                onClose();
              }}
            >
              Confirm
            </Button>
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent='left'></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const classNames = {
  contentButton: 'content-button',
  icon: 'icon',
};
