import { Box, Heading, Text, Link } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { isSome } from '../../config/Maybe';
import { Routes } from '../../config/Routes';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

export const ContentInitial = (): JSX.Element => {
  const history = useHistory();
  const currentProductGroup = useAppSelector(selectCurrentProductGroup);

  return (
    <Box>
      <Heading as='h1' fontSize='3xl' color='gray.900' pt={5} pb={5}>
        Content
      </Heading>
      <Box color='gray.700'>
        <Text pb={3}>
          This is where content media is uploaded and organized into topic and
          sub-topic folders. Drag and drop items to order the list to determine
          how it will be displayed in the table of contents.
        </Text>
        {!isSome(currentProductGroup) && (
          <Text pb={3}>
            Create a{' '}
            <Link
              onClick={() => history.push(Routes.PRODUCT_GROUPS)}
              fontWeight={700}
              color='viduals.greenDarker'
              textDecoration='underline'
            >
              product group
            </Link>{' '}
            to get started.
          </Text>
        )}
      </Box>
    </Box>
  );
};
