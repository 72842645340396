import {
  HStack,
  VStack,
  Text,
  RadioGroup,
  Radio,
  Divider,
  Heading,
} from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { FolderWithTags } from '../../../objects/FolderWithTags';
import closedFolder from '../../../images/fileIcons/closedFolder.svg';
import '../Popup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { ContentWithTags } from '../../../objects/ContentWithTags';
import {
  FoldersOrContentWithTags,
  ItemType,
} from '../../../objects/FolderOrContentItem';
import { useEffect, useState } from 'react';
import { Folder } from '../../../objects/Folder';
import { Content } from '../../../objects/Content';
import { useAppSelector } from '../../../store/hooks';
import {
  selectContentsInTree,
  selectFoldersInTree,
} from '../../../store/treeItemSlice';
import { isSome } from '../../../config/Maybe';
import {
  QTemplateContent,
  QTemplateFolder,
  QTemplateOptions,
} from '../../../objects/QuestionnaireTemplate';
import { QTemplateSettingsRadioLabels } from './QTemplateSettingsRadioLabels';

interface QTemplateFoldersAndContentsListProps {
  foldersWithTags: FolderWithTags[];
  selectionFolders: QTemplateFolder[];
  setFolderSelection: (folderId: string, option: QTemplateOptions) => void;
  contentsWithTags: ContentWithTags[];
  selectionContents: QTemplateContent[];
  setContentSelection: (contentId: string, option: QTemplateOptions) => void;
}

export const QTemplateFoldersAndContentsList = ({
  foldersWithTags,
  selectionFolders,
  setFolderSelection,
  contentsWithTags,
  selectionContents,
  setContentSelection,
}: QTemplateFoldersAndContentsListProps): JSX.Element => {
  const [allRootItems, setAllRootItems] = useState<FoldersOrContentWithTags[]>(
    []
  );
  const allFolders: Folder[] = useAppSelector(selectFoldersInTree);
  const allContents: Content[] = useAppSelector(selectContentsInTree);

  useEffect(() => {
    const newRootItems: FoldersOrContentWithTags[] = [
      ...foldersWithTags.map((folder): FoldersOrContentWithTags => {
        const match = allFolders.find((f) => f.id === folder.id);
        return {
          item: {
            ...folder,
            indexWithinParent: isSome(match)
              ? match.indexWithinParent
              : folder.indexWithinParent,
          },
          type: ItemType.Folder,
        };
      }),
      ...contentsWithTags.map((content): FoldersOrContentWithTags => {
        const match = allContents.find((c) => c.id === content.id);
        return {
          item: {
            ...content,
            indexWithinParent: isSome(match)
              ? match.indexWithinParent
              : content.indexWithinParent,
          },
          type: ItemType.Content,
        };
      }),
    ].sort((a, b) => {
      return a.item.indexWithinParent - b.item.indexWithinParent;
    });

    setAllRootItems(newRootItems);
  }, [allFolders, allContents, foldersWithTags, contentsWithTags]);

  return (
    <VStack w='100%' spacing={0}>
      <QTemplateSettingsRadioLabels
        title={
          <Heading as='h4' size='md' px='10px' height={'fit-content'} mb='10px'>
            Content
          </Heading>
        }
      />
      {allRootItems.map((folderOrContent) => {
        if (folderOrContent.type === ItemType.Folder) {
          const folder = folderOrContent.item;
          const selectionOption = selectionFolders.find(
            (f) => f.folderId === folder.id
          );
          return (
            <HStack
              borderTop={'1px solid #d4d4d8'}
              key={`template folder option: ${folder.id}`}
              w='100%'
              h='52px'
              px='10px'
              justifyContent='space-between'
            >
              <HStack>
                <img className={classNames.icon} src={closedFolder} />
                <Text>{folder.name}</Text>
              </HStack>
              <RadioGroup
                h='100%'
                w='200px'
                value={selectionOption?.selection ?? ''}
              >
                <HStack
                  h='100%'
                  px='10px'
                  w='100%'
                  justifyContent='space-between'
                >
                  <Radio
                    colorScheme='green'
                    value={QTemplateOptions.Mandatory}
                    onClick={() =>
                      setFolderSelection(folder.id, QTemplateOptions.Mandatory)
                    }
                  />
                  <Radio
                    colorScheme='green'
                    value={QTemplateOptions.Optional}
                    onClick={() =>
                      setFolderSelection(folder.id, QTemplateOptions.Optional)
                    }
                  />
                  <Divider orientation='vertical' />
                  <Radio
                    colorScheme='green'
                    value={QTemplateOptions.Unavailable}
                    onClick={() =>
                      setFolderSelection(
                        folder.id,
                        QTemplateOptions.Unavailable
                      )
                    }
                  />
                </HStack>
              </RadioGroup>
            </HStack>
          );
        } else {
          const content = folderOrContent.item;
          const selectionOption = selectionContents.find(
            (c) => c.contentId === content.id
          );
          return (
            <HStack
              borderTop={'1px solid #d4d4d8'}
              key={`template content option: ${content.id}`}
              w='100%'
              h='52px'
              px='10px'
              justifyContent='space-between'
            >
              <HStack>
                <FontAwesomeIcon
                  className={(classNames.icon, classNames.faIcon)}
                  icon={faPlayCircle}
                  color={appColors.GRAY400}
                />
                <Text>{content.name}</Text>
              </HStack>
              <RadioGroup
                h='100%'
                w='200px'
                value={selectionOption?.selection ?? ''}
              >
                <HStack
                  h='100%'
                  px='10px'
                  w='100%'
                  justifyContent='space-between'
                >
                  <Radio
                    colorScheme='green'
                    value={QTemplateOptions.Mandatory}
                    onClick={() =>
                      setContentSelection(
                        content.id,
                        QTemplateOptions.Mandatory
                      )
                    }
                  />
                  <Radio
                    colorScheme='green'
                    value={QTemplateOptions.Optional}
                    onClick={() =>
                      setContentSelection(content.id, QTemplateOptions.Optional)
                    }
                  />
                  <Divider orientation='vertical' />
                  <Radio
                    colorScheme='green'
                    value={QTemplateOptions.Unavailable}
                    onClick={() =>
                      setContentSelection(
                        content.id,
                        QTemplateOptions.Unavailable
                      )
                    }
                  />
                </HStack>
              </RadioGroup>
            </HStack>
          );
        }
      })}
    </VStack>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
