import { useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Text,
  Stack,
  Input,
  Modal,
  ModalContent,
  Heading,
  ModalOverlay,
  ModalFooter,
  ModalBody,
  HStack,
  Container,
  useDisclosure,
  FormControl,
  InputGroup,
  FormErrorMessage,
} from '@chakra-ui/react';
import { TextInput } from '../../components/TextInput';
import { useForm } from 'react-hook-form';
import { isSome } from '../../config/Maybe';
import { useCreateProdGroupMutation } from '../../services/prodGroupsEndpoints';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentOrg } from '../../store/organizationSlice';
import { NewProdGroupParams, ProductGroupType } from '../../objects/ProdGroup';
import { PositiveConfirmModal } from './PositiveConfirmModal';
import { addGeneralError } from '../../store/toastSlice';

interface AddProdGroupPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddProdGroupPopup = ({
  isOpen,
  onClose,
}: AddProdGroupPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState,
    formState: { errors },
  } = useForm<NewProdGroupParams>();
  const [createProdGroup] = useCreateProdGroupMutation();
  const selectedOrg = useAppSelector(selectCurrentOrg);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    isOpen: isOpenConfirmUrlPopup,
    onOpen: onOpenConfirmUrlPopup,
    onClose: onCloseConfirmUrlPopup,
  } = useDisclosure();

  const prodGroupNameRegister = register('name', { required: true });
  const prodGroupNameText = watch('name');
  const prodGroupUrlText = watch('urlExtension');
  const prodGroupDescrRegister = register('description');
  const prodGroupDescrText = watch('description');

  const [prodGroupType, setProdGroupType] = useState<ProductGroupType>(
    ProductGroupType.System
  );

  const onSubmit = async (temporary: any): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
      temporary.organizationId = selectedOrg?.id;
      const response = await createProdGroup({
        ...temporary,
        urlExtension: encodeURIComponent(temporary.urlExtension),
      });
      if ('data' in response) {
        reset();
        onCloseConfirmUrlPopup();
        setIsLoading(false);
        onClose();
      } else {
        dispatch(
          addGeneralError(
            'Failed to create new Product Group, is your url unique?'
          )
        );
      }
    }
  };

  const allowOnlyValidUrlChars = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (encodeURIComponent(e.key) !== e.key) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {isOpenConfirmUrlPopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmUrlPopup}
          onClose={onCloseConfirmUrlPopup}
          title={`Use this URL extension? This cannot be changed.`}
          message={encodeURIComponent(prodGroupUrlText)}
          onCancel={onCloseConfirmUrlPopup}
          onConfirm={async () => {
            onCloseConfirmUrlPopup();
            await handleSubmit(onSubmit)();
          }}
        />
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onOpenConfirmUrlPopup();
        }}
      >
        <ModalContent py={5}>
          <ModalBody>
            <Stack px={5} spacing={4}>
              <Heading as='h1' size='md' textAlign='center'>
                Create a product group
              </Heading>
              <Box>
                <TextInput
                  placeholder='Product group name'
                  register={prodGroupNameRegister}
                  text={prodGroupNameText}
                />
                {isSome(errors.name) && errors.name.type === 'required' && (
                  <Text variant={'error'}>
                    You must enter a name. Please try again.
                  </Text>
                )}
              </Box>
              <FormControl
                isRequired
                isInvalid={!!formState.errors?.configurationName}
              >
                <FormLabel requiredIndicator={<></>}>
                  Configuration Name
                </FormLabel>
                <InputGroup>
                  <Input
                    placeholder='Configuration Name'
                    defaultValue={'Specific Configuration'}
                    {...register('configurationName', {
                      required: true,
                    })}
                  />
                </InputGroup>
                <Text paddingLeft='16px' color='gray.500' fontStyle={'italic'}>
                  Specific Configuration
                </Text>
                <FormErrorMessage>
                  <Text variant={'error'}>
                    {formState.errors.configurationName?.message}
                  </Text>
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!formState.errors?.configurationIdLabel}
              >
                <FormLabel requiredIndicator={<></>}>
                  Configuration ID Label
                </FormLabel>
                <InputGroup>
                  <Input
                    placeholder='Configuration ID Label'
                    defaultValue={'Configuration ID'}
                    {...register('configurationIdLabel', {
                      required: true,
                    })}
                  />
                </InputGroup>
                <Text paddingLeft='16px' color='gray.500' fontStyle={'italic'}>
                  Configuration ID
                </Text>
                <FormErrorMessage>
                  <Text variant={'error'}>
                    {formState.errors.configurationIdLabel?.message}
                  </Text>
                </FormErrorMessage>
              </FormControl>
              <Box>
                <TextInput
                  placeholder='Description (optional; not publicly viewable)'
                  register={prodGroupDescrRegister}
                  text={prodGroupDescrText}
                />
              </Box>
              <Box>
                <FormLabel>Custom URL extension</FormLabel>
                <HStack>
                  <Text color='gray.500'>
                    ...viduals.com/{selectedOrg?.urlExtension}/
                  </Text>
                  <Input
                    {...register('urlExtension', { required: true })}
                    placeholder='URL'
                    type='text'
                    onKeyPress={allowOnlyValidUrlChars}
                  />
                </HStack>
                {isSome(errors.urlExtension) &&
                  errors.urlExtension.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter a URL extension. Please try again.
                    </Text>
                  )}
              </Box>
              <Stack textAlign='center' ml={0}>
                <Heading as='h2' size='sm' pt={5} pb={3}>
                  Choose your product group type
                </Heading>
                <input
                  {...register('type', {
                    required: true,
                    value: prodGroupType,
                  })}
                  style={{ visibility: 'hidden', width: 0, height: 0 }}
                />
                <HStack alignItems='flex-start' w='100%' p={0} m={0}>
                  <Container
                    bg='gray.100'
                    borderRadius={8}
                    w='45%'
                    p={5}
                    onClick={() => {
                      setProdGroupType(ProductGroupType.System);
                    }}
                    variant={
                      prodGroupType === ProductGroupType.System
                        ? 'active-type'
                        : 'inactive-type'
                    }
                  >
                    <Heading as='h3' fontSize='md' pb={5}>
                      Pre-Configured
                    </Heading>
                    <Text>
                      Create pre-defined configurations for end users to select
                      by looking up an ID or by URL.
                    </Text>
                  </Container>
                  <Container
                    bg='gray.100'
                    borderRadius={8}
                    w='45%'
                    p={5}
                    onClick={() => {
                      setProdGroupType(ProductGroupType.Questionnaire);
                    }}
                    variant={
                      prodGroupType === ProductGroupType.Questionnaire
                        ? 'active-type'
                        : 'inactive-type'
                    }
                  >
                    <Heading as='h3' fontSize='md' pb={5}>
                      Questionnaire
                    </Heading>
                    <Text>
                      Make a user-friendly guide to let end users identify what
                      exactly is in their product.
                    </Text>
                  </Container>
                </HStack>
                {isSome(errors.type) && errors.type.type === 'required' && (
                  <Text variant={'error'}>
                    You must enter a product group type. Please try again.
                  </Text>
                )}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent='space-evenly'>
            <Button
              w='45%'
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Input
              type='submit'
              value='Create'
              w='45%'
              bg='viduals.greenDarker'
              color='white'
              fontWeight={500}
            />
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
