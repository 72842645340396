import {
  Heading,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { isSome } from '../../config/Maybe';

interface PositiveConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const PositiveConfirmModal = ({
  isOpen,
  onClose,
  title,
  message,
  onCancel,
  onConfirm,
}: PositiveConfirmModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent w='458px'>
        <ModalHeader pt={15}>
          <Heading as='h1' size='lg' textAlign='center'>
            {title}
          </Heading>
        </ModalHeader>
        {isSome(message) && (
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
        )}
        <ModalFooter>
          <HStack className='space-between width100'>
            <Button w='45%' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              w='45%'
              onClick={onConfirm}
              backgroundColor={appColors.GREEN_DARKER}
              color='white'
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
