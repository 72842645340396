import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Content, contentListToMap } from '../objects/Content';
import omit from 'lodash/omit';
import { RootState } from './store';

import { Folder } from '../objects/Folder';

interface FolderWithContent {
  folder: Folder;
  content: Content;
}

const initialContents: Record<string, Content> = {};

export const contentSlice = createSlice({
  name: 'contents',
  initialState: {
    contents: initialContents,
    rawContents: [] as Content[],
  },
  reducers: {
    setContents: (state, { payload: contents }: PayloadAction<Content[]>) => {
      state.contents = contentListToMap(contents);
    },
    setRawContents: (
      state,
      { payload: contents }: PayloadAction<Content[]>
    ) => {
      state.rawContents = contents;
    },
    createOrUpdateContents: (
      state,
      { payload: contentsToCreate }: PayloadAction<Content[]>
    ) => {
      contentsToCreate.forEach((contentToCreate: Content) => {
        state.contents[contentToCreate.id] = contentToCreate;
      });
    },
    deleteContentsByIds: (
      state,
      { payload: contentIdsToDelete }: PayloadAction<string[]>
    ) => {
      state.contents = omit(state.contents, contentIdsToDelete);
    },
    setParentFolder: (
      state,
      { payload: folderWithContent }: PayloadAction<FolderWithContent>
    ) => {
      state.contents[folderWithContent.content.id].parentFolderId =
        folderWithContent.folder.id;
    }, // not sure if this is necessary
  },
});
export const selectContentsMap = (state: RootState): Record<string, Content> =>
  state.contents.contents;

export const selectRawContents = (state: RootState): Content[] =>
  state.contents.rawContents;

// Action creators are generated for each case reducer function
export const {
  setContents,
  setRawContents,
  createOrUpdateContents,
  deleteContentsByIds,
} = contentSlice.actions;

export default contentSlice.reducer;
