import {
  ContentVersion,
  ContentVersionFileTypes,
} from '../../objects/ContentVersion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { HStack, Text, Center, VStack } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { useAppDispatch } from '../../store/hooks';
import {
  setContentToDisplay,
  setFileTypeToDisplay,
  setVersionToDisplay,
} from '../../store/endUserSlice';
import { Content } from '../../objects/Content';
import { isSome } from '../../config/Maybe';

interface VideoItemProps {
  contentVersion: ContentVersion;
  content: Content;
  pathName?: string;
  showDescription?: boolean;
  color: string | undefined;
  additionalOnClick?: () => void;
}

export const VideoItem = ({
  contentVersion,
  content,
  pathName,
  showDescription,
  color,
  additionalOnClick,
}: VideoItemProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const displayColor = color ?? appColors.GRAY400;

  return (
    <HStack
      justifyContent='flex-start'
      alignItems='center'
      w='100%'
      minH='fit-content'
      spacing={0}
    >
      <Center minWidth={7} h='100%'>
        <FontAwesomeIcon
          className={(classNames.icon, classNames.faIcon)}
          icon={faPlayCircle}
          color={displayColor}
        />
      </Center>
      <VStack
        w='100%'
        justifyContent='center'
        alignItems='flex-start'
        spacing={0}
      >
        <Text
          onClick={() => {
            dispatch(setFileTypeToDisplay(ContentVersionFileTypes.VIDEO));
            dispatch(setVersionToDisplay(contentVersion));
            dispatch(setContentToDisplay(content));
            if (isSome(additionalOnClick)) {
              additionalOnClick();
            }
          }}
          className={classNames.content}
          maxW='100%'
          isTruncated
        >
          {content.name}
        </Text>
        {isSome(pathName) && (
          <Text className={classNames.pathName} style={{ color: displayColor }}>
            {pathName}
          </Text>
        )}
        {isSome(content.description) && showDescription === true && (
          <Text className={classNames.description}>{content.description}</Text>
        )}
      </VStack>
    </HStack>
  );
};

const classNames = {
  content: 'content',
  icon: 'icon',
  faIcon: 'faIcon',
  pathName: 'end_user_search_result_path',
  description: 'end_user_search_result_description',
};
