import { useEffect, useState } from 'react';
import { Box, Text, HStack, Stack, IconButton, Image } from '@chakra-ui/react';
import { Customizations } from '../../../objects/Customizations';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { QOptionCard } from './QOptionCard';
import { isSome } from '../../../config/Maybe';
import { DefaultEndUserColors } from '../../../config/constants';
import { Questionnaire } from '../../../objects/Questions/Questionnaire';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectQuestionnaireSelectedTemplateOption,
  setQTemplateOption,
} from '../../../store/questionnaireAnswers';
import { StartQuestionnaire } from './StartQuestionnaire';
import { QuestionnaireForTemplate } from './QuestionnaireForTemplate';
import { buildFileGetQuestionImageUrlByProdGroup } from '../../../config/S3Client';

export interface EndUserQuestionnaireProps {
  questionnaire: Questionnaire;
  customizations?: Customizations;
}

export const EndUserQuestionnaire = ({
  customizations,
  questionnaire,
}: EndUserQuestionnaireProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const selectedQTemplateOption = useAppSelector(
    selectQuestionnaireSelectedTemplateOption
  );

  const imageUrl = buildFileGetQuestionImageUrlByProdGroup(
    questionnaire.productGroupId,
    questionnaire.imageUrl ?? ''
  );

  const renderQuestionImage = (imageUrl: string): JSX.Element => {
    return <Image src={imageUrl} w={'100%'} h='100%' fit='contain' />;
  };

  useEffect(() => {
    dispatch(setQTemplateOption(undefined));
    setShowIntro(true);
  }, []);

  return (
    <Box pt={10}>
      {showIntro ? (
        <StartQuestionnaire
          customizations={customizations}
          onProceed={() => setShowIntro(false)}
        />
      ) : (
        <>
          {isSome(selectedQTemplateOption) ? (
            <QuestionnaireForTemplate
              customizations={customizations}
              qtemplateOption={selectedQTemplateOption}
              pGroupId={questionnaire.productGroupId}
            />
          ) : (
            <HStack px={10} alignItems='flex-start'>
              <Box w={16} pt={1}>
                <IconButton
                  aria-label='back'
                  icon={<ArrowBackIcon w={6} h={6} />}
                  color={
                    isSome(customizations?.primaryTextColor)
                      ? '#' + String(customizations?.primaryTextColor)
                      : DefaultEndUserColors.PRIM_TEXT_COLOR
                  }
                  border='none'
                  onClick={() => setShowIntro(true)}
                />
              </Box>
              <Stack
                w='60%'
                minW={600}
                overflow='auto'
                h={`calc(100vh - 450px)`}
              >
                <Text variant='end-user-questionnaire'>
                  {questionnaire.text}
                </Text>
                {questionnaire.questionnaireTemplateOptions
                  .filter((qto) => qto.available)
                  .map((qto) => (
                    <QOptionCard
                      key={`qto answer for questionnaire:${qto.id}`}
                      customizations={customizations}
                      templateOption={qto}
                    />
                  ))}
              </Stack>
              <Box
                maxH={'100%'}
                w={'40%'}
                display={'flex'}
                flexDirection={'column'}
              >
                {questionnaire.imageUrl && renderQuestionImage(imageUrl)}
                <Text>{questionnaire.helpText}</Text>
              </Box>
            </HStack>
          )}
        </>
      )}
    </Box>
  );
};
