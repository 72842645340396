export const Routes = {
  FOLDERS_AND_CONTENT: '/',
  LOGIN: '/login',
  PRODUCT_GROUPS: '/prodGroups',
  CUSTOMIZATIONS: '/customizations',
  SUPER_ADMIN: '/superadmin',
  TAGS: '/tags',
  SYSTEMS: '/systems',
  QUESTIONNAIRE: '/questionnaire',
  QUESTIONNAIRE_RESULTS: '/questionnaire/results',
};
