import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    viduals: {
      blueLight: '#d9fbff',
      blueOriginal: '#00e5ff',
      blueDarker: '#05b7de',
      greenOriginal: '#00d700',
      greenDarker: '#419941',
      RED: '#B91C1C',
      GRAY_MEDIUM: '#f4f4f5',
      BORDERGRAY: '#D4D4D8',
      BORDERBLUE: '#0055C9',
    },
  },
  styles: {
    global: {
      body: {
        fontFamily: `'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Arial',
        Segoe UI, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', sans-serif`,
      },
    },
  },
  fonts: {
    heading: `'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Arial',
    Segoe UI, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  layerStyles: {
    selected: {
      border: 'none',
      borderRadius: 0,
      justifyContent: 'left',
      boxShadow: 'inset 4px 0px 0px #0055C9',
      backgroundColor: 'viduals.GRAY_MEDIUM',
      color: '#52525b',
    },
    'selected-row': {
      borderRadius: 0,
      justifyContent: 'left',
      boxShadow: 'inset 4px 0px 0px #0055C9',
      backgroundColor: 'blue.50',
      color: '#52525b',
    },
    'warning-row': {
      borderRadius: 0,
      justifyContent: 'left',
      backgroundColor: 'yellow.100',
      color: '#52525b',
    },
    'warning-box': {
      border: '2px solid',
      borderColor: 'yellow.200',
      borderRadius: '4px',
      justifyContent: 'space-between',
      backgroundColor: 'yellow.100',
      color: '#52525b',
    },
    'alt-warning-row': {
      borderRadius: 0,
      justifyContent: 'left',
      backgroundColor: 'yellow.200',
      color: '#52525b',
    },
    'questionnaire-card': {
      bg: 'gray.50',
      borderColor: 'gray.300',
      borderWidth: 1,
      boxSizing: 'border-box',
      p: 5,
      rounded: 'xl',
      w: 400,
    },
    'questionnaire-card-selected': {
      bg: 'gray.50',
      borderColor: 'viduals.greenDarker',
      borderWidth: 3,
      boxSizing: 'border-box',
      p: 5,
      rounded: 'xl',
      w: 400,
    },
    'questionnaire-option-checkbox': {
      bg: 'white',
      h: 50,
      w: '100%',
      p: 3,
      rounded: 'md',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
    },
    'questionnaire-option-checkbox-selected': {
      borderColor: 'viduals.greenDarker',
      borderWidth: 3,
      bg: 'blue.50',
    },
    'questionnaire-option-checkbox-checked': {
      borderColor: 'viduals.greenDarker',
      borderWidth: 1,
      bg: 'blue.50',
    },
    'questionnaire-option-checkbox-unchecked': {
      borderColor: 'gray.300',
      borderWidth: 1,
    },
    'questionnaire-tag-option': {
      borderColor: 'gray.500',
      borderWidth: 1,
      borderRadius: '10px',
      marginRight: '10px',
      padding: '10px',
    },
  },
  components: {
    Button: {
      baseStyle: {
        bg: 'transparent',
        fontWeight: 500,
        _hover: { bg: '#80808080' },
      },
      variants: {
        'with-shadow': {
          bg: 'transparent',
          boxShadow: '0 0 2px 2px #efdfde',
        },
        outline: {
          color: 'viduals.greenDarker',
          borderColor: 'viduals.greenDarker',
        },
        fill: {
          bg: 'viduals.greenDarker',
          color: 'white',
        },
        delete: {
          borderColor: 'red.700',
          color: 'red.700',
        },
        'delete-confirm': {
          bg: 'red.700',
          borderColor: 'red.700',
          color: 'white',
        },
        'side-nav-bar': {
          color: 'gray.400',
          border: 'none',
          borderRadius: 0,
          justifyContent: 'left',
        },
        'side-nav-bar-active': {
          border: 'none',
          borderRadius: 0,
          justifyContent: 'left',
          boxShadow: 'inset 4px 0px 0px #0055C9',
          backgroundColor: 'viduals.GRAY_MEDIUM',
          color: '#52525b',
        },
        solid: () => ({
          bg: 'transparent',
          color: 'viduals.greenDarker',
        }),
        small: () => ({
          w: 170,
          color: 'viduals.greenDarker',
        }),
      },
    },
    Container: {
      baseStyle: {},
      variants: {
        'active-type': {
          border: '2px solid',
          borderColor: 'viduals.greenDarker',
          boxSizing: 'content-box',
        },
        'inactive-type': {
          boxSizing: 'content-box',
        },
      },
    },
    Heading: {
      variants: {
        questionnaire: {
          color: 'gray.500',
          fontWeight: 400,
          fontSize: 16,
        },
      },
    },
    FormLabel: {
      baseStyle: {
        color: 'gray.600',
        fontWeight: 700,
        fontSize: 14,
        margin: 0,
      },
    },
    Icon: {
      baseStyle: {
        w: 16,
        h: 16,
        px: 5,
        mx: 5,
      },
      variants: {
        'i-con': {
          bg: '#000',
        },
      },
    },
    IconButton: {
      // no work
      baseStyle: {
        border: 'none',
        borderWidth: 0,
      },
    },
    Input: {
      variants: {
        questionnaire: {
          bg: 'white',
          borderColor: 'gray.300',
          borderWidth: 1,
          boxSizing: 'border-box',
          rounded: 'md',
          resize: 'none',
          focusBorderColor: 'viduals.greenDarker',
        },
      },
    },
    ListItem: {
      baseStyle: {},
      variants: {
        active: {
          backgroundColor: 'gray.100',
          boxShadow: 'inset 4px 0px 0px viduals.BORDERBLUE',
        },
        inactive: {},
      },
    },
    Text: {
      variants: {
        label: {
          color: 'gray.600',
          fontWeight: 700,
          fontSize: 14,
        },
        error: {
          color: 'red.600',
          pt: 1,
          pl: 1,
          fontSize: 'sm',
        },
        'error-box': {
          border: '1px solid',
          borderColor: 'red.300',
          color: 'red.600',
          bg: 'red.100',
          borderRadius: 4,
          p: 1,
          mt: 3,
          textAlign: 'center',
        },
        'content-version-tag': {
          border: '1px solid',
          borderColor: 'gray.500',
          color: 'gray.500',
          fontWeight: 500,
          borderRadius: 999,
          fontSize: '16px',
          px: 2,
          py: 0.5,
          textAlign: 'center',
        },
        'active-content-version-tag': {
          border: '1px solid',
          borderColor: 'gray.500',
          backgroundColor: 'gray.500',
          color: 'white',
          fontWeight: 500,
          borderRadius: 999,
          fontSize: '16px',
          px: 2,
          py: 0.5,
          textAlign: 'center',
        },
        questionnaire: {
          color: 'gray.500',
          fontWeight: 400,
          fontSize: 16,
        },
        'end-user-questionnaire': {
          fontSize: '2xl',
          fontWeight: 700,
        },
      },
    },
    Textarea: {
      variants: {
        questionnaire: {
          bg: 'white',
          borderColor: 'gray.300',
          borderWidth: 1,
          boxSizing: 'border-box',
          rounded: 'md',
          resize: 'none',
          focusBorderColor: 'viduals.greenDarker',
        },
      },
    },
    Th: {
      baseStyle: { color: 'gray.300' },
    },
  },
});

export default theme;
