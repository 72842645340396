import { Box } from '@chakra-ui/react';
import { Customizations } from '../../objects/Customizations';
import { System } from '../../objects/System';
import { ContentFrame } from './ContentFrame';
import { ContentInfo } from './ContentInfo';

interface ContentViewerProps {
  system: System | undefined;
  customizations: Customizations | null | undefined;
}

export const ContentViewer = ({
  system,
  customizations,
}: ContentViewerProps): JSX.Element => {
  return (
    <Box mx={10} w='100%' overflowY='hidden'>
      <ContentFrame system={system} customizations={customizations} />
      <ContentInfo system={system} customizations={customizations} />
    </Box>
  );
};
