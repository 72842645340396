import { Text, IconButton, Tr, Td, useDisclosure } from '@chakra-ui/react';
import { User } from '../../objects/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { appColors } from '../../config/constants';
import 'firebase/app';
import 'firebase/auth';
import { useFirebase } from '../../contexts/firebase';
import { RemoveUserPopup } from '../modal/superadmin/RemoveUserPopup';

interface UserItemProps {
  user: User;
}

export const UserItem = ({ user }: UserItemProps): JSX.Element => {
  const app = useFirebase();

  const {
    isOpen: isOpenDeleteContentPopup,
    onOpen: onOpenDeleteUserPopup,
    onClose: onCloseDeleteUserPopup,
  } = useDisclosure();

  return (
    <Tr>
      <Td>{user.email}</Td>
      <Td>
        <Text noOfLines={2}>{user.displayName}</Text>
      </Td>
      <Td textAlign='right'>
        <IconButton
          aria-label='Send Reset Email'
          border='none'
          icon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faEnvelope}
              style={{ width: '18px', height: '24px' }}
            />
          }
          onClick={async () => {
            await app.auth().sendPasswordResetEmail(user.email ?? '');
          }}
        />
        <IconButton
          aria-label='Delete User'
          border='none'
          icon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faTrashAlt}
              style={{
                width: '16px',
                height: '18px',
                color: appColors.RED,
              }}
            />
          }
          onClick={onOpenDeleteUserPopup}
        />
        <RemoveUserPopup
          isOpen={isOpenDeleteContentPopup}
          onClose={onCloseDeleteUserPopup}
          user={user}
        />
      </Td>
    </Tr>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
