import firebase from 'firebase/app';
import 'firebase/auth';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'local';
const isLocal = ENVIRONMENT === 'local' || ENVIRONMENT === 'docker';

const app = isLocal
  ? firebase.initializeApp({
      apiKey: 'AIzaSyBAtHCItdeqtsMSfDwGwlnodRSADTbPkVQ',
      projectId: 'demo-viduals',
    })
  : firebase.initializeApp({
      apiKey: 'AIzaSyBAtHCItdeqtsMSfDwGwlnodRSADTbPkVQ',
      projectId: 'viduals-20c32',
    });

export const firebaseApp = app;
