/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Stack, HStack, Heading, Text, Button } from '@chakra-ui/react';
import { RefObject, useEffect, useState } from 'react';
import { ColorInput } from '../../components/ColorInput';
import { isSome } from '../../config/Maybe';
import { ImageIcon } from '../../config/icons';
import { ImagePreview } from '../../components/ImagePreview';
import { Customizations } from '../../objects/Customizations';
import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { buildFileGetUrlByProdGroup } from '../../config/S3Client';
import { ProdGroup } from '../../objects/ProdGroup';

export interface ColorAppearanceProps {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  errors: DeepMap<any, FieldError>;
  logoImageRef: RefObject<HTMLInputElement>;
  coverImageRef: RefObject<HTMLInputElement>;
  bannerImageRef: RefObject<HTMLInputElement>;
  customizations: Customizations | null | undefined;
  prodGroup: ProdGroup | null | undefined;
}

export const ColorAppearance = ({
  register,
  watch,
  setValue,
  errors,
  logoImageRef,
  coverImageRef,
  bannerImageRef,
  customizations,
  prodGroup,
}: ColorAppearanceProps): JSX.Element => {
  useEffect(() => {
    setValue(PRIM_COLOR_KEY, customizations?.primaryColor ?? '');
    setValue(SEC_COLOR_KEY, customizations?.secondaryColor ?? '');
    setValue(BTN_COLOR_KEY, customizations?.buttonColor ?? '');
    setValue(PRIM_TEXT_COLOR_KEY, customizations?.primaryTextColor ?? '');
    setValue(SEC_TEXT_COLOR_KEY, customizations?.secondaryTextColor ?? '');

    setImageFromCustomizations(
      customizations?.logoImageName ?? '',
      setLogoImageState,
      setLogoImageNameState
    );
    setImageFromCustomizations(
      customizations?.coverImageName ?? '',
      setCoverImageState,
      setCoverImageNameState
    );
    setImageFromCustomizations(
      customizations?.bannerImageName ?? '',
      setBannerImageState,
      setBannerImageNameState
    );
  }, [customizations]);

  const primaryColorRegister = register(PRIM_COLOR_KEY, {
    required: true,
  });
  const primaryColorText: string = watch(PRIM_COLOR_KEY);

  const secondaryColorRegister = register(SEC_COLOR_KEY, {
    required: true,
  });
  const secondaryColorText: string = watch(SEC_COLOR_KEY);

  const buttonColorRegister = register(BTN_COLOR_KEY, {
    required: true,
  });
  const buttonColorText: string = watch(BTN_COLOR_KEY);

  const primaryTextColorRegister = register(PRIM_TEXT_COLOR_KEY, {
    required: true,
  });
  const primaryTextColorText: string = watch(PRIM_TEXT_COLOR_KEY);

  const secondaryTextColorRegister = register(SEC_TEXT_COLOR_KEY, {
    required: true,
  });
  const secondaryTextColorText: string = watch(SEC_TEXT_COLOR_KEY);

  // the useRef hook does not trigger re-renders, so we set these state
  // variables when the selected file changes
  const [logoImage, setLogoImageState] = useState<File | undefined>(undefined);
  const [logoImageName, setLogoImageNameState] = useState<string>('');
  const [logoImageUrl, setLogoImageUrl] = useState<string>('');

  const [bannerImage, setBannerImageState] = useState<File | undefined>(
    undefined
  );
  const [bannerImageName, setBannerImageNameState] = useState<string>('');
  const [bannerImageUrl, setBannerImageUrl] = useState<string>('');

  const [coverImage, setCoverImageState] = useState<File | undefined>(
    undefined
  );
  const [coverImageName, setCoverImageNameState] = useState<string>('');
  const [coverImageUrl, setCoverImageUrl] = useState<string>('');

  useEffect(() => {
    if (isSome(logoImage)) {
      setLogoImageUrl(URL.createObjectURL(logoImage));
    }
  }, [logoImage]);

  useEffect(() => {
    if (isSome(bannerImage)) {
      setBannerImageUrl(URL.createObjectURL(bannerImage));
    }
  }, [bannerImage]);

  useEffect(() => {
    if (isSome(coverImage)) {
      setCoverImageUrl(URL.createObjectURL(coverImage));
    }
  }, [coverImage]);

  const setImageFromCustomizations = async (
    imageName: string,
    setImageState: (file: File) => void,
    setImageNameState: (name: string) => void
  ): Promise<void> => {
    if (isSome(customizations)) {
      const response = await fetch(
        buildFileGetUrlByProdGroup(prodGroup, imageName)
      );
      const data = await response.blob();
      const metadata = {
        type: 'image',
      };
      const file = new File(
        [data],
        customizations?.helpImageName ?? '',
        metadata
      );
      setImageState(file);
      setImageNameState(imageName);
    }
  };

  const setImage = (
    ref: RefObject<HTMLInputElement>,
    setImageState: (file: File) => void,
    setImageNameState: (name: string) => void
  ): void => {
    const file = ref?.current?.files?.item(0) ?? undefined;
    if (file !== undefined) {
      setImageState(file);
      setImageNameState(file.name);
    }
  };

  const removeImage = (
    ref: RefObject<HTMLInputElement>,
    setImageState: (file: File | undefined) => void,
    setImageNameState: (name: string) => void
  ): void => {
    if (isSome(ref) && isSome(ref.current)) {
      ref.current.files = null;
      setImageState(undefined);
    }
    setImageNameState('');
  };

  const renderButton = (
    imageRef: React.RefObject<HTMLInputElement>,
    imageLabel: string
  ): JSX.Element => {
    return (
      <Button
        onClick={() => {
          imageRef?.current?.click();
        }}
        leftIcon={<ImageIcon />}
      >
        Add a {imageLabel} image
      </Button>
    );
  };

  const renderErrorMessage = (): JSX.Element => {
    if (
      isSome(errors.primaryColor) ||
      isSome(errors.secondaryColor) ||
      isSome(errors.buttonColor) ||
      isSome(errors.primaryTextColor) ||
      isSome(errors.secondaryTextColor)
    ) {
      return <Text variant={'error'}>Please fill out all fields.</Text>;
    }
    return <></>;
  };

  return (
    <Box>
      <Heading as='h2' size='md' pb={2}>
        Color and appearance
      </Heading>
      <HStack w='100%' alignItems='flex-start' spacing='5%'>
        <Stack w='50%' spacing={2}>
          {renderErrorMessage()}
          <ColorInput
            placeholder='Primary color'
            register={primaryColorRegister}
            color={
              isSome(primaryColorText)
                ? primaryColorText
                : String(customizations?.primaryColor)
            }
          />
          <ColorInput
            placeholder='Secondary color'
            register={secondaryColorRegister}
            color={
              isSome(secondaryColorText)
                ? secondaryColorText
                : String(customizations?.secondaryColor)
            }
          />
          <ColorInput
            placeholder='Button color'
            register={buttonColorRegister}
            color={
              isSome(buttonColorText)
                ? buttonColorText
                : String(customizations?.buttonColor)
            }
          />
          <ColorInput
            placeholder='Text color'
            register={primaryTextColorRegister}
            color={
              isSome(primaryTextColorText)
                ? primaryTextColorText
                : String(customizations?.primaryTextColor)
            }
          />
          <ColorInput
            placeholder='Secondary text color'
            register={secondaryTextColorRegister}
            color={
              isSome(secondaryTextColorText)
                ? secondaryTextColorText
                : String(customizations?.secondaryTextColor)
            }
          />
          {(isSome(customizations?.logoImageName) &&
            customizations?.logoImageName !== '' &&
            isSome(logoImage)) ||
          ((customizations?.logoImageName === undefined ||
            customizations?.logoImageName === '') &&
            isSome(logoImage) &&
            logoImageName !== '') ? (
            <ImagePreview
              fileUrl={logoImageUrl}
              imageLabel='Logo'
              imageName={logoImageName}
              removeFunc={() =>
                removeImage(
                  logoImageRef,
                  setLogoImageState,
                  setLogoImageNameState
                )
              }
            />
          ) : (
            renderButton(logoImageRef, 'logo')
          )}
          {(isSome(customizations?.coverImageName) &&
            customizations?.coverImageName !== '' &&
            isSome(coverImage)) ||
          ((customizations?.coverImageName === undefined ||
            customizations?.coverImageName === '') &&
            isSome(coverImage) &&
            coverImageName !== '') ? (
            <ImagePreview
              fileUrl={coverImageUrl}
              imageLabel='Cover'
              imageName={coverImageName}
              removeFunc={() =>
                removeImage(
                  coverImageRef,
                  setCoverImageState,
                  setCoverImageNameState
                )
              }
            />
          ) : (
            renderButton(coverImageRef, 'cover')
          )}
          {(isSome(customizations?.bannerImageName) &&
            customizations?.bannerImageName !== '' &&
            isSome(bannerImage)) ||
          ((customizations?.bannerImageName === undefined ||
            customizations?.bannerImageName === '') &&
            isSome(bannerImage) &&
            bannerImageName !== '') ? (
            <ImagePreview
              fileUrl={bannerImageUrl}
              imageLabel='Banner'
              imageName={bannerImageName}
              removeFunc={() =>
                removeImage(
                  bannerImageRef,
                  setBannerImageState,
                  setBannerImageNameState
                )
              }
            />
          ) : (
            renderButton(bannerImageRef, 'banner')
          )}
          <input
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            ref={logoImageRef}
            onChange={() =>
              setImage(logoImageRef, setLogoImageState, setLogoImageNameState)
            }
          />
          <input
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            ref={coverImageRef}
            onChange={() =>
              setImage(
                coverImageRef,
                setCoverImageState,
                setCoverImageNameState
              )
            }
          />
          <input
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            ref={bannerImageRef}
            onChange={() =>
              setImage(
                bannerImageRef,
                setBannerImageState,
                setBannerImageNameState
              )
            }
          />
        </Stack>
        <Stack w='50%'>
          <Heading as='h3' size='xs' color='gray.500' fontWeight={400}>
            Color preview
          </Heading>
          <Box
            h={270}
            padding={10}
            bg={
              isSome(primaryColorText)
                ? '#' + primaryColorText
                : '#' + String(customizations?.primaryColor)
            }
            borderRadius={8}
          >
            <Stack spacing={5}>
              <Heading
                size='md'
                color={
                  isSome(primaryTextColorText)
                    ? '#' + primaryTextColorText
                    : '#' + String(customizations?.primaryTextColor)
                }
              >
                Example
              </Heading>
              <Text
                color={
                  isSome(primaryTextColorText)
                    ? '#' + primaryTextColorText
                    : '#' + String(customizations?.primaryTextColor)
                }
              >
                The primary color will be used as the main part.
              </Text>
              <Button
                variant='fill'
                borderColor={
                  isSome(buttonColorText)
                    ? '#' + buttonColorText
                    : '#' + String(customizations?.buttonColor)
                }
                bg={
                  isSome(buttonColorText)
                    ? '#' + buttonColorText
                    : '#' + String(customizations?.buttonColor)
                }
                color={
                  isSome(primaryTextColorText)
                    ? '#' + primaryTextColorText
                    : '#' + String(customizations?.primaryTextColor)
                }
              >
                Button Example
              </Button>
              <Text
                color={
                  isSome(secondaryTextColorText)
                    ? '#' + secondaryTextColorText
                    : '#' + String(customizations?.secondaryTextColor)
                }
                bg={
                  isSome(secondaryColorText)
                    ? '#' + secondaryColorText
                    : '#' + String(customizations?.secondaryColor)
                }
              >
                The secondary color will be used for the sidebar.
              </Text>
            </Stack>
          </Box>
        </Stack>
      </HStack>
    </Box>
  );
};

const PRIM_COLOR_KEY = 'primaryColor';
const SEC_COLOR_KEY = 'secondaryColor';
const BTN_COLOR_KEY = 'buttonColor';
const PRIM_TEXT_COLOR_KEY = 'primaryTextColor';
const SEC_TEXT_COLOR_KEY = 'secondaryTextColor';
