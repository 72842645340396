import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { isSome } from '../../config/Maybe';
import { Routes } from '../../config/Routes';
import { ProdGroup, ProductGroupType } from '../../objects/ProdGroup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectProductGroupToEdit,
  setProductGroupToEdit,
} from '../../store/modalFormSlice';
import {
  selectProductGroups,
  setCurrentProductGroup,
} from '../../store/productGroupSlice';
import { EditProdGroupPopup } from '../modal/productGroup/EditProdGroupPopup';
import { appColors } from '../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

export const ProdGroupsTable = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const prodGroups = useAppSelector(selectProductGroups);
  const prodGroupToEdit = useAppSelector(selectProductGroupToEdit);
  const history = useHistory();

  const onSelectProductGroup = (pGroup: ProdGroup): void => {
    dispatch(setCurrentProductGroup(pGroup));
    history.push(Routes.FOLDERS_AND_CONTENT);
  };

  const pGroupTypeToLabel = (type: ProductGroupType): string => {
    if (type === ProductGroupType.Questionnaire) return 'Questionnaire';
    return 'Pre-Configured';
  };

  const renderButtons = (pGroup: ProdGroup): JSX.Element => {
    return (
      <HStack h='100%'>
        <Tooltip label={'Edit product group'} aria-label='edit product group'>
          <IconButton
            aria-label='edit product group'
            color={appColors.GREEN_DARKER}
            border='none'
            h='90%'
            onClick={(event) => {
              event.stopPropagation();
              dispatch(setProductGroupToEdit(pGroup));
            }}
            className={classNames.button}
            icon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faPen}
              />
            }
          />
        </Tooltip>
      </HStack>
    );
  };

  return (
    <Box>
      {isSome(prodGroupToEdit) && (
        <EditProdGroupPopup productGroup={prodGroupToEdit} />
      )}
      <Heading as='h1' fontSize='lg' color='gray.900' pt={5} pb={5}>
        Product groups
      </Heading>
      <Table variant='simple' size='sm'>
        <Thead bg='gray.100'>
          <Tr color='gray.300'>
            <Th>Name</Th>
            <Th>Configuration Name</Th>
            <Th>Filtration Method</Th>
            <Th>Created on</Th>
            <Th>Last Updated</Th>
            <Th>URL Extension</Th>
            <Th>Description</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {prodGroups?.map((prodGroup) => {
            return (
              <Tr
                key={prodGroup.id}
                _hover={{ backgroundColor: appColors.GRAY_LIGHT }}
                onClick={() => {
                  onSelectProductGroup(prodGroup);
                }}
                style={{ cursor: 'pointer' }}
                className={classNames.tableRow}
              >
                <Td>{prodGroup.name}</Td>
                <Td>
                  <Tooltip label='configuration'>
                    <Text as='span'>{prodGroup.configurationName}</Text>
                  </Tooltip>
                </Td>
                <Td>{pGroupTypeToLabel(prodGroup.type)}</Td>
                <Td>{new Date(prodGroup.createdDate).toLocaleDateString()}</Td>
                <Td>{new Date(prodGroup.updatedDate).toLocaleDateString()}</Td>
                <Td>.../{prodGroup.urlExtension}</Td>
                <Td>
                  <Text noOfLines={2}>{prodGroup.description}</Text>
                </Td>
                <Td>{renderButtons(prodGroup)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
  button: 'system_table_hover_button',
  tableRow: 'system_table_row',
};
