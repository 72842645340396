import { Box, Flex, Text, Image, HStack } from '@chakra-ui/react';
import VidualsIcon from '../../images/vidualsIcon.png';
import { useGetCustomizationsByProductGroupIdQuery } from '../../services/customizationsEndpoints';
import { isSome } from '../../config/Maybe';
import { selectQPGroup, selectQSystem } from '../../store/QSystemSlice';
import { QSystemHeader } from './QSystem/QSystemHeader';
import { useAppSelector } from '../../store/hooks';
import { QSystemSidebar } from './QSystem/QSystemSidebar';
import { QSystemContentViewer } from './QSystem/QSystemContentViewer';
import { useState, useEffect } from 'react';
import { ProdGroup } from '../../objects/ProdGroup';
import { EndUserContextContext } from '../../contexts/EndUserContext';

export const EndUserSystemFromQuestionnairePage = (): JSX.Element => {
  const [productGroup, setProdGroup] = useState<ProdGroup | undefined>();
  const qSystem = useAppSelector(selectQSystem);
  const qPGroup = useAppSelector(selectQPGroup);

  const { data: currentCustomizations } =
    useGetCustomizationsByProductGroupIdQuery(qSystem?.productGroupId ?? '', {
      skip: !isSome(qSystem),
    });

  useEffect(() => {
    setProdGroup(qPGroup);
  }, [qPGroup]);

  return (
    <EndUserContextContext.Provider value={{ productGroup, setProdGroup }}>
      <Box
        w='100vw'
        h='100vh'
        bg={
          isSome(currentCustomizations?.primaryColor)
            ? '#' + String(currentCustomizations?.primaryColor)
            : 'black'
        }
        color={
          isSome(currentCustomizations?.primaryTextColor)
            ? '#' + String(currentCustomizations?.primaryTextColor)
            : 'white'
        }
      >
        {isSome(qSystem) ? (
          <>
            <QSystemHeader
              qSystem={qSystem}
              customizations={currentCustomizations}
            />
            {/* Calc height minus height of header */}
            <Flex h='calc(100vh - 134px)'>
              <QSystemSidebar
                qSystem={qSystem}
                customizations={currentCustomizations}
              />
              <QSystemContentViewer
                prodGroupId={qSystem.productGroupId}
                customizations={currentCustomizations}
              />
            </Flex>
          </>
        ) : (
          <Text>
            No questionnaire results found, are you sure you completed it?
          </Text>
        )}
        <HStack bottom='5' right='10' position='absolute'>
          <Image src={VidualsIcon} />
          <Text color='gray.300'>Powered by Viduals TM 2021</Text>
        </HStack>
      </Box>
    </EndUserContextContext.Provider>
  );
};
