import { Button, HStack, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { DefaultEndUserColors } from '../../../config/constants';
import { isSome } from '../../../config/Maybe';
import { Customizations } from '../../../objects/Customizations';
import { QuestionnaireTemplateOption } from '../../../objects/Questions/QuestionnaireTemplateOption';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectQTemplateById } from '../../../store/qTemplateSlice';
import { setQTemplateOption } from '../../../store/questionnaireAnswers';

interface QOptionCardProps {
  customizations?: Customizations;
  templateOption: QuestionnaireTemplateOption;
}
export const QOptionCard = ({
  customizations,
  templateOption,
}: QOptionCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const qTemplate = useAppSelector((state) =>
    selectQTemplateById(state, templateOption.questionnaireTemplateId)
  );

  const onSelect = (): void => {
    dispatch(setQTemplateOption(templateOption));
  };

  const textColor = isSome(customizations?.primaryTextColor)
    ? '#' + String(customizations?.primaryTextColor)
    : DefaultEndUserColors.PRIM_TEXT_COLOR;
  const primaryColor = isSome(customizations?.primaryColor)
    ? '#' + String(customizations?.primaryColor)
    : DefaultEndUserColors.PRIM_COLOR;

  const [selected] = useState<boolean>(false);

  return (
    <>
      {isSome(qTemplate) ? (
        <Button
          w={569}
          h={83}
          minH={83}
          bg={!selected ? primaryColor : textColor}
          borderColor={'var(--vid-customizations-button-color)'}
          color={'var(--vid-customizations-button-color)'}
          borderWidth={!selected ? 1 : 2}
          borderRadius='md'
          justifyContent='center'
          boxSizing='border-box'
          onClick={onSelect}
        >
          <HStack w='100%' h='100%' alignItems='center'>
            {isSome(qTemplate.imageUrl) && qTemplate.imageUrl !== '' && (
              <Image src={qTemplate.imageUrl} w={134} h='100%' fit='contain' />
            )}
            <Text>{qTemplate.name}</Text>
          </HStack>
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};
