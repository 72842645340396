import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DefaultEndUserColors } from '../../../config/constants';
import { isNone, isSome } from '../../../config/Maybe';
import { Routes } from '../../../config/Routes';
import { useEndUserContextContext } from '../../../contexts/EndUserContext';
import { Customizations } from '../../../objects/Customizations';
import { ItemType } from '../../../objects/FolderOrContentItem';
import {
  QTemplate,
  QTemplateOptions,
} from '../../../objects/QuestionnaireTemplate';
import { FolderOrContentQuestion } from '../../../objects/Questions/FolderOrContentQuestion';
import { ITagGroupQuestion } from '../../../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../../../objects/Questions/UngroupedTagQuestion';
import { QSystem } from '../../../objects/System';
import { useAppDispatch } from '../../../store/hooks';
import { setQPGroup, setQSystem } from '../../../store/QSystemSlice';
import { setQTemplateOption } from '../../../store/questionnaireAnswers';
import { QuestionAnswerForm } from './QuestionAnswerForm';

interface QuestionListProps {
  customizations?: Customizations;
  allQuestions: Array<
    FolderOrContentQuestion | ITagGroupQuestion | IUngroupedTagQuestion
  >;
  updateQuestion: (
    updatedQuestion:
      | FolderOrContentQuestion
      | ITagGroupQuestion
      | IUngroupedTagQuestion
  ) => void;
  pGroupId: string;
  qTemplate: QTemplate;
}

export const QuestionList = ({
  customizations,
  allQuestions,
  updateQuestion,
  pGroupId,
  qTemplate,
}: QuestionListProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  const history = useHistory();
  const dispatch = useAppDispatch();
  // index of current questions
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleGoBack = (): void => {
    if (currentIndex === 0) {
      dispatch(setQTemplateOption(undefined));
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Using this to avoid finishing before last answer reaches state
  // TODO: if a template doesn't have any questions what to do?
  useEffect(() => {
    if (allQuestions.length > 0 && isNone(allQuestions[currentIndex])) {
      onFinish();
    }
  }, [currentIndex]);

  const onFinish = (): void => {
    const checkedFolderIds: string[] = [];
    const checkedContentIds: string[] = [];
    const checkedTagIds: string[] = [];

    allQuestions.forEach((q) => {
      const asFolderContentQuestion = q as FolderOrContentQuestion;
      if (isSome(asFolderContentQuestion.type)) {
        // is a FCQuestion
        if (asFolderContentQuestion.userBoolAnswer === true) {
          if (asFolderContentQuestion.type === ItemType.Folder) {
            checkedFolderIds.push(asFolderContentQuestion.folderOrContentId);
          } else {
            checkedContentIds.push(asFolderContentQuestion.folderOrContentId);
          }
        }
      } else {
        const asGroupQuestion = q as ITagGroupQuestion;
        // is a TGQuestion
        if (isSome(asGroupQuestion.tagGroupId)) {
          if (isSome(asGroupQuestion.userIdAnswer)) {
            checkedTagIds.push(asGroupQuestion.userIdAnswer);
          }
        } else {
          const asUngroupedQuestion = q as IUngroupedTagQuestion;
          if (
            isSome(asUngroupedQuestion.tagId) &&
            asUngroupedQuestion.userBoolAnswer === true
          ) {
            checkedTagIds.push(asUngroupedQuestion.tagId);
          }
        }
      }
    });

    qTemplate.qTemplateTags.forEach((qtt) => {
      if (qtt.selection === QTemplateOptions.Mandatory) {
        checkedTagIds.push(qtt.tagId);
      }
    });

    qTemplate.qTemplateContents.forEach((qtc) => {
      if (qtc.selection === QTemplateOptions.Mandatory) {
        checkedContentIds.push(qtc.contentId);
      }
    });

    qTemplate.qTemplateFolders.forEach((qtf) => {
      if (qtf.selection === QTemplateOptions.Mandatory) {
        checkedFolderIds.push(qtf.folderId);
      }
    });

    const qSystem: QSystem = {
      productGroupId: pGroupId,
      checkedFolderIds: checkedFolderIds,
      checkedTagIds: checkedTagIds,
      checkedContentIds: checkedContentIds,
    };
    dispatch(setQSystem(qSystem));
    dispatch(setQPGroup(productGroup));
    history.push(Routes.QUESTIONNAIRE_RESULTS);
  };

  const answerQuestion = (answer: boolean | string): void => {
    if (isSome(allQuestions[currentIndex])) {
      const asTagGroupQuestion = allQuestions[
        currentIndex
      ] as ITagGroupQuestion;
      if (isSome(asTagGroupQuestion.tagGroupId)) {
        updateQuestion({
          ...allQuestions[currentIndex],
          userIdAnswer: answer as string,
        });
      } else {
        updateQuestion({
          ...allQuestions[currentIndex],
          userBoolAnswer: answer as boolean,
        });
      }
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <HStack px={10} alignItems='flex-start'>
      <Box w={16} pt={1}>
        <IconButton
          aria-label='back'
          icon={<ArrowBackIcon w={6} h={6} />}
          color={
            isSome(customizations?.primaryTextColor)
              ? '#' + String(customizations?.primaryTextColor)
              : DefaultEndUserColors.PRIM_TEXT_COLOR
          }
          border='none'
          onClick={handleGoBack}
        />
      </Box>
      {isSome(allQuestions[currentIndex]) && (
        <QuestionAnswerForm
          answerQuestion={answerQuestion}
          question={allQuestions[currentIndex]}
          pGroupId={pGroupId}
        />
      )}
    </HStack>
  );
};
