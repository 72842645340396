import { Flex } from '@chakra-ui/react';
import { isSome } from '../../../config/Maybe';
import { appValues } from '../../../config/constants';
import { Customizations } from '../../../objects/Customizations';
import { QSystem } from '../../../objects/System';
import { QSystemFileList } from './QSystemFileList';

interface QSystemSidebarProps {
  qSystem: QSystem;
  customizations?: Customizations;
}

export const QSystemSidebar = ({
  qSystem,
  customizations,
}: QSystemSidebarProps): JSX.Element => {
  return (
    <Flex
      pos='sticky'
      left='0'
      h='100%'
      w={appValues.END_USER_SIDEBAR_WIDTH}
      maxW='40vw'
      minW={300}
      flexDir='column'
      bg={
        isSome(customizations?.secondaryColor)
          ? '#' + String(customizations?.secondaryColor)
          : 'gray.700'
      }
      color={
        isSome(customizations?.secondaryTextColor)
          ? '#' + String(customizations?.secondaryTextColor)
          : 'gray.200'
      }
      overflowX='hidden'
      overflowY='scroll'
      zIndex={2}
      pt={5}
    >
      <QSystemFileList qSystem={qSystem} customizations={customizations} />
    </Flex>
  );
};
