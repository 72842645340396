import {
  Heading,
  Text,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useDeleteOrgByIdMutation } from '../../../services/orgEndpoints';
import { selectOrgToEdit } from '../../../store/modalFormSlice';
import { useAppSelector } from '../../../store/hooks';
import { User } from '../../../objects/User';
import { useUpdateUserMutation } from '../../../services/userEndpoints';

interface DeleteOrgPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseParent: () => void;
}

export const DeleteOrgPopup = ({
  isOpen,
  onClose,
  onCloseParent,
}: DeleteOrgPopupProps): JSX.Element => {
  const [deleteOrgApi] = useDeleteOrgByIdMutation();

  const orgToDelete = useAppSelector(selectOrgToEdit);

  const [updateUser] = useUpdateUserMutation();

  const deleteOrgFromMembers = async (): Promise<void> => {
    const usersInOrg: User[] = [];
    // const usersInOrg: string[] = getUsersByOrgId();

    usersInOrg.map(async (user: User): Promise<void> => {
      const idToDelete = orgToDelete?.id ?? '';

      const updatedOrgIds = user.orgIds.filter(
        (item: string) => item !== idToDelete
      );

      const updatedUserValues = {
        ...user,
        orgIds: updatedOrgIds,
        displayName: user.displayName ?? '',
        email: user.email ?? '',
        uid: user.id,
      };

      await updateUser(updatedUserValues);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='md'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>
          <Heading as='h1' textAlign='center' size='lg'>
            Deleting this organization
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text>Are you sure you want to delete this organization?</Text>
          <Text as='b'>This action cannot be undone.</Text>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%'>
            <Button w='45%' onClick={onClose}>
              Go back
            </Button>
            <Button
              w='45%'
              variant='delete-confirm'
              onClick={async () => {
                await deleteOrgFromMembers();
                await deleteOrgApi(orgToDelete?.id ?? '');
                onCloseParent();
                onClose();
              }}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
