import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  HStack,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { isSome } from '../../config/Maybe';
import { useDeleteSystemMutation } from '../../services/systemEndpoints';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import {
  selectSystems,
  selectSystemToDuplicate,
  selectSystemToEdit,
  setSystemToConfigure,
  setSystemToDuplicate,
  setSystemToEdit,
} from '../../store/systemSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { System } from '../../objects/System';
import { CopyIcon, LinkIcon } from '@chakra-ui/icons';
import './SystemTable.scss';
import { DuplicateSystemPopup } from '../modal/Systems/DuplicateSystemPopup';
import { EditSystemPopup } from '../modal/Systems/EditSystemPopup';
import { selectCurrentOrg } from '../../store/organizationSlice';
import { useState } from 'react';
import { WarningModal } from '../modal/WarningModal';

export const SystemsTable = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const systemToDuplicate = useAppSelector(selectSystemToDuplicate);
  const systemToEdit = useAppSelector(selectSystemToEdit);
  const [systemToDelete, setSystemToDelete] = useState<System | null>(null);

  const selectedOrganization = useAppSelector(selectCurrentOrg);
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const systems = useAppSelector(selectSystems);

  const [deleteSystem] = useDeleteSystemMutation();

  const copySystemLinkToClipboard = async (system: System): Promise<void> => {
    if (isSome(selectedOrganization) && isSome(selectedProductGroup)) {
      await navigator.clipboard.writeText(
        `${window.location.origin.toString()}/${
          selectedOrganization.urlExtension
        }/${selectedProductGroup.urlExtension}/${system.urlExtension}`
      );
    }
  };

  const renderButtons = (system: System): JSX.Element => {
    return (
      <HStack h='100%'>
        <Tooltip
          label={`Edit ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }`}
          aria-label={`edit ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }`}
        >
          <IconButton
            aria-label={`edit ${
              selectedProductGroup?.configurationName ?? 'configuration'
            }`}
            color={appColors.GREEN_DARKER}
            border='none'
            h='90%'
            onClick={(event) => {
              event.stopPropagation();
              dispatch(setSystemToEdit(system));
            }}
            className={classNames.button}
            icon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faPen}
              />
            }
          />
        </Tooltip>
        <Tooltip
          label={`Copy ${
            selectedProductGroup?.configurationName ?? 'configuration'
          } link`}
          aria-label={`copy ${
            selectedProductGroup?.configurationName ?? 'configuration'
          } link`}
        >
          <IconButton
            aria-label={`copy ${
              selectedProductGroup?.configurationName ?? 'configuration'
            } link`}
            color={appColors.GREEN_DARKER}
            border='none'
            h='90%'
            onClick={async (event) => {
              event.stopPropagation();
              await copySystemLinkToClipboard(system);
            }}
            className={classNames.button}
            icon={<LinkIcon />}
          />
        </Tooltip>
        <Tooltip
          label={`Duplicate ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }`}
          aria-label={`duplicate ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }`}
        >
          <IconButton
            aria-label={`duplicate ${
              selectedProductGroup?.configurationName ?? 'configuration'
            }`}
            color={appColors.GREEN_DARKER}
            border='none'
            h='90%'
            onClick={(event) => {
              event.stopPropagation();
              dispatch(setSystemToDuplicate(system));
            }}
            className={classNames.button}
            icon={<CopyIcon />}
          />
        </Tooltip>
        <Tooltip
          label={`Delete ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }`}
          aria-label={`delete ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }`}
        >
          <IconButton
            aria-label={`delete ${
              selectedProductGroup?.configurationName ?? 'configuration'
            }`}
            color={appColors.RED}
            border='none'
            h='90%'
            onClick={(event) => {
              event.stopPropagation();
              setSystemToDelete(system);
            }}
            className={classNames.button}
            icon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faTrashAlt}
              />
            }
          />
        </Tooltip>
      </HStack>
    );
  };

  return (
    <>
      {isSome(systemToDuplicate) && (
        <DuplicateSystemPopup system={systemToDuplicate} />
      )}
      {isSome(systemToEdit) && <EditSystemPopup system={systemToEdit} />}
      {isSome(systemToDelete) && (
        <WarningModal
          isOpen={true}
          onClose={() => setSystemToDelete(null)}
          title={`Delete this ${
            selectedProductGroup?.configurationName ?? 'configuration'
          }?`}
          message={systemToDelete.serialNumber}
          onCancel={() => setSystemToDelete(null)}
          onConfirm={async () => {
            await deleteSystem({ id: systemToDelete.id }).unwrap();
            setSystemToDelete(null);
          }}
        />
      )}
      <Table variant='simple' size='sm'>
        <Thead bg='gray.100'>
          <Tr color='gray.300'>
            <Th>Template Used</Th>
            <Th>
              <Tooltip label='configuration id'>
                <Text as='span'>
                  {selectedProductGroup?.configurationIdLabel ?? 'ID'}
                </Text>
              </Tooltip>
            </Th>
            <Th>Client Name</Th>
            <Th>Warranty</Th>
            <Th>Last Updated</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {systems?.map((system) => {
            return (
              <Tr
                key={system.id}
                onClick={() => {
                  dispatch(setSystemToConfigure(system));
                }}
                style={{ cursor: 'pointer' }}
                _hover={{ backgroundColor: appColors.GRAY_LIGHT }}
                className={classNames.tableRow}
              >
                <Td>{system.lastAppliedTemplate ?? ''}</Td>
                <Td>{system.serialNumber}</Td>
                <Td>{system.clientName}</Td>
                <Td>
                  {isSome(system.warrantyStart) && isSome(system.warrantyEnd) && (
                    <Text>
                      {system.warrantyStart} - {system.warrantyEnd}
                    </Text>
                  )}
                </Td>
                <Td>{new Date(system.updatedDate).toLocaleDateString()}</Td>
                <Td>{renderButtons(system)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
  button: 'system_table_hover_button',
  tableRow: 'system_table_row',
};
