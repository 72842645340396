import {
  Button,
  Stack,
  Modal,
  ModalContent,
  Heading,
  ModalFooter,
  ModalBody,
  HStack,
  ModalOverlay,
  Input,
  Text,
  FormLabel,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { TextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import '../Popup.scss';
import { DuplicateSystemParams, System } from '../../../objects/System';
import { useDuplicateSystemMutation } from '../../../services/systemEndpoints';
import 'react-datepicker/dist/react-datepicker.css';
import { setSystemToDuplicate } from '../../../store/systemSlice';
import { PositiveConfirmModal } from '../PositiveConfirmModal';
import { selectCurrentOrg } from '../../../store/organizationSlice';
import { addGeneralError } from '../../../store/toastSlice';

interface DuplicateSystemPopupProps {
  system: System;
}

export const DuplicateSystemPopup = ({
  system,
}: DuplicateSystemPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { register, watch, handleSubmit } = useForm<DuplicateSystemParams>();

  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const selectedOrg = useAppSelector(selectCurrentOrg);
  const [duplicateSystem] = useDuplicateSystemMutation();

  const handleFormSubmit = async (
    systemInfo: DuplicateSystemParams
  ): Promise<void> => {
    try {
      await duplicateSystem({
        ...systemInfo,
        systemId: system.id,
      }).unwrap();

      dispatch(setSystemToDuplicate(undefined));
    } catch (err: any) {
      if (err.status === 409) {
        dispatch(
          addGeneralError(
            `Failed to create ${
              selectedProductGroup?.configurationName ?? 'Configuration'
            }, are you sure your ${
              selectedProductGroup?.configurationIdLabel ?? 'ID'
            } and url extension are unique?`
          )
        );
      } else {
        dispatch(
          addGeneralError(
            `Failed to create ${
              selectedProductGroup?.configurationName ?? 'Configuration'
            }.`
          )
        );
      }
    }
  };

  const allowOnlyValidUrlChars = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (encodeURIComponent(e.key) !== e.key) {
      e.preventDefault();
    }
  };

  const {
    isOpen: isOpenConfirmUrlPopup,
    onOpen: onOpenConfirmUrlPopup,
    onClose: onCloseConfirmUrlPopup,
  } = useDisclosure();

  const systemSerialRegister = register('serialNumber', { required: true });
  const systemSerialText = watch('serialNumber');
  const urlText = watch('urlExtension');

  return (
    <Modal
      isOpen={true}
      onClose={() => dispatch(setSystemToDuplicate(undefined))}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {isOpenConfirmUrlPopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmUrlPopup}
          onClose={onCloseConfirmUrlPopup}
          title={`Use this URL extension? This cannot be changed.`}
          message={encodeURIComponent(urlText)}
          onCancel={onCloseConfirmUrlPopup}
          onConfirm={handleSubmit(handleFormSubmit)}
        />
      )}
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onOpenConfirmUrlPopup();
          }}
        >
          <ModalBody>
            <Stack spacing={30} pt={15}>
              <Heading as='h1' size='md'>
                <Tooltip label='configuration'>
                  <Text as='span'>
                    {selectedProductGroup?.configurationName ?? 'Configuration'}
                    &nbsp;
                  </Text>
                </Tooltip>
                information
              </Heading>

              <Stack>
                <FormLabel>URL</FormLabel>
                <HStack>
                  <Text color='gray.500'>
                    ...viduals.com/{selectedOrg?.urlExtension}/
                    {selectedProductGroup?.urlExtension}/
                  </Text>
                  <Input
                    {...register('urlExtension', { required: true })}
                    placeholder='URL'
                    type='text'
                    onKeyPress={allowOnlyValidUrlChars}
                  />
                </HStack>
                <TextInput
                  placeholder={`${
                    selectedProductGroup?.configurationIdLabel ?? 'ID'
                  }`}
                  register={systemSerialRegister}
                  text={systemSerialText}
                />
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack className='space-between width100'>
              <Button
                w='45%'
                onClick={() => dispatch(setSystemToDuplicate(undefined))}
              >
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='50%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
