// import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, history } from './store/store';
import { ConnectedRouter } from 'connected-react-router';
import { FirebaseProvider } from './contexts/firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import { isNone } from './config/Maybe';
import { firebaseApp } from './config/firebase.config';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'local';

initializeFirebase()
  .then((app) => renderApp(app))
  .catch((error) => console.error('Error initializing firebase', error));

function renderApp(app: firebase.app.App): void {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {/* <React.StrictMode> */}
        <FirebaseProvider app={app}>
          <App />
        </FirebaseProvider>
        {/* </React.StrictMode> */}
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
}

async function initializeFirebase(): Promise<firebase.app.App> {
  const isLocal = ENVIRONMENT === 'local' || ENVIRONMENT === 'docker';

  if (isLocal) {
    console.debug('Using local Firebase emulator');
    firebaseApp.auth().useEmulator('http://localhost:9099');
    await createTestUser(firebaseApp);
  }

  return firebaseApp;
}

async function createTestUser(app: firebase.app.App): Promise<void> {
  console.debug('Checking test user');
  const email = 'test@compozelabs.com';
  const password = 'test123';
  // The client SDK only lets you verify a user exists by trying to sign in as it
  const user = await app
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => userCredential.user)
    .catch((_error) => null);
  if (isNone(user)) {
    // If the test user doesn't exist, create it
    await app.auth().createUserWithEmailAndPassword(email, password);
    console.debug('Created test user');
  } else {
    console.debug('Test user already existed');
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
