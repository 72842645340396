import { z } from 'zod';

export const IdentifiableSchema = z.object({
  id: z.string(),
});

export type Identifiable = z.infer<typeof IdentifiableSchema>;

export const BaseSchema = IdentifiableSchema.extend({
  createdDate: z.string(),
  updatedDate: z.string(),
});

export type Base = z.infer<typeof BaseSchema>;
