import { Stack, Heading, Text, Button, Link, Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { isSome } from '../../config/Maybe';
import {
  ContentVersion,
  ContentVersionFileTypes,
} from '../../objects/ContentVersion';
import { useAppSelector } from '../../store/hooks';
import {
  selectContentToDisplay,
  selectFileTypeToDisplay,
  selectVersionToDisplay,
  selectNumPagesPdf,
} from '../../store/endUserSlice';
import { Content } from '../../objects/Content';
import { Customizations } from '../../objects/Customizations';
import { System } from '../../objects/System';
import { getS3BucketName } from '../../config/S3Client';
interface ContentInfoProps {
  system: System | undefined;
  customizations: Customizations | null | undefined;
}

export const ContentInfo = ({
  system,
  customizations,
}: ContentInfoProps): JSX.Element => {
  const version: ContentVersion | undefined = useAppSelector(
    selectVersionToDisplay
  );
  const content: Content | undefined = useAppSelector(selectContentToDisplay);
  const type: ContentVersionFileTypes | undefined = useAppSelector(
    selectFileTypeToDisplay
  );
  const numPages: number = useAppSelector(selectNumPagesPdf);
  const startPage = isSome(version?.pdfStartPage)
    ? String(version?.pdfStartPage)
    : '1';

  const buildFileGetUrl = (fileName: string): string => {
    const bucketName = getS3BucketName();
    const productGroupPrefix: string = system?.productGroup.id ?? '';
    const encodedFileName = encodeURIComponent(fileName);

    const url = `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/${encodedFileName}/${encodedFileName}`;

    return url;
  };

  // const [videoTimeString, setVideoTimeString] = useState<string>('');
  // const videoDurationSeconds: number = useAppSelector(
  //   selectVideoDurationSeconds
  // );

  // useEffect(() => {
  //   setVideoTimeString(videoDurationMinutes());
  // }, [videoDurationSeconds]);

  // const videoDurationMinutes = (): string => {
  //   let durationString = '';

  //   const minutes = Math.floor(videoDurationSeconds / 60);
  //   durationString += minutes.toString() + ':';
  //   const seconds = Math.floor(videoDurationSeconds % 60);
  //   if (seconds <= 9) {
  //     durationString += '0';
  //   }
  //   durationString += seconds.toString();

  //   return durationString;
  // };

  // const noInfo = (): JSX.Element => {
  //   return (
  //     <Stack>
  //       <Skeleton h={10} mb={5} />
  //       <SkeletonText h={20} />
  //     </Stack>
  //   );
  // };

  const pdfInfo = (): JSX.Element => {
    return (
      <Box>
        <Text>PDF start page: {startPage}</Text>
        <Text>PDF total pages: {numPages}</Text>
        <Button
          color={
            isSome(customizations?.primaryTextColor)
              ? '#' + String(customizations?.primaryTextColor)
              : 'gray.200'
          }
          bg={
            isSome(customizations?.buttonColor)
              ? '#' + String(customizations?.buttonColor)
              : 'black'
          }
          borderColor={
            isSome(customizations?.buttonColor)
              ? '#' + String(customizations?.buttonColor)
              : 'gray.200'
          }
          mt={5}
          h={10}
          w={250}
          leftIcon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faFilePdf}
              style={{ width: '18px' }}
            />
          }
          _hover={{ bg: 'gray.600' }}
        >
          <Link
            href={
              buildFileGetUrl(version?.pdfName ?? '') + '#page=' + startPage
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            View the full user manual
          </Link>
        </Button>
      </Box>
    );
  };

  const videoInfo = (): JSX.Element => {
    return (
      <>
        {isSome(version?.pdfName) && (
          <Button
            color={
              isSome(customizations?.primaryTextColor)
                ? '#' + String(customizations?.primaryTextColor)
                : 'gray.200'
            }
            bg={
              isSome(customizations?.buttonColor)
                ? '#' + String(customizations?.buttonColor)
                : 'black'
            }
            borderColor={
              isSome(customizations?.buttonColor)
                ? '#' + String(customizations?.buttonColor)
                : 'gray.200'
            }
            h={10}
            w={250}
            leftIcon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faFilePdf}
                style={{ width: '18px' }}
              />
            }
            _hover={{ bg: 'gray.600' }}
          >
            <Link
              href={
                buildFileGetUrl(version?.pdfName ?? '') + '#page=' + startPage
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              View the full user manual
            </Link>
          </Button>
        )}

        {/* <Text>
            Video Duration –{' '}
            <span style={{ fontWeight: 700 }}>{videoTimeString}</span>
          </Text> */}
      </>
    );
  };

  return (
    <Stack spacing={4} pt={2} h='40%' overflowY='auto'>
      <Heading as='h1' pb={2}>
        {content?.name}
      </Heading>
      {type === ContentVersionFileTypes.VIDEO && videoInfo()}
      {type === ContentVersionFileTypes.PDF && pdfInfo()}
      {/* {isNone(type) && noInfo()} */}
      <Text>{content?.description}</Text>
    </Stack>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
