import { useState, useEffect, ComponentType, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectCurrentUserData } from '../store/authSlice';
import firebase from 'firebase/app';
import { useFirebase } from '../contexts/firebase';
import { isSome } from '../config/Maybe';
import { useLazyGetCurrentUserQuery } from '../services/userEndpoints';
import { useLazyGetOrgsQuery } from '../services/orgEndpoints';
import { selectCurrentOrg } from '../store/organizationSlice';
import {
  useLazyGetRootFoldersAndContentQuery,
  useLazyGetProdGroupsQuery,
  useLazyGetFoldersWithTagsByProductGroupIdQuery,
  useLazyGetRootContentWithTagsByProductGroupIdQuery,
} from '../services/prodGroupsEndpoints';
import {
  selectCurrentProductGroup,
  setCurrentProductGroup,
} from '../store/productGroupSlice';
import { useLazyGetUngroupedTagsByProductGroupIdQuery } from '../services/tagEndpoints';
import { useLazyGetTagGroupsByProductGroupIdQuery } from '../services/tagGroupEndpoints';
import { addTags, setTags } from '../store/tagSlice';
import { Routes } from '../config/Routes';
import { setTagGroups } from '../store/tagGroupSlice';
import { setTemplates } from '../store/templateSlice';
import { useLazyGetTemplatesByProductGroupIdQuery } from '../services/templateEndpoints';
import { ToastId, useToast, Box, Spinner } from '@chakra-ui/react';
import { appColors } from '../config/constants';
import {
  selectUploadingFiles,
  selectFailedFileUploads,
  selectSucceededFileUploads,
  removeFailedFileToUpload,
  removeSucceededFileUpload,
  selectGeneralErrors,
  removeGeneralError,
} from '../store/toastSlice';
import { FolderOrContentItem, ItemType } from '../objects/FolderOrContentItem';
import axios from 'axios';
import { getBaseUrl } from '../services/vidualsApi';
import {
  addFolderOrContentToTree,
  setTreeRootFoldersAndContent,
} from '../store/treeItemSlice';
import {
  resetSystemSlice,
  setSystems,
  setSystemToConfigure,
} from '../store/systemSlice';
import { useLazyGetSystemsByProductGroupIdQuery } from '../services/systemEndpoints';
import { ProductGroupType } from '../objects/ProdGroup';
import { useLazyGetQTemplatesByProductGroupIdQuery } from '../services/qTemplateEndpoints';
import { setQTemplates } from '../store/qTemplateSlice';
import {
  setContentsWithTags,
  setFoldersWithTags,
} from '../store/foldersAndContentsWithTagsSlice';
import {
  resetQCSlice,
  setOptionToConfigure,
  setQuestionnaireToConfigure,
  setRootQuestionsToConfigure,
} from '../store/questionnaireConfigurationSlice';

enum AuthState {
  pending,
  authenticated,
  unauthenticated,
}

export function AuthenticatedPage<T>(Component: ComponentType<T>) {
  return function WrapComponent(props: T): JSX.Element | null {
    const [isAuth, setIsAuth] = useState<AuthState>(AuthState.pending);
    const [loadingToastActive, setLoadingToastActive] =
      useState<boolean>(false);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const app = useFirebase();
    const [fetchUser] = useLazyGetCurrentUserQuery();
    const [fetchUserOrgs] = useLazyGetOrgsQuery();
    const [fetchOrgProdGroups] = useLazyGetProdGroupsQuery();
    const [fetchUngroupedTags] = useLazyGetUngroupedTagsByProductGroupIdQuery();
    const [fetchTagGroupsByProdGroup, tagGroupResult] =
      useLazyGetTagGroupsByProductGroupIdQuery();
    const [fetchTemplatesByProdGroup] =
      useLazyGetTemplatesByProductGroupIdQuery();
    const [fetchQTemplatesByProdGroup] =
      useLazyGetQTemplatesByProductGroupIdQuery();
    const [fetchRootFoldersAndContents, rootFoldersAndContentResult] =
      useLazyGetRootFoldersAndContentQuery();
    const [fetchSystems] = useLazyGetSystemsByProductGroupIdQuery();
    const userData = useAppSelector(selectCurrentUserData);
    const selectedOrg = useAppSelector(selectCurrentOrg);
    const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
    const filesUploading = useAppSelector(selectUploadingFiles);
    const failedFileUploads = useAppSelector(selectFailedFileUploads);
    const generalErrors = useAppSelector(selectGeneralErrors);
    const succededFileUploads = useAppSelector(selectSucceededFileUploads);
    const [getFolderWithTags] =
      useLazyGetFoldersWithTagsByProductGroupIdQuery();
    const [getContentsWithTags] =
      useLazyGetRootContentWithTagsByProductGroupIdQuery();

    const toast = useToast();
    const fileUploadToastIdRef = useRef<ToastId>();

    useEffect(() => {
      if (
        filesUploading.length > 0 &&
        !toast.isActive('loading') &&
        !loadingToastActive
      ) {
        startLoadingToast();
        window.addEventListener('beforeunload', alertUser);
        return () => {
          window.removeEventListener('beforeunload', alertUser);
        };
      } else if (filesUploading.length === 0) {
        closeToast();
        return window.removeEventListener('beforeunload', alertUser);
      }
    }, [filesUploading, loadingToastActive]);

    useEffect(() => {
      if (failedFileUploads.length > 0) {
        addFailureToast(failedFileUploads[0]);
        dispatch(removeFailedFileToUpload(failedFileUploads[0]));
      }
    }, [failedFileUploads]);

    useEffect(() => {
      if (succededFileUploads.length > 0) {
        addSuccessToast(succededFileUploads[0]);
        dispatch(removeSucceededFileUpload(succededFileUploads[0]));
      }
    }, [succededFileUploads]);

    useEffect(() => {
      if (generalErrors.length > 0) {
        addGeneralErrorToast(generalErrors[0]);
        dispatch(removeGeneralError(generalErrors[0]));
      }
    }, [generalErrors]);

    const alertUser = (e: BeforeUnloadEvent): void => {
      e.preventDefault();
      e.returnValue = '';
    };

    const startLoadingToast = (): void => {
      fileUploadToastIdRef.current = toast({
        id: 'loading',
        duration: null,
        position: 'bottom-right',
        render: function LoadingSpinner() {
          return (
            <Box
              color='white'
              p={8}
              mb={20}
              borderRadius={8}
              bg={appColors.GREEN_DARKER}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Spinner height={12} width={12} /> <span>Uploading file....</span>
            </Box>
          );
        },
      });
      setLoadingToastActive(true);
    };
    const closeToast = (): void => {
      if (fileUploadToastIdRef?.current !== undefined) {
        toast.close('loading');
        setLoadingToastActive(false);
      }
    };

    const addFailureToast = (failedFileName: string): void => {
      toast({
        title: `Failed to upload file: ${failedFileName}`,
        duration: null,
        position: 'top-right',
        isClosable: true,
        status: 'error',
      });
    };

    const addGeneralErrorToast = (errorString: string): void => {
      toast({
        title: errorString,
        duration: null,
        position: 'top-right',
        isClosable: true,
        status: 'error',
      });
    };

    const addSuccessToast = (fileName: string): void => {
      toast({
        title: `File uploaded successfully: ${fileName}`,
        duration: 4000,
        position: 'top-right',
        status: 'success',
      });
    };

    useEffect(() => {
      const unsubscribe = firebase
        .auth(app)
        .onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
          if (isSome(firebaseUser)) {
            setIsAuth(AuthState.authenticated);
            history.push(Routes.FOLDERS_AND_CONTENT);
          } else {
            setIsAuth(AuthState.unauthenticated);
            history.push(Routes.LOGIN);
          }
        });
      return () => {
        unsubscribe();
      };
    }, []);

    useEffect(() => {
      if (app.auth().currentUser !== null) {
        fetchUser();
      }
    }, [isAuth]);

    useEffect(() => {
      if (app.auth().currentUser !== null) {
        fetchUserOrgs();
      }
    }, [userData]);

    useEffect(() => {
      if (isSome(selectedOrg)) {
        fetchOrgProdGroups(selectedOrg.id);
      } else {
        dispatch(setCurrentProductGroup(null));
      }
    }, [selectedOrg]);

    useEffect(() => {
      if (isSome(selectedProductGroup)) {
        dispatch(setTags([]));
        dispatch(setTagGroups([]));
        fetchUngroupedTags(selectedProductGroup.id);
        fetchTagGroupsByProdGroup(selectedProductGroup.id);
        fetchRootFoldersAndContents(selectedProductGroup.id);
        getFolderWithTags(selectedProductGroup.id);
        getContentsWithTags(selectedProductGroup.id);
        if (selectedProductGroup.type === ProductGroupType.System) {
          dispatch(setQTemplates([]));

          fetchSystems(selectedProductGroup.id);
          fetchTemplatesByProdGroup(selectedProductGroup.id);
        } else {
          dispatch(setTemplates([]));
          dispatch(setSystems([]));

          fetchQTemplatesByProdGroup(selectedProductGroup.id);
        }
      } else {
        // no product group, so we empty anything that depends on it
        dispatch(setTreeRootFoldersAndContent([]));
        dispatch(setTags([]));
        dispatch(setTagGroups([]));
        dispatch(setTemplates([]));
        dispatch(setQTemplates([]));
        dispatch(setSystems([]));
        dispatch(setSystemToConfigure(undefined));
        dispatch(setContentsWithTags([]));
        dispatch(setFoldersWithTags([]));
      }
      dispatch(resetQCSlice());
      dispatch(resetSystemSlice());
      dispatch(setOptionToConfigure(undefined));
      dispatch(setQuestionnaireToConfigure(undefined));
      dispatch(setRootQuestionsToConfigure([]));
    }, [selectedProductGroup]);

    useEffect(() => {
      if (isSome(selectedProductGroup)) {
        (async function () {
          if (
            isSome(rootFoldersAndContentResult.data) &&
            rootFoldersAndContentResult.data.length > 0 &&
            !rootFoldersAndContentResult.isLoading
          ) {
            await Promise.all(
              rootFoldersAndContentResult.data.map(
                async (folderOrContent): Promise<void> => {
                  if (folderOrContent.type === ItemType.Folder) {
                    return await fetchChildrenOfFolder(
                      folderOrContent.item.id,
                      selectedProductGroup.id
                    );
                  }
                }
              )
            );
          }
        })()
          .then((res) => console.log(res)) // Uneccesary logs, but the linter wont let me igonre the promise, not sure what to do
          .catch((err) => console.log(err));
      }
    }, [rootFoldersAndContentResult]);

    useEffect(() => {
      if (isSome(selectedProductGroup)) {
        (async function () {
          if (
            isSome(tagGroupResult.data) &&
            tagGroupResult.data.length > 0 &&
            !tagGroupResult.isLoading &&
            !tagGroupResult.isFetching
          ) {
            await Promise.all(
              tagGroupResult.data.map(async (group): Promise<void> => {
                await fetchTagsInGroup(group.id);
              })
            );
          }
        })()
          .then((res) => console.log(res)) // Uneccesary logs, but the linter wont let me igonre the promise, not sure what to do
          .catch((err) => console.log(err));
      }
    }, [tagGroupResult]);

    const fetchChildrenOfFolder = async (
      folderId: string,
      pgroupId: string
    ): Promise<void> => {
      const url = `${getBaseUrl()}/productGroups/${pgroupId}/foldersAndContentInFolder/${folderId}`;
      const response = await axios.get(url);
      dispatch(addFolderOrContentToTree(response.data));
      const data = response.data as FolderOrContentItem[];
      await Promise.all(
        data.map(
          async (folderOrContent: FolderOrContentItem): Promise<void> => {
            if (folderOrContent.type === ItemType.Folder) {
              return await fetchChildrenOfFolder(
                folderOrContent.item.id,
                pgroupId
              );
            }
          }
        )
      );
    };

    const fetchTagsInGroup = async (groupId: string): Promise<void> => {
      const url = `${getBaseUrl()}/tags/tagGroup/${groupId}`;
      const response = await axios.get(url);
      dispatch(addTags(response.data));
    };

    switch (isAuth) {
      case AuthState.authenticated:
        return <Component {...props} />;
      case AuthState.unauthenticated:
        history.push(Routes.LOGIN);
        return null;
      case AuthState.pending:
        return null;
    }
  };
}
