export const appColors = {
  // VIDUALS SPECIFIC COLORS HERE
  BLUE_LIGHT: '#d9fbff',
  BLUE_ORIGINAL: '#00e5ff',
  BLUE_DARKER: '#05b7de',
  GREEN_ORIGINAL: '#00d700',
  GREEN_DARKER: '#419941',
  YELLOW_100: '#FEF9C3',
  YELLOW_200: '#FEF08A',

  RED: '#B91C1C',
  GRAY200: '#E4E4E7',
  GRAY400: '#A1A1AA',
  GRAY500: '#71717A',
  GRAY_MEDIUM: '#f4f4f5',
  GRAY_LIGHT: '#FAFAFA',
  BORDERGRAY: '#D4D4D8',
  BORDERBLUE: '#0055C9',
};

export const DefaultEndUserColors = {
  PRIM_COLOR: '#000000',
  PRIM_TEXT_COLOR: '#FFFFFF',
  BTN_COLOR: appColors.GREEN_DARKER,
  SEC_COLOR: appColors.GRAY200,
};

export const appValues = {
  SIDEBAR_WIDTH: 220,
  END_USER_SIDEBAR_WIDTH: 600,
  END_USER_HEADER_HEIGHT: 134,
};
