import {
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useDisclosure,
  IconButton,
  Text,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { setTemplateToEdit } from '../../../store/modalFormSlice';
import { appColors } from '../../../config/constants';
import { WarningModal } from '../WarningModal';
import {
  TemplateFolder,
  TemplateTag,
  SelectionMarkOptions,
  Template,
  TemplateContent,
} from '../../../objects/Template';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { useSetTemplateSelectionsMutation } from '../../../services/templateEndpoints';
import { useEffect, useState } from 'react';
import { TemplateFoldersAndContentsList } from './TemplateFoldersAndContentsList';
import { TemplateAvailableTagListRedesign } from './TemplateAvailableTagListRedesign';
import { Tag } from '../../../objects/Tag';
import {
  selectContentsWithTags,
  selectFoldersWithTags,
} from '../../../store/foldersAndContentsWithTagsSlice';
import { selectTags } from '../../../store/tagSlice';
import { isSome } from '../../../config/Maybe';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';

interface TemplateSettingsModalRedesignProps {
  template: Template;
}

export const TemplateSettingsModalRedesign = ({
  template,
}: TemplateSettingsModalRedesignProps): JSX.Element => {
  const foldersWithTags = useAppSelector(selectFoldersWithTags);
  const contentsWithTags = useAppSelector(selectContentsWithTags);
  const allTags = useAppSelector(selectTags);
  const pGroup = useAppSelector(selectCurrentProductGroup);

  const dispatch = useAppDispatch();
  const [updateTemplateSelection] = useSetTemplateSelectionsMutation();

  const [selectionFolders, setSelectionFolders] = useState<TemplateFolder[]>(
    template.templateFolders.map((tf) => {
      return { selection: tf.selection, folderId: tf.folderId };
    })
  );
  const [selectionContents, setSelectionContents] = useState<TemplateContent[]>(
    template.templateContents.map((tc) => {
      return { selection: tc.selection, contentId: tc.contentId };
    })
  );
  const [selectionTags, setSelectionTags] = useState<TemplateTag[]>(
    template.templateTags.map((st) => {
      return { selection: st.selection, tagId: st.tagId };
    })
  );
  const [tagsToShow, setTagsToShow] = useState<Tag[]>([]);

  const onConfirm = async (): Promise<void> => {
    await updateTemplateSelection({
      id: template.id,
      folders: selectionFolders.map((sf) => {
        return { selection: sf.selection, folderId: sf.folderId };
      }),
      tags: selectionTags.map((st) => {
        return { selection: st.selection, tagId: st.tagId };
      }),
      contents: selectionContents.map((c) => {
        return { selection: c.selection, contentId: c.contentId };
      }),
    });
    dispatch(setTemplateToEdit(undefined));
  };

  useEffect(() => {
    const newTagsToShow: Tag[] = [];
    foldersWithTags.forEach((folder) => {
      if (
        selectionFolders
          .filter((sf) => sf.selection !== SelectionMarkOptions.Unavailable)
          .map((sf) => sf.folderId)
          .includes(folder.id)
      ) {
        newTagsToShow.push(...folder.availableTags);
      }
    });
    contentsWithTags.forEach((content) => {
      if (
        selectionContents
          .filter((sc) => sc.selection !== SelectionMarkOptions.Unavailable)
          .map((sc) => sc.contentId)
          .includes(content.id)
      ) {
        newTagsToShow.push(...content.availableTags);
      }
    });
    // sort and remove duplicates
    const ids = newTagsToShow
      .sort((a: Tag, b: Tag) => {
        return a.indexWithinParent - b.indexWithinParent;
      })
      .map((tag) => tag.id);
    const uniqueTags = newTagsToShow.filter(
      (tag, index) => !ids.includes(tag.id, index + 1)
    );
    setTagsToShow(uniqueTags);
  }, [selectionFolders, selectionContents, foldersWithTags, contentsWithTags]);

  useEffect(() => {
    foldersWithTags.map((folder) => {
      // if a new folder has been added since the last time this was modified
      // add the folder and default to available
      if (
        !selectionFolders.some(
          (templateFolder) => templateFolder.folderId === folder.id
        )
      ) {
        setSelectionFolders((current) => [
          ...current,
          {
            ...folder,
            selection: SelectionMarkOptions.Available,
            folderId: folder.id,
          },
        ]);
      }
      // same for tags
      folder.availableTags.map((tag) => {
        if (
          !selectionTags.some((templateTag) => templateTag.tagId === tag.id)
        ) {
          setSelectionTags((current) => [
            ...current,
            {
              ...tag,
              selection: SelectionMarkOptions.Available,
              tagId: tag.id,
            },
          ]);
        }
        return tag;
      });
      return folder;
    });
    contentsWithTags.map((content) => {
      // if a new root level content has been added since the last time this was modified
      // add the content and default to available
      if (
        !selectionContents.some(
          (templateContent) => templateContent.contentId === content.id
        )
      ) {
        setSelectionContents((current) => [
          ...current,
          {
            ...content,
            selection: SelectionMarkOptions.Available,
            contentId: content.id,
          },
        ]);
      }
      // same for tags in that content
      content.availableTags.map((tag) => {
        if (
          !selectionTags.some((templateTag) => templateTag.tagId === tag.id)
        ) {
          setSelectionTags((current) => [
            ...current,
            {
              ...tag,
              selection: SelectionMarkOptions.Available,
              tagId: tag.id,
            },
          ]);
        }
        return tag;
      });
      return content;
    });
  }, [foldersWithTags, contentsWithTags]);

  const setFolderSelection = (
    folderId: string,
    option: SelectionMarkOptions
  ): void => {
    setSelectionFolders((currentFolders) => [
      ...currentFolders.map((folder) =>
        folder.folderId === folderId ? { ...folder, selection: option } : folder
      ),
    ]);
  };

  const setContentSelection = (
    contentId: string,
    option: SelectionMarkOptions
  ): void => {
    setSelectionContents((currentContents) => [
      ...currentContents.map((content) =>
        content.contentId === contentId
          ? { ...content, selection: option }
          : content
      ),
    ]);
  };

  const setTagSelection = (
    tagId: string,
    option: SelectionMarkOptions
  ): void => {
    if (option === SelectionMarkOptions.Preselected) {
      let siblingTags: Tag[] = [];
      const updatedTag = allTags.find((tag) => tagId === tag.id);
      if (isSome(updatedTag) && isSome(updatedTag.tagGroupId)) {
        siblingTags = allTags.filter(
          (tag) => tag.id !== tagId && tag.tagGroupId === updatedTag.tagGroupId
        );
      }
      setSelectionTags((currentTags) => [
        ...currentTags.map((tag) => {
          if (tag.tagId === tagId) {
            return { ...tag, selection: option };
          } else if (
            siblingTags.some((sibTag) => sibTag.id === tag.tagId) &&
            tag.selection === SelectionMarkOptions.Preselected
          ) {
            return { ...tag, selection: SelectionMarkOptions.Available };
          } else {
            return tag;
          }
        }),
      ]);
    } else {
      setSelectionTags((currentTags) => [
        ...currentTags.map((tag) =>
          tag.tagId === tagId ? { ...tag, selection: option } : tag
        ),
      ]);
    }
  };

  const {
    isOpen: isOpenCloseConfirmPopup,
    onOpen: onOpenCloseConfirmPopup,
    onClose: onCloseCloseConfirmPopup,
  } = useDisclosure();

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        return dispatch(setTemplateToEdit(undefined));
      }}
      closeOnOverlayClick={false}
      isCentered
    >
      {isOpenCloseConfirmPopup && (
        <WarningModal
          isOpen={isOpenCloseConfirmPopup}
          onClose={onCloseCloseConfirmPopup}
          title={'Close this form?'}
          message={'Any unsaved changes you have made will be lost.'}
          onCancel={onCloseCloseConfirmPopup}
          onConfirm={() => dispatch(setTemplateToEdit(undefined))}
        />
      )}
      <ModalOverlay />
      <ModalContent maxWidth='unset' w='90%' h='100vh'>
        <ModalHeader
          flexWrap='nowrap'
          pt={15}
          justifyContent='space-between'
          flexDirection='row'
          display='flex'
        >
          <HStack>
            <Button
              borderColor='gray.400'
              color='gray.900'
              fontWeight={400}
              bg='white'
              rightIcon={<ChevronRightIcon color='gray.500' w={7} h={7} />}
              fontSize={16}
              justifyContent='space-between'
              onClick={onOpenCloseConfirmPopup}
            >
              {template.name}
            </Button>
            <Button
              backgroundColor={appColors.GREEN_DARKER}
              color={'white'}
              onClick={onConfirm}
            >
              Submit Template
            </Button>
          </HStack>
          <IconButton
            aria-label='Delete PDF'
            className='icon-button'
            color={'gray.500'}
            icon={<CloseIcon height={12} />}
            onClick={onOpenCloseConfirmPopup}
          />
        </ModalHeader>
        <ModalBody h='90%' overflow={'hidden'}>
          <Text mb='10px'>
            Specify which root-level folders and tags should be preselected,
            available, or unavailable if a
            <Tooltip label='configuration'>
              <Text as='span'>
                &nbsp;
                {pGroup?.configurationName ?? 'configuration'}
              </Text>
            </Tooltip>
            &nbsp; was based off this template.
          </Text>
          <HStack h='95%' w='100%' alignItems='flex-start' spacing='20px'>
            <Box
              w='60%'
              h='100%'
              overflowY={'auto'}
              overscrollBehavior='contain'
            >
              <TemplateFoldersAndContentsList
                foldersWithTags={foldersWithTags}
                selectionFolders={selectionFolders}
                setFolderSelection={setFolderSelection}
                contentsWithTags={contentsWithTags}
                selectionContents={selectionContents}
                setContentSelection={setContentSelection}
              />
            </Box>
            <Box
              w='40%'
              h='100%'
              overflowY={'auto'}
              overscrollBehavior='contain'
            >
              <TemplateAvailableTagListRedesign
                tags={tagsToShow}
                selectionTags={selectionTags}
                setTagSelection={setTagSelection}
              />
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
