import { isSome } from '../../config/Maybe';
import { ContentWithTags } from '../ContentWithTags';
import { ItemType } from '../FolderOrContentItem';
import { FolderWithTags } from '../FolderWithTags';
import { ProdGroup } from '../ProdGroup';
import {
  QTemplateContent,
  QTemplateFolder,
  QTemplateOptions,
} from '../QuestionnaireTemplate';
import { FolderOrContentQuestion } from './FolderOrContentQuestion';
import { Question } from './Question';
import {
  InputQuestionnaireTemplateOption,
  QuestionnaireTemplateOption,
} from './QuestionnaireTemplateOption';

export interface Questionnaire extends Question {
  id: string;
  productGroupId: string;
  questionnaireTemplateOptions: QuestionnaireTemplateOption[];
}

export interface InputQuestionnaire {
  id: string;
  text: string;
  helpText: string;
  imageUrl?: string | null;
  inputQuestionnaireTemplateOptions: InputQuestionnaireTemplateOption[];
}

export const getNecessaryContentQuestions = (
  qTemplateContents: QTemplateContent[],
  contentsWithTags: ContentWithTags[],
  templateOption: QuestionnaireTemplateOption,
  pGroup: ProdGroup
): FolderOrContentQuestion[] =>
  qTemplateContents
    .filter(
      (qtContent) =>
        qtContent.selection === QTemplateOptions.Optional ||
        qtContent.selection === QTemplateOptions.Mandatory
    )
    .map((qtContent): FolderOrContentQuestion => {
      const content = contentsWithTags.find(
        (cwt) => cwt.id === qtContent.contentId
      );
      if (!isSome(content)) {
        return {
          text: `This is an error`,
          helpText: '',
          templateQuestionnaireOptionId: '',
          folderOrContentId: '',
          type: ItemType.Content,
        };
      }
      return {
        text: `Does your ${pGroup.configurationName} have a ${content.name}?`,
        helpText: '',
        templateQuestionnaireOptionId: templateOption.id,
        folderOrContentId: content.id,
        type: ItemType.Content,
        indexWithinParent: content.indexWithinParent,
        mandatory: qtContent.selection === QTemplateOptions.Mandatory,
        unsaved: true,
      };
    })
    .filter((question) => isSome(question.indexWithinParent));

export const getNecessaryFolderQuestions = (
  qTemplateFolders: QTemplateFolder[],
  foldersWithTags: FolderWithTags[],
  templateOption: QuestionnaireTemplateOption,
  pGroup: ProdGroup
): FolderOrContentQuestion[] =>
  qTemplateFolders
    .filter(
      (qtFolder) =>
        qtFolder.selection === QTemplateOptions.Optional ||
        qtFolder.selection === QTemplateOptions.Mandatory
    )
    .map((qtFolder): FolderOrContentQuestion => {
      const folder = foldersWithTags.find(
        (fwt) => fwt.id === qtFolder.folderId
      );
      if (!isSome(folder)) {
        return {
          text: `This is an error`,
          helpText: '',
          templateQuestionnaireOptionId: '',
          folderOrContentId: '',
          type: ItemType.Folder,
        };
      }
      return {
        text: `Does your ${pGroup.configurationName} have a ${folder.name}?`,
        helpText: '',
        templateQuestionnaireOptionId: templateOption.id,
        folderOrContentId: folder.id,
        type: ItemType.Folder,
        indexWithinParent: folder.indexWithinParent,
        mandatory: qtFolder.selection === QTemplateOptions.Mandatory,
        unsaved: true,
      };
    })
    .filter((question) => isSome(question.indexWithinParent));
