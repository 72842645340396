import { Box, Text, Image, HStack } from '@chakra-ui/react';
import { HeaderLarge } from './HeaderLarge';
import VidualsIcon from '../../images/vidualsIcon.png';
import { SystemSearch } from '../../sharedComponents/endUserSystemsFind/SystemSearch';
import { useParams } from 'react-router-dom';
import { useGetProductGroupByUrlQuery } from '../../services/prodGroupsEndpoints';
import { ProdGroup, ProductGroupType } from '../../objects/ProdGroup';
import { useGetCustomizationsByProductGroupIdQuery } from '../../services/customizationsEndpoints';
import { isSome } from '../../config/Maybe';
import { DefaultEndUserColors } from '../../config/constants';
import { QuestionnaireWrapper } from '../EndUserSystemsViewPage/Questionnaire/QuestionnaireWrapper';
import { EndUserContextContext } from '../../contexts/EndUserContext';
import { useEffect, useState } from 'react';

interface RouteParams {
  org: string;
  prodGroup: string;
}

export const EndUserSystemsFindPage = (): JSX.Element => {
  const [productGroup, setProdGroup] = useState<ProdGroup | undefined>();
  const params = useParams<RouteParams>();

  const { data: prodGroup, error } = useGetProductGroupByUrlQuery({
    pgUrl: params.prodGroup,
    orgUrl: params.org,
  });

  useEffect(() => {
    setProdGroup(prodGroup);
  }, [prodGroup]);

  const { data: currentCustomizations } =
    useGetCustomizationsByProductGroupIdQuery(prodGroup?.id ?? '', {
      skip: !isSome(prodGroup),
    });

  console.log(error); // TODO: this will be important especially if we can't find the one they search for

  return (
    <EndUserContextContext.Provider value={{ productGroup, setProdGroup }}>
      <Box
        w='100vw'
        h='100vh'
        bg={
          isSome(currentCustomizations?.primaryColor)
            ? '#' + String(currentCustomizations?.primaryColor)
            : DefaultEndUserColors.PRIM_COLOR
        }
        color={
          isSome(currentCustomizations?.primaryTextColor)
            ? '#' + String(currentCustomizations?.primaryTextColor)
            : DefaultEndUserColors.PRIM_TEXT_COLOR
        }
      >
        <HeaderLarge customizations={currentCustomizations} />
        {prodGroup?.type === ProductGroupType.System && (
          <SystemSearch customizations={currentCustomizations} />
        )}
        {prodGroup?.type === ProductGroupType.Questionnaire && (
          <QuestionnaireWrapper customizations={currentCustomizations} />
        )}
        <HStack bottom='10' right='10' position='absolute'>
          <Image src={VidualsIcon} />
          <Text color='gray.300'>Powered by Viduals TM 2021</Text>
        </HStack>
      </Box>
    </EndUserContextContext.Provider>
  );
};
