import {
  Text,
  Stack,
  Modal,
  ModalContent,
  Heading,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { System } from '../../../objects/System';
import { useAppSelector } from '../../../store/hooks';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { selectTemplates } from '../../../store/templateSlice';
import { TemplateSystemChoice } from './TemplateSystemChoice';

interface SelectTemplateForSystemProps {
  system: System;
  isOpen: boolean;
  onClose: () => void;
}

export const SelectTemplateForSystem = ({
  system,
  isOpen,
  onClose,
}: SelectTemplateForSystemProps): JSX.Element => {
  const pGroup = useAppSelector(selectCurrentProductGroup);
  const templates = useAppSelector(selectTemplates);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalContent>
        <ModalBody>
          <Stack spacing={5} pt={15}>
            <Stack>
              <Heading as='h1' size='md'>
                Templates
              </Heading>
              <Text
                color='gray.500'
                borderBottom={'1px'}
                borderColor={appColors.BORDERGRAY}
              >
                Use the pre-selections from templates as a starting point for
                your
                <Tooltip label='configuration'>
                  <Text as='span'>
                    &nbsp;
                    {pGroup?.configurationName ?? 'configuration'}.
                  </Text>
                </Tooltip>
              </Text>
            </Stack>
            <Stack>
              {templates.map((template) => (
                <TemplateSystemChoice
                  system={system}
                  key={`template choice ${template.id} for system`}
                  template={template}
                />
              ))}
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button w='45%' onClick={onClose}>
            Go back
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
