import {
  IconButton,
  Flex,
  Spacer,
  HStack,
  Stack,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import 'firebase/app';
import 'firebase/auth';
import { UserIcon } from '../config/icons';
import { ProfilePopup } from '../sharedComponents/modal/ProfilePopup';
import { SuperadminTable } from '../sharedComponents/superadmin/SuperadminTable';
import LogoSuperadmin from '../images/logo_superadmin.svg';
import { useAppSelector } from '../store/hooks';
import { selectCurrentUserData } from '../store/authSlice';
import { useHistory } from 'react-router-dom';

export const SuperadminPage = (): JSX.Element => {
  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();
  const history = useHistory();

  const userData = useAppSelector(selectCurrentUserData);

  if (!userData.superAdmin) {
    history.goBack();
  }

  return (
    <Flex>
      <Flex className='main'>
        <Stack pt={5} w='100%'>
          <HStack mx={0}>
            <Image src={LogoSuperadmin} />
            <Spacer />
            <IconButton
              aria-label='Profile'
              border='none'
              icon={<UserIcon className='icon' w={8} h={8} />}
              onClick={onOpenProfilePopup}
            />
            <ProfilePopup
              isOpen={isOpenProfilePopup}
              onClose={onCloseProfilePopup}
            />
          </HStack>
          <SuperadminTable />
        </Stack>
      </Flex>
    </Flex>
  );
};
