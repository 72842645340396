/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useEffect, useState } from 'react';
import { Folder } from '../../objects/Folder';
import { Box } from '@chakra-ui/react';
import './Popup.scss';
import { useAppSelector } from '../../store/hooks';
import { FileTreeItemTypeSchema } from '../../objects/FileTreeItem';
import SortableTree, {
  getTreeFromFlatData,
  TreeItem,
} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { isSome } from '../../config/Maybe';
import { selectFoldersInTree } from '../../store/treeItemSlice';
import { FolderItem } from '../folders/FolderItem';

export const ROOT_TREE_ID = 'root';

interface FoldersListProps {
  setCurrentFolder: (folder: Folder) => void;
  selectedFolderId?: string;
}

export const FoldersList = ({
  setCurrentFolder,
  selectedFolderId,
}: FoldersListProps): JSX.Element => {
  const availableFolders = useAppSelector(selectFoldersInTree);

  useEffect(() => {
    const treeItems = getTreeItemsFromFlatItems(availableFolders);
    const treeStruct = getTreeFromFlatData({
      flatData: treeItems,
      getKey: (fileTreeNode) => fileTreeNode.id,
      getParentKey: (fileTreeNode) => fileTreeNode.parentId,
      rootKey: ROOT_TREE_ID,
    });

    setTreeData(treeStruct);
  }, [availableFolders]);

  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  const getTreeItemsFromFlatItems = (
    folders: Folder[]
  ): {
    [key: number]: TreeItem;
  } => {
    return folders.map((folder): TreeItem => {
      const parentId =
        isSome(folder.parent) && folder.parent.isRoot
          ? ROOT_TREE_ID
          : folder.parentFolderId;
      return {
        title: (
          <FolderItem
            overWriteOnClick={setCurrentFolder}
            disableOnClick={true}
            key={folder.id}
            folder={folder}
          />
        ),
        id: folder.id,
        parentId: parentId,
        expanded: false,
        folder: folder,
        type: FileTreeItemTypeSchema.Enum.FOLDER,
        childAllowed: true,
      };
    });
  };

  return (
    <Box minH={100} h={300} w='100%'>
      <SortableTree
        treeData={treeData}
        canDrag={false}
        onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
        theme={FileExplorerTheme}
        rowHeight={42}
        reactVirtualizedListProps={{
          className: 'file-tree-list',
        }}
        generateNodeProps={({ node }) => ({
          className:
            isSome(selectedFolderId) && node.folder.id === selectedFolderId
              ? classNames.treeCheckedRow
              : '',
        })}
      />
    </Box>
  );
};

const classNames = {
  treeCheckedRow: 'file-tree-checked-row',
  tree: 'system-configuration-file-tree',
};
