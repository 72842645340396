import { FormLabel, Stack, Input, InputGroup } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputProps {
  placeholder: string;
  register: UseFormRegisterReturn;
  text?: string;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const TextInput = ({
  placeholder,
  register,
  onKeyPress,
}: InputProps): JSX.Element => {
  return (
    <Stack display='flex' w='100%'>
      <FormLabel>{placeholder}</FormLabel>

      <InputGroup w='100%'>
        <Input
          onKeyPress={onKeyPress}
          {...register}
          w='100%'
          type='text'
          placeholder={placeholder}
        />
      </InputGroup>
    </Stack>
  );
};
