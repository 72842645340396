import {
  Box,
  Button,
  FormLabel,
  Heading,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FolderIcon, ImageIcon, PreviewIcon } from '../../../config/icons';
import { ContentWithTags } from '../../../objects/ContentWithTags';
import { ItemType } from '../../../objects/FolderOrContentItem';
import { FolderWithTags } from '../../../objects/FolderWithTags';
import { FolderOrContentQuestion } from '../../../objects/Questions/FolderOrContentQuestion';
import { useAppSelector } from '../../../store/hooks';
import {
  selectContentWithTagsById,
  selectFolderWithTagsById,
} from '../../../store/foldersAndContentsWithTagsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { appColors } from '../../../config/constants';
import { selectCurrentQuestionnaireRootContent } from '../../../store/questionnaireConfigurationSlice';
import { isNone, isSome } from '../../../config/Maybe';
import Select from 'react-select';
import { ImagePreview } from '../../../components/ImagePreview';
import { useGetUsedQuestionnaireImagesQuery } from '../../../services/questionnaireEndpoints';
import { buildFileGetQuestionImageUrlByProdGroup } from '../../../config/S3Client';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';

interface SelectInterface {
  value: string;
  label: string;
}

interface ContentOrFolderQuestionCardProps {
  question: FolderOrContentQuestion;
  updateQuestion: (
    text: string,
    helpText: string,
    itemId: string,
    imageUrl: string | null | undefined,
    file: File | string | undefined
  ) => void;
  setRootItem: (question: FolderOrContentQuestion) => void;
}

export const ContentOrFolderQuestionCard = ({
  question,
  updateQuestion,
  setRootItem,
}: ContentOrFolderQuestionCardProps): JSX.Element => {
  const currentRootContent = useAppSelector(
    selectCurrentQuestionnaireRootContent
  );
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const [showHelpInput, setShowHelpInput] = useState<boolean>(
    question.helpText !== ''
  );
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const folderOrContent: FolderWithTags | ContentWithTags | undefined =
    question.type === ItemType.Content
      ? useAppSelector((state) =>
          selectContentWithTagsById(state, question.folderOrContentId)
        )
      : useAppSelector((state) =>
          selectFolderWithTagsById(state, question.folderOrContentId)
        );

  useEffect(() => {
    if (isSome(currentRootContent) && isSome(folderOrContent)) {
      setIsSelected(currentRootContent.item.id === folderOrContent.id);
    } else {
      setIsSelected(false);
    }
  }, [currentRootContent, folderOrContent]);

  // useRef hook does not trigger a rerender, so we do it manually.
  const [imageFile, setImageFileState] = useState<File | undefined>(undefined);
  const [imageFileUrl, setImageFileUrl] = useState<string>('');

  useEffect(() => {
    if (isSome(imageFile)) {
      setImageFileUrl(URL.createObjectURL(imageFile));
    } else {
      setImageFileUrl('');
    }
  }, [imageFile]);

  const [selectedExistingFile, setSelectedExistingFile] = useState<string>(
    question.imageUrl ?? ''
  );
  const [fileOptions, setFileOptions] = useState<SelectInterface[]>([]);

  const { data: exsitingImages } = useGetUsedQuestionnaireImagesQuery(
    selectedProductGroup?.id ?? '',
    {
      skip: isNone(selectedProductGroup),
    }
  );

  useEffect(() => {
    if (isSome(exsitingImages)) {
      setFileOptions(
        exsitingImages.map((name) => {
          return { value: name, label: name };
        })
      );
    } else {
      setFileOptions([]);
    }
  }, [exsitingImages]);

  const thumbnailImageRef = useRef<HTMLInputElement>(null);

  const setImageFile = (): void => {
    const file = thumbnailImageRef?.current?.files?.item(0) ?? undefined;
    if (isSome(file) && fileOptions.map((fo) => fo.value).includes(file.name)) {
      window.alert(
        `duplicate file name will overwrite existing file with name:${file.name}`
      );
    }
    updateQuestion(
      question.text,
      question.helpText,
      question.folderOrContentId,
      question.imageUrl,
      file
    );

    setImageFileState(file);
  };

  const onSelectFile = async (
    option: SelectInterface | null
  ): Promise<void> => {
    if (isSome(option)) {
      setSelectedExistingFile(option.value);
      updateQuestion(
        question.text,
        question.helpText,
        question.folderOrContentId,
        option.value,
        question.imageFileObjectUrl
      );
      removeImageFile();
    } else {
      updateQuestion(
        question.text,
        question.helpText,
        question.folderOrContentId,
        '',
        question.imageFileObjectUrl
      );
      setSelectedExistingFile('');
    }
  };

  const removeImageFile = (): void => {
    if (isSome(thumbnailImageRef) && isSome(thumbnailImageRef.current)) {
      thumbnailImageRef.current.files = null;
      thumbnailImageRef.current.value = '';

      setImageFileState(undefined);
    }
    setImageFileUrl('');
  };

  const renderImagePreview = (): JSX.Element => {
    if (isSome(imageFile)) {
      return (
        <ImagePreview
          fileUrl={imageFileUrl}
          imageLabel='Help'
          imageName={imageFile.name}
          removeFunc={() => {
            updateQuestion(
              question.text,
              question.helpText,
              question.folderOrContentId,
              question.imageUrl,
              undefined
            );
            removeImageFile();
          }}
        />
      );
    } else if (selectedExistingFile !== '' && selectedProductGroup) {
      const imageUrl = buildFileGetQuestionImageUrlByProdGroup(
        selectedProductGroup.id,
        selectedExistingFile
      );
      return (
        <ImagePreview
          fileUrl={imageUrl}
          imageLabel='Help'
          imageName={selectedExistingFile}
          removeFunc={() => {
            updateQuestion(
              question.text,
              question.helpText,
              question.folderOrContentId,
              '',
              question.imageFileObjectUrl
            );
            setSelectedExistingFile('');
          }}
        />
      );
    }
    return <Text>Image could not be rendered</Text>;
  };

  return (
    <Box
      layerStyle={
        isSelected ? 'questionnaire-card-selected' : 'questionnaire-card'
      }
      onClick={() => setRootItem(question)}
    >
      {folderOrContent !== undefined && (
        <Stack>
          {question.mandatory === true && (
            <Heading as='h2' variant='questionnaire'>
              Mandatory
            </Heading>
          )}

          <FormLabel display='flex' alignItems={'center'}>
            {question.type === ItemType.Folder ? (
              <FolderIcon mr='5px' />
            ) : (
              <FontAwesomeIcon
                style={{ marginRight: '5px' }}
                className={(classNames.icon, classNames.faIcon)}
                icon={faPlayCircle}
                color={appColors.GRAY400}
              />
            )}{' '}
            {folderOrContent.name}
          </FormLabel>
          {question.mandatory !== true && (
            <>
              <Textarea
                variant='questionnaire'
                placeholder={''}
                value={question.text}
                onChange={(e) =>
                  updateQuestion(
                    e.target.value,
                    question.helpText,
                    question.folderOrContentId,
                    question.imageUrl,
                    question.imageFileObjectUrl
                  )
                }
              />
              <Stack justifyContent={'space-between'}>
                {isSome(imageFile) || selectedExistingFile !== '' ? (
                  renderImagePreview()
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        thumbnailImageRef?.current?.click();
                      }}
                      leftIcon={<ImageIcon />}
                    >
                      Upload New Help Image
                    </Button>
                    <Box w='100%' my='20px'>
                      <Text>Choose image from exsiting files</Text>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        name='fileName'
                        options={fileOptions}
                        onChange={onSelectFile}
                      />
                    </Box>
                  </>
                )}
                <input
                  onClick={(e) => (e.currentTarget.value = '')}
                  type='file'
                  style={{ display: 'none' }}
                  accept='image/*'
                  ref={thumbnailImageRef}
                  onChange={setImageFile}
                />
                {!showHelpInput && (
                  <Button
                    w='48%'
                    leftIcon={<PreviewIcon className='icon' w={8} h={5} />}
                    onClick={() => setShowHelpInput(!showHelpInput)}
                  >
                    Add help text
                  </Button>
                )}
              </Stack>
              {showHelpInput && (
                <>
                  <FormLabel>Help text</FormLabel>
                  <Textarea
                    variant='questionnaire'
                    placeholder={''}
                    value={question.helpText}
                    onChange={(e) =>
                      updateQuestion(
                        question.text,
                        e.target.value,
                        question.folderOrContentId,
                        question.imageUrl,
                        question.imageFileObjectUrl
                      )
                    }
                  />
                </>
              )}
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
