import '../TagsPage.scss';
import { Box } from '@chakra-ui/react';
import { TagGroup } from '../../../objects/TagGroup';

interface TagGroupItemProps {
  tagGroup: TagGroup;
  onClick: () => void;
}

export const TagGroupItem = ({
  tagGroup,
  onClick,
}: TagGroupItemProps): JSX.Element => {
  return (
    <Box>
      <span onClick={onClick} className={classNames.folder}>
        {tagGroup.name}
      </span>
    </Box>
  );
};

const classNames = {
  folder: 'folder',
};
