import { Flex, Text } from '@chakra-ui/react';
import './QTemplateSettingsRadioLabels.scss';

interface QTemplateSettingsRadioLabelsProps {
  title: JSX.Element;
}

export const QTemplateSettingsRadioLabels = ({
  title,
}: QTemplateSettingsRadioLabelsProps): JSX.Element => {
  return (
    <Flex
      w='100%'
      h='fit-content'
      justifyContent={'space-between'}
      alignItems='flex-end'
    >
      {title}
      <Flex w='220px' mr='10px' h='100px' alignItems={'flex-end'}>
        <Text className={classNames.angleLabel}>Mandatory</Text>
        <Text className={classNames.angleLabel}>Optional</Text>
        <Text className={classNames.angleLabel}>Unavailable</Text>
      </Flex>
    </Flex>
  );
};

const classNames = {
  angleLabel: 'template_settings_45Degree_label',
};
