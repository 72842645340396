import { ProdGroup } from '../objects/ProdGroup';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'local';

export const getS3BucketName = (): string => {
  let host;
  switch (ENVIRONMENT) {
    case 'dev':
    case 'stage':
    case 'prod':
      host = `content.vidualsportal.compoze.${ENVIRONMENT}`;
      break;
    default:
      host = 'content.vidualsportal.compoze.dev';
      break;
  }
  return host;
};

export const buildFileGetUrlByProdGroup = (
  prodGroup: ProdGroup | null | undefined,
  fileName: string
): string => {
  const bucketName = getS3BucketName();
  const productGroupPrefix: string = prodGroup?.id ?? '';
  const encodedFileName = encodeURIComponent(fileName);

  const url = `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/${encodedFileName}/${encodedFileName}`;

  return url;
};

export const buildFileGetTagImageUrlByProdGroup = (
  prodGroupId: string,
  fileName: string
): string => {
  const bucketName = getS3BucketName();
  const productGroupPrefix: string = prodGroupId;
  const encodedFileName = encodeURIComponent(fileName);

  const url = `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/tagImage/${encodedFileName}/${encodedFileName}`;

  return url;
};

export const buildFileGetQuestionImageUrlByProdGroup = (
  prodGroupId: string,
  fileName: string
): string => {
  const bucketName = getS3BucketName();
  const productGroupPrefix: string = prodGroupId;
  const encodedFileName = encodeURIComponent(fileName);

  const url = `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/questionImage/${encodedFileName}/${encodedFileName}`;

  return url;
};
