/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContentVersion,
  contentVersionListToMap,
} from '../objects/ContentVersion';
import { RootState } from './store';

const initialContentVersions: Record<string, ContentVersion> = {};

export const contentVersionSlice = createSlice({
  name: 'contentVersions',
  initialState: {
    contentVersions: initialContentVersions,
    versionsToDelete: [] as ContentVersion[],
  },
  reducers: {
    setContentVersions: (
      state,
      { payload: contentVersions }: PayloadAction<ContentVersion[]>
    ) => {
      state.contentVersions = contentVersionListToMap(contentVersions);
    },
    createOrUpdateContentVersions: (
      state,
      { payload: contentVersionsToCreate }: PayloadAction<ContentVersion[]>
    ) => {
      contentVersionsToCreate.forEach(
        (contentVersionToCreate: ContentVersion) => {
          state.contentVersions[contentVersionToCreate.id] =
            contentVersionToCreate;
        }
      );
    },
    setVersionsToDelete: (
      state,
      { payload: contentVersionsToDelete }: PayloadAction<ContentVersion[]>
    ) => {
      state.versionsToDelete = contentVersionsToDelete;
    },
  },
});

export const selectContentVerionMap = (
  state: RootState
): Record<string, ContentVersion> => state.contentVersions.contentVersions;

export const selectVersionsToDelete = (state: RootState): ContentVersion[] =>
  state.contentVersions.versionsToDelete;

// Action creators are generated for each case reducer function
export const {
  setContentVersions,
  createOrUpdateContentVersions,
  setVersionsToDelete,
} = contentVersionSlice.actions;

export default contentVersionSlice.reducer;
