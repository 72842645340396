/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from '../objects/Tag';
import { RootState } from './store';

export const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tags: [] as Tag[],
  },
  reducers: {
    createTag: (state, { payload: tagToCreate }: PayloadAction<Tag>) => {
      state.tags.push(tagToCreate);
    },
    setTags: (state, { payload: tags }: PayloadAction<Tag[]>) => {
      state.tags = tags;
    },
    addTags: (state, { payload: tags }: PayloadAction<Tag[]>) => {
      return {
        ...state,
        tags: [...state.tags, ...tags],
      };
    },
    updateTag: (state, { payload: updatedTag }: PayloadAction<Tag>) => {
      return {
        ...state,
        tags: state.tags.map((tag) =>
          tag.id === updatedTag.id ? updatedTag : tag
        ),
      };
    },
    updateTagOrder: (state, { payload: orderedTags }: PayloadAction<Tag[]>) => {
      return {
        ...state,
        tags: state.tags
          .map((tag) => {
            const updatedTag: Tag | undefined = orderedTags.find(
              (orderedTag) => orderedTag.id === tag.id
            );
            return updatedTag !== undefined
              ? {
                  ...tag,
                  indexWithinParent: updatedTag.indexWithinParent,
                }
              : tag;
          })
          .sort((a: Tag, b: Tag) => {
            return a.indexWithinParent - b.indexWithinParent;
          }),
      };
    },
    deleteTags: (state, { payload: deletedIds }: PayloadAction<string[]>) => {
      return {
        ...state,
        tags: state.tags.filter((tag) => !deletedIds.includes(tag.id)),
      };
    },
  },
});

export const selectTags = (state: RootState): Tag[] => state.tag.tags;

export const selectAllTagsInOrder = (state: RootState): Tag[] => {
  const allTags: Tag[] = [];
  const sortedGroups = [...state.tagGroups.tagGroups].sort((a, b) => {
    return a.indexWithinParent - b.indexWithinParent;
  });
  sortedGroups.forEach((group) => {
    allTags.push(
      ...[...state.tag.tags]
        .filter((t) => t.tagGroupId === group.id)
        .sort((a, b) => a.indexWithinParent - b.indexWithinParent)
    );
  });
  allTags.push(
    ...[...state.tag.tags]
      .filter((t) => t.tagGroupId === undefined || t.tagGroupId === null)
      .sort((a, b) => a.indexWithinParent - b.indexWithinParent)
  );

  return allTags;
};

export const selectTagById = (
  state: RootState,
  tagId: string
): Tag | undefined => state.tag.tags.find((tag) => tag.id === tagId);

export const selectTagsInGroup = (state: RootState, groupId: string): Tag[] =>
  state.tag.tags.filter((tag) => tag.tagGroupId === groupId);

export const selectUngroupedTags = (state: RootState): Tag[] =>
  state.tag.tags.filter((tag) => {
    return tag.tagGroupId === undefined || tag.tagGroupId === null;
  });

export const selectGroupedTags = (state: RootState): Tag[] =>
  state.tag.tags.filter((tag) => {
    return tag.tagGroupId !== undefined && tag.tagGroupId !== null;
  });

export const {
  createTag,
  setTags,
  addTags,
  updateTag,
  updateTagOrder,
  deleteTags,
} = tagSlice.actions;

export default tagSlice.reducer;
