/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  DeleteFoldersResult,
  Folder,
  InputFolderParams,
  UpdateFolderParams,
} from '../objects/Folder';
import {
  ContentsWithTagsList,
  FoldersWithTagsList,
  IOnQueryAPI,
  vidualsApi,
} from './vidualsApi';
import { setRawFolders, setFolders } from '../store/folderSlice';
import {
  addFolderOrContentToTree,
  deleteFolderContentOrVersion,
  updateFolderContentOrVersion,
  updateFolderOrContentIndexWithinParent,
} from '../store/treeItemSlice';
import { ItemType } from '../objects/FolderOrContentItem';
import { Content } from '../objects/Content';
import { removeFolderFromTemplate } from '../store/templateSlice';

const updateFoldersState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setRawFolders(data));
    dispatch(setFolders(data));
  } catch (err) {
    console.trace(err);
  }
};

const addCreatedFolderToTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addFolderOrContentToTree([{ item: data, type: ItemType.Folder }]));
  } catch (err) {
    console.trace(err);
  }
};

const updateFolderOnTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(
      updateFolderContentOrVersion({ item: data, type: ItemType.Folder })
    );
  } catch (err) {
    console.trace(err);
  }
};

const updateFolderChildIds = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateFolderOrContentIndexWithinParent(data));
  } catch (err) {
    console.trace(err);
  }
};

const deleteFolderFromTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(deleteFolderContentOrVersion([data]));
    dispatch(removeFolderFromTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

export const foldersApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getFoldersByProductGroupId: builder.query<Folder[], string>({
      query: (pgid) => `folders/productGroup/${pgid}`,
      onQueryStarted: updateFoldersState,
    }),
    createFolder: builder.mutation<Folder, InputFolderParams>({
      query: (body) => {
        return {
          url: `folders`,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: addCreatedFolderToTree,
    }),
    updateFolder: builder.mutation<
      Folder,
      { id: string; folder: UpdateFolderParams }
    >({
      query: ({ id, folder }) => {
        return {
          url: `folders/${id}`,
          method: 'PUT',
          body: folder,
        };
      },
      invalidatesTags: [FoldersWithTagsList, ContentsWithTagsList],
      onQueryStarted: updateFolderOnTree,
    }),
    updateFolderChildrenOrder: builder.mutation<
      Array<Folder | Content>,
      {
        id: string;
        sortedChildIds: Array<{ id: string; type: ItemType }>;
      }
    >({
      query: ({ id, sortedChildIds }) => {
        return {
          url: `folders/${id}/setChildrenOrder`,
          method: 'PUT',
          body: sortedChildIds,
        };
      },
      onQueryStarted: updateFolderChildIds,
    }),
    deleteFolderById: builder.mutation<DeleteFoldersResult, string>({
      query: (folderId) => {
        return {
          url: `folders/${folderId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [FoldersWithTagsList, ContentsWithTagsList],
      onQueryStarted: deleteFolderFromTree,
    }),
  }),
});

export const {
  useLazyGetFoldersByProductGroupIdQuery,
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useUpdateFolderChildrenOrderMutation,
  useDeleteFolderByIdMutation,
} = foldersApi;
