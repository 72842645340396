/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { isSome } from '../config/Maybe';
import {
  DeleteOrgResult,
  Organization,
  OrganizationDeleteUsersParams,
  OrganizationParams,
} from '../objects/Organization';
import { User } from '../objects/User';
import {
  setCurrentOrganization,
  setUserOrganizations,
} from '../store/organizationSlice';
import {
  IOnQueryAPI,
  OrgCacheKey,
  OrgListCacheId,
  OrgUserCacheKey,
  vidualsApi,
} from './vidualsApi';

const updateOrganizationState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;

    dispatch(setUserOrganizations(data));
    dispatch(setCurrentOrganization(data[0] ?? null));
  } catch (err) {
    console.trace(err);
  }
};

export const orgsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrgs: builder.query<Organization[], void>({
      query: () => `organizations`,
      providesTags: [{ type: OrgCacheKey, id: OrgListCacheId }],
      onQueryStarted: updateOrganizationState,
    }),
    createOrg: builder.mutation<Organization, OrganizationParams>({
      query: (orgInput) => {
        return {
          url: `organizations`,
          method: 'POST',
          body: orgInput,
        };
      },
      invalidatesTags: [{ type: OrgCacheKey, id: OrgListCacheId }],
    }),
    updateOrg: builder.mutation<Organization, OrganizationParams>({
      query: (orgInput) => {
        return {
          url: `organizations/${orgInput?.id ?? 'undefined'}`,
          method: 'PUT',
          body: {
            name: orgInput.name,
            notes: orgInput.notes,
          },
        };
      },
      invalidatesTags: [{ type: OrgCacheKey, id: OrgListCacheId }],
    }),
    getUsersForOrg: builder.query<User[], string>({
      query: (orgId) => {
        return {
          url: `organizations/${orgId}/users`,
          method: 'GET',
        };
      },
      providesTags: [OrgUserCacheKey],
    }),
    addUsersToOrg: builder.mutation<Organization, OrganizationParams>({
      query: (orgInput) => {
        return {
          url: `organizations/${orgInput?.id ?? 'undefined'}/users`,
          method: 'POST',
          body: orgInput.users ?? [],
        };
      },
      invalidatesTags: [
        { type: OrgCacheKey, id: OrgListCacheId },
        OrgUserCacheKey,
      ],
    }),
    deleteUsersFromOrg: builder.mutation<
      Organization,
      OrganizationDeleteUsersParams
    >({
      query: (orgInput) => {
        return {
          url: `organizations/${orgInput?.orgId ?? 'undefined'}/users`,
          method: 'DELETE',
          body: orgInput.userUids,
        };
      },
      invalidatesTags: [
        { type: OrgCacheKey, id: OrgListCacheId },
        OrgUserCacheKey,
      ],
    }),
    deleteOrgById: builder.mutation<DeleteOrgResult, string>({
      query: (orgId) => {
        return {
          url: `organizations/${orgId ?? 'undefined'}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result) => {
        if (isSome(result)) {
          return [{ type: OrgCacheKey, id: result.orgId }];
        }
        return [];
      },
    }),
  }),
});

export const {
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useGetUsersForOrgQuery,
  useAddUsersToOrgMutation,
  useDeleteUsersFromOrgMutation,
  useDeleteOrgByIdMutation,
  useLazyGetOrgsQuery,
} = orgsApi;
