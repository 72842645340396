import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Content } from '../objects/Content';
import { Folder } from '../objects/Folder';
import { Organization } from '../objects/Organization';
import { ProdGroup } from '../objects/ProdGroup';
import { QTemplate } from '../objects/QuestionnaireTemplate';
import { Tag } from '../objects/Tag';
import { TagGroup } from '../objects/TagGroup';
import { Template } from '../objects/Template';
import { User } from '../objects/User';
import { RootState } from './store';

export const modalFormSlice = createSlice({
  name: 'modalForm',
  initialState: {
    showCreateEditFolderModal: false,
    showCreateEditContentModal: false,
    showDeleteFolderModal: false,
    showDeleteContentModal: false,
    folderToEdit: undefined as Folder | undefined,
    contentToEdit: undefined as Content | undefined,
    tagToEdit: undefined as Tag | undefined,
    tagGroupToEdit: undefined as TagGroup | undefined,
    orgToEdit: undefined as Organization | undefined,
    userToEdit: undefined as User | undefined,
    templateToEdit: undefined as Template | undefined,
    qTemplateToEdit: undefined as QTemplate | undefined,
    productGroupToEdit: undefined as ProdGroup | undefined,
  },
  reducers: {
    setShowCreateEditFolderModal: (
      state,
      { payload: showModal }: PayloadAction<boolean>
    ) => {
      state.showCreateEditFolderModal = showModal;
    },
    setShowCreateEditContentModal: (
      state,
      { payload: showModal }: PayloadAction<boolean>
    ) => {
      state.showCreateEditContentModal = showModal;
    },
    setShowDeleteFolderModal: (
      state,
      { payload: showModal }: PayloadAction<boolean>
    ) => {
      state.showDeleteFolderModal = showModal;
    },
    setShowDeleteContentModal: (
      state,
      { payload: showModal }: PayloadAction<boolean>
    ) => {
      state.showDeleteContentModal = showModal;
    },
    setFolderToEdit: (
      state,
      { payload: folder }: PayloadAction<Folder | undefined>
    ) => {
      state.folderToEdit = folder;
    },
    setContentToEdit: (
      state,
      { payload: content }: PayloadAction<Content | undefined>
    ) => {
      state.contentToEdit = content;
    },
    setTagToEdit: (state, { payload: tag }: PayloadAction<Tag | undefined>) => {
      state.tagToEdit = tag;
    },
    setTagGroupToEdit: (
      state,
      { payload: tagGroup }: PayloadAction<TagGroup | undefined>
    ) => {
      state.tagGroupToEdit = tagGroup;
    },
    setTemplateToEdit: (
      state,
      { payload: template }: PayloadAction<Template | undefined>
    ) => {
      state.templateToEdit = template;
    },
    setQTemplateToEdit: (
      state,
      { payload: qTemplate }: PayloadAction<QTemplate | undefined>
    ) => {
      state.qTemplateToEdit = qTemplate;
    },
    setOrgToEdit: (state, { payload: org }: PayloadAction<Organization>) => {
      state.orgToEdit = org;
    },
    setUserToEdit: (
      state,
      { payload: user }: PayloadAction<User | undefined>
    ) => {
      state.userToEdit = user;
    },
    setProductGroupToEdit: (
      state,
      { payload: productGroup }: PayloadAction<ProdGroup | undefined>
    ) => {
      state.productGroupToEdit = productGroup;
    },
  },
});

export const selectShowCreateEditFolderModal = (state: RootState): boolean =>
  state.modalForm.showCreateEditFolderModal;

export const selectShowCreateEditContentModal = (state: RootState): boolean =>
  state.modalForm.showCreateEditContentModal;

export const selectShowDeleteFolderModal = (state: RootState): boolean =>
  state.modalForm.showDeleteFolderModal;

export const selectShowDeleteContentModal = (state: RootState): boolean =>
  state.modalForm.showDeleteContentModal;

export const selectFolderToEdit = (state: RootState): Folder | undefined =>
  state.modalForm.folderToEdit;

export const selectContentToEdit = (state: RootState): Content | undefined =>
  state.modalForm.contentToEdit;

export const selectTagToEdit = (state: RootState): Tag | undefined =>
  state.modalForm.tagToEdit;

export const selectTagGroupToEdit = (state: RootState): TagGroup | undefined =>
  state.modalForm.tagGroupToEdit;

export const selectTemplateToEdit = (state: RootState): Template | undefined =>
  state.modalForm.templateToEdit;

export const selectQTemplateToEdit = (
  state: RootState
): QTemplate | undefined => state.modalForm.qTemplateToEdit;

export const selectOrgToEdit = (state: RootState): Organization | undefined =>
  state.modalForm.orgToEdit;

export const selectUserToEdit = (state: RootState): User | undefined =>
  state.modalForm.userToEdit;

export const selectProductGroupToEdit = (
  state: RootState
): ProdGroup | undefined => state.modalForm.productGroupToEdit;

export const {
  setShowCreateEditFolderModal,
  setShowCreateEditContentModal,
  setShowDeleteFolderModal,
  setShowDeleteContentModal,
  setFolderToEdit,
  setContentToEdit,
  setTagToEdit,
  setTagGroupToEdit,
  setTemplateToEdit,
  setQTemplateToEdit,
  setOrgToEdit,
  setUserToEdit,
  setProductGroupToEdit,
} = modalFormSlice.actions;

export default modalFormSlice.reducer;
