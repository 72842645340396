import {
  Button,
  Stack,
  Modal,
  ModalContent,
  Heading,
  ModalFooter,
  ModalBody,
  HStack,
  ModalOverlay,
  Input,
  Text,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { TextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import '../Popup.scss';
import { TextAreaInput } from '../../../components/TextAreaInput';
import { InputSystemParams, System } from '../../../objects/System';
import { useEditSystemMutation } from '../../../services/systemEndpoints';
import { isSome } from '../../../config/Maybe';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { setSystemToEdit } from '../../../store/systemSlice';
import { selectCurrentOrg } from '../../../store/organizationSlice';
import { useGetCustomizationsByProductGroupIdQuery } from '../../../services/customizationsEndpoints';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface EditSystemPopupProps {
  system: System;
}

export const EditSystemPopup = ({
  system,
}: EditSystemPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const warrantyStartParsed = Date.parse(system.warrantyStart);
  const warrantyStartState = !isNaN(warrantyStartParsed)
    ? new Date(warrantyStartParsed)
    : null;
  const warrantyEndParsed = Date.parse(system.warrantyEnd);
  const warrantyEndState = !isNaN(warrantyEndParsed)
    ? new Date(warrantyEndParsed)
    : null;
  const [warrantyStart, setWarrantyStart] = useState<Date | null>(
    warrantyStartState
  );
  const [warrantyEnd, setWarrantyEnd] = useState<Date | null>(warrantyEndState);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<InputSystemParams>({
    defaultValues: {
      description: system.description,
      serialNumber: system.serialNumber,
      clientName: system.clientName,
    },
  });

  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const selectedOrg = useAppSelector(selectCurrentOrg);
  const [editSystem] = useEditSystemMutation();
  const { data: currentCustomizations } =
    useGetCustomizationsByProductGroupIdQuery(
      selectedProductGroup?.id ?? skipToken
    );

  const handleFormSubmit = async (
    systemInfo: InputSystemParams
  ): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const warrantyStartString = isSome(warrantyStart)
        ? new Date(warrantyStart).toLocaleDateString()
        : '';
      const warrantyEndString = isSome(warrantyEnd)
        ? new Date(warrantyEnd).toLocaleDateString()
        : '';

      await editSystem({
        id: system.id,
        ...systemInfo,
        warrantyStart: warrantyStartString,
        warrantyEnd: warrantyEndString,
      });
    }
    dispatch(setSystemToEdit(undefined));
  };

  const handleWarrantyStartChange = (date: Date | null): void => {
    if (isSome(date)) {
      setWarrantyStart(date);
    } else {
      setWarrantyStart(date);
    }
  };

  const handleWarrantyEndChange = (date: Date | null): void => {
    if (isSome(date)) {
      setWarrantyEnd(date);
    } else {
      setWarrantyEnd(null);
    }
  };

  const systemDescriptionRegister = register('description');
  const systemDescriptionText = watch('description');
  const systemSerialRegister = register('serialNumber', { required: true });
  const systemSerialText = watch('serialNumber');
  const systemClientRegister = register('clientName', {
    required: currentCustomizations?.inclClientName ?? true,
  });
  const systemClientText = watch('clientName');

  return (
    <Modal
      isOpen={true}
      onClose={() => dispatch(setSystemToEdit(undefined))}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalBody>
            <Stack spacing={30} pt={15}>
              <Heading as='h1' size='md'>
                System information
              </Heading>

              <Stack>
                <FormLabel>URL</FormLabel>
                <HStack>
                  <Text color='gray.500'>
                    ...viduals.com/{selectedOrg?.urlExtension}/
                    {selectedProductGroup?.urlExtension}/
                  </Text>
                  <Input
                    disabled
                    {...register('urlExtension', { required: true })}
                    placeholder='URL'
                    defaultValue={system.urlExtension}
                    type='text'
                  />
                </HStack>
                <TextAreaInput
                  placeholder='Description (optional)'
                  register={systemDescriptionRegister}
                  text={systemDescriptionText}
                />
                <TextInput
                  placeholder={`${
                    selectedProductGroup?.configurationIdLabel ?? 'ID'
                  }`}
                  register={systemSerialRegister}
                  text={systemSerialText}
                />
                {isSome(errors.serialNumber) &&
                  errors.serialNumber.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter a(n)&nbsp;
                      <Tooltip label='configuration id'>
                        <Text as='span'>
                          {selectedProductGroup?.configurationIdLabel ?? 'ID'}.
                        </Text>
                      </Tooltip>
                      Please try again.
                    </Text>
                  )}
                <TextInput
                  placeholder='Client Name'
                  register={systemClientRegister}
                  text={systemClientText}
                />
                {isSome(errors.clientName) &&
                  errors.clientName.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter a client name. Please try again. If you do
                      not wish to inlcude the client name, navigate to the
                      customizations page, uncheck &quot;Include client
                      name&quot; and save the change.
                    </Text>
                  )}
                <FormLabel>Warranty</FormLabel>
                <HStack w='100%' spacing='5%'>
                  <HStack w='45%' boxSizing='border-box'>
                    <Text>Start:</Text>
                    <DatePicker
                      selected={warrantyStart}
                      onChange={handleWarrantyStartChange}
                    />
                  </HStack>
                  <HStack w='45%' boxSizing='border-box'>
                    <Text>End:</Text>
                    <DatePicker
                      selected={warrantyEnd}
                      onChange={handleWarrantyEndChange}
                    />
                  </HStack>
                </HStack>
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack className='space-between width100'>
              <Button
                w='45%'
                onClick={() => dispatch(setSystemToEdit(undefined))}
              >
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='50%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
