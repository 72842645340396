import { Flex, Text, Divider, Box } from '@chakra-ui/react';
import SortableTree, {
  changeNodeAtPath,
  ExtendedNodeData,
  getVisibleNodeCount,
  TreeItem,
} from 'react-sortable-tree';
import { isSome } from '../../config/Maybe';
import { FileTreeItemTypeSchema } from '../../objects/FileTreeItem';
import { PdfItem } from './PdfItem';
import { VideoItem } from './VideoItem';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import React, { useEffect, useState } from 'react';
import './SearchResults.scss';

interface SearchResultsProps {
  matches: TreeItem[];
  color: string;
  resetSearchTerm: () => void;
}

export const SearchResults = ({
  matches,
  color,
  resetSearchTerm,
}: SearchResultsProps): JSX.Element => {
  const versionItems = matches.filter(
    (item) => item.type === FileTreeItemTypeSchema.Enum.CONTENT_VERSION
  );

  const [matchFolderItems, setMatchFolderItems] = useState<TreeItem[]>(
    matches.filter((item) => item.type === FileTreeItemTypeSchema.Enum.FOLDER)
  );

  const [folderExpand, setFolderExpand] = useState<boolean>(false);

  // getNodeAtPath --> will help get path names

  useEffect(() => {
    const folderItems: TreeItem[] = matches
      .filter((item) => item.type === FileTreeItemTypeSchema.Enum.FOLDER)
      // search automatically expands results in original tree
      // this defaults them back to closed
      .map((item) => {
        item.expanded = false;
        return item;
      });

    setMatchFolderItems(folderItems);
  }, [matches]);

  const updateFolderTreeState = (folderItem: TreeItem, index: number): void => {
    const newItems = matchFolderItems;
    newItems[index] = folderItem;
    setMatchFolderItems(newItems);
    // This exists only because the sortable tree doesn't recognize the change to expand/collapse
    // when we break the tree into weird pieces like we do here
    setFolderExpand(!folderExpand);
  };

  const toggleNodeExpandIfFolder = (
    nodeData: ExtendedNodeData,
    folderItem: TreeItem,
    index: number
  ): void => {
    const { node, path } = nodeData;
    if (node.children && node.children.length > 0) {
      const newItems = matchFolderItems;
      const updatedFileTree = changeNodeAtPath({
        treeData: [folderItem],
        path,
        getNodeKey: (fileTreeNode) => fileTreeNode.treeIndex,
        newNode: { ...node, expanded: !node.expanded },
      });
      newItems[index] = updatedFileTree[0];
      setMatchFolderItems(newItems);
      // This exists only because the sortable tree doesn't recognize the change to expand/collapse
      // when we break the tree into weird pieces like we do here
      setFolderExpand(!folderExpand);
    }
  };

  return (
    <Flex w='100%' flexDirection='column' pb={80}>
      {matches.length === 0 && <Text>No matches found</Text>}
      {versionItems.map((versionItem) => {
        if (
          isSome(versionItem.fileTreeItem.videoName) &&
          versionItem.fileTreeItem.videoName !== ''
        ) {
          return (
            <React.Fragment
              key={`search match version ${String(
                versionItem.fileTreeItem.id
              )}`}
            >
              <VideoItem
                contentVersion={versionItem.fileTreeItem}
                content={versionItem.content}
                pathName={versionItem.pathName}
                showDescription={true}
                color={color}
                additionalOnClick={resetSearchTerm}
              />
              <Divider pb={3} mb={3} />
            </React.Fragment>
          );
        } else {
          if (
            isSome(versionItem.fileTreeItem.pdfName) &&
            versionItem.fileTreeItem.pdfName !== ''
          ) {
            return (
              <React.Fragment
                key={`search match version ${String(
                  versionItem.fileTreeItem.id
                )}`}
              >
                <PdfItem
                  contentVersion={versionItem.fileTreeItem}
                  content={versionItem.content}
                  pathName={versionItem.pathName}
                  showDescription={true}
                  color={color}
                  additionalOnClick={resetSearchTerm}
                />
                <Divider pb={3} mb={3} />
              </React.Fragment>
            );
          } else {
            return undefined;
          }
        }
      })}
      {matchFolderItems.map((folderItem: TreeItem, index: number) => {
        const visibleCount = getVisibleNodeCount({ treeData: [folderItem] });
        const fullHeight = visibleCount * 42; // count times row height
        const height =
          folderItem.expanded === true ? `${fullHeight}px` : '42px';
        return (
          <React.Fragment
            key={`search match tree:${String(folderItem.fileTreeItem.id)}`}
          >
            <Box
              style={{ height, width: '100%' }}
              mb={
                folderItem.expanded === true || folderItem.pathName !== ''
                  ? '15px'
                  : 0
              }
            >
              <SortableTree
                treeData={[folderItem]}
                canDrag={false}
                onChange={(updatedTreeData) =>
                  updateFolderTreeState(updatedTreeData[0], index)
                }
                theme={FileExplorerTheme}
                rowHeight={42}
                reactVirtualizedListProps={{
                  className: 'file-tree-list',
                }}
                style={{
                  width: '100%',
                  height: height,
                  marginBottom: '15px', // make space for pathname
                }}
                generateNodeProps={(nodeData: ExtendedNodeData) => {
                  const { node, path } = nodeData;
                  if (
                    path.length === 1 &&
                    path[0] === 0 &&
                    node.expanded === false
                  ) {
                    return {
                      class: classNames.searchResultFolderNotExpanded,
                      onClick: () =>
                        toggleNodeExpandIfFolder(nodeData, folderItem, index),
                    };
                  } else {
                    return {
                      onClick: () =>
                        toggleNodeExpandIfFolder(nodeData, folderItem, index),
                    };
                  }
                }}
              />
              {folderItem.expanded === false && folderItem.pathName !== '' && (
                <Text
                  position='relative'
                  justifySelf='flex-start'
                  className={classNames.pathName}
                  style={{
                    color: color ?? 'gray.400',
                    marginTop: '-20px',
                    width: '100%',
                    marginLeft: '35px',
                  }}
                >
                  {folderItem.pathName}
                </Text>
              )}
            </Box>
            <Divider pb={3} mb={3} />
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

const classNames = {
  pathName: 'end_user_search_result_path',
  searchResultFolderNotExpanded: 'end_user_folder_search_result',
};
