import {
  Box,
  HStack,
  FormLabel,
  Stack,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface ColorInputProps {
  placeholder: string;
  register: UseFormRegisterReturn;
  color: string;
}

export const ColorInput = ({
  placeholder,
  register,
  color,
}: ColorInputProps): JSX.Element => {
  return (
    <Stack display='flex' w='100%'>
      <FormLabel>{placeholder}</FormLabel>
      <HStack spacing={3}>
        <Box
          borderRadius={999}
          borderColor='gray.200'
          borderWidth={1}
          w={7}
          h={7}
          bg={'#' + color}
        />
        <InputGroup w='100%'>
          <Input {...register} w='100%' type='text' placeholder={placeholder} />
        </InputGroup>
      </HStack>
    </Stack>
  );
};
