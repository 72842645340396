import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text, HStack, Checkbox, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { isSome } from '../../../config/Maybe';
import { QuestionnaireTemplateOption } from '../../../objects/Questions/QuestionnaireTemplateOption';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectQTemplateById } from '../../../store/qTemplateSlice';
import {
  setCurrentQuestionnaireRootContent,
  selectQuestionnaireTemplateOptionToConfigure,
  setOptionToConfigure,
} from '../../../store/questionnaireConfigurationSlice';

interface QTemplateOptionCheckboxProps {
  qTemplateOption: QuestionnaireTemplateOption;
  toggleTemplateChecked: (templateId: string) => void;
}

export const QTemplateOptionCheckbox = ({
  qTemplateOption,
  toggleTemplateChecked,
}: QTemplateOptionCheckboxProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const qTemplate = useAppSelector((state) =>
    selectQTemplateById(state, qTemplateOption.questionnaireTemplateId)
  );
  const qtOptionToConfigure = useAppSelector(
    selectQuestionnaireTemplateOptionToConfigure
  );

  const [currentSecondLayerStyle, setCurrentSecondLayerStyle] =
    useState<string>('');

  useEffect(() => {
    qtOptionToConfigure?.id === qTemplateOption.id
      ? setCurrentSecondLayerStyle('questionnaire-option-checkbox-selected')
      : setCurrentSecondLayerStyle('');
  }, [qtOptionToConfigure]);

  return (
    <HStack
      layerStyle={[
        'questionnaire-option-checkbox',
        qTemplateOption.available
          ? 'questionnaire-option-checkbox-checked'
          : 'questionnaire-option-checkbox-unchecked',
        currentSecondLayerStyle,
      ]}
    >
      {isSome(qTemplate) && (
        <>
          <HStack>
            <Checkbox
              onChange={() => {
                toggleTemplateChecked(qTemplateOption.questionnaireTemplateId);
              }}
              colorScheme='green'
              isChecked={qTemplateOption.available}
            />
            <Text>{qTemplate.name}</Text>
          </HStack>
          <HStack>
            <Text color='gray.400' fontWeight={700}>
              {'#'} Questions
            </Text>
            <IconButton
              aria-label='set template to configure'
              border='none'
              icon={<ChevronRightIcon color='gray.500' w={7} h={7} />}
              onClick={() => {
                dispatch(setOptionToConfigure(qTemplateOption));
                dispatch(setCurrentQuestionnaireRootContent(undefined));
              }}
            />
          </HStack>
        </>
      )}
    </HStack>
  );
};
