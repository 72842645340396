/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { FolderWithTags } from '../objects/FolderWithTags';
import { ContentWithTags } from '../objects/ContentWithTags';
import {
  FoldersOrContentWithTags,
  ItemType,
} from '../objects/FolderOrContentItem';

export const folderandContentsWithTagsSlice = createSlice({
  name: 'folderAndContentsWithTags',
  initialState: {
    foldersWithTags: [] as FolderWithTags[],
    contentsWithTags: [] as ContentWithTags[],
  },
  reducers: {
    setFoldersWithTags: (
      state,
      { payload: folders }: PayloadAction<FolderWithTags[]>
    ) => {
      state.foldersWithTags = folders;
    },
    setContentsWithTags: (
      state,
      { payload: contents }: PayloadAction<ContentWithTags[]>
    ) => {
      state.contentsWithTags = contents;
    },
  },
});

export const selectFoldersWithTags = (state: RootState): FolderWithTags[] =>
  state.folderAndContentsWithTags.foldersWithTags;

export const selectContentsWithTags = (state: RootState): ContentWithTags[] =>
  state.folderAndContentsWithTags.contentsWithTags;

export const selectFolderWithTagsById = (
  state: RootState,
  folderId: string
): FolderWithTags | undefined => {
  return state.folderAndContentsWithTags.foldersWithTags.find(
    (fwt) => fwt.id === folderId
  );
};

export const selectContentWithTagsById = (
  state: RootState,
  contentId: string
): ContentWithTags | undefined => {
  return state.folderAndContentsWithTags.contentsWithTags.find(
    (cwt) => cwt.id === contentId
  );
};

export const selectFoldersAndContentsWithTags = (
  state: RootState
): FoldersOrContentWithTags[] =>
  [
    ...state.folderAndContentsWithTags.contentsWithTags.map(
      (cwt): FoldersOrContentWithTags => {
        return {
          item: cwt,
          type: ItemType.Content,
        };
      }
    ),
    ...state.folderAndContentsWithTags.foldersWithTags.map(
      (fwt): FoldersOrContentWithTags => {
        return {
          item: fwt,
          type: ItemType.Folder,
        };
      }
    ),
  ].sort(
    (a, b) => (a.item.indexWithinParent ?? 0) - (b.item.indexWithinParent ?? 0)
  );

export const { setFoldersWithTags, setContentsWithTags } =
  folderandContentsWithTagsSlice.actions;

export default folderandContentsWithTagsSlice.reducer;
