import {
  Heading,
  Text,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useDeleteFolderByIdMutation } from '../../services/folderEndpoints';
import { selectFolderToEdit } from '../../store/modalFormSlice';
import { useAppSelector } from '../../store/hooks';
import {
  selectFoldersInTree,
  selectContentsInTree,
} from '../../store/treeItemSlice';
import { Folder } from '../../objects/Folder';
import { Content } from '../../objects/Content';
import { useState, useEffect } from 'react';
import { isSome } from '../../config/Maybe';

interface DeleteFolderPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseParent: () => void;
}

export const DeleteFolderPopup = ({
  isOpen,
  onClose,
  onCloseParent,
}: DeleteFolderPopupProps): JSX.Element => {
  const folderToEdit = useAppSelector(selectFolderToEdit);
  const [hasChildren, setHasChildren] = useState<boolean>(false);
  const folders: Folder[] = useAppSelector(selectFoldersInTree);
  const contents: Content[] = useAppSelector(selectContentsInTree);

  useEffect(() => {
    if (isSome(folderToEdit)) {
      if (folders.some((folder) => folder.parentFolderId === folderToEdit.id)) {
        setHasChildren(true);
        return;
      }
      if (
        contents.some((content) => content.parentFolderId === folderToEdit.id)
      ) {
        setHasChildren(true);
      }
    }
  }, [folders, contents, folderToEdit]);

  const [deleteFoldersApi] = useDeleteFolderByIdMutation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='md'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>
          <Heading as='h1' textAlign='center' size='lg'>
            Deleting this folder
          </Heading>
        </ModalHeader>
        {hasChildren ? (
          <>
            <ModalBody>
              <Text>
                Folders can not be deleted if they have content or other folders
                nested within them.
              </Text>
            </ModalBody>
            <ModalFooter>
              <HStack justifyContent='space-between' w='100%'>
                <Button w='100%' onClick={onClose}>
                  Go back
                </Button>
              </HStack>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody>
              <Text>
                Are you sure you want to delete this? This action cannot be
                reversed.
              </Text>
            </ModalBody>
            <ModalFooter>
              <HStack justifyContent='space-between' w='100%'>
                <Button w='45%' onClick={onClose}>
                  Go back
                </Button>
                <Button
                  w='45%'
                  variant='delete-confirm'
                  onClick={async () => {
                    await deleteFoldersApi(folderToEdit?.id ?? '');
                    onCloseParent();
                    onClose();
                  }}
                >
                  Confirm
                </Button>
              </HStack>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
