import { SideNavBar } from '../../sharedComponents/sideNavBar/SideNavBar';
import './TagsPage.scss';
import {
  IconButton,
  Stack,
  Flex,
  Spacer,
  HStack,
  useDisclosure,
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';
import { UserIcon } from '../../config/icons';
import { ProfilePopup } from '../../sharedComponents/modal/ProfilePopup';
import { TagsPageHeader } from './TagsPageHeader';
import { UngroupedTagsList } from './UngroupedTagsList';
import { TagGroupList } from './TagGroups/TagGroupList';
import { useAppSelector } from '../../store/hooks';
import {
  selectTagGroupToEdit,
  selectTagToEdit,
} from '../../store/modalFormSlice';
import { isSome } from '../../config/Maybe';
import { EditTagPopup } from '../../sharedComponents/modal/Tags/EditTagPopup';
import { appValues } from '../../config/constants';
import { EditTagGroupPopup } from '../../sharedComponents/modal/TagGroups/EditTagGroupPopup';

export const TagsPage = (): JSX.Element => {
  const tagToEdit = useAppSelector(selectTagToEdit);
  const tagGroupToEdit = useAppSelector(selectTagGroupToEdit);
  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();

  return (
    <Flex h='100vh'>
      <SideNavBar />
      <Flex className='main' ml={appValues.SIDEBAR_WIDTH}>
        <Stack w='100%' h='100%'>
          <HStack w='100%'>
            <TagsPageHeader />
            <Spacer />
            <IconButton
              aria-label='Profile'
              border='none'
              icon={<UserIcon className='icon' w={8} h={8} />}
              onClick={onOpenProfilePopup}
            />
            <ProfilePopup
              isOpen={isOpenProfilePopup}
              onClose={onCloseProfilePopup}
            />
          </HStack>
          <Box>
            <Heading as='h1' fontSize='3xl' color='gray.900' pt={5} pb={5}>
              Tags
            </Heading>
            <Box color='gray.700'>
              <Text>
                Tags help differentiate variations of content, and makes
                creating configurations easy. When configuring,{' '}
                <span style={{ fontWeight: 700 }}>only one</span> of the tags
                within a tag group can be chosen.
              </Text>
            </Box>
          </Box>
          <HStack w='100%' h='100%'>
            <TagGroupList />
            <UngroupedTagsList />
          </HStack>
        </Stack>
        {isSome(tagToEdit) && (
          <EditTagPopup tag={tagToEdit} isOpen={isSome(tagToEdit)} />
        )}
        {isSome(tagGroupToEdit) && (
          <EditTagGroupPopup
            tagGroup={tagGroupToEdit}
            isOpen={isSome(tagGroupToEdit)}
          />
        )}
      </Flex>
    </Flex>
  );
};
