import { useState, useRef, useEffect } from 'react';
import {
  HStack,
  Button,
  Text,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Spacer,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faClone } from '@fortawesome/free-regular-svg-icons';
import { AddVideoIcon, PdfIcon, TagVersionIcon } from '../../../config/icons';
import { appColors } from '../../../config/constants';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import { isNone, isSome } from '../../../config/Maybe';
import Creatable from 'react-select/creatable';
import { OptionsType } from 'react-select';
import { selectTags } from '../../../store/tagSlice';
import { useAppSelector } from '../../../store/hooks';
import { InputTagParams } from '../../../objects/Tag';
import { useCreateTagMutation } from '../../../services/tagEndpoints';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { InputContentVersionParams } from '../../../objects/ContentVersion';
import { PdfInputPopup } from '../PdfInputPopup';
import { VideoInputPopup } from '../VideoInputPopup';
import { selectTagGroups } from '../../../store/tagGroupSlice';

interface ContentVersionFormProps {
  pdfsBeingUploaded: File[];
  videosBeingUploaded: File[];
  version: InputContentVersionParams;
  index: number;
  removeVersion: (index: number) => void;
  duplicateVersion: (version: InputContentVersionParams) => void;
  triggerModifiedFileUploads: (file: File | undefined) => void;
}

export const ContentVersionForm = ({
  pdfsBeingUploaded,
  videosBeingUploaded,
  version,
  index,
  removeVersion,
  duplicateVersion,
  triggerModifiedFileUploads,
}: ContentVersionFormProps): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const [createTag] = useCreateTagMutation();
  const videoInputRef = useRef<HTMLInputElement>(null);
  const pdfInputRef = useRef<HTMLInputElement>(null);
  // the useRef hook does not trigger re-renders, so we set these state
  // variables when the selected file changes
  const [, setVideoFileState] = useState<File | undefined>(undefined);
  const [, setPdfFileState] = useState<File | undefined>(undefined);
  // Same for changing file names
  const [, setPdfFileNameState] = useState<string>('');
  const [, setVideoFileNameState] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<SelectInterface[]>([]);

  const availableTags = useAppSelector(selectTags);
  const availableGroups = useAppSelector(selectTagGroups);
  const sortedGroups = [...availableGroups];
  sortedGroups.sort((a, b) => a.indexWithinParent - b.indexWithinParent);

  let selectorTags: SelectInterface[] = [];
  sortedGroups.forEach((group) => {
    const groupTags = availableTags
      .filter((tag) => tag.tagGroupId === group.id)
      .sort((a, b) => {
        return a.indexWithinParent - b.indexWithinParent;
      });
    groupTags.forEach((tag) => {
      selectorTags = [
        ...selectorTags,
        {
          label: `${tag.name}: ${
            isSome(tag.tagGroup) ? tag.tagGroup.name : 'Ungrouped'
          }`,
          value: tag.id,
        },
      ];
    });
  });
  const ungroupedAvailableTags = availableTags
    .filter((tag) => !isSome(tag.tagGroupId))
    .sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });
  ungroupedAvailableTags.forEach(
    (tag) =>
      (selectorTags = [
        ...selectorTags,
        {
          label: `${tag.name}: ${
            isSome(tag.tagGroup) ? tag.tagGroup.name : 'Ungrouped'
          }`,
          value: tag.id,
        },
      ])
  );

  useEffect(() => {
    const tags: SelectInterface[] = [];
    if (isSome(version.tagIds)) {
      version.tagIds.map((id) => {
        const match = selectorTags.find((selected) => selected.value === id);
        if (isSome(match)) {
          tags.push(match);
        }
        return version;
      });
    }
    setSelectedTags(tags);
  }, [version.tagIds]);

  useEffect(() => {
    setVideoFileNameState(version.videoName ?? '');
    setPdfFileNameState(version.pdfName ?? '');
  }, []);

  const onSelectTag = async (
    opts: OptionsType<SelectInterface>
  ): Promise<void> => {
    const newTags: SelectInterface[] = [];
    opts.forEach((opt) => {
      newTags.push(opt);
    });
    setSelectedTags(newTags);
    version.tagIds = newTags.map((tag) => tag.value);
  };

  const [showTagInput, setshowTagInput] = useState<boolean>(
    selectedTags.length > 0 || version.tagIds.length > 0
  );

  const handleCreateTag = async (newTagName: string): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const newTag: InputTagParams = {
        name: newTagName,
        description: '',
        productGroupId: selectedProductGroup.id,
      };

      const createdTag = await createTag(newTag).unwrap();
      const selectInterface: SelectInterface = {
        label: `${createdTag.name}: ${
          isSome(createdTag.tagGroup) ? createdTag.tagGroup.name : 'Ungrouped'
        }`,
        value: createdTag.id,
      };

      setSelectedTags((current) => [...current, selectInterface]);
      version.tagIds.push(createdTag.id);
    }
  };

  const handleRemoveVersion = (index: number): void => {
    const close = window.confirm(
      'Are you sure you wish remove this variation?'
    );

    if (close) {
      removeVersion(index);
    }
  };

  const setPdfFile = (): void => {
    const file = pdfInputRef?.current?.files?.item(0) ?? undefined;
    version.pdfFile = file;
    setPdfFileState(file);
    triggerModifiedFileUploads(file);
  };

  const setVideoFile = (): void => {
    const file = videoInputRef?.current?.files?.item(0) ?? undefined;
    version.videoFile = file;
    setVideoFileState(file);
    triggerModifiedFileUploads(file);
  };

  const removePdfFile = (): void => {
    if (isSome(pdfInputRef) && isSome(pdfInputRef.current)) {
      pdfInputRef.current.files = null;
      version.pdfFile = undefined;
      setPdfFileState(undefined);
      triggerModifiedFileUploads(undefined);
    }
    setPdfFileNameState('');
    version.pdfName = undefined;
  };

  const removeVideoFile = (): void => {
    if (isSome(videoInputRef) && isSome(videoInputRef.current)) {
      videoInputRef.current.files = null;
      version.videoFile = undefined;
      setVideoFileState(undefined);
      triggerModifiedFileUploads(undefined);
    }
    setVideoFileNameState('');
    version.videoName = undefined;
  };

  const {
    isOpen: isVideoPopupOpen,
    onOpen: onOpenVideoPopup,
    onClose: onCloseVideoPopup,
  } = useDisclosure();

  const addVideo = (): JSX.Element => {
    if (isNone(version.videoName) && isNone(version.videoFile)) {
      return (
        <Button
          className={classNames.contentButton}
          onClick={onOpenVideoPopup}
          justifyContent='left'
          leftIcon={<AddVideoIcon w={5} />}
        >
          Add Video
        </Button>
      );
    } else {
      return (
        <HStack w='100%'>
          <AddVideoIcon className={classNames.icon} ml={10} w={8} h={10} />

          <Flex className={classNames.uploaded} flex={1} alignItems='center'>
            <Text>
              {isSome(version.videoFile)
                ? version.videoFile.name
                : version.videoName}
            </Text>
            <Spacer />
            <IconButton
              aria-label='Delete PDF'
              className='icon-button'
              color={appColors.GRAY500}
              icon={<CloseIcon height={12} />}
              onClick={removeVideoFile}
            />
          </Flex>
        </HStack>
      );
    }
  };

  const {
    isOpen: isPdfPopupOpen,
    onOpen: onOpenPdfPopup,
    onClose: onClosePdfPopup,
  } = useDisclosure();

  const addPdf = (): JSX.Element => {
    if (isNone(version.pdfName) && isNone(version.pdfFile)) {
      return (
        <Button
          className={classNames.contentButton}
          onClick={onOpenPdfPopup}
          justifyContent='left'
          leftIcon={<PdfIcon w={5} />}
        >
          Add PDF
        </Button>
      );
    } else {
      return (
        <HStack display='flex' flex={1}>
          <PdfIcon className={classNames.icon} ml={10} w={8} h={10} />
          <Flex className={classNames.uploaded} flex={2} alignItems='center'>
            <Text>
              {isSome(version.pdfFile) ? version.pdfFile.name : version.pdfName}
            </Text>
            <Spacer />
            <IconButton
              aria-label='Delete PDF'
              className='icon-button'
              color={appColors.GRAY500}
              icon={<CloseIcon height={12} />}
              onClick={removePdfFile}
            />
          </Flex>
          <HStack>
            <Text>Start Page:</Text>
            <NumberInput
              min={1}
              max={999}
              defaultValue={version.pdfStartPage}
              size='sm'
              maxH={40}
              maxW={16 * 4}
              onChange={(number: string) => {
                version.pdfStartPage = parseInt(number);
              }}
            >
              <NumberInputField maxH={40} />
              <NumberInputStepper color={appColors.GRAY500}>
                <NumberIncrementStepper>
                  <ChevronUpIcon />
                </NumberIncrementStepper>
                <NumberDecrementStepper>
                  <ChevronDownIcon />
                </NumberDecrementStepper>
              </NumberInputStepper>
            </NumberInput>
          </HStack>
        </HStack>
      );
    }
  };

  const customStyles = {
    multiValue: (provided: any) => {
      return {
        ...provided,
        backgroundColor: 'transparent',
        borderWidth: '1px',
        borderColor: appColors.GRAY500,
        borderRadius: '999px',
      };
    },
    multiValueLabel: (provided: any) => {
      return {
        ...provided,
        color: appColors.GREEN_DARKER,
        fontWeight: 600,
      };
    },
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: appColors.GRAY500,
      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    input: (provided: any) => {
      return {
        ...provided,
        '[type="text"]': {
          boxShadow: '0 0 0 0 transparent !important',
        },
      };
    },
    container: (provided: any) => {
      return {
        ...provided,
        width: '100%',
      };
    },
  };

  const tagVersion = (): JSX.Element => {
    if (!showTagInput) {
      return (
        <Button
          className={classNames.contentButton}
          justifyContent='left'
          leftIcon={<TagVersionIcon />}
          onClick={() => setshowTagInput(true)}
        >
          Tag this variation
        </Button>
      );
    } else {
      return (
        <HStack>
          <TagVersionIcon w={10} pl={3} />
          <Creatable
            isMulti
            options={selectorTags}
            onChange={onSelectTag}
            styles={customStyles}
            onCreateOption={handleCreateTag}
            value={selectedTags}
          />
        </HStack>
      );
    }
  };

  return (
    <Stack className='left-border' w='100%' pl={1}>
      {addVideo()}

      {addPdf()}

      {tagVersion()}

      <HStack>
        <Button
          className={classNames.contentButton}
          justifyContent='left'
          w='50%'
          color={appColors.RED}
          onClick={() => handleRemoveVersion(index)}
          leftIcon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faTrashAlt}
            />
          }
        >
          Remove variation
        </Button>
        <Button
          className={classNames.contentButton}
          justifyContent='left'
          w='50%'
          leftIcon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faClone}
            />
          }
          onClick={() => duplicateVersion(version)}
        >
          Duplicate variation
        </Button>
      </HStack>

      <PdfInputPopup
        pdfInputRef={pdfInputRef}
        setPdfFileName={(name: string) => {
          if (isSome(name) && name.trim() !== '') {
            version.pdfName = name;
          } else {
            version.pdfName = undefined;
          }
          setPdfFileNameState(name);
        }}
        isOpen={isPdfPopupOpen}
        onClose={onClosePdfPopup}
        pdfsSetToUpload={pdfsBeingUploaded}
        removePdfFile={removePdfFile}
      />

      <VideoInputPopup
        videoInputRef={videoInputRef}
        setVideoFileName={(name: string) => {
          if (isSome(name) && name.trim() !== '') {
            version.videoName = name;
          } else {
            version.videoName = undefined;
          }
          setVideoFileNameState(name);
        }}
        isOpen={isVideoPopupOpen}
        onClose={onCloseVideoPopup}
        videosSetToUpload={videosBeingUploaded}
        removeVideoFile={removeVideoFile}
      />

      <input
        onClick={(e) => (e.currentTarget.value = '')}
        type='file'
        style={{ display: 'none' }}
        accept='.pdf'
        ref={pdfInputRef}
        onChange={setPdfFile}
      />
      <input
        onClick={(e) => (e.currentTarget.value = '')}
        onChange={setVideoFile}
        ref={videoInputRef}
        type='file'
        style={{ display: 'none' }}
        accept='video/*'
      />
    </Stack>
  );
};

interface SelectInterface {
  value: string;
  label: string;
}

const classNames = {
  contentButton: 'content-button',
  uploaded: 'uploaded',
  icon: 'icon',
  faIcon: 'faIcon',
};
