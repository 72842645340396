import {
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useDisclosure,
  IconButton,
  Text,
  Box,
} from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { WarningModal } from '../WarningModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { Tag } from '../../../objects/Tag';
import { isSome } from '../../../config/Maybe';
import {
  QTemplate,
  QTemplateContent,
  QTemplateFolder,
  QTemplateOptions,
  QTemplateTag,
} from '../../../objects/QuestionnaireTemplate';
import { useSetQTemplateSelectionsMutation } from '../../../services/qTemplateEndpoints';
import { setQTemplateToEdit } from '../../../store/modalFormSlice';
import { QTemplateAvailableTagList } from './QTemplateAvailableTagList';
import { QTemplateFoldersAndContentsList } from './QTemplateFoldersAndContentsList';
import { selectTags } from '../../../store/tagSlice';
import {
  selectContentsWithTags,
  selectFoldersWithTags,
} from '../../../store/foldersAndContentsWithTagsSlice';
import { resetQCSlice } from '../../../store/questionnaireConfigurationSlice';

interface QTemplateSettingsModalProps {
  qTemplate: QTemplate;
}

export const QTemplateSettingsModal = ({
  qTemplate,
}: QTemplateSettingsModalProps): JSX.Element => {
  const allTags = useAppSelector(selectTags);
  const foldersWithTags = useAppSelector(selectFoldersWithTags);
  const contentsWithTags = useAppSelector(selectContentsWithTags);

  const dispatch = useAppDispatch();
  const [updateQTemplateSelection] = useSetQTemplateSelectionsMutation();

  const [selectionFolders, setSelectionFolders] = useState<QTemplateFolder[]>(
    qTemplate.qTemplateFolders.map((tf) => {
      return { selection: tf.selection, folderId: tf.folderId };
    })
  );
  const [selectionContents, setSelectionContents] = useState<
    QTemplateContent[]
  >(
    qTemplate.qTemplateContents.map((tc) => {
      return { selection: tc.selection, contentId: tc.contentId };
    })
  );
  const [selectionTags, setSelectionTags] = useState<QTemplateTag[]>(
    qTemplate.qTemplateTags.map((st) => {
      return { selection: st.selection, tagId: st.tagId };
    })
  );
  const [tagsToShow, setTagsToShow] = useState<Tag[]>([]);

  const onConfirm = async (): Promise<void> => {
    await updateQTemplateSelection({
      id: qTemplate.id,
      folders: selectionFolders.map((sf) => {
        return { selection: sf.selection, folderId: sf.folderId };
      }),
      tags: selectionTags.map((st) => {
        if (!tagsToShow.some((tag) => tag.id === st.tagId)) {
          return { selection: QTemplateOptions.Unavailable, tagId: st.tagId };
        }
        return { selection: st.selection, tagId: st.tagId };
      }),
      contents: selectionContents.map((c) => {
        return { selection: c.selection, contentId: c.contentId };
      }),
    });
    dispatch(setQTemplateToEdit(undefined));
    dispatch(resetQCSlice());
    alert(
      'If this template was used in any questionnaire, you will need to review the questionnaire, and save the changes before it will be available to users.'
    );
  };

  useEffect(() => {
    const newTagsToShow: Tag[] = [];
    foldersWithTags.forEach((folder) => {
      if (
        selectionFolders
          .filter((sf) => sf.selection !== QTemplateOptions.Unavailable)
          .map((sf) => sf.folderId)
          .includes(folder.id)
      ) {
        newTagsToShow.push(...folder.availableTags);
      }
    });
    contentsWithTags.forEach((content) => {
      if (
        selectionContents
          .filter((sc) => sc.selection !== QTemplateOptions.Unavailable)
          .map((sc) => sc.contentId)
          .includes(content.id)
      ) {
        newTagsToShow.push(...content.availableTags);
      }
    });
    // sort and remove duplicates
    const ids = newTagsToShow
      .sort((a: Tag, b: Tag) => {
        return a.indexWithinParent - b.indexWithinParent;
      })
      .map((tag) => tag.id);
    const uniqueTags = newTagsToShow.filter(
      (tag, index) => !ids.includes(tag.id, index + 1)
    );
    setTagsToShow(uniqueTags);
  }, [selectionFolders, selectionContents, foldersWithTags, contentsWithTags]);

  useEffect(() => {
    foldersWithTags.map((folder) => {
      // if a new folder has been added since the last time this was modified
      // add the folder and default to available
      if (
        !selectionFolders.some(
          (templateFolder) => templateFolder.folderId === folder.id
        )
      ) {
        setSelectionFolders((current) => [
          ...current,
          {
            ...folder,
            selection: QTemplateOptions.Unavailable,
            folderId: folder.id,
          },
        ]);
      }
      // same for tags
      folder.availableTags.map((tag) => {
        if (
          !selectionTags.some((templateTag) => templateTag.tagId === tag.id)
        ) {
          setSelectionTags((current) => [
            ...current,
            {
              selection: QTemplateOptions.Unavailable,
              tagId: tag.id,
            },
          ]);
        }
        return tag;
      });
      return folder;
    });
    contentsWithTags.map((content) => {
      // if a new root level content has been added since the last time this was modified
      // add the content and default to available
      if (
        !selectionContents.some(
          (templateContent) => templateContent.contentId === content.id
        )
      ) {
        setSelectionContents((current) => [
          ...current,
          {
            ...content,
            selection: QTemplateOptions.Unavailable,
            contentId: content.id,
          },
        ]);
      }
      // same for tags in that content
      content.availableTags.map((tag) => {
        if (
          !selectionTags.some((templateTag) => templateTag.tagId === tag.id)
        ) {
          setSelectionTags((current) => [
            ...current,
            {
              selection: QTemplateOptions.Unavailable,
              tagId: tag.id,
            },
          ]);
        }
        return tag;
      });
      return content;
    });
  }, [foldersWithTags, contentsWithTags]);

  const setFolderSelection = (
    folderId: string,
    option: QTemplateOptions
  ): void => {
    setSelectionFolders((currentFolders) => [
      ...currentFolders.map((folder) =>
        folder.folderId === folderId ? { ...folder, selection: option } : folder
      ),
    ]);
  };

  const setContentSelection = (
    contentId: string,
    option: QTemplateOptions
  ): void => {
    setSelectionContents((currentContents) => [
      ...currentContents.map((content) =>
        content.contentId === contentId
          ? { ...content, selection: option }
          : content
      ),
    ]);
  };

  const setTagSelection = (tagId: string, option: QTemplateOptions): void => {
    if (option === QTemplateOptions.Mandatory) {
      let siblingTags: Tag[] = [];
      const updatedTag = allTags.find((tag) => tagId === tag.id);
      if (isSome(updatedTag) && isSome(updatedTag.tagGroupId)) {
        siblingTags = allTags.filter(
          (tag) => tag.id !== tagId && tag.tagGroupId === updatedTag.tagGroupId
        );
      }
      setSelectionTags((currentTags) => [
        ...currentTags.map((tag) => {
          if (tag.tagId === tagId) {
            return { ...tag, selection: option };
          } else if (siblingTags.some((sibTag) => sibTag.id === tag.tagId)) {
            return { ...tag, selection: QTemplateOptions.Unavailable };
          } else {
            return tag;
          }
        }),
      ]);
    } else if (option === QTemplateOptions.Optional) {
      let siblingTags: Tag[] = [];
      const updatedTag = allTags.find((tag) => tagId === tag.id);
      if (isSome(updatedTag) && isSome(updatedTag.tagGroupId)) {
        siblingTags = allTags.filter(
          (tag) => tag.id !== tagId && tag.tagGroupId === updatedTag.tagGroupId
        );
      }
      setSelectionTags((currentTags) => [
        ...currentTags.map((tag) => {
          if (tag.tagId === tagId) {
            return { ...tag, selection: option };
          } else if (
            siblingTags.some((sibTag) => sibTag.id === tag.tagId) &&
            tag.selection === QTemplateOptions.Mandatory
          ) {
            return { ...tag, selection: QTemplateOptions.Optional };
          } else {
            return tag;
          }
        }),
      ]);
    } else {
      setSelectionTags((currentTags) => [
        ...currentTags.map((tag) =>
          tag.tagId === tagId ? { ...tag, selection: option } : tag
        ),
      ]);
    }
  };

  const {
    isOpen: isOpenCloseConfirmPopup,
    onOpen: onOpenCloseConfirmPopup,
    onClose: onCloseCloseConfirmPopup,
  } = useDisclosure();

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        return dispatch(setQTemplateToEdit(undefined));
      }}
      closeOnOverlayClick={false}
      isCentered
    >
      {isOpenCloseConfirmPopup && (
        <WarningModal
          isOpen={isOpenCloseConfirmPopup}
          onClose={onCloseCloseConfirmPopup}
          title={'Close this form?'}
          message={'Any unsaved changes you have made will be lost.'}
          onCancel={onCloseCloseConfirmPopup}
          onConfirm={() => dispatch(setQTemplateToEdit(undefined))}
        />
      )}
      <ModalOverlay />
      <ModalContent maxWidth='unset' w='90%' h='90%'>
        <ModalHeader
          flexWrap='nowrap'
          pt={15}
          justifyContent='space-between'
          flexDirection='row'
          display='flex'
        >
          <HStack>
            <Button
              borderColor='gray.400'
              color='gray.900'
              fontWeight={400}
              bg='white'
              rightIcon={<ChevronRightIcon color='gray.500' w={7} h={7} />}
              fontSize={16}
              justifyContent='space-between'
              onClick={onOpenCloseConfirmPopup}
            >
              {qTemplate.name}
            </Button>
            <Button
              backgroundColor={appColors.GREEN_DARKER}
              color={'white'}
              onClick={onConfirm}
            >
              Submit Template
            </Button>
          </HStack>
          <IconButton
            aria-label='Delete PDF'
            className='icon-button'
            color={'gray.500'}
            icon={<CloseIcon height={12} />}
            onClick={onOpenCloseConfirmPopup}
          />
        </ModalHeader>
        <ModalBody h='90%' overflow={'hidden'}>
          <Text mb='10px'>
            Specify which root-level folders and tags should be mandatory,
            optional, or unavailable in the Questionnaire when this template is
            chosen
          </Text>
          <Text mb='10px'>
            Anything marked as unavailable, or mandatory will not be asked in
            the Questionnaire. Marking a tag within a group as mandatory makes
            all other tags in the group Unavailable.
          </Text>
          <HStack h='85%' w='100%' alignItems='flex-start' spacing='20px'>
            <Box
              w='60%'
              h='100%'
              overflowY={'auto'}
              overscrollBehavior='contain'
            >
              <QTemplateFoldersAndContentsList
                foldersWithTags={foldersWithTags}
                selectionFolders={selectionFolders}
                setFolderSelection={setFolderSelection}
                contentsWithTags={contentsWithTags}
                selectionContents={selectionContents}
                setContentSelection={setContentSelection}
              />
            </Box>
            <Box
              w='40%'
              h='100%'
              overflowY={'auto'}
              overscrollBehavior='contain'
            >
              <QTemplateAvailableTagList
                tags={tagsToShow}
                selectionTags={selectionTags}
                setTagSelection={setTagSelection}
              />
            </Box>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
