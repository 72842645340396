import { Text, Button, Box, HStack, useDisclosure } from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { System } from '../../../objects/System';
import { Template } from '../../../objects/Template';
import { useApplyTemplateToSystemMutation } from '../../../services/systemEndpoints';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import '../Popup.scss';
import { PositiveConfirmModal } from '../PositiveConfirmModal';
import { setSystemToConfigure } from '../../../store/systemSlice';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';

interface TemplateSystemChoiceProps {
  system: System;
  template: Template;
}

export const TemplateSystemChoice = ({
  system,
  template,
}: TemplateSystemChoiceProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const pGroup = useAppSelector(selectCurrentProductGroup);
  const {
    isOpen: isOpenConfirmApplyTemplatePopup,
    onOpen: onOpenConfirmApplyTemplatePopup,
    onClose: onCloseConfirmApplyTemplatePopup,
  } = useDisclosure();

  const [applyTemplateToSystem] = useApplyTemplateToSystemMutation();

  const onConfirm = async (): Promise<void> => {
    const updatedSystem = await applyTemplateToSystem({
      systemId: system.id,
      templateId: template.id,
    }).unwrap();
    dispatch(setSystemToConfigure(updatedSystem));
    onCloseConfirmApplyTemplatePopup();
  };

  return (
    <Box
      w='100%'
      h='40px'
      mt='0px'
      borderBottom={'1px'}
      borderColor={appColors.BORDERGRAY}
    >
      <HStack h='100%' w='100%' backgroundColor={'white'}>
        <Button
          border='none'
          justifyContent={'space-between'}
          className={(classNames.navItem, classNames.navItemActive)}
          h='100%'
          w='100%'
          onClick={onOpenConfirmApplyTemplatePopup}
        >
          <Text color={appColors.GRAY500}>{template.name}</Text>
        </Button>
      </HStack>
      {isOpenConfirmApplyTemplatePopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmApplyTemplatePopup}
          onClose={onCloseConfirmApplyTemplatePopup}
          title={`Apply ${template.name} to your ${
            pGroup?.configurationName ?? 'configuration'
          }?`}
          onCancel={onCloseConfirmApplyTemplatePopup}
          onConfirm={onConfirm}
        />
      )}
    </Box>
  );
};

const classNames = {
  navItem: 'sideBar-item',
  navItemActive: 'sideBar-item-active',
  icon: 'icon',
  faIcon: 'faIcon',
};
