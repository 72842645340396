/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { isSome } from '../config/Maybe';
import {
  Content,
  DeleteContentResult,
  InputContentParams,
  UpdateContentParams,
} from '../objects/Content';
import { ContentVersion } from '../objects/ContentVersion';
import { ItemType } from '../objects/FolderOrContentItem';
import { setRawContents, setContents } from '../store/contentSlice';
import { removeContentFromTemplate } from '../store/templateSlice';
import {
  addFolderOrContentToTree,
  deleteFolderContentOrVersion,
  updateFolderContentOrVersion,
  updateVersions,
} from '../store/treeItemSlice';
import {
  ContentsWithTagsList,
  FoldersWithTagsList,
  IOnQueryAPI,
  vidualsApi,
} from './vidualsApi';

const updateContentState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setRawContents(data));
    dispatch(setContents(data));
  } catch (err) {
    console.trace(err);
  }
};

const addCreatedContentToTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(
      addFolderOrContentToTree([{ item: data, type: ItemType.Content }])
    );
  } catch (err) {
    console.trace(err);
  }
};

const updateContentOnTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(
      updateFolderContentOrVersion({ item: data, type: ItemType.Content })
    );
  } catch (err) {
    console.trace(err);
  }
};

const deleteContentFromTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(deleteFolderContentOrVersion(data.contentsIds));
    dispatch(removeContentFromTemplate(data.contentsIds));
  } catch (err) {
    console.trace(err);
  }
};

const updateVersionOrder = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateVersions(data));
  } catch (err) {
    console.trace(err);
  }
};

export const contentsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getContentsByProductGroupId: builder.query<Content[], string>({
      query: (pgid) => `contents/productGroup/${pgid}`,
      onQueryStarted: updateContentState,
    }),
    createContent: builder.mutation<Content, InputContentParams>({
      query: (contentInput) => {
        return {
          url: `contents`,
          method: 'POST',
          body: contentInput,
        };
      },
      invalidatesTags: [FoldersWithTagsList, ContentsWithTagsList],
      onQueryStarted: addCreatedContentToTree,
    }),
    updateContent: builder.mutation<
      Content,
      { id: string; content: UpdateContentParams }
    >({
      query: ({ id, content }) => {
        return {
          url: `contents/${id}`,
          method: 'PUT',
          body: content,
        };
      },
      invalidatesTags: [FoldersWithTagsList, ContentsWithTagsList],
      onQueryStarted: updateContentOnTree,
    }),
    deleteContentsByIds: builder.mutation<DeleteContentResult, string[]>({
      query: (contentIds) => {
        return {
          url: `contents`,
          method: 'DELETE',
          body: contentIds,
        };
      },
      invalidatesTags: (result) => {
        if (isSome(result)) {
          return [FoldersWithTagsList, ContentsWithTagsList];
        }
        return [];
      },
      onQueryStarted: deleteContentFromTree,
    }),
    updateVersionOrder: builder.mutation<
      ContentVersion[],
      {
        id: string;
        sortedChildIds: string[];
      }
    >({
      query: ({ id, sortedChildIds }) => {
        return {
          url: `contents/${id}/setVersionOrder`,
          method: 'PUT',
          body: sortedChildIds,
        };
      },
      onQueryStarted: updateVersionOrder,
    }),
  }),
});

export const {
  useLazyGetContentsByProductGroupIdQuery,
  useCreateContentMutation,
  useUpdateContentMutation,
  useDeleteContentsByIdsMutation,
  useUpdateVersionOrderMutation,
} = contentsApi;
