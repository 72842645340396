import './SystemsPage.scss';
import { Spinner, Text } from '@chakra-ui/react';
import { useGetSystemByIdQuery } from '../../services/systemEndpoints';
import { SystemConfigurationPage } from './SystemConfigurationPage';

interface SystemPageFetchWrapperProps {
  systemId: string;
}

export const SystemPageFetchWrapper = ({
  systemId,
}: SystemPageFetchWrapperProps): JSX.Element => {
  const {
    data: foundSystem,
    isLoading,
    isError,
  } = useGetSystemByIdQuery({
    systemId: systemId,
  });

  if (isError) {
    return (
      <>
        <Text>Failed to get system</Text>
      </>
    );
  }

  return (
    <>
      {foundSystem && !isLoading ? (
        <SystemConfigurationPage system={foundSystem} />
      ) : (
        <Spinner justifySelf='center' />
      )}
    </>
  );
};
