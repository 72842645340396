import { SideNavBar } from '../../sharedComponents/sideNavBar/SideNavBar';
import './SystemsPage.scss';
import {
  IconButton,
  Stack,
  Flex,
  Spacer,
  HStack,
  useDisclosure,
  Box,
  Heading,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { UserIcon } from '../../config/icons';
import { ProfilePopup } from '../../sharedComponents/modal/ProfilePopup';
import { SystemsPageHeader } from './SystemsPageHeader';
import { SystemsTable } from '../../sharedComponents/systems/SystemsTable';
import {
  resetSystemSlice,
  selectSystemToConfigure,
} from '../../store/systemSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isSome } from '../../config/Maybe';
import { appValues } from '../../config/constants';
import { useEffect } from 'react';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { SystemPageFetchWrapper } from './SystemPageFetchWrapper';

export const SystemsPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const systemToConfigure = useAppSelector(selectSystemToConfigure);
  const productGroup = useAppSelector(selectCurrentProductGroup);

  useEffect(() => {
    return () => {
      dispatch(resetSystemSlice());
    };
  }, []);

  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();

  return (
    <Flex h='100vh'>
      <SideNavBar />
      <Flex className='main' ml={appValues.SIDEBAR_WIDTH}>
        {isSome(systemToConfigure) ? (
          <SystemPageFetchWrapper systemId={systemToConfigure.id} />
        ) : (
          <Stack w='100%' h='100%'>
            <HStack w='100%'>
              <SystemsPageHeader />
              <Spacer />
              <IconButton
                aria-label='Profile'
                border='none'
                icon={<UserIcon className='icon' w={8} h={8} />}
                onClick={onOpenProfilePopup}
              />
              <ProfilePopup
                isOpen={isOpenProfilePopup}
                onClose={onCloseProfilePopup}
              />
            </HStack>
            <Box>
              <Heading as='h1' fontSize='3xl' color='gray.900' pt={5} pb={5}>
                <Tooltip label='configuration'>
                  <Text as='span'>
                    {productGroup?.configurationName ?? 'configuration'}s
                  </Text>
                </Tooltip>
              </Heading>
              <Box color='gray.700'>
                <Text>
                  Assemble a{' '}
                  <Tooltip label='configuration'>
                    <Text as='span'>
                      {productGroup?.configurationName ?? 'configuration'} with
                    </Text>
                  </Tooltip>
                  folders and videos based off a template.
                </Text>
              </Box>
            </Box>
            <SystemsTable />
          </Stack>
        )}
      </Flex>
    </Flex>
  );
};
