import '../folders/FileTree.scss';
import { HStack, Text, Link } from '@chakra-ui/react';
import { useAppSelector } from '../../store/hooks';
import { ContentVersion } from '../../objects/ContentVersion';
import { isSome } from '../../config/Maybe';
import { getS3BucketName } from '../../config/S3Client';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { selectAllTagsInOrder } from '../../store/tagSlice';
import { useEffect, useState } from 'react';
import { Tag } from '../../objects/Tag';

interface ContentVersionItemProps {
  contentVersion: ContentVersion;
  usedTagIds?: string[];
}

export const ContentVersionItem = ({
  contentVersion,
  usedTagIds,
}: ContentVersionItemProps): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const allSortedTags = useAppSelector(selectAllTagsInOrder);
  const [tagsToShow, setTagsToShow] = useState<Tag[]>([]);

  useEffect(() => {
    setTagsToShow(
      allSortedTags.filter((at) => {
        if (isSome(contentVersion.tags)) {
          return contentVersion.tags.some((tag) => tag.id === at.id);
        }
        return contentVersion.tagIds?.includes(at.id);
      })
    );
  }, [
    allSortedTags,
    contentVersion.content,
    contentVersion.tagIds,
    contentVersion.tags,
  ]);

  const buildFileGetUrl = (fileName: string): string => {
    const bucketName = getS3BucketName();
    const productGroupPrefix: string = selectedProductGroup?.id ?? '';
    const encodedFileName = encodeURIComponent(fileName);

    return `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/${encodedFileName}/${encodedFileName}`;
  };

  return (
    <HStack justifyContent='space-between' w='100%' h='100%'>
      <HStack w='100%'>
        <Text color='gray.400' fontWeight='500'>
          Variation:
        </Text>
        {isSome(contentVersion.videoName) && (
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={buildFileGetUrl(contentVersion.videoName)}
          >
            <Text color='blue.600' fontWeight={500}>
              {contentVersion.videoName}
            </Text>
          </Link>
        )}
        {isSome(contentVersion.pdfName) && (
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={buildFileGetUrl(contentVersion.pdfName)}
          >
            <Text color='blue.600' fontWeight={500}>
              {contentVersion.pdfName}
            </Text>
          </Link>
        )}
      </HStack>

      <HStack>
        {tagsToShow.map((tag) => {
          let variant = 'content-version-tag';
          if (isSome(usedTagIds) && usedTagIds.includes(tag.id)) {
            variant = 'active-content-version-tag';
          }
          return (
            <Text key={tag.id} variant={variant}>
              {tag.name}
            </Text>
          );
        })}
      </HStack>
    </HStack>
  );
};
