import {
  Button,
  Text,
  Stack,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import { isSome } from '../../../config/Maybe';
import { Customizations } from '../../../objects/Customizations';
import { System } from '../../../objects/System';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useEndUserContextContext } from '../../../contexts/EndUserContext';

interface InfoPopupProps {
  system: System | undefined;
  customizations: Customizations | null | undefined;

  isOpen: boolean;
  onClose: () => void;
}

export const InfoPopup = ({
  system,
  customizations,
  isOpen,
  onClose,
}: InfoPopupProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        w={315}
        bg={
          isSome(customizations)
            ? '#' + String(customizations.primaryColor)
            : 'black'
        }
        color={
          isSome(customizations)
            ? '#' + String(customizations.primaryTextColor)
            : 'white'
        }
      >
        <ModalHeader pb={2}>
          <Tooltip label='configuration'>
            <Text as='span'>
              {productGroup?.configurationName ?? 'Configuration'} Info
            </Text>
          </Tooltip>
        </ModalHeader>
        <ModalCloseButton border='none' />
        <ModalBody>
          <Stack spacing={3}>
            <Text>
              {productGroup?.configurationIdLabel ?? 'ID'}:{' '}
              {system?.serialNumber}
            </Text>
            <Text>{system?.clientName}</Text>
            {system?.warrantyStart !== '' || system?.warrantyEnd !== '' ? (
              <Text>
                Warranty: {system?.warrantyStart} - {system?.warrantyEnd}
              </Text>
            ) : (
              <></>
            )}
            <Button
              bg={
                isSome(customizations)
                  ? '#' + String(customizations.buttonColor)
                  : 'black'
              }
              borderColor={
                isSome(customizations)
                  ? '#' + String(customizations.buttonColor)
                  : 'black'
              }
              color={
                isSome(customizations)
                  ? '#' + String(customizations.primaryTextColor)
                  : 'white'
              }
              _hover={{ background: '#80808080' }}
              w='100%'
              rightIcon={
                <FontAwesomeIcon
                  className={(classNames.icon, classNames.faIcon)}
                  icon={faExternalLinkAlt}
                />
              }
            >
              <Link>Learn more about {system?.clientName} </Link>
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
