/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isSome } from '../config/Maybe';
import {
  QTemplate,
  QTemplateOptions,
  QTemplateTag,
} from '../objects/QuestionnaireTemplate';
import { RootState } from './store';

export const qTemplateSlice = createSlice({
  name: 'qTemplate',
  initialState: {
    qTemplates: [] as QTemplate[],
  },
  reducers: {
    setQTemplates: (
      state,
      { payload: qTemplates }: PayloadAction<QTemplate[]>
    ) => {
      state.qTemplates = qTemplates;
    },
    updateQTemplate: (
      state,
      { payload: updatedQTemplate }: PayloadAction<QTemplate>
    ) => {
      state.qTemplates = state.qTemplates.map((template) =>
        template.id === updatedQTemplate.id ? updatedQTemplate : template
      );
    },
    addQTemplate: (state, { payload: qTemplate }: PayloadAction<QTemplate>) => {
      state.qTemplates.push(qTemplate);
    },
    deleteQTemplate: (state, { payload: deletedId }: PayloadAction<string>) => {
      return {
        ...state,
        qTemplates: state.qTemplates.filter(
          (qTemplate) => qTemplate.id !== deletedId
        ),
      };
    },
  },
});

export const selectQTemplates = (state: RootState): QTemplate[] =>
  state.qTemplate.qTemplates;

export const selectQTemplateById = (
  state: RootState,
  qTemplateId: string
): QTemplate | undefined => {
  return state.qTemplate.qTemplates.find(
    (qTemplate) => qTemplate.id === qTemplateId
  );
};

export const selectOptionalOrMandatoryTagsForQTemplateById = (
  state: RootState,
  qTemplateId: string
): QTemplateTag[] => {
  const qTemplate = state.qTemplate.qTemplates.find(
    (qTemplate) => qTemplate.id === qTemplateId
  );
  if (isSome(qTemplate)) {
    return qTemplate.qTemplateTags.filter(
      (qt) =>
        qt.selection === QTemplateOptions.Optional ||
        qt.selection === QTemplateOptions.Mandatory
    );
  }
  return [];
};

export const { setQTemplates, addQTemplate, updateQTemplate, deleteQTemplate } =
  qTemplateSlice.actions;

export default qTemplateSlice.reducer;
