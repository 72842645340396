import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Stack,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormControl,
  InputGroup,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { useAppDispatch } from '../../../store/hooks';
import { isSome } from '../../../config/Maybe';
import { setProductGroupToEdit } from '../../../store/modalFormSlice';
import { ProdGroup, UpdateProdGroupParams } from '../../../objects/ProdGroup';
import { useUpdateProdGroupMutation } from '../../../services/prodGroupsEndpoints';

interface EditProdGroupPopupProps {
  productGroup: ProdGroup;
}

export const EditProdGroupPopup = ({
  productGroup,
}: EditProdGroupPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    formState,
    reset,
  } = useForm<UpdateProdGroupParams>();

  const [updateProductGroup] = useUpdateProdGroupMutation();

  const onSubmit = async (
    updatedParams: UpdateProdGroupParams
  ): Promise<void> => {
    await updateProductGroup({
      id: productGroup.id,
      ...updatedParams,
    }).unwrap();

    reset();
    dispatch(setProductGroupToEdit(undefined));
  };

  watch();

  return (
    <Modal
      isOpen={true}
      onClose={() => dispatch(setProductGroupToEdit(undefined))}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w='458px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <ModalHeader pt={15}>
              <Heading as='h1' size='lg' textAlign='center'>
                Edit product group
              </Heading>
            </ModalHeader>
            <Stack>
              <Heading as='h2' size='sm'>
                Name
              </Heading>
              <Input
                defaultValue={productGroup.name}
                backgroundColor='#ffffff'
                w='100%'
                type={'text'}
                placeholder='Product Group Name'
                {...register('name', {
                  required: {
                    value: true,
                    message: 'You must enter a name',
                  },
                })}
              />
              {isSome(errors.name) && errors.name.type === 'required' && (
                <Text variant={'error'}>
                  You must enter a name. Please try again.
                </Text>
              )}
              <FormControl
                isRequired
                isInvalid={!!formState.errors?.configurationName}
              >
                <FormLabel requiredIndicator={<></>}>
                  Configuration Name
                </FormLabel>
                <InputGroup>
                  <Input
                    placeholder='Configuration Name'
                    defaultValue={productGroup.configurationName}
                    {...register('configurationName', {
                      required: true,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <Text variant={'error'}>
                    {formState.errors.configurationName?.message}
                  </Text>
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!formState.errors?.configurationIdLabel}
              >
                <FormLabel requiredIndicator={<></>}>
                  Configuration ID Label
                </FormLabel>
                <InputGroup>
                  <Input
                    placeholder='Configuration ID Label'
                    defaultValue={productGroup.configurationIdLabel}
                    {...register('configurationIdLabel', {
                      required: true,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <Text variant={'error'}>
                    {formState.errors.configurationIdLabel?.message}
                  </Text>
                </FormErrorMessage>
              </FormControl>
              <Heading as='h2' size='sm'>
                Description
              </Heading>
              <Input
                defaultValue={productGroup.description}
                backgroundColor='#ffffff'
                w='100%'
                type={'text'}
                placeholder='Product Group Description'
                {...register('description')}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack className='space-between width100'>
              <Button
                w='45%'
                onClick={() => dispatch(setProductGroupToEdit(undefined))}
              >
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='45%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
