import '../TagsPage.scss';
import { Box, HStack } from '@chakra-ui/react';
import { Tag } from '../../../objects/Tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { appColors } from '../../../config/constants';

interface TagItemProps {
  tag: Tag;
  onClick: () => void;
}

export const TagItem = ({ tag, onClick }: TagItemProps): JSX.Element => {
  return (
    <Box className={classNames.row}>
      <HStack>
        <FontAwesomeIcon
          className={(classNames.icon, classNames.faIcon)}
          icon={faImage}
          color={appColors.GREEN_ORIGINAL}
        />
        <span onClick={onClick} className={classNames.folder}>
          {tag.name}
        </span>
      </HStack>
    </Box>
  );
};

const classNames = {
  row: 'tag-item-row',
  folder: 'folder',
  icon: 'icon',
  faIcon: 'faIcon',
};
