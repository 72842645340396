import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Stack,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { InputFolderParams } from '../../../objects/Folder';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectFolderToEdit,
  setFolderToEdit,
} from '../../../store/modalFormSlice';
import { TextInput } from '../../../components/TextInput';
import { isSome } from '../../../config/Maybe';
import { DeleteFolderPopup } from '../DeleteFolderPopup';
import { useUpdateFolderMutation } from '../../../services/folderEndpoints';

export const EditFolderPopup = (): JSX.Element => {
  const [updateFolder] = useUpdateFolderMutation();
  const dispatch = useAppDispatch();

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InputFolderParams>();

  const folderToEdit = useAppSelector(selectFolderToEdit);

  const onSubmit = async (folderToCreate: InputFolderParams): Promise<void> => {
    if (isSome(folderToEdit)) {
      folderToCreate.parentFolderId = folderToEdit.parentFolderId;
      folderToCreate.indexWithinParent = folderToEdit.indexWithinParent;

      await updateFolder({
        id: folderToEdit.id,
        folder: folderToCreate,
      }).unwrap();
    }
    reset();
    dispatch(setFolderToEdit(undefined));
  };

  const registerTextInput = register('name', {
    required: true,
    value: folderToEdit?.name ?? '',
  });
  const text = watch('name');

  const {
    isOpen: isOpenDeleteFolderPopup,
    onOpen: onOpenDeleteFolderPopup,
    onClose: onCloseDeleteFolderPopup,
  } = useDisclosure();

  return (
    <Modal
      isOpen={true}
      onClose={() => dispatch(setFolderToEdit(undefined))}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w='458px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader pt={15}>
            <Heading as='h1' size='lg' textAlign='center'>
              Name your folder
            </Heading>
          </ModalHeader>
          <ModalBody>
            <Stack>
              <TextInput
                placeholder='Folder Name'
                register={registerTextInput}
                text={text}
              />
              {isSome(errors.name) && errors.name.type === 'required' && (
                <Text variant={'error'}>
                  You must enter a folder name. Please try again.
                </Text>
              )}
            </Stack>
            <Button
              w='100%'
              mt={10}
              variant='delete'
              onClick={() => {
                onOpenDeleteFolderPopup();
              }}
              leftIcon={
                <FontAwesomeIcon
                  className={(classNames.icon, classNames.faIcon)}
                  icon={faTrashAlt}
                />
              }
            >
              Delete this folder
            </Button>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent='space-between' w='100%' pt={30}>
              <Button
                w='45%'
                onClick={() => dispatch(setFolderToEdit(undefined))}
              >
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='45%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
      <DeleteFolderPopup
        isOpen={isOpenDeleteFolderPopup}
        onClose={onCloseDeleteFolderPopup}
        onCloseParent={() => dispatch(setFolderToEdit(undefined))}
      />
    </Modal>
  );
};
const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
