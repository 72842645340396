import { SideNavBar } from '../sharedComponents/sideNavBar/SideNavBar';
import {
  IconButton,
  Button,
  Flex,
  Spacer,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import 'firebase/app';
import 'firebase/auth';
import { NewProdGroupIcon, UserIcon } from '../config/icons';
import { ProfilePopup } from '../sharedComponents/modal/ProfilePopup';
import { AddProdGroupPopup } from '../sharedComponents/modal/AddProdGroupPopup';
import { ProdGroupsInitial } from '../sharedComponents/productGroups/ProdGroupsInitial';
import { ProdGroupsTable } from '../sharedComponents/productGroups/ProdGroupsTable';
import { appValues } from '../config/constants';

export const ProdGroupsPage = (): JSX.Element => {
  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();

  const {
    isOpen: isOpenProdGroupPopup,
    onOpen: onOpenProdGroupPopup,
    onClose: onCloseProdGroupPopup,
  } = useDisclosure();

  return (
    <Flex>
      <SideNavBar />
      <Flex className='main' ml={appValues.SIDEBAR_WIDTH}>
        <Stack pl={10} pr={10} pt={5}>
          <HStack>
            <Button
              onClick={onOpenProdGroupPopup}
              variant='fill'
              leftIcon={<NewProdGroupIcon />}
            >
              New product group
            </Button>
            <AddProdGroupPopup
              isOpen={isOpenProdGroupPopup}
              onClose={onCloseProdGroupPopup}
            />
            <Spacer />
            <IconButton
              aria-label='Profile'
              border='none'
              icon={<UserIcon className='icon' w={8} h={8} />}
              onClick={onOpenProfilePopup}
            />
            <ProfilePopup
              isOpen={isOpenProfilePopup}
              onClose={onCloseProfilePopup}
            />
          </HStack>
          <ProdGroupsInitial />
          <ProdGroupsTable />
        </Stack>
      </Flex>
    </Flex>
  );
};
