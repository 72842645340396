/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { FolderOrContentQuestion } from '../objects/Questions/FolderOrContentQuestion';
import {
  Questionnaire,
  InputQuestionnaire,
} from '../objects/Questions/Questionnaire';
import {
  InputQuestionnaireTemplateOption,
  QuestionnaireTemplateOption,
} from '../objects/Questions/QuestionnaireTemplateOption';
import { ITagGroupQuestion } from '../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../objects/Questions/UngroupedTagQuestion';
import {
  QTemplateOptionGroupQKey,
  QTemplateOptionRootQKey,
  QTemplateOptionUngroupedQKey,
  QuestionnaireImagesKey,
  QuestionnaireKey,
  vidualsApi,
} from './vidualsApi';

export const questionnaireApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionnaireByProdGroup: builder.query<Questionnaire, string>({
      query: (pgId) => `questionnaires/productGroup/${pgId}`,
      providesTags: [QuestionnaireKey],
    }),
    getUsedQuestionnaireImages: builder.query<string[], string>({
      query: (pgId) => `questionnaires/usedImagess/productGroup/${pgId}`,
      providesTags: [QuestionnaireImagesKey],
    }),
    getRootQuestionsForTemplateOption: builder.query<
      FolderOrContentQuestion[],
      string
    >({
      query: (QTOId) =>
        `questionnaires/QuestionnaireTemplateOption/${QTOId}/rootQuestions`,
      providesTags: (_result, _error, arg) => [
        { type: QTemplateOptionRootQKey, id: arg },
      ],
    }),
    getTagGroupQuestionsForTemplateOption: builder.query<
      ITagGroupQuestion[],
      string
    >({
      query: (QTOId) =>
        `questionnaires/QuestionnaireTemplateOption/${QTOId}/taggroupQuestions`,
      providesTags: (_result, _error, arg) => [
        { type: QTemplateOptionGroupQKey, id: arg },
      ],
    }),
    getUngroupedTagQuestionsForTemplateOption: builder.query<
      IUngroupedTagQuestion[],
      string
    >({
      query: (QTOId) =>
        `questionnaires/QuestionnaireTemplateOption/${QTOId}/ungroupedTagQuestions`,
      providesTags: (_result, _error, arg) => [
        { type: QTemplateOptionUngroupedQKey, id: arg },
      ],
    }),
    editQuestionnaire: builder.mutation<Questionnaire, InputQuestionnaire>({
      query: (body) => {
        return {
          url: `questionnaires`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [QuestionnaireKey, QuestionnaireImagesKey],
    }),
    upsertRootQuestionsForTemplateOption: builder.mutation<
      FolderOrContentQuestion[],
      {
        inputQuestions: FolderOrContentQuestion[];
        productGroupId: string;
        updatedTemplateOptionIds: string[];
      }
    >({
      query: (body) => {
        return {
          url: `questionnaires/saveRootQuestions`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, arg) => {
        const foundTemplateOptionsIds = Array.from(
          new Set(
            arg.inputQuestions.map((iQ) => iQ.templateQuestionnaireOptionId)
          )
        );
        return [
          ...foundTemplateOptionsIds.map((id) => {
            return { type: QTemplateOptionRootQKey, id };
          }),
        ];
      },
    }),
    upsertGroupQuestionsForTemplateOption: builder.mutation<
      ITagGroupQuestion[],
      {
        inputQuestions: ITagGroupQuestion[];
        productGroupId: string;
        updatedTemplateOptionIds: string[];
      }
    >({
      query: (body) => {
        return {
          url: `questionnaires/saveGroupQuestions`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, args) => [
        ...args.inputQuestions.map((tq) => {
          return {
            type: QTemplateOptionGroupQKey,
            id: tq.templateQuestionnaireOptionId,
          };
        }),
      ],
    }),
    upsertUngroupedQuestionsForTemplateOption: builder.mutation<
      IUngroupedTagQuestion[],
      {
        inputQuestions: IUngroupedTagQuestion[];
        productGroupId: string;
        updatedTemplateOptionIds: string[];
      }
    >({
      query: (body) => {
        return {
          url: `questionnaires/saveUngroupedQuestions`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, args) => [
        ...args.inputQuestions.map((tq) => {
          return {
            type: QTemplateOptionUngroupedQKey,
            id: tq.templateQuestionnaireOptionId,
          };
        }),
      ],
    }),
    addQTemplateOption: builder.mutation<
      QuestionnaireTemplateOption[],
      InputQuestionnaireTemplateOption[]
    >({
      query: (body) => {
        return {
          url: `questionnaires/createQTemplateOptions`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [QuestionnaireKey],
    }),
  }),
});

export const {
  useLazyGetQuestionnaireByProdGroupQuery,
  useGetQuestionnaireByProdGroupQuery,
  useEditQuestionnaireMutation,
  useGetRootQuestionsForTemplateOptionQuery,
  useGetTagGroupQuestionsForTemplateOptionQuery,
  useGetUngroupedTagQuestionsForTemplateOptionQuery,
  useAddQTemplateOptionMutation,
  useUpsertRootQuestionsForTemplateOptionMutation,
  useUpsertGroupQuestionsForTemplateOptionMutation,
  useUpsertUngroupedQuestionsForTemplateOptionMutation,
  useGetUsedQuestionnaireImagesQuery,
} = questionnaireApi;
