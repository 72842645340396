/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useEffect, useState } from 'react';
import { Folder, UpdateFolderParams } from '../../objects/Folder';
import { Box } from '@chakra-ui/react';
import { Content, UpdateContentParams } from '../../objects/Content';
import './FileTree.scss';
import { useAppSelector } from '../../store/hooks';
import { EditContentPopup } from '../modal/content/EditContentPopup';
import { FileTreeItemTypeSchema } from '../../objects/FileTreeItem';
import { ContentItem } from '../content/ContentItem';
import { FolderItem } from './FolderItem';
import { EditFolderPopup } from '../modal/folder/EditFolderPopup';
import SortableTree, {
  getTreeFromFlatData,
  TreeItem,
  find,
  NodeData,
  OnMovePreviousAndNextLocation,
  FullTree,
} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import {
  useUpdateFolderMutation,
  useUpdateFolderChildrenOrderMutation,
} from '../../services/folderEndpoints';
import {
  useUpdateContentMutation,
  useUpdateVersionOrderMutation,
} from '../../services/contentsEndpoints';
import { isSome } from '../../config/Maybe';
import {
  ContentVersion,
  InputContentVersionParams,
} from '../../objects/ContentVersion';
import {
  selectContentToEdit,
  selectFolderToEdit,
} from '../../store/modalFormSlice';
import { selectFoldersContentsOrVersions } from '../../store/treeItemSlice';
import {
  FolderContentOrVersion,
  ItemType,
} from '../../objects/FolderOrContentItem';
import { ContentVersionItem } from '../content/ContentVersionItem';

export const ROOT_TREE_ID = 'root';

interface FileTreePaginatedProps {
  updateContentVersions: (
    contentId: string,
    versions: InputContentVersionParams[]
  ) => void;
  updateContentFunc: (id: string, content: UpdateContentParams) => void;
}

export const FileTreePaginated = ({
  updateContentFunc,
  updateContentVersions,
}: FileTreePaginatedProps): JSX.Element => {
  const [updateFolder] = useUpdateFolderMutation();
  const [sortFolderChildren] = useUpdateFolderChildrenOrderMutation();
  const [sortContentVersions] = useUpdateVersionOrderMutation();
  const [updateContent] = useUpdateContentMutation();

  const folderToEdit = useAppSelector(selectFolderToEdit);
  const contentToEdit = useAppSelector(selectContentToEdit);

  const foldersContentsOrVersions: FolderContentOrVersion[] = useAppSelector(
    selectFoldersContentsOrVersions
  );

  useEffect(() => {
    const treeItems = getTreeItemsFromFlatItems(
      foldersContentsOrVersions,
      treeData
    );
    const treeStruct = getTreeFromFlatData({
      flatData: treeItems,
      getKey: (fileTreeNode) => fileTreeNode.id,
      getParentKey: (fileTreeNode) => fileTreeNode.parentId,
      rootKey: ROOT_TREE_ID,
    });

    setTreeData(treeStruct);
  }, [foldersContentsOrVersions]);

  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  const sortVersions = async (
    updatedData: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode } = updatedData;
    const { matches } = find({
      treeData: treeData,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node }) => {
        return node.parentId === movedNode.parentId && node.id !== movedNode.id;
      },
    });

    const sorted = [...matches, updatedData].sort(
      (a, b) => a.treeIndex - b.treeIndex
    );

    const idsInOrder = sorted.map((data) => data.node.id);

    await sortContentVersions({
      id: movedNode.parentId,
      sortedChildIds: idsInOrder,
    });
  };

  const onMoveNode = async (
    updatedData: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode, nextParentNode } = updatedData;
    const newParentFolderId: string | undefined = nextParentNode?.id;

    if (movedNode.type === FileTreeItemTypeSchema.Enum.CONTENT_VERSION) {
      await sortVersions(updatedData);
      return;
    }

    const { matches } = find({
      treeData: treeData,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node }) => {
        if (node.parentId === ROOT_TREE_ID) {
          return newParentFolderId === undefined && node.id !== movedNode.id;
        }
        return node.parentId === newParentFolderId && node.id !== movedNode.id;
      },
    });

    const sorted = [...matches, updatedData].sort(
      (a, b) => a.treeIndex - b.treeIndex
    );

    const idsInOrder = sorted.map((data) => {
      let type = ItemType.Folder;
      if (data.node.type === FileTreeItemTypeSchema.Enum.CONTENT) {
        type = ItemType.Content;
      } else if (
        data.node.type === FileTreeItemTypeSchema.Enum.CONTENT_VERSION
      ) {
        type = ItemType.Version;
      }
      return { id: data.node.id as string, type };
    });

    if (movedNode.type === FileTreeItemTypeSchema.Enum.FOLDER) {
      const movedFolder = movedNode.fileTreeItem as Folder;
      const updatedFolderParams: UpdateFolderParams = {
        name: movedFolder.name,
        indexWithinParent: 0,
        parentFolderId: newParentFolderId,
      };
      await updateFolder({
        id: movedFolder.id ?? '',
        folder: updatedFolderParams,
      }).unwrap();
    } else if (movedNode.type === FileTreeItemTypeSchema.Enum.CONTENT) {
      const movedContent = movedNode.fileTreeItem as Content;
      const updatedContentParams: UpdateContentParams = {
        name: movedContent.name,
        description: movedContent.description,
        parentFolderId: newParentFolderId,
      };
      await updateContent({
        id: movedNode.id ?? '',
        content: updatedContentParams,
      }).unwrap();
    }
    await sortFolderChildren({
      id: newParentFolderId ?? ROOT_TREE_ID,
      sortedChildIds: idsInOrder,
    });
  };

  return (
    <Box>
      {isSome(folderToEdit) && <EditFolderPopup />}
      {isSome(contentToEdit) && (
        <EditContentPopup
          updateContentFunc={updateContentFunc}
          updateContentVersions={updateContentVersions}
        />
      )}
      <Box minH={100} h='calc(100vh - 230px)' w='100%'>
        <SortableTree
          treeData={treeData}
          canDrop={(data) => {
            if (isSome(data.nextParent)) {
              if (
                data.node.type === FileTreeItemTypeSchema.Enum.CONTENT_VERSION
              ) {
                return data.nextParent.id === data.node.fileTreeItem.contentId;
              }
              return data.nextParent.childAllowed;
            }
            return (
              data.node.type !== FileTreeItemTypeSchema.Enum.CONTENT_VERSION
            );
          }}
          canDrag={true}
          onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
          theme={FileExplorerTheme}
          rowHeight={42}
          reactVirtualizedListProps={{
            className: 'file-tree-list',
          }}
          onMoveNode={onMoveNode}
        />
      </Box>
    </Box>
  );
};

function getTreeItemsFromFlatItems(
  flatTreeItems: FolderContentOrVersion[],
  currentTree: TreeItem[]
): {
  [key: number]: TreeItem;
} {
  return flatTreeItems.map((node): TreeItem => {
    let expanded = false;
    const { matches } = find({
      treeData: currentTree,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node: searchNode }) => {
        return searchNode.id === node.item.id;
      },
    });
    if (matches.length > 0) {
      expanded = matches[0].node.expanded ?? false;
    }

    if (node.type === ItemType.Folder) {
      const folder = node.item as Folder;
      const parentId =
        isSome(folder.parent) && folder.parent.isRoot
          ? ROOT_TREE_ID
          : folder.parentFolderId;
      return {
        title: <FolderItem key={folder.id} folder={folder} />,
        id: folder.id,
        parentId: parentId,
        expanded,
        fileTreeItem: folder,
        type: FileTreeItemTypeSchema.Enum.FOLDER,
        childAllowed: true,
      };
    } else if (node.type === ItemType.Content) {
      const content = node.item as Content;
      const parentId =
        isSome(content.parent) && content.parent.isRoot
          ? ROOT_TREE_ID
          : content.parentFolderId;
      const versions = flatTreeItems.filter((treeItem) => {
        if (treeItem.type === ItemType.Version) {
          const version = treeItem.item as ContentVersion;
          return version.contentId === content.id;
        }
        return false;
      }) as unknown as ContentVersion[];
      return {
        title: (
          <ContentItem key={content.id} content={content} versions={versions} />
        ),
        expanded,
        id: content.id,
        parentId: parentId,
        fileTreeItem: content,
        type: FileTreeItemTypeSchema.Enum.CONTENT,
        childAllowed: false,
      };
    } else {
      const version = node.item as ContentVersion;
      return {
        title: <ContentVersionItem key={version.id} contentVersion={version} />,
        id: version.id,
        parentId: version.contentId,
        fileTreeItem: version,
        type: FileTreeItemTypeSchema.Enum.CONTENT_VERSION,
        childAllowed: false,
        children: [],
      };
    }
  });
}
