import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Stack,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Select as ChakraSelect,
  useDisclosure,
  Box,
  FormLabel,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { isSome } from '../../../config/Maybe';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { InputTagParams, Tag, UpdateTagParams } from '../../../objects/Tag';
import {
  useUpdateTagMutation,
  useDeleteTagMutation,
} from '../../../services/tagEndpoints';
import { selectTagGroups } from '../../../store/tagGroupSlice';
import { setTagToEdit } from '../../../store/modalFormSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { appColors } from '../../../config/constants';
import { WarningModal } from '../WarningModal';
import { ContentVersion } from '../../../objects/ContentVersion';
import { deleteTags, selectTags } from '../../../store/tagSlice';
import { useEffect, useRef, useState } from 'react';
import {
  addFailedFileToUpload,
  addFileToUpload,
  addSucceededFileUpload,
  removeFileToUpload,
} from '../../../store/toastSlice';
import {
  OptionalProdGroup,
  ProductGroupType,
} from '../../../objects/ProdGroup';
import axios from 'axios';
import { getBaseUrl } from '../../../services/vidualsApi';
import { ImageIcon } from '../../../config/icons';
import { ImagePreview } from '../../../components/ImagePreview';
import Select from 'react-select';
import { buildFileGetTagImageUrlByProdGroup } from '../../../config/S3Client';
import firebase from 'firebase/app';
import { firebaseApp } from '../../../config/firebase.config';

interface SelectInterface {
  value: string;
  label: string;
}

interface EditTagPopupProps {
  isOpen: boolean;
  tag: Tag;
}

export const EditTagPopup = ({
  isOpen,
  tag,
}: EditTagPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const tagGroups = useAppSelector(selectTagGroups);
  const existingTags = useAppSelector(selectTags);

  // useRef hook does not trigger a rerender, so we do it manually.
  const [imageFile, setImageFileState] = useState<File | undefined>(undefined);
  const [imageFileUrl, setImageFileUrl] = useState<string>('');

  const [selectedExistingFile, setSelectedExistingFile] = useState<string>(
    tag.thumbnailUrl ?? ''
  );
  const [fileOptions, setFileOptions] = useState<SelectInterface[]>([]);

  useEffect(() => {
    if (isSome(imageFile)) {
      setImageFileUrl(URL.createObjectURL(imageFile));
    } else {
      setImageFileUrl('');
    }
  }, [imageFile]);

  useEffect(() => {
    const exisitingImages = existingTags
      .filter((tag) => isSome(tag.thumbnailUrl))
      .map((tag) => tag.thumbnailUrl) as string[];
    const uniqueImageOptions: SelectInterface[] = [
      ...new Set(exisitingImages),
    ].map((name) => {
      return { value: name, label: name };
    });

    setFileOptions(uniqueImageOptions);
  }, [existingTags]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputTagParams>();

  const [updateTag] = useUpdateTagMutation();
  const [deleteTag, deleteResult] = useDeleteTagMutation();

  const thumbnailImageRef = useRef<HTMLInputElement>(null);

  const setImageFile = (): void => {
    const file = thumbnailImageRef?.current?.files?.item(0) ?? undefined;
    if (isSome(file) && fileOptions.map((fo) => fo.value).includes(file.name)) {
      window.alert(
        `duplicate file name will overwrite existing file with name:${file.name}`
      );
    }
    setImageFileState(file);
  };

  const removeImageFile = (): void => {
    if (isSome(thumbnailImageRef) && isSome(thumbnailImageRef.current)) {
      thumbnailImageRef.current.files = null;
      thumbnailImageRef.current.value = '';

      setImageFileState(undefined);
    }
    setImageFileUrl('');
  };

  useEffect(() => {
    const { error } = deleteResult;
    if (error !== undefined) {
      if ('status' in error && error.status === 409 && isSome(error.data)) {
        const versions: ContentVersion[] = error.data as ContentVersion[];
        const versionsWithTag: string = versions
          .map((version) => String(version.content?.name))
          .join(',');
        alert(
          `Could not delete tag, tag is still in use by content: ${versionsWithTag}`
        );
      } else {
        alert('An error occurred, please try again');
      }
    } else if (deleteResult.isSuccess) {
      reset();
      dispatch(deleteTags([deleteResult.data as string]));
      dispatch(setTagToEdit(undefined));
    }
  }, [deleteResult]);

  const onDelete = async (): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const tagToDelete: UpdateTagParams = {
        id: tag.id,
        name: tag.name,
        description: tag.description ?? '',
        tagGroupId: tag.tagGroup?.id ?? '',
        productGroupId: selectedProductGroup.id,
      };

      await deleteTag(tagToDelete).unwrap();
    }
  };

  const onSubmit = async (tagToCreate: InputTagParams): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const file = thumbnailImageRef?.current?.files?.item(0) ?? undefined;
      if (isSome(file)) {
        try {
          dispatch(addFileToUpload(file.name));
          await uploadFile(
            firebaseApp.auth().currentUser,
            selectedProductGroup,
            file
          );
          tagToCreate.thumbnailUrl = file.name;
          dispatch(addSucceededFileUpload(file.name));
        } catch (err) {
          console.log(err);
          dispatch(addFailedFileToUpload(file.name));
        } finally {
          dispatch(removeFileToUpload(file.name));
        }
      } else if (selectedExistingFile !== '') {
        tagToCreate.thumbnailUrl = selectedExistingFile;
      } else {
        tagToCreate.thumbnailUrl = undefined;
      }

      tagToCreate.productGroupId = selectedProductGroup.id;
      tagToCreate.description = '';

      // if an <option> value is set to undefined, it uses the label as the value,
      //  since we don't want (Ungrouped) as a group id, I handle it here.
      if (tagToCreate.tagGroupId === '') {
        tagToCreate.tagGroupId = undefined;
      }

      await updateTag({ id: tag.id, ...tagToCreate }).unwrap();
    }

    reset();
    dispatch(setTagToEdit(undefined));
  };

  const renderTagGroupOptions = (): JSX.Element => {
    return (
      <>
        <option value={''}>(Ungrouped)</option>
        {tagGroups.map((tagGroup) => (
          <option
            key={`add tag select group:${tagGroup.id}`}
            value={tagGroup.id}
          >
            {tagGroup.name}
          </option>
        ))}
      </>
    );
  };

  const onSelectFile = async (
    option: SelectInterface | null
  ): Promise<void> => {
    if (isSome(option)) {
      setSelectedExistingFile(option.value);
      removeImageFile();
    } else {
      setSelectedExistingFile('');
    }
  };

  const renderImagePreview = (): JSX.Element => {
    if (isSome(imageFile)) {
      return (
        <ImagePreview
          fileUrl={imageFileUrl}
          imageLabel='Logo'
          imageName={imageFile.name}
          removeFunc={removeImageFile}
        />
      );
    } else if (selectedExistingFile !== '') {
      if (isSome(selectedProductGroup)) {
        const imageUrl = buildFileGetTagImageUrlByProdGroup(
          selectedProductGroup.id,
          selectedExistingFile
        );
        return (
          <ImagePreview
            fileUrl={imageUrl}
            imageLabel='Logo'
            imageName={selectedExistingFile}
            removeFunc={() => setSelectedExistingFile('')}
          />
        );
      }
    }
    return <Text>Image could not be rendered</Text>;
  };

  watch();

  const {
    isOpen: isOpenDeleteConfirmPopup,
    onOpen: onOpenDeleteConfirmPopup,
    onClose: onCloseDeleteConfirmPopup,
  } = useDisclosure();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dispatch(setTagToEdit(undefined))}
      closeOnOverlayClick={false}
    >
      {isOpenDeleteConfirmPopup && (
        <WarningModal
          isOpen={isOpenDeleteConfirmPopup}
          onClose={onCloseDeleteConfirmPopup}
          title={'Delete this tag?'}
          message={
            'This will remove the tag from any groups and content variations that use it permanently.'
          }
          onCancel={onCloseDeleteConfirmPopup}
          onConfirm={async () => await onDelete()}
        />
      )}
      <ModalOverlay />
      <ModalContent w='458px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <ModalHeader pt={15}>
              <Heading as='h1' size='lg' textAlign='center'>
                Edit tag
              </Heading>
            </ModalHeader>
            <Stack>
              <FormLabel>Name</FormLabel>
              <Input
                defaultValue={tag.name}
                backgroundColor='#ffffff'
                w='100%'
                type={'text'}
                placeholder='Tag Name'
                {...register('name', {
                  required: {
                    value: true,
                    message: 'You must enter a name',
                  },
                })}
              />
              {isSome(errors.name) && errors.name.type === 'required' && (
                <Text variant={'error'}>
                  You must enter a tag name. Please try again.
                </Text>
              )}
              {selectedProductGroup?.type ===
                ProductGroupType.Questionnaire && (
                <>
                  {isSome(imageFile) || selectedExistingFile !== '' ? (
                    renderImagePreview()
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          thumbnailImageRef?.current?.click();
                        }}
                        leftIcon={<ImageIcon />}
                      >
                        Upload new image
                      </Button>
                      <Box w='100%' my='20px'>
                        <Text>Choose image from exsiting files</Text>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          name='fileName'
                          options={fileOptions}
                          onChange={onSelectFile}
                        />
                      </Box>
                    </>
                  )}
                  <input
                    onClick={(e) => (e.currentTarget.value = '')}
                    type='file'
                    style={{ display: 'none' }}
                    accept='image/*'
                    ref={thumbnailImageRef}
                    onChange={setImageFile}
                  />
                </>
              )}
              <Text as='h2' size='sm' color='gray.500'>
                Tag Group
              </Text>
              <ChakraSelect
                minW='75px'
                w='unset'
                {...register('tagGroupId')}
                defaultValue={tag.tagGroup?.id}
              >
                {renderTagGroupOptions()}
              </ChakraSelect>
              <Button
                className={classNames.contentButton}
                justifyContent='center'
                w='100%'
                color={appColors.RED}
                onClick={onOpenDeleteConfirmPopup}
                leftIcon={
                  <FontAwesomeIcon
                    className={(classNames.icon, classNames.faIcon)}
                    icon={faTrashAlt}
                  />
                }
              >
                Delete this tag
              </Button>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack className='space-between width100'>
              <Button w='45%' onClick={() => dispatch(setTagToEdit(undefined))}>
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='45%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const classNames = {
  contentButton: 'content-button',
  icon: 'icon',
  faIcon: 'faIcon',
};

async function getPresignedUrl(
  currentUser: firebase.User | null,
  selectedProductGroup: OptionalProdGroup,
  file: File
): Promise<string> {
  const token = await currentUser?.getIdTokenResult();

  if (isSome(token?.token) && isSome(selectedProductGroup)) {
    const headers = {
      Authorization: `Bearer ${token?.token ?? ''}`,
    };
    const url = `${getBaseUrl()}/presign/generate-put`;
    // grab a string to add a key prefix for aws
    // see https://docs.aws.amazon.com/AmazonS3/latest/userguide/optimizing-performance.html
    const prefix = file.name;
    const putUrl = await axios.post(
      url,
      {
        key: `${selectedProductGroup.id}/tagImage/${prefix}/${file.name}`,
        contentType: file.type,
      },
      { headers }
    );
    return putUrl.data;
  } else throw new Error('Error in generating upload URL');
}

async function uploadFile(
  currentUser: firebase.User | null,
  selectedProductGroup: OptionalProdGroup,
  file: File
): Promise<void> {
  const putUrl = await getPresignedUrl(currentUser, selectedProductGroup, file);

  await axios.put(putUrl, file, { headers: { 'Content-Type': file.type } });
}
