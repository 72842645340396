/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  InputTemplateParams,
  ITemplateWithFoldersContentsAndTags,
  Template,
  UpdateTemplateParams,
} from '../objects/Template';
import {
  addTemplate,
  deleteTemplate,
  setTemplates,
  updateTemplate,
} from '../store/templateSlice';
import { IOnQueryAPI, vidualsApi } from './vidualsApi';

const updateTemplatesState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setTemplates(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateTemplateOnSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

const addTemplateToSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

const deleteTemplateFromSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(deleteTemplate(data));
  } catch (err) {
    console.trace(err);
  }
};

export const templatesApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplatesByProductGroupId: builder.query<Template[], string>({
      query: (pgid) => `templates/productGroup/${pgid}`,
      onQueryStarted: updateTemplatesState,
    }),
    createTemplate: builder.mutation<Template, InputTemplateParams>({
      query: (body) => {
        return {
          url: `templates`,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: addTemplateToSlice,
    }),
    updateTemplate: builder.mutation<Template, UpdateTemplateParams>({
      query: (body) => {
        return {
          url: `templates`,
          method: 'PUT',
          body: body,
        };
      },
      onQueryStarted: updateTemplateOnSlice,
    }),
    setTemplateSelections: builder.mutation<
      Template,
      ITemplateWithFoldersContentsAndTags
    >({
      query: (body) => {
        return {
          url: `templates/selections`,
          method: 'PUT',
          body: body,
        };
      },
      onQueryStarted: updateTemplateOnSlice,
    }),
    deleteTemplate: builder.mutation<string, UpdateTemplateParams>({
      query: (body) => {
        return {
          url: `templates`,
          method: 'DELETE',
          body: body,
        };
      },
      onQueryStarted: deleteTemplateFromSlice,
    }),
  }),
});

export const {
  useLazyGetTemplatesByProductGroupIdQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
  useSetTemplateSelectionsMutation,
} = templatesApi;
