import { Content } from '../../objects/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { HStack, Text } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { useAppDispatch } from '../../store/hooks';
import { setContentToEdit } from '../../store/modalFormSlice';
import { isSome } from '../../config/Maybe';
import { ContentVersion } from '../../objects/ContentVersion';

interface ContentItemProps {
  content: Content;
  disableOnClick?: boolean;
  versions?: ContentVersion[];
}

export const ContentItem = ({
  content,
  disableOnClick,
  versions,
}: ContentItemProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <HStack justifyContent='space-between' w='100%' h='100%'>
      <HStack>
        <FontAwesomeIcon
          className={(classNames.icon, classNames.faIcon)}
          icon={faPlayCircle}
          color={appColors.GRAY400}
        />
        <Text
          onClick={() => {
            if (disableOnClick !== true) {
              dispatch(setContentToEdit(content));
            }
          }}
          className={classNames.content}
        >
          {content.name}
        </Text>
      </HStack>
      {isSome(versions) && (
        <Text color='gray.500' fontWeight={400}>
          {versions.length} Variation
          {versions.length !== 1 && 's'}
        </Text>
      )}
    </HStack>
  );
};

const classNames = {
  content: 'content',
  icon: 'icon',
  faIcon: 'faIcon',
};
