import { createContext, useContext } from 'react';
import { ProdGroup } from '../objects/ProdGroup';

interface EndUserContext {
  productGroup: ProdGroup | undefined;
  setProdGroup: React.Dispatch<React.SetStateAction<ProdGroup | undefined>>;
}

const EndUserContextContext = createContext<EndUserContext>({
  productGroup: undefined,
  setProdGroup: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

const useEndUserContextContext = (): EndUserContext =>
  useContext(EndUserContextContext);

export { EndUserContextContext, useEndUserContextContext };
