import { HStack, VStack, Text, Checkbox, Box } from '@chakra-ui/react';
import { FolderWithTags } from '../../../objects/FolderWithTags';
import closedFolder from '../../../images/fileIcons/closedFolder.svg';
import './SystemAvailableFileTree.scss';
import { ContentWithTags } from '../../../objects/ContentWithTags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { appColors } from '../../../config/constants';
import SortableTree, { find, TreeItem } from 'react-sortable-tree';
import { useAppSelector } from '../../../store/hooks';
import { Folder } from '../../../objects/Folder';
import {
  FileTreeItem,
  FileTreeItemTypeSchema,
} from '../../../objects/FileTreeItem';
import { Content } from '../../../objects/Content';
import { FolderItem } from '../../folders/FolderItem';
import { ContentItem } from '../../content/ContentItem';
import { useState, useEffect } from 'react';
import {
  selectContentsInTree,
  selectFoldersInTree,
  selectVersionsInTree,
} from '../../../store/treeItemSlice';
import { ContentVersionItem } from '../../content/ContentVersionItem';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import {
  ContentVersion,
  versionTagsMatchCheckedSelection,
} from '../../../objects/ContentVersion';
import {
  FoldersOrContentWithTags,
  ItemType,
} from '../../../objects/FolderOrContentItem';
import { isSome } from '../../../config/Maybe';

interface TreeItemWithWarning {
  items: TreeItem[];
  containsWarning: boolean;
  containsUsedVersion: boolean;
}

interface SystemAvailableFileTreeProps {
  foldersWithTags: FolderWithTags[];
  contentsWithTags: ContentWithTags[];
  checkedTagIds: string[];
  checkedFolderIds: string[];
  checkedContentIds: string[];
  hideUnselected: boolean;
  toggleFolderId: (folderId: string) => void;
  toggleContentId: (contentId: string) => void;
  setTreeContainsConflict: (conflict: boolean) => void;
}

export const SystemAvailableFileTree = ({
  foldersWithTags,
  contentsWithTags,
  checkedFolderIds,
  checkedContentIds,
  checkedTagIds,
  toggleFolderId,
  toggleContentId,
  setTreeContainsConflict,
  hideUnselected,
}: SystemAvailableFileTreeProps): JSX.Element => {
  const [treeData, setTreeData] = useState<TreeItem[]>([]);
  const allFolders: Folder[] = useAppSelector(selectFoldersInTree);
  const allContents: Content[] = useAppSelector(selectContentsInTree);
  const allVersions: ContentVersion[] = useAppSelector(selectVersionsInTree);

  const fileTreeItems: FileTreeItem[] = [
    ...allFolders.map((folder): FileTreeItem => {
      return { ...folder, type: FileTreeItemTypeSchema.Enum.FOLDER };
    }),
    ...allContents.map((content): FileTreeItem => {
      return { ...content, type: FileTreeItemTypeSchema.Enum.CONTENT };
    }),
  ];

  useEffect(() => {
    setData();
  }, [
    allFolders,
    allContents,
    foldersWithTags,
    contentsWithTags,
    hideUnselected,
  ]);

  useEffect(() => {
    setTreeContainsConflict(
      treeData.some(
        (item: TreeItem) => item.checked === true && item.warning === true
      )
    );
  }, [treeData]);

  const shouldBeExpanded = (
    folderOrContentId: string,
    currentTree: TreeItem[]
  ): boolean => {
    const { matches } = find({
      treeData: currentTree,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node: searchNode }) => {
        return searchNode.id === folderOrContentId;
      },
    });
    if (matches.length > 0) {
      return matches[0].node.expanded ?? false;
    }
    return false;
  };

  const setData = (): void => {
    const allRootItems: FoldersOrContentWithTags[] = [
      ...foldersWithTags.map((folder): FoldersOrContentWithTags => {
        const match = allFolders.find((f) => f.id === folder.id);
        return {
          item: {
            ...folder,
            indexWithinParent: isSome(match)
              ? match.indexWithinParent
              : folder.indexWithinParent,
          },
          type: ItemType.Folder,
        };
      }),
      ...contentsWithTags.map((content): FoldersOrContentWithTags => {
        const match = allContents.find((c) => c.id === content.id);
        return {
          item: {
            ...content,
            indexWithinParent: isSome(match)
              ? match.indexWithinParent
              : content.indexWithinParent,
          },
          type: ItemType.Content,
        };
      }),
    ].sort((a, b) => {
      return a.item.indexWithinParent - b.item.indexWithinParent;
    });

    const finalData: TreeItem[] = [];

    allRootItems.map((folderOrContent) => {
      if (folderOrContent.type === ItemType.Folder) {
        const checked = checkedFolderIds.some(
          (id) => id === folderOrContent.item.id
        );
        if (checked || !hideUnselected) {
          const folderChildren = setTreeDataRecursive(
            folderOrContent.item.id,
            checked
          );
          if (!hideUnselected || folderChildren.containsUsedVersion) {
            finalData.push({
              title: (
                <HStack
                  key={`system folder option: ${folderOrContent.item.id}`}
                  w='100%'
                  h='100%'
                  px='10px'
                  justifyContent='space-between'
                >
                  <HStack>
                    <Checkbox
                      onChange={() => toggleFolderId(folderOrContent.item.id)}
                      colorScheme='green'
                      isChecked={checked}
                    />
                    <img className={classNames.icon} src={closedFolder} />
                    <Text>{folderOrContent.item.name}</Text>
                  </HStack>
                </HStack>
              ),
              id: folderOrContent.item.id,
              fileTreeItem: folderOrContent.item as Folder,
              type: FileTreeItemTypeSchema.Enum.FOLDER,
              childAllowed: true,
              children: folderChildren.items,
              checked: checked,
              warning: folderChildren.containsWarning,
              used: folderChildren.containsUsedVersion,
              expanded: shouldBeExpanded(folderOrContent.item.id, treeData),
            });
          }
        }
      } else {
        const checked = checkedContentIds.some(
          (id) => id === folderOrContent.item.id
        );
        if (checked || !hideUnselected) {
          const versions: ContentVersion[] = allVersions
            .filter((version) => version.contentId === folderOrContent.item.id)
            .sort((a, b) => {
              return a.indexWithinParent - b.indexWithinParent;
            });
          const content = allContents.find(
            (c) => c.id === folderOrContent.item.id
          );
          const children = contentVersionsTreeItems(
            versions,
            checked,
            content ? content.requiredUngroupedTags ?? [] : []
          );

          if (!hideUnselected || children.containsUsedVersion) {
            finalData.push({
              title: (
                <HStack
                  key={`system content option: ${folderOrContent.item.id}`}
                  w='100%'
                  h='100%'
                  px='10px'
                  justifyContent='space-between'
                >
                  <HStack>
                    <Checkbox
                      onChange={() => toggleContentId(folderOrContent.item.id)}
                      colorScheme='green'
                      isChecked={checked}
                    />
                    <FontAwesomeIcon
                      className={(classNames.icon, classNames.faIcon)}
                      icon={faPlayCircle}
                      color={appColors.GRAY400}
                    />
                    <Text>{folderOrContent.item.name}</Text>
                  </HStack>
                </HStack>
              ),
              id: folderOrContent.item.id,
              fileTreeItem: folderOrContent.item as Content,
              type: FileTreeItemTypeSchema.Enum.CONTENT,
              childAllowed: false,
              children: children.items,
              checked: checked,
              warning: children.containsWarning,
              used: children.containsUsedVersion,
              expanded: shouldBeExpanded(folderOrContent.item.id, treeData),
            });
          }
        }
      }
      return folderOrContent;
    });

    setTreeData(finalData);
  };

  const setTreeDataRecursive = (
    parentFolderId: string,
    checked: boolean
  ): TreeItemWithWarning => {
    const firstChildren = fileTreeItems
      .filter((item) => item.parentFolderId === parentFolderId)
      .sort((a, b) => {
        return a.indexWithinParent - b.indexWithinParent;
      });
    let containsWarning = false;
    let containsUsedVersion = false;

    const treeChildren: TreeItem[] = firstChildren.map((child): TreeItem => {
      if (child.type === FileTreeItemTypeSchema.Enum.FOLDER) {
        const folderChildItems = setTreeDataRecursive(child.id, checked);
        if (folderChildItems.containsWarning) {
          containsWarning = true;
        }
        if (folderChildItems.containsUsedVersion) {
          containsUsedVersion = true;
        }
        return {
          title: (
            <FolderItem
              key={child.id}
              folder={child as Folder}
              disableOnClick={true}
            />
          ),
          id: child.id,
          expanded: shouldBeExpanded(child.id, treeData),
          fileTreeItem: child as Folder,
          type: FileTreeItemTypeSchema.Enum.FOLDER,
          childAllowed: true,
          children: folderChildItems.items,
          checked: checked,
          warning: folderChildItems.containsWarning,
          used: folderChildItems.containsUsedVersion,
        };
      } else {
        const childContent: Content = child as Content;
        const versions: ContentVersion[] = allVersions
          .filter((version) => version.contentId === childContent.id)
          .sort((a, b) => {
            return a.indexWithinParent - b.indexWithinParent;
          });
        const versionTreeItems = contentVersionsTreeItems(
          versions,
          checked,
          childContent.requiredUngroupedTags ?? []
        );
        if (versionTreeItems.containsWarning) {
          containsWarning = true;
        }
        if (versionTreeItems.containsUsedVersion) {
          containsUsedVersion = true;
        }

        return {
          title: (
            <ContentItem
              key={child.id}
              content={child as Content}
              disableOnClick={true}
            />
          ),
          expanded: shouldBeExpanded(child.id, treeData),
          id: child.id,
          fileTreeItem: child as Content,
          type: FileTreeItemTypeSchema.Enum.CONTENT,
          childAllowed: false,
          children: versionTreeItems.items,
          checked: checked,
          warning: versionTreeItems.containsWarning,
          used: versionTreeItems.containsUsedVersion,
        };
      }
    });

    return {
      items: treeChildren.filter((item) => {
        if (hideUnselected) {
          return item.used === true;
        }
        return true;
      }),
      containsWarning,
      containsUsedVersion,
    };
  };

  const contentVersionsTreeItems = (
    versions: ContentVersion[],
    checked: boolean,
    requiredUngroupedTags: string[]
  ): TreeItemWithWarning => {
    const contentVersionsItems: TreeItem[] = [];
    let numberUsed = 0;
    versions.map((contentVersion) => {
      const used = versionTagsMatchCheckedSelection(
        contentVersion,
        requiredUngroupedTags,
        checkedTagIds,
        checked
      );
      if (used) {
        numberUsed += 1;
      }

      if (hideUnselected && !used) {
        // if unused should be hidden, don't add to tree
        return contentVersion;
      }

      contentVersionsItems.push({
        title: (
          <ContentVersionItem
            key={contentVersion.id}
            contentVersion={contentVersion}
            usedTagIds={checkedTagIds}
          />
        ),
        id: contentVersion.id,
        fileTreeItem: contentVersion,
        type: FileTreeItemTypeSchema.Enum.CONTENT_VERSION,
        childAllowed: false,
        checked: checked,
        used: used,
      });
      return contentVersion;
    });

    contentVersionsItems.forEach((item) => {
      if (numberUsed > 1) {
        item.warning = true;
      }
    });
    return {
      items: contentVersionsItems,
      containsWarning: numberUsed > 1,
      containsUsedVersion: numberUsed > 0,
    };
  };

  return (
    <VStack w='100%' h='100%' spacing={0}>
      <Box h={`calc(100vh - 180px)`} w='100%'>
        <SortableTree
          className={classNames.tree}
          treeData={treeData}
          canDrag={false}
          theme={FileExplorerTheme}
          rowHeight={42}
          reactVirtualizedListProps={{
            className: 'file-tree-list',
          }}
          onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
          generateNodeProps={({ node }) => ({
            className:
              node.warning === true && node.used === true
                ? classNames.treeWarningRow
                : node.checked === true && node.used === true
                ? classNames.treeCheckedRow
                : '',
          })}
        />
      </Box>
    </VStack>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
  treeWarningRow: 'file-tree-warning-row',
  treeCheckedRow: 'file-tree-checked-row',
  tree: 'system-configuration-file-tree',
};
