import {
  Heading,
  Text,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useAppSelector } from '../../../store/hooks';
import { selectOrgToEdit } from '../../../store/modalFormSlice';
import { useDeleteUsersFromOrgMutation } from '../../../services/orgEndpoints';
import { isSome } from '../../../config/Maybe';
import { User } from '../../../objects/User';

interface RemoveUserPopupProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
}

export const RemoveUserPopup = ({
  isOpen,
  onClose,
  user,
}: RemoveUserPopupProps): JSX.Element => {
  const orgToEdit = useAppSelector(selectOrgToEdit);
  const [deleteUsersFromOrg] = useDeleteUsersFromOrgMutation();

  const onDelete = async (): Promise<void> => {
    if (isSome(orgToEdit)) {
      await deleteUsersFromOrg({
        orgId: orgToEdit.id,
        userUids: [user.uid],
      });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='md'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>
          <Heading as='h1' textAlign='center' size='lg'>
            Removing this User
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text>
            Are you sure you want to remove this user from the organization?
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%'>
            <Button w='45%' onClick={onClose}>
              Go back
            </Button>
            {'\t'}
            <Button w='45%' variant='delete-confirm' onClick={onDelete}>
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
