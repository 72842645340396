import { z } from 'zod';
import { BaseSchema } from './Base';

// Versions re-labeled as variations
export const ContentVersionEntitySchema = BaseSchema.extend({
  contentId: z.string(),
  content: BaseSchema.extend({
    name: z.string(),
    description: z.string(),
  }).optional(),
  videoName: z.string().optional(),
  pdfName: z.string().optional(),
  pdfStartPage: z.number().optional(),
  tags: z
    .array(
      BaseSchema.extend({
        name: z.string(),
        description: z.string().optional(),
        thumbnailUrl: z.string().optional(),
        tagGroupId: z.string().optional(),
      })
    )
    .optional(),
  tagIds: z.array(z.string()).optional(),
  indexWithinParent: z.number(),
});

export interface ContentVersionFiles {
  videoFile?: File;
  pdfFile?: File;
}

export enum ContentVersionFileTypes {
  VIDEO = 'VIDEO',
  PDF = 'PDF',
}

export type ContentVersionEntity = z.infer<typeof ContentVersionEntitySchema>;

// Frontend-only information for content
export const ContentVersionSchema = ContentVersionEntitySchema;
export type ContentVersion = z.infer<typeof ContentVersionSchema> &
  ContentVersionFiles;

// Information needed to create a new content version
export const InputContentVersionParamsSchema = ContentVersionEntitySchema.omit({
  id: true,
  createdDate: true,
  updatedDate: true,
  indexWithinParent: true,
}).extend({
  // omitted to allow for creating, optional to allow update
  id: z.string().optional(),
  key: z.number().optional(),
  tagIds: z.array(z.string()),
});

export type InputContentVersionParams = z.infer<
  typeof InputContentVersionParamsSchema
> &
  ContentVersionFiles;

export interface UpdateVersionsParams {
  contentId: string;
  inputVersions: InputContentVersionParams[];
}

export const DeleteContentVersionsResultSchema = z.object({
  contentIds: z.array(z.string()),
  affected: z.number().optional(),
});
export type DeleteContentVersionsResult = z.infer<
  typeof DeleteContentVersionsResultSchema
>;

export const contentVersionListToMap = (
  contentVersions: ContentVersion[]
): Record<string, ContentVersion> => {
  return contentVersions.reduce((map, contentVersion) => {
    map[contentVersion.id] = contentVersion;
    return map;
  }, {});
};

export const versionTagsMatchCheckedSelection = (
  version: ContentVersion,
  requiredUngroupedTagIds: string[],
  checkedIds: string[],
  checked: boolean
): boolean => {
  const checkedUngroupedTags = requiredUngroupedTagIds.filter((rut) =>
    checkedIds.some((id) => rut === id)
  );
  const ungroupedTagsThatCannotBeUsed = requiredUngroupedTagIds.filter(
    (rut) => !checkedIds.includes(rut)
  );

  if (version.tags) {
    // if no tags always selected
    if (version.tags.length === 0) {
      return true;
    }
    // if contains an ungrouped tag that is not checked, not selected
    if (
      version.tags.some((tag) =>
        ungroupedTagsThatCannotBeUsed.some((ut) => ut === tag.id)
      )
    ) {
      return false;
    }

    const presentTagGroupIds: string[] = [];
    const usedTags: Array<{
      description?: string | undefined;
      thumbnailUrl?: string | undefined;
      tagGroupId?: string | undefined;
      id: string;
      createdDate: string;
      updatedDate: string;
      name: string;
    }> = [];

    version.tags.forEach((tag) => {
      if (!presentTagGroupIds.some((id) => id === tag.tagGroupId)) {
        if (tag.tagGroupId) {
          presentTagGroupIds.push(tag.tagGroupId);
        }
      }
      if (checkedIds.some((id) => id === tag.id) && checked) {
        usedTags.push(tag);
      }
    });
    for (const groupId of presentTagGroupIds) {
      const usedTagsForGroup = usedTags.filter(
        (usedTag) => usedTag.tagGroupId === groupId
      );
      // if does not contain a selected tag for a present group, not selected
      if (usedTagsForGroup.length < 1) {
        return false;
      }
    }
    if (checkedUngroupedTags.length > 0) {
      // if ungrouped tags are present in siblings and ungrouped tag is checked,
      //  must contain that tag or not selected
      if (
        !checkedUngroupedTags.every((rut) =>
          usedTags.some((tag) => tag.id === rut)
        )
      ) {
        return false;
      }
    }
    // if we meet all above requirements, version is 'used'
    return true;
  }
  // can't run the logic without tags, you lose
  return false;
};
