import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { createBrowserHistory, History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { vidualsApi } from '../services/vidualsApi';
import folderReducer from './folderSlice';
import contentReducer from './contentSlice';
import contentVersionReducer from './contentVersionSlice';
import authReducer from './authSlice';
import modalFormReducer from './modalFormSlice';
import tagReducer from './tagSlice';
import tagGroupReducer from './tagGroupSlice';
import organizationReducer from './organizationSlice';
import productGroupReducer from './productGroupSlice';
import templateReducer from './templateSlice';
import qTemplateReducer from './qTemplateSlice';
import systemReducer from './systemSlice';
import endUserReducer from './endUserSlice';
import toastSlice from './toastSlice';
import treeItemSlice from './treeItemSlice';
import questionnaireConfigurationSlice from './questionnaireConfigurationSlice';
import folderandContentsWithTagsSlice from './foldersAndContentsWithTagsSlice';
import questionnaireAnswers from './questionnaireAnswers';
import qSystemSlice from './QSystemSlice';

export const history: History = createBrowserHistory();

export const store = configureStore({
  reducer: {
    folders: folderReducer,
    contents: contentReducer,
    contentVersions: contentVersionReducer,
    endUser: endUserReducer,
    tag: tagReducer,
    auth: authReducer,
    modalForm: modalFormReducer,
    organizations: organizationReducer,
    productGroups: productGroupReducer,
    tagGroups: tagGroupReducer,
    template: templateReducer,
    qTemplate: qTemplateReducer,
    system: systemReducer,
    toast: toastSlice,
    treeItems: treeItemSlice,
    qSystem: qSystemSlice,
    questionnaireConfiguration: questionnaireConfigurationSlice,
    folderAndContentsWithTags: folderandContentsWithTagsSlice,
    questionnaireAnswers: questionnaireAnswers,
    [vidualsApi.reducerPath]: vidualsApi.reducer,

    // https://github.com/reduxjs/redux-toolkit/issues/506
    // https://github.com/supasate/connected-react-router/issues/416
    // @ts-expect-error connected-react-router's types are wrong
    router: connectRouter(history),
  },

  // Adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(vidualsApi.middleware)
      .concat(routerMiddleware(history)),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
