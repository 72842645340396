import { FormLabel, Stack, Textarea, InputGroup } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface InputProps {
  placeholder: string;
  register: UseFormRegisterReturn;
  text?: string;
}

export const TextAreaInput = ({
  placeholder,
  register,
}: InputProps): JSX.Element => {
  return (
    <Stack display='flex' w='100%'>
      <FormLabel>{placeholder}</FormLabel>

      <InputGroup w='100%'>
        <Textarea
          {...register}
          w='100%'
          type='text'
          placeholder={placeholder}
          size='sm'
          resize={'none'}
        />
      </InputGroup>
    </Stack>
  );
};
