// import { FileTree } from '../../sharedComponents/folders/FileTree';
import { ContentHeader } from '../../sharedComponents/contentHeader/ContentHeader';
import { SideNavBar } from '../../sharedComponents/sideNavBar/SideNavBar';
import './FoldersPage.scss';
import {
  IconButton,
  Stack,
  Flex,
  Spacer,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import 'firebase/app';
import 'firebase/auth';
import { useUpdateContentMutation } from '../../services/contentsEndpoints';
import { UpdateContentParams } from '../../objects/Content';
import { appValues } from '../../config/constants';
import { UserIcon } from '../../config/icons';
import { ProfilePopup } from '../../sharedComponents/modal/ProfilePopup';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { isSome } from '../../config/Maybe';
import { InputContentVersionParams } from '../../objects/ContentVersion';
import { useUpdateContentVersionMutation } from '../../services/contentVersionsEndpoints';
import {
  addFailedFileToUpload,
  addFileToUpload,
  addSucceededFileUpload,
  removeFileToUpload,
} from '../../store/toastSlice';
import { FileTreePaginated } from '../../sharedComponents/folders/FileTreePaginated';
import {
  selectVersionsToDelete,
  setVersionsToDelete,
} from '../../store/contentVersionSlice';
import { deleteFolderContentOrVersion } from '../../store/treeItemSlice';
import { ContentInitial } from '../../sharedComponents/folders/ContentInitial';
import { uploadFile } from '../../config/fileupload';
import { firebaseApp } from '../../config/firebase.config';

export const FoldersPage = (): JSX.Element => {
  const [updateContent] = useUpdateContentMutation();
  const [updateContentVersions] = useUpdateContentVersionMutation();
  const dispatch = useAppDispatch();

  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const versionsToDelete = useAppSelector(selectVersionsToDelete);

  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();

  const updateContentFunc = async (
    id: string,
    content: UpdateContentParams
  ): Promise<void> => {
    await updateContent({
      id: id,
      content: {
        name: content.name,
        description: content.description,
        parentFolderId: content.parentFolderId,
      },
    }).unwrap();
  };

  const updateContentVersionsFunc = async (
    contentId: string,
    versions: InputContentVersionParams[]
  ): Promise<void> => {
    const versionsToCreate: InputContentVersionParams[] = [];
    for (const version of versions) {
      if (isSome(version.pdfFile)) {
        try {
          dispatch(addFileToUpload(version.pdfFile.name));
          await uploadFile(
            firebaseApp.auth().currentUser,
            selectedProductGroup,
            version.pdfFile
          );
          version.pdfName = version.pdfFile.name;
          dispatch(addSucceededFileUpload(version.pdfFile.name));
        } catch (err) {
          console.log(err);
          dispatch(addFailedFileToUpload(version.pdfFile.name));
          version.pdfName = undefined;
        } finally {
          dispatch(removeFileToUpload(version.pdfFile.name));
        }
      }

      if (isSome(version.videoFile)) {
        try {
          dispatch(addFileToUpload(version.videoFile.name));
          await uploadFile(
            firebaseApp.auth().currentUser,
            selectedProductGroup,
            version.videoFile
          );
          version.videoName = version.videoFile.name;
          dispatch(addSucceededFileUpload(version.videoFile.name));
        } catch (err) {
          console.log(err);
          dispatch(addFailedFileToUpload(version.videoFile.name));
          version.videoName = undefined;
        } finally {
          dispatch(removeFileToUpload(version.videoFile.name));
        }
      }

      versionsToCreate.push({
        id: version.id ?? undefined,
        contentId,
        tagIds: version.tagIds ?? [],
        videoName: version.videoName,
        pdfName: version.pdfName,
        pdfStartPage: version.pdfStartPage ?? undefined,
      });
    }

    if (isSome(selectedProductGroup)) {
      await updateContentVersions({
        contentId,
        inputVersions: versionsToCreate,
      });
      if (versionsToDelete.length > 0) {
        dispatch(
          deleteFolderContentOrVersion(
            versionsToDelete.map((version) => version.id)
          )
        );
        dispatch(setVersionsToDelete([]));
      }
    }
  };

  return (
    <Flex>
      <SideNavBar />
      <Flex className='main' ml={appValues.SIDEBAR_WIDTH}>
        <Stack w='100%' h='100%'>
          <HStack w='100%'>
            <ContentHeader />
            <Spacer />
            <IconButton
              aria-label='Profile'
              border='none'
              icon={<UserIcon className='icon' w={8} h={8} />}
              onClick={onOpenProfilePopup}
            />
            <ProfilePopup
              isOpen={isOpenProfilePopup}
              onClose={onCloseProfilePopup}
            />
          </HStack>
          <ContentInitial />
          <FileTreePaginated
            updateContentFunc={updateContentFunc}
            updateContentVersions={updateContentVersionsFunc}
          />
        </Stack>
      </Flex>
    </Flex>
  );
};
