/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template } from '../objects/Template';
import { RootState } from './store';

export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    templates: [] as Template[],
  },
  reducers: {
    setTemplates: (
      state,
      { payload: templates }: PayloadAction<Template[]>
    ) => {
      state.templates = templates;
    },
    updateTemplate: (
      state,
      { payload: updatedTemplate }: PayloadAction<Template>
    ) => {
      state.templates = state.templates.map((template) =>
        template.id === updatedTemplate.id ? updatedTemplate : template
      );
    },
    addTemplate: (state, { payload: template }: PayloadAction<Template>) => {
      state.templates.push(template);
    },
    deleteTemplate: (state, { payload: deletedId }: PayloadAction<string>) => {
      return {
        ...state,
        templates: state.templates.filter(
          (template) => template.id !== deletedId
        ),
      };
    },
    removeFolderFromTemplate: (
      state,
      { payload: deletedFolderId }: PayloadAction<string>
    ) => {
      return {
        ...state,
        templates: state.templates.map((template) => {
          return {
            ...template,
            templateFolders: template.templateFolders.filter(
              (tf) => tf.folderId !== deletedFolderId
            ),
          };
        }),
      };
    },
    removeContentFromTemplate: (
      state,
      { payload: deletedContentIds }: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        templates: state.templates.map((template) => {
          return {
            ...template,
            templateContents: template.templateContents.filter(
              (tc) => !deletedContentIds.includes(tc.contentId)
            ),
          };
        }),
      };
    },
    removeTagFromTemplate: (
      state,
      { payload: deletedTagId }: PayloadAction<string>
    ) => {
      return {
        ...state,
        templates: state.templates.map((template) => {
          return {
            ...template,
            templateTags: template.templateTags.filter(
              (tt) => tt.tagId !== deletedTagId
            ),
          };
        }),
      };
    },
  },
});

export const selectTemplates = (state: RootState): Template[] =>
  state.template.templates;

export const {
  setTemplates,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  removeFolderFromTemplate,
  removeContentFromTemplate,
  removeTagFromTemplate,
} = templateSlice.actions;

export default templateSlice.reducer;
