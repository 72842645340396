import { z } from 'zod';
import { BaseSchema } from './Base';

export const FileTreeItemTypeSchema = z.enum([
  'FOLDER',
  'CONTENT',
  'CONTENT_VERSION',
] as const);

export type FileTreeItemType = z.infer<typeof FileTreeItemTypeSchema>;

// Common interface for anything showing up in the folders/content list
export const FileTreeItemSchema = BaseSchema.extend({
  // https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes-func.html#discriminated-unions
  type: FileTreeItemTypeSchema,
  name: z.string(),
  indexWithinParent: z.number(),
  parent: BaseSchema.extend({
    name: z.string(),
    indexWithinParent: z.number(),
    isRoot: z.boolean(),
    productGroupId: z.string(),
  }),
  parentFolderId: z.string().optional(),
  checked: z.boolean(),
});

export type FileTreeItem = z.infer<typeof FileTreeItemSchema>;

export const toFileTreeItemsMap = (
  items: FileTreeItem[]
): Record<string, FileTreeItem> => {
  return items.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
};
