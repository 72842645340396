/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  ContentVersion,
  InputContentVersionParams,
  UpdateVersionsParams,
} from '../objects/ContentVersion';
import { System } from '../objects/System';
import { addVersionToTree, updateVersions } from '../store/treeItemSlice';
import {
  ContentsWithTagsList,
  FoldersWithTagsList,
  IOnQueryAPI,
  vidualsApi,
} from './vidualsApi';

const addCreatedVersionToTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addVersionToTree(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateVersionOnTree = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateVersions(data));
  } catch (err) {
    console.trace(err);
  }
};

export const contentVersionsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllContentVersionsByContentId: builder.query<ContentVersion[], string>({
      query: (contentId) => `contentsVersions/${contentId}`,
    }),
    createContentVersion: builder.mutation<
      ContentVersion,
      InputContentVersionParams[]
    >({
      query: (contentVersion) => {
        return {
          url: `contentVersions`,
          method: 'POST',
          body: contentVersion,
        };
      },
      invalidatesTags: [FoldersWithTagsList, ContentsWithTagsList],
      onQueryStarted: addCreatedVersionToTree,
    }),
    updateContentVersion: builder.mutation<
      ContentVersion[],
      UpdateVersionsParams
    >({
      query: (body) => {
        return {
          url: `contentVersions`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: [FoldersWithTagsList, ContentsWithTagsList],
      onQueryStarted: updateVersionOnTree,
    }),
    getSystemsUsingVersions: builder.mutation<
      System[],
      {
        versionIds: string[];
      }
    >({
      query: (body) => {
        return {
          url: `contentVersions/systems`,
          method: 'PUT',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetAllContentVersionsByContentIdQuery,
  useCreateContentVersionMutation,
  useUpdateContentVersionMutation,
  useGetSystemsUsingVersionsMutation,
} = contentVersionsApi;
