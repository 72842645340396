import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Stack,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  FormLabel,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { isSome } from '../../../config/Maybe';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import {
  InputTagGroupParams,
  TagGroup,
  UpdateTagGroupParams,
} from '../../../objects/TagGroup';
import {
  useUpdateTagGroupMutation,
  useDeleteTagGroupMutation,
} from '../../../services/tagGroupEndpoints';
import { setTagGroupToEdit } from '../../../store/modalFormSlice';
import { WarningModal } from '../WarningModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { appColors } from '../../../config/constants';

interface EditTagGroupPopupProps {
  isOpen: boolean;
  tagGroup: TagGroup;
}

export const EditTagGroupPopup = ({
  isOpen,
  tagGroup,
}: EditTagGroupPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const [updateTagGroup] = useUpdateTagGroupMutation();
  const [deleteTagGroup, deleteResult] = useDeleteTagGroupMutation();

  useEffect(() => {
    const { error } = deleteResult;
    if (error !== undefined) {
      alert('An error occurred, please try again');
    } else if (deleteResult.isSuccess) {
      reset();
      dispatch(setTagGroupToEdit(undefined));
    }
  }, [deleteResult]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputTagGroupParams>();

  watch();

  const [showDeleteErrorMessage, setShowDeleteErrorMessage] =
    useState<boolean>(false);

  const onSubmit = async (tagToCreate: InputTagGroupParams): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      tagToCreate.productGroupId = selectedProductGroup.id;

      await updateTagGroup({ id: tagGroup.id, ...tagToCreate }).unwrap();
    }

    reset();
    dispatch(setTagGroupToEdit(undefined));
  };

  const deleteOrErrorMessage = (): void => {
    if (isSome(tagGroup.tags)) {
      setShowDeleteErrorMessage(true);
    } else {
      onOpenDeleteConfirmPopup();
    }
  };

  const onDelete = async (): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const tagGroupToDelete: UpdateTagGroupParams = {
        id: tagGroup.id,
        name: tagGroup.name,
        productGroupId: selectedProductGroup.id,
      };

      await deleteTagGroup(tagGroupToDelete).unwrap();
    }
  };

  const {
    isOpen: isOpenDeleteConfirmPopup,
    onOpen: onOpenDeleteConfirmPopup,
    onClose: onCloseDeleteConfirmPopup,
  } = useDisclosure();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dispatch(setTagGroupToEdit(undefined))}
      closeOnOverlayClick={false}
    >
      {isOpenDeleteConfirmPopup && (
        <WarningModal
          isOpen={isOpenDeleteConfirmPopup}
          onClose={onCloseDeleteConfirmPopup}
          title={'Delete this tag group?'}
          message={'This will permanently delete the tag group.'}
          onCancel={onCloseDeleteConfirmPopup}
          onConfirm={async () => await onDelete()}
        />
      )}
      <ModalOverlay />
      <ModalContent w='458px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <ModalHeader pt={15}>
              <Heading as='h1' size='lg' textAlign='center'>
                Edit tag group
              </Heading>
            </ModalHeader>
            <Stack pt={15}>
              <FormLabel>Name</FormLabel>
              <Input
                defaultValue={tagGroup.name}
                backgroundColor='#ffffff'
                w='100%'
                type={'text'}
                placeholder='Tag Group'
                {...register('name', {
                  required: {
                    value: true,
                    message: 'You must enter a name',
                  },
                })}
              />
              {isSome(errors.name) && errors.name.type === 'required' && (
                <Text variant={'error'}>
                  You must enter a tag group name. Please try again.
                </Text>
              )}
              <Button
                className={classNames.contentButton}
                justifyContent='center'
                w='100%'
                color={appColors.RED}
                onClick={deleteOrErrorMessage}
                leftIcon={
                  <FontAwesomeIcon
                    className={(classNames.icon, classNames.faIcon)}
                    icon={faTrashAlt}
                  />
                }
              >
                Delete this tag group
              </Button>
              {showDeleteErrorMessage && (
                <Text variant={'error'}>
                  Tag groups must be empty to delete.
                </Text>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack className='space-between width100'>
              <Button
                w='45%'
                onClick={() => dispatch(setTagGroupToEdit(undefined))}
              >
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='45%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const classNames = {
  contentButton: 'content-button',
  icon: 'icon',
  faIcon: 'faIcon',
};
