import { Tag } from './Tag';
import { TagGroup } from './TagGroup';

export enum TagTreeType {
  Group = 'Group',
  Tag = 'Tag',
}

export interface TagOrTagGroup {
  item: Tag | TagGroup;
  type: TagTreeType;
}
