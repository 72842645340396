import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Heading,
  Button,
  Input,
  Text,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  CloseButton,
  useDisclosure,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  InputContentParams,
  UpdateContentParams,
} from '../../../objects/Content';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Folder } from '../../../objects/Folder';
import { TextInput } from '../../../components/TextInput';
import { isSome } from '../../../config/Maybe';
import {
  selectContentToEdit,
  setContentToEdit,
} from '../../../store/modalFormSlice';
import { DeleteContentPopup } from '../DeleteContentPopup';
import {
  ContentVersion,
  InputContentVersionParams,
} from '../../../objects/ContentVersion';
import { ContentVersionForm } from './ContentVersionForm';
import { EditContentVersionForm } from './EditContentVersionForm';
import {
  selectVersionsToDelete,
  setVersionsToDelete,
} from '../../../store/contentVersionSlice';
import { selectVersionsForContentInTree } from '../../../store/treeItemSlice';
import { FoldersList } from '../FoldersList';

interface EditContentPopupProps {
  updateContentVersions: (
    contentId: string,
    versions: InputContentVersionParams[]
  ) => void;
  updateContentFunc: (id: string, content: UpdateContentParams) => void;
}

let versionCounter = 1;

export const EditContentPopup = ({
  updateContentFunc,
  updateContentVersions,
}: EditContentPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const contentToEdit = useAppSelector(selectContentToEdit);
  const currentVersions = useAppSelector((state) =>
    selectVersionsForContentInTree(state, contentToEdit?.id ?? '')
  );

  const versionsToDelete = useAppSelector(selectVersionsToDelete);

  const [parentFolder, setParentFolder] = useState<Folder>();
  const [newVersions, setNewVersions] = useState<InputContentVersionParams[]>(
    []
  );
  const [existingVersions, setExistingVersions] = useState<ContentVersion[]>(
    []
  );
  const [pdfFilesToUpload, setPdfsToUpload] = useState<File[]>([]);
  const [videoFilesToUpload, setVideosToUpload] = useState<File[]>([]);
  const [fileChangesString, setFileUploadsModified] = useState<string>('');

  const duplicateVersion = (
    versionToDuplicate: InputContentVersionParams
  ): void => {
    setNewVersions((current) => [
      ...current,
      {
        contentId: versionToDuplicate.contentId,
        tagIds: versionToDuplicate.tagIds,
        videoName: versionToDuplicate.videoName,
        videoFile: versionToDuplicate.videoFile,
        pdfName: versionToDuplicate.pdfName,
        pdfFile: versionToDuplicate.pdfFile,
        pdfStartPage: versionToDuplicate.pdfStartPage,
        key: versionCounter,
      },
    ]);
    versionCounter++;
  };

  const removeVersion = (index: number): void => {
    const updatedNewVersions = [
      ...newVersions.slice(0, index),
      ...newVersions.slice(index + 1),
    ];
    setNewVersions(updatedNewVersions);
  };

  const removeExistingVersion = (index: number): void => {
    dispatch(
      setVersionsToDelete([...versionsToDelete, existingVersions[index]])
    );
    const updatedVersions = [
      ...existingVersions.slice(0, index),
      ...existingVersions.slice(index + 1),
    ];
    setExistingVersions(updatedVersions);
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<InputContentParams>();

  useEffect(() => {
    if (isSome(contentToEdit)) {
      if (isSome(contentToEdit.parent)) {
        setParentFolder(contentToEdit.parent);
      }

      // when the content changes, we get a new set of existing versions
      // These are created as the versions connected to content to edit are
      // not extensible, so I can't add tagIds to track the tag changes
      const existingVersionsFromContent: ContentVersion[] = [];
      currentVersions.map((version) => {
        if (isSome(version.tags)) {
          const tagIds = version.tags.map((version) => version.id);
          existingVersionsFromContent.push({ ...version, tagIds });
        }
        return version;
      });
      setExistingVersions(existingVersionsFromContent);
    }
    setParentFolder(undefined);
  }, [contentToEdit]);

  const onSubmit = async (formContent: InputContentParams): Promise<void> => {
    dispatch(setContentToEdit(undefined));

    formContent.parentFolderId =
      parentFolder?.id ?? contentToEdit?.parentFolderId;

    if (isSome(contentToEdit)) {
      // udpate content itself
      updateContentFunc(contentToEdit.id, {
        ...formContent,
        name: formContent.name,
      });

      // Any versions not in this array will be removed from the content
      const versionsToUpdate: InputContentVersionParams[] = [];
      // add any newly created versions
      newVersions.map(async (version) => {
        versionsToUpdate.push({
          contentId: contentToEdit.id,
          tagIds: version.tagIds ?? [],
          videoName: version.videoName ?? version.videoFile?.name,
          pdfName: version.pdfName ?? version.pdfFile?.name,
          videoFile: version.videoFile,
          pdfFile: version.pdfFile,
          pdfStartPage: version.pdfStartPage ?? undefined,
        });
      });

      // update any existing versions
      existingVersions.map(async (version) => {
        versionsToUpdate.push({
          id: version.id,
          contentId: contentToEdit.id,
          tagIds: version.tagIds ?? [],
          videoName: version.videoName ?? version.videoFile?.name,
          pdfName: version.pdfName ?? version.pdfFile?.name,
          videoFile: version.videoFile,
          pdfFile: version.pdfFile,
          pdfStartPage: version.pdfStartPage ?? undefined,
        });
      });
      updateContentVersions(contentToEdit.id, versionsToUpdate);
    }

    setNewVersions([]);
    setParentFolder(undefined);
    reset();
  };

  const contentNameRegister = register('name', {
    required: true,
    value: contentToEdit?.name ?? '',
  });
  const contentNameText = watch('name');

  const contentDescriptionRegister = register('description', {
    value: contentToEdit?.description ?? '',
  });
  const contentDescriptionText = watch('description');

  const btnRef = useRef<HTMLButtonElement>(null);

  const handleClosePopup = (): void => {
    const close = window.confirm(
      'Are you sure you wish close this? All progress will be lost.'
    );

    if (close) {
      dispatch(setContentToEdit(undefined));
      dispatch(setVersionsToDelete([]));
    }
  };

  const {
    isOpen: isOpenDeleteContentPopup,
    onOpen: onOpenDeleteContentPopup,
    onClose: onCloseDeleteContentPopup,
  } = useDisclosure();

  const parentFolderId =
    parentFolder?.id ??
    contentToEdit?.parentFolderId ??
    contentToEdit?.parent.id ??
    '';

  useEffect(() => {
    const pdfsBeingUploaded: File[] = newVersions
      .map((version) => version.pdfFile)
      .filter((file) => file !== undefined) as File[];
    const pdfsBeingUploadedToExisting: File[] = existingVersions
      .map((version) => version.pdfFile)
      .filter((file) => file !== undefined) as File[];
    setPdfsToUpload([...pdfsBeingUploaded, ...pdfsBeingUploadedToExisting]);

    const videosBeingUploaded: File[] = newVersions
      .map((version) => version.videoFile)
      .filter((file) => file !== undefined) as File[];
    const videosBeingUploadedToExisting: File[] = newVersions
      .map((version) => version.videoFile)
      .filter((file) => file !== undefined) as File[];
    setVideosToUpload([
      ...videosBeingUploaded,
      ...videosBeingUploadedToExisting,
    ]);
  }, [newVersions, existingVersions, fileChangesString]);

  const modifyFileUploads = (file: File | undefined): void => {
    setFileUploadsModified((current) =>
      current.concat(file !== undefined ? file.name : 'undefined')
    );
  };

  return (
    <>
      <Drawer
        isOpen={true}
        placement='right'
        onClose={() => dispatch(setContentToEdit(undefined))}
        finalFocusRef={btnRef}
        size='lg'
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent width={400} pr={0}>
            <CloseButton
              alignSelf='flex-end'
              mt='10px'
              mr='10px'
              onClick={handleClosePopup}
            />
            <DrawerHeader align='center'>Edit content</DrawerHeader>
            <DrawerBody>
              <Stack>
                <Heading as='h1' size='md'>
                  Basic Information
                </Heading>
                <TextInput
                  placeholder='Content name'
                  register={contentNameRegister}
                  text={contentNameText}
                />
                {isSome(errors.name) && errors.name.type === 'required' && (
                  <Text variant={'error'}>
                    You must enter a content name. Please try again.
                  </Text>
                )}
                <TextInput
                  placeholder='Description (optional)'
                  register={contentDescriptionRegister}
                  text={contentDescriptionText}
                />
                <Heading as='h2' size='sm'>
                  Location
                </Heading>
                <FoldersList
                  selectedFolderId={parentFolderId}
                  setCurrentFolder={(folder: Folder) => setParentFolder(folder)}
                />
                <Heading as='h1' size='md'>
                  Variations
                </Heading>
                <Button
                  onClick={() => {
                    setNewVersions((current) => [
                      ...current,
                      { contentId: '', tagIds: [], key: versionCounter },
                    ]);
                    versionCounter++;
                  }}
                >
                  Add new variation
                </Button>
                <Text>
                  Every variation must have either a video or PDF (or both).
                </Text>
                {existingVersions.length > 0 &&
                  existingVersions.map((existingVersion, index) => (
                    <EditContentVersionForm
                      key={`edit version form: ${existingVersion.id}`}
                      version={existingVersion}
                      index={index}
                      removeVersion={removeExistingVersion}
                      duplicateVersion={duplicateVersion}
                      pdfsBeingUploaded={pdfFilesToUpload}
                      videosBeingUploaded={videoFilesToUpload}
                      triggerModifiedFileUploads={modifyFileUploads}
                    />
                  ))}
                {newVersions.map(
                  (version, index): JSX.Element => (
                    <React.Fragment key={`add version form frag:${index}`}>
                      {/* key is added just to get delete to show the right thing */}
                      {isSome(version.key) && (
                        <ContentVersionForm
                          key={`edit version form: ${version.key}`}
                          version={version}
                          index={index}
                          removeVersion={removeVersion}
                          duplicateVersion={duplicateVersion}
                          pdfsBeingUploaded={pdfFilesToUpload}
                          videosBeingUploaded={videoFilesToUpload}
                          triggerModifiedFileUploads={modifyFileUploads}
                        />
                      )}
                    </React.Fragment>
                  )
                )}
                {isSome(errors.name) && errors.name.type === 'required' && (
                  <Text className='error'>You must enter a folder name.</Text>
                )}
              </Stack>
              <Tooltip
                label={
                  'A piece of content cannot be deleted if there are variations within it'
                }
                aria-label='edit configuration'
                isDisabled={
                  newVersions.length === 0 && existingVersions.length === 0
                }
              >
                <Box w='100%'>
                  <Button
                    w='100%'
                    mt={10}
                    variant='delete'
                    onClick={onOpenDeleteContentPopup}
                    leftIcon={
                      <FontAwesomeIcon
                        className={(classNames.icon, classNames.faIcon)}
                        icon={faTrashAlt}
                      />
                    }
                    disabled={
                      newVersions.length > 0 || existingVersions.length > 0
                    }
                  >
                    Delete this content
                  </Button>
                </Box>
              </Tooltip>
            </DrawerBody>
            <DrawerFooter justifyContent='space-between'>
              <Button w='45%' onClick={handleClosePopup}>
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='50%' />
            </DrawerFooter>
          </DrawerContent>
        </form>
        <DeleteContentPopup
          isOpen={isOpenDeleteContentPopup}
          onClose={onCloseDeleteContentPopup}
          onCloseParent={() => dispatch(setContentToEdit(undefined))}
        />
      </Drawer>
    </>
  );
};

const classNames = {
  row: 'row',
  folder: 'folder',
  icon: 'icon',
  faIcon: 'faIcon',
};
