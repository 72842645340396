import { ProdGroup } from './ProdGroup';

export enum SelectionCheckableOptions {
  Checked = 'Checked',
  UnChecked = 'UnChecked',
  Unavailable = 'Unavailable',
}

export interface SystemFolder {
  selection: SelectionCheckableOptions;
  folderId: string;
}

export interface SystemContent {
  selection: SelectionCheckableOptions;
  contentId: string;
}

export interface SystemTag {
  selection: SelectionCheckableOptions;
  tagId: string;
}

// Re-named Pre-Configured
export interface System {
  id: string;
  updatedDate: string;
  description: string;
  lastAppliedTemplate?: string | null;
  urlExtension: string;
  serialNumber: string;
  clientName: string;
  warrantyStart: string;
  warrantyEnd: string;
  productGroup: ProdGroup;
  systemFolders: SystemFolder[];
  systemTags: SystemTag[];
  systemContents: SystemContent[];
}

export interface InputSystemParams {
  description: string;
  urlExtension: string;
  serialNumber: string;
  clientName: string;
  productGroupId: string;
  warrantyStart: string;
  warrantyEnd: string;
}

export interface EditSystemParams {
  id: string;
  description: string;
  serialNumber: string;
  clientName: string;
  warrantyStart: string;
  warrantyEnd: string;
}

export interface DuplicateSystemParams {
  systemId: string;
  urlExtension: string;
  serialNumber: string;
}

export interface ISystemFoldersContentsAndTags {
  id: string;
  folders: SystemFolder[];
  contents: SystemContent[];
  tags: SystemTag[];
}

// A system created by answering a questionnaire
export interface QSystem {
  productGroupId: string;

  checkedFolderIds: string[];
  checkedTagIds: string[];
  checkedContentIds: string[];
}
