import {
  Box,
  HStack,
  Image,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { appValues } from '../../../config/constants';
import { getS3BucketName } from '../../../config/S3Client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Customizations } from '../../../objects/Customizations';
import { isSome } from '../../../config/Maybe';
import { HelpPopup } from '../../../sharedComponents/modal/endUserView/HelpPopup';
import { QSystem } from '../../../objects/System';
interface QSystemHeaderProps {
  qSystem: QSystem;
  customizations?: Customizations;
}

export const QSystemHeader = ({
  qSystem,
  customizations,
}: QSystemHeaderProps): JSX.Element => {
  //   const {
  //     isOpen: isOpenInfoPopup,
  //     onOpen: onOpenInfoPopup,
  //     onClose: onCloseInfoPopup,
  //   } = useDisclosure();

  const {
    isOpen: isOpenHelpPopup,
    onOpen: onOpenHelpPopup,
    onClose: onCloseHelpPopup,
  } = useDisclosure();

  const buildFileGetUrl = (fileName: string): string => {
    const bucketName = getS3BucketName();
    const productGroupPrefix: string = qSystem?.productGroupId ?? '';
    const encodedFileName = encodeURIComponent(fileName);

    const url = `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/${encodedFileName}/${encodedFileName}`;

    return url;
  };

  const primaryTextColor = isSome(customizations)
    ? '#' + customizations?.primaryTextColor
    : 'white';

  return (
    <HStack
      bg={'var(--vid-customizations-secondary-color)'}
      h={appValues.END_USER_HEADER_HEIGHT}
      w='100%'
      px={10}
      justifyContent='space-between'
      bgImage={buildFileGetUrl(customizations?.bannerImageName ?? '')}
      zIndex={4}
    >
      <Box>
        <Image
          src={buildFileGetUrl(customizations?.logoImageName ?? '')}
          maxH={appValues.END_USER_HEADER_HEIGHT}
          maxW='30vw'
        />
      </Box>
      <HStack>
        {/* <IconButton
          borderRadius='999px'
          borderWidth='2px'
          borderColor={primaryTextColor}
          color={primaryTextColor}
          _hover={{
            bg: '#80808080',
          }}
          aria-label='Information'
          icon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faInfo}
            />
          }
          onClick={() => {
            console.log('info button');
          }}
        /> */}
        <IconButton
          borderRadius='999px'
          borderWidth='2px'
          borderColor={primaryTextColor}
          color={primaryTextColor}
          _hover={{
            bg: '#80808080',
          }}
          aria-label='Help'
          icon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faQuestion}
            />
          }
          onClick={onOpenHelpPopup}
        />
        {/* <InfoPopup
          system={system}
          customizations={customizations}
          isOpen={isOpenInfoPopup}
          onClose={onCloseInfoPopup}
        /> */}
        <HelpPopup
          customizations={customizations}
          isOpen={isOpenHelpPopup}
          onClose={onCloseHelpPopup}
        />
      </HStack>
    </HStack>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
