/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { Content } from '../objects/Content';
import { Folder } from '../objects/Folder';
import {
  InputSystemParams,
  ISystemFoldersContentsAndTags,
  DuplicateSystemParams,
  EditSystemParams,
  System,
} from '../objects/System';
import {
  addSystem,
  deleteSystem,
  setSystems,
  updateSystem,
} from '../store/systemSlice';
import { IOnQueryAPI, vidualsApi } from './vidualsApi';

const updateSystemsState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setSystems(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateSystemOnSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateSystem(data));
  } catch (err) {
    console.trace(err);
  }
};

const addSystemToSlice = async (_arg: any, api: IOnQueryAPI): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addSystem(data));
  } catch (err: any) {
    console.trace(err);
    throw err;
  }
};

const deleteSystemFromSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(deleteSystem(data));
  } catch (err) {
    console.trace(err);
  }
};

export const systemsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemsByProductGroupId: builder.query<System[], string>({
      query: (pgid) => `systems/productGroup/${pgid}`,
      onQueryStarted: updateSystemsState,
    }),
    getSystemByUrl: builder.query<
      System,
      { systemUrl: string; pgUrl: string; orgUrl: string }
    >({
      query: ({ systemUrl, pgUrl, orgUrl }) =>
        `systems/${systemUrl}/productGroup/${pgUrl}/organization/${orgUrl}`,
    }),
    getSystemBySerialNumber: builder.query<
      System,
      { serialNumber: string; pgId: string }
    >({
      query: ({ serialNumber, pgId }) =>
        `systems/serialNumber/${serialNumber}/productGroup/${pgId}`,
    }),
    getSystemById: builder.query<System, { systemId: string }>({
      query: ({ systemId }) => `systems/${systemId}`,
    }),
    getSystemFoldersChildrenById: builder.query<Folder[], { systemId: string }>(
      {
        query: ({ systemId }) => `systems/${systemId}/foldersChildren`,
      }
    ),
    getSystemContentsById: builder.query<Content[], { systemId: string }>({
      query: ({ systemId }) => `systems/${systemId}/systemContents`,
    }),
    createSystem: builder.mutation<System, InputSystemParams>({
      query: (body) => {
        return {
          url: `systems`,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: addSystemToSlice,
    }),
    editSystem: builder.mutation<System, EditSystemParams>({
      query: (body) => {
        return {
          url: `systems`,
          method: 'PUT',
          body,
        };
      },
      onQueryStarted: updateSystemOnSlice,
    }),
    duplicateSystem: builder.mutation<System, DuplicateSystemParams>({
      query: (body) => {
        return {
          url: `systems/duplicate`,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: addSystemToSlice,
    }),
    setSystemSelections: builder.mutation<
      System,
      ISystemFoldersContentsAndTags
    >({
      query: (body) => {
        return {
          url: `systems/selections`,
          method: 'Put',
          body,
        };
      },
      onQueryStarted: updateSystemOnSlice,
    }),
    applyTemplateToSystem: builder.mutation<
      System,
      { systemId: string; templateId: string }
    >({
      query: (body) => {
        return {
          url: `systems/applyTemplate`,
          method: 'Put',
          body,
        };
      },
      onQueryStarted: updateSystemOnSlice,
    }),
    deleteSystem: builder.mutation<string, { id: string }>({
      query: (body) => {
        return {
          url: `systems`,
          method: 'DELETE',
          body: body,
        };
      },
      onQueryStarted: deleteSystemFromSlice,
    }),
  }),
});

export const {
  useLazyGetSystemsByProductGroupIdQuery,
  useGetSystemByUrlQuery,
  useLazyGetSystemBySerialNumberQuery,
  useGetSystemFoldersChildrenByIdQuery,
  useGetSystemContentsByIdQuery,
  useCreateSystemMutation,
  useEditSystemMutation,
  useDuplicateSystemMutation,
  useSetSystemSelectionsMutation,
  useApplyTemplateToSystemMutation,
  useDeleteSystemMutation,
  useGetSystemByIdQuery,
} = systemsApi;
