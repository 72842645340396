import { Box, HStack, Image, IconButton, Text } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ImagePreviewProps {
  fileUrl: string;
  imageLabel: string;
  imageName: string;
  removeFunc: () => void;
}
export const ImagePreview = ({
  fileUrl,
  imageLabel,
  imageName,
  removeFunc,
}: ImagePreviewProps): JSX.Element => {
  return (
    <Box>
      <Text variant='label'>{imageLabel} image</Text>
      <Box
        w='100%'
        h={40}
        borderColor='gray.300'
        borderWidth={1}
        borderRadius={4}
        boxShadow='0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)'
        justifyContent='center'
      >
        <HStack
          w='100%'
          h='100%'
          justifyContent='space-between'
          alignItems='center'
        >
          <Image src={fileUrl} w={40} h='100%' fit='contain' />
          <Text color='viduals.greenDarker' fontWeight={700}>
            {imageName}
          </Text>
          <IconButton
            aria-label='delete image'
            onClick={removeFunc}
            icon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faTrashAlt}
              />
            }
            border='none'
            color='gray.500'
          />
        </HStack>
      </Box>
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
