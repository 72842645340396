import { useParams } from 'react-router-dom';
import { Box, Flex, Text, Image, HStack } from '@chakra-ui/react';
import { Header } from './Header';
import VidualsIcon from '../../images/vidualsIcon.png';
import { Sidebar } from '../../sharedComponents/endUserSystemsView/Sidebar';
import { ContentViewer } from '../../sharedComponents/endUserSystemsView/ContentViewer';
import { useGetSystemByUrlQuery } from '../../services/systemEndpoints';
import { useGetCustomizationsByProductGroupIdQuery } from '../../services/customizationsEndpoints';
import { isSome } from '../../config/Maybe';
import { useGetProductGroupByUrlQuery } from '../../services/prodGroupsEndpoints';
import { useEffect, useState } from 'react';
import { ProdGroup } from '../../objects/ProdGroup';
import { EndUserContextContext } from '../../contexts/EndUserContext';

interface RouteParams {
  org: string;
  prodGroup: string;
  system: string;
}

export const EndUserSystemsViewPage = (): JSX.Element => {
  const [productGroup, setProdGroup] = useState<ProdGroup | undefined>();
  const params = useParams<RouteParams>();

  const { data: system } = useGetSystemByUrlQuery({
    systemUrl: params.system,
    pgUrl: params.prodGroup,
    orgUrl: params.org,
  });

  const { data: prodGroup } = useGetProductGroupByUrlQuery({
    pgUrl: params.prodGroup,
    orgUrl: params.org,
  });

  useEffect(() => {
    setProdGroup(prodGroup);
  }, [prodGroup]);

  const { data: currentCustomizations } =
    useGetCustomizationsByProductGroupIdQuery(prodGroup?.id ?? '', {
      skip: !isSome(prodGroup),
    });

  return (
    <EndUserContextContext.Provider value={{ productGroup, setProdGroup }}>
      <Box
        w='100vw'
        h='100vh'
        bg={
          isSome(currentCustomizations?.primaryColor)
            ? '#' + String(currentCustomizations?.primaryColor)
            : 'black'
        }
        color={
          isSome(currentCustomizations?.primaryTextColor)
            ? '#' + String(currentCustomizations?.primaryTextColor)
            : 'white'
        }
      >
        <Header system={system} customizations={currentCustomizations} />
        {/* Calc height minus height of header */}
        <Flex h='calc(100vh - 134px)'>
          <Sidebar system={system} customizations={currentCustomizations} />
          <ContentViewer
            system={system}
            customizations={currentCustomizations}
          />
        </Flex>
        <HStack bottom='5' right='10' position='absolute'>
          <Image src={VidualsIcon} />
          <Text color='gray.300'>Powered by Viduals TM 2021</Text>
        </HStack>
      </Box>
    </EndUserContextContext.Provider>
  );
};
