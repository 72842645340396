import './TagsPage.scss';
import { Box, HStack } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Tag, UpdateTagParams } from '../../objects/Tag';
import { TagItem } from './Tags/TagItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { appColors } from '../../config/constants';
import { setTagToEdit } from '../../store/modalFormSlice';
import SortableTree, {
  find,
  FullTree,
  getTreeFromFlatData,
  NodeData,
  OnMovePreviousAndNextLocation,
  TreeItem,
} from 'react-sortable-tree';
import { selectUngroupedTags } from '../../store/tagSlice';
import { useState, useEffect } from 'react';
import { TagTreeType } from '../../objects/TagOrTagGroup';
import { isSome } from '../../config/Maybe';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { useUpdateTagMutation } from '../../services/tagEndpoints';
import { useSetTagOrderInGroupMutation } from '../../services/tagGroupEndpoints';

export const ROOT_TREE_ID = 'root';

export const UngroupedTagsList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const ungroupedTags = useAppSelector(selectUngroupedTags);
  const [updateTag] = useUpdateTagMutation();
  const [setTagOrderInGroup] = useSetTagOrderInGroupMutation();
  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  useEffect(() => {
    const treeItems = getTreeItemsFromTags(ungroupedTags);
    const treeStruct = getTreeFromFlatData({
      flatData: treeItems,
      getKey: (fileTreeNode) => fileTreeNode.id,
      getParentKey: (fileTreeNode) => fileTreeNode.parentId,
      rootKey: ROOT_TREE_ID,
    });

    setTreeData(treeStruct);
  }, [ungroupedTags]);

  const getTreeItemsFromTags = (
    tags: Tag[]
  ): {
    [key: number]: TreeItem;
  } => {
    return tags.map((tag): TreeItem => {
      return {
        id: tag.id,
        title: (
          <TagItem onClick={() => dispatch(setTagToEdit(tag))} tag={tag} />
        ),
        type: TagTreeType.Tag,
        tag: tag,
        parentId: ROOT_TREE_ID,
        childAllowed: false,
      };
    });
  };

  const onMoveNode = async (
    data: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode, nextPath } = data;
    if (nextPath === null) {
      // node moved outside of tree, handle elsewhere
      return;
    }

    if (isSome(movedNode.tag)) {
      if (movedNode.tag.tagGroupId !== null) {
        const updatedTag: UpdateTagParams = {
          id: movedNode.tag.id,
          name: movedNode.tag.name,
          description: movedNode.tag.description,
          productGroupId: movedNode.tag.productGroupId,
          thumbnailUrl: movedNode.tag.thumnailUrl,
        };
        await updateTag(updatedTag);
      }
      await saveTagOrderForUngroupedTags(data);
    }
  };

  const saveTagOrderForUngroupedTags = async (
    data: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode, nextParentNode } = data;
    if (isSome(nextParentNode)) {
      // is somehow a child of somthing in the tree,
      // shouldn't happen here
      return;
    }
    const { matches } = find({
      treeData: treeData,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node: searchNode }) => {
        // get all other
        return searchNode.id !== movedNode.id;
      },
    });

    const sorted = [...matches, data].sort((a, b) => a.treeIndex - b.treeIndex);
    const idsInOrder = sorted.map((data) => {
      return data.node.id as string;
    });

    await setTagOrderInGroup({
      groupId: '',
      sortedTagIds: idsInOrder,
    });
  };

  return (
    <Box className='checkableItems' w='50%' h='100%'>
      <HStack>
        <FontAwesomeIcon
          className={(classNames.icon, classNames.faIcon)}
          icon={faTag}
          color={appColors.GRAY400}
        />
        <span style={{ fontWeight: 700 }} className={classNames.columnTitle}>
          Ungrouped Tags
        </span>
      </HStack>
      <Box minH={100} h={'100%'} w='80%'>
        <SortableTree
          treeData={treeData}
          canDrop={(data) => {
            return (
              data.node.type === TagTreeType.Tag && data.nextParent === null
            );
          }}
          onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
          getNodeKey={({ node }) => node.id}
          theme={FileExplorerTheme}
          rowHeight={30}
          rowDirection={'ltr'}
          reactVirtualizedListProps={{
            className: 'file-tree-list',
          }}
          onMoveNode={onMoveNode}
          dndType={'externalNodeType'}
          shouldCopyOnOutsideDrop={false}
        />
      </Box>
    </Box>
  );
};

const classNames = {
  columnTitle: 'tags-page-column-titles',
  icon: 'icon',
  faIcon: 'faIcon',
};
