import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Content } from '../objects/Content';
import {
  ContentVersion,
  ContentVersionFileTypes,
} from '../objects/ContentVersion';
import { RootState } from './store';

export const endUserSlice = createSlice({
  name: 'endUser',
  initialState: {
    versionToDisplay: undefined as ContentVersion | undefined,
    contentToDisplay: undefined as Content | undefined,
    fileTypeToDisplay: undefined as ContentVersionFileTypes | undefined,
    videoDurationSeconds: 0 as number,
    numPagesPdf: 0 as number,
  },
  reducers: {
    setVersionToDisplay: (
      state,
      { payload: version }: PayloadAction<ContentVersion | undefined>
    ) => {
      state.versionToDisplay = version;
    },
    setContentToDisplay: (
      state,
      { payload: content }: PayloadAction<Content | undefined>
    ) => {
      state.contentToDisplay = content;
    },
    setFileTypeToDisplay: (
      state,
      { payload: fileType }: PayloadAction<ContentVersionFileTypes | undefined>
    ) => {
      state.fileTypeToDisplay = fileType;
    },
    setVideoDurationSeconds: (
      state,
      { payload: videoDurationSeconds }: PayloadAction<number>
    ) => {
      state.videoDurationSeconds = videoDurationSeconds;
    },
    setNumPagesPdf: (
      state,
      { payload: numPagesPdf }: PayloadAction<number>
    ) => {
      state.numPagesPdf = numPagesPdf;
    },
  },
});
export const selectVersionToDisplay = (
  state: RootState
): ContentVersion | undefined => state.endUser.versionToDisplay;
export const selectContentToDisplay = (state: RootState): Content | undefined =>
  state.endUser.contentToDisplay;
export const selectFileTypeToDisplay = (
  state: RootState
): ContentVersionFileTypes | undefined => state.endUser.fileTypeToDisplay;
export const selectVideoDurationSeconds = (state: RootState): number =>
  state.endUser.videoDurationSeconds;
export const selectNumPagesPdf = (state: RootState): number =>
  state.endUser.numPagesPdf;

export const {
  setVersionToDisplay,
  setContentToDisplay,
  setFileTypeToDisplay,
  setVideoDurationSeconds,
  setNumPagesPdf,
} = endUserSlice.actions;

export default endUserSlice.reducer;
