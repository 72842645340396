/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { Tag } from '../objects/Tag';
import {
  InputTagGroupParams,
  TagGroup,
  UpdateTagGroupParams,
} from '../objects/TagGroup';
import {
  addGroups,
  deleteTagGroups,
  setTagGroups,
  updateGroupOrder,
  updateTagGroup,
} from '../store/tagGroupSlice';
import { updateTagOrder } from '../store/tagSlice';
import { IOnQueryAPI, vidualsApi } from './vidualsApi';

const updateTagGroupsState = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setTagGroups(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateTagGroupSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateTagGroup(data));
  } catch (err) {
    console.trace(err);
  }
};

const deleteTagGroupSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(deleteTagGroups(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateGroupOrderSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateGroupOrder(data));
  } catch (err) {
    console.trace(err);
  }
};

const updateTagOrderSlice = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateTagOrder(data));
  } catch (err) {
    console.trace(err);
  }
};

const addGroupToSlice = async (_arg: any, api: IOnQueryAPI): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addGroups([data]));
  } catch (err) {
    console.trace(err);
  }
};

export const tagGroupsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getTagGroupsByProductGroupId: builder.query<TagGroup[], string>({
      query: (pgid) => `tagGroups/productGroup/${pgid}`,
      onQueryStarted: updateTagGroupsState,
    }),
    createTagGroup: builder.mutation<TagGroup, InputTagGroupParams>({
      query: (contentInput) => {
        return {
          url: `tagGroups`,
          method: 'POST',
          body: contentInput,
        };
      },
      onQueryStarted: addGroupToSlice,
    }),
    updateTagGroup: builder.mutation<Tag, UpdateTagGroupParams>({
      query: (tagGroupInput) => {
        return {
          url: `tagGroups`,
          method: 'PUT',
          body: tagGroupInput,
        };
      },
      onQueryStarted: updateTagGroupSlice,
    }),
    deleteTagGroup: builder.mutation<string, UpdateTagGroupParams>({
      query: (tagGroupInput) => {
        return {
          url: `tagGroups`,
          method: 'DELETE',
          body: tagGroupInput,
        };
      },
      onQueryStarted: deleteTagGroupSlice,
    }),
    setTagOrderInGroup: builder.mutation<
      Tag[],
      {
        groupId: string;
        sortedTagIds: string[];
      }
    >({
      query: ({ groupId, sortedTagIds }) => {
        if (groupId === '') {
          // I guess OpenApi doesn't like an empty parameter
          // so I had to make a separate endpoint
          return {
            url: `tagGroups/setOrderUngroupedTags`,
            method: 'PUT',
            body: sortedTagIds,
          };
        }
        return {
          url: `tagGroups/${groupId}/setOrderInGroup`,
          method: 'PUT',
          body: sortedTagIds,
        };
      },
      onQueryStarted: updateTagOrderSlice,
    }),
    setTagGroupOrderInProductGroup: builder.mutation<
      TagGroup[],
      {
        pgid: string;
        sortedTagGroupIds: string[];
      }
    >({
      query: ({ pgid, sortedTagGroupIds }) => {
        return {
          url: `tagGroups/productGroup/${pgid}/setTagGroupOrder`,
          method: 'PUT',
          body: sortedTagGroupIds,
        };
      },
      onQueryStarted: updateGroupOrderSlice,
    }),
  }),
});

export const {
  useLazyGetTagGroupsByProductGroupIdQuery,
  useCreateTagGroupMutation,
  useUpdateTagGroupMutation,
  useDeleteTagGroupMutation,
  useSetTagOrderInGroupMutation,
  useSetTagGroupOrderInProductGroupMutation,
} = tagGroupsApi;
