import { Box, Heading } from '@chakra-ui/react';
import { appColors } from '../../../config/constants';
import { Tag } from '../../../objects/Tag';

interface GroupQuestionTagOptionProps {
  tag: Tag;
}

export const GroupQuestionTagOption = ({
  tag,
}: GroupQuestionTagOptionProps): JSX.Element => {
  return (
    <Box layerStyle={'questionnaire-tag-option'}>
      <Heading as='h5' size={'sm'} color={appColors.GREEN_DARKER}>
        {tag.name}
      </Heading>
    </Box>
  );
};
