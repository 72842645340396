import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  Button,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { RefObject, useEffect, useState } from 'react';
import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { ImagePreview } from '../../components/ImagePreview';
import { TextInput } from '../../components/TextInput';
import { ImageIcon } from '../../config/icons';
import { isSome } from '../../config/Maybe';
import { buildFileGetUrlByProdGroup } from '../../config/S3Client';
import { Customizations } from '../../objects/Customizations';
import { ProdGroup } from '../../objects/ProdGroup';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

export interface HelpMessagingProps {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  helpImageRef: RefObject<HTMLInputElement>;
  customizations: Customizations | null | undefined;
  prodGroup: ProdGroup | null | undefined;
  errors: DeepMap<any, FieldError>;
}

export const HelpMessaging = ({
  register,
  watch,
  setValue,
  helpImageRef,
  customizations,
  prodGroup,
  errors,
}: HelpMessagingProps): JSX.Element => {
  const pGroup = useAppSelector(selectCurrentProductGroup);
  useEffect(() => {
    setValue(HELP_DESCRIPTION_KEY, customizations?.helpDescription ?? '');
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setHelpImageFromCustomizations();
  }, [customizations]);

  const helpDescriptionRegister = register(HELP_DESCRIPTION_KEY, {
    required: true,
  });
  const helpDescriptionText = watch(HELP_DESCRIPTION_KEY);

  const [helpImage, setHelpImageState] = useState<File | undefined>(undefined);
  const [helpImageName, setHelpImageNameState] = useState<string>('');
  const [helpImageUrl, setHelpImageUrl] = useState<string>('');

  useEffect(() => {
    if (isSome(helpImage)) {
      setHelpImageUrl(URL.createObjectURL(helpImage));
    }
  }, [helpImage]);

  const setHelpImageFromCustomizations = async (): Promise<void> => {
    if (isSome(customizations?.helpImageName)) {
      const response = await fetch(
        buildFileGetUrlByProdGroup(
          prodGroup,
          customizations?.helpImageName ?? ''
        )
      );
      const data = await response.blob();
      const metadata = {
        type: 'image',
      };
      const file = new File(
        [data],
        customizations?.helpImageName ?? '',
        metadata
      );
      setHelpImageState(file);
      setHelpImageNameState(customizations?.helpImageName ?? '');
    }
  };

  const setHelpImage = (): void => {
    const file = helpImageRef?.current?.files?.item(0) ?? undefined;
    setHelpImageState(file);
    if (file !== undefined) {
      setHelpImageNameState(file.name);
    }
  };
  const removeHelpImage = (): void => {
    if (isSome(helpImageRef) && isSome(helpImageRef.current)) {
      helpImageRef.current.files = null;
      setHelpImageState(undefined);
    }
    setHelpImageNameState('');
  };

  const renderImagePreview = (): JSX.Element => {
    if (
      isSome(customizations?.helpImageName) &&
      customizations?.helpImageName !== '' &&
      isSome(helpImage)
    ) {
      return <Image src={helpImageUrl} h={200} fit='contain' />;
    }
    return <></>;
  };

  return (
    <Box>
      <Heading as='h2' size='md' pb={2}>
        Help messaging
      </Heading>
      <HStack w='100%' alignItems='flex-start' spacing='5%'>
        <Stack w='50%'>
          <Text>
            Provide your end users additional instructions on how to identify
            your products’ templates/models or&nbsp;
            <Tooltip label='configuration id'>
              <Text as='span'>{pGroup?.configurationIdLabel ?? 'ID'}.</Text>
            </Tooltip>
          </Text>
          {isSome(customizations?.helpImageName) &&
          customizations?.helpImageName !== '' &&
          isSome(helpImage) ? (
            <ImagePreview
              fileUrl={helpImageUrl}
              imageLabel='Help'
              imageName={helpImageName}
              removeFunc={removeHelpImage}
            />
          ) : (
            <Button
              variant='small'
              leftIcon={<ImageIcon />}
              onClick={() => {
                helpImageRef?.current?.click();
              }}
            >
              Add image
            </Button>
          )}
          <input
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            ref={helpImageRef}
            onChange={setHelpImage}
          />
          <TextInput
            placeholder='Description'
            register={helpDescriptionRegister}
            text={helpDescriptionText}
          />
          {isSome(errors.helpDescription) &&
            errors.helpDescription.type === 'required' && (
              <Text variant={'error'}>
                You must enter a help description. Please try again.
              </Text>
            )}
        </Stack>
        <Stack w='50%'>
          <Heading as='h3' size='xs' color='gray.500' fontWeight={400}>
            Help overlay
          </Heading>
          <Box
            h={300}
            padding={10}
            borderRadius={8}
            bg={
              isSome(customizations)
                ? '#' + customizations.primaryColor
                : 'gray.400'
            }
            color={
              isSome(customizations)
                ? '#' + customizations.primaryTextColor
                : 'black'
            }
          >
            <Stack spacing={6}>
              {renderImagePreview()}
              <Text>
                {isSome(helpDescriptionText)
                  ? helpDescriptionText
                  : String(customizations?.helpDescription)}
              </Text>
            </Stack>
          </Box>
        </Stack>
      </HStack>
    </Box>
  );
};

const HELP_DESCRIPTION_KEY = 'helpDescription';
