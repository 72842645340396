import { Box, Text, Center } from '@chakra-ui/react';
import { isNone, isSome } from '../../config/Maybe';
import { getS3BucketName } from '../../config/S3Client';
import {
  ContentVersion,
  ContentVersionFileTypes,
} from '../../objects/ContentVersion';
import { System } from '../../objects/System';
import {
  selectVersionToDisplay,
  selectFileTypeToDisplay,
  setNumPagesPdf,
} from '../../store/endUserSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Customizations } from '../../objects/Customizations';

interface ContentFrameProps {
  system: System | undefined;
  customizations: Customizations | null | undefined;
}

export const ContentFrame = ({
  system,
  customizations,
}: ContentFrameProps): JSX.Element => {
  const dispatch = useAppDispatch();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${String(
    pdfjs.version
  )}/pdf.worker.js`;

  const version: ContentVersion | undefined = useAppSelector(
    selectVersionToDisplay
  );
  const type: ContentVersionFileTypes | undefined = useAppSelector(
    selectFileTypeToDisplay
  );

  // const [videoRef, setVideoRef] = useState<ReactPlayer>();
  // const videoRef2 = useRef<HTMLVideoElement>(null);

  // useEffect(() => {
  //   if (isSome(videoRef2.current) && isSome(videoRef2.current.duration)) {
  //     dispatch(setVideoDurationSeconds(videoRef2.current.duration));
  //   }
  // }, [videoRef2.current?.duration]);

  // const setRef = (player: ReactPlayer): void => {
  //   setVideoRef(player);
  //   if (player !== null) {
  //     if (player.getDuration() !== null) {
  //       dispatch(setVideoDurationSeconds(player.getDuration()));
  //     }
  //   }
  // };

  const buildFileGetUrl = (fileName: string): string => {
    const bucketName = getS3BucketName();
    const productGroupPrefix: string = system?.productGroup.id ?? '';
    const encodedFileName = encodeURIComponent(fileName);

    const url = `https://s3.amazonaws.com/${bucketName}/${productGroupPrefix}/${encodedFileName}/${encodedFileName}`;

    return url;
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    dispatch(setNumPagesPdf(numPages));
  };

  const renderVideo = (): JSX.Element => {
    if (isSome(version?.videoName)) {
      return (
        <Box h='100%' maxH='50vh' alignContent='center'>
          <Center h='100%'>
            <video
              key={version?.id}
              id='video-player'
              controls
              style={{ height: '100%' }}
              preload='auto'
              autoPlay={true}
              controlsList='nodownload'
              data-setup='{height: 100%, maxHeight: 50vh, responsive: true}'
              // ref={videoRef2}
            >
              <source
                key={version?.id}
                src={buildFileGetUrl(version?.videoName ?? '')}
                type='video/mp4'
              ></source>
              <p>
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that supports HTML5 video
              </p>
            </video>
            {/* <ReactPlayer
              ref={setRef}
              url={buildFileGetUrl(version?.videoName ?? '')}
              playing
            /> */}
          </Center>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const renderPdf = (): JSX.Element => {
    if (isSome(version?.pdfName)) {
      return (
        <Box w='100%' maxW='100%' h='100%'>
          <Box w='100%' maxW='100%' h='100%' overflowY='scroll'>
            <Center>
              <Document
                file={buildFileGetUrl(version?.pdfName ?? '')}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={version?.pdfStartPage ?? 1} />
              </Document>
            </Center>
          </Box>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const renderWelcome = (): JSX.Element => {
    return (
      <Center h='100%'>
        <Text
          color={
            isSome(customizations?.primaryTextColor)
              ? '#' + String(customizations?.primaryTextColor)
              : 'white'
          }
          opacity={0.8}
          fontSize={36}
          fontWeight={700}
          textAlign='center'
        >
          Select a video to get started
        </Text>
      </Center>
    );
  };

  return (
    <Box
      h='50vh'
      w='100%'
      justifyContent='center'
      alignItems='center'
      pt='20px'
    >
      {type === ContentVersionFileTypes.VIDEO && renderVideo()}
      {type === ContentVersionFileTypes.PDF && renderPdf()}
      {isNone(type) && renderWelcome()}
    </Box>
  );
};
