import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Box,
  Stack,
  Heading,
  Text,
  Button,
  IconButton,
  useDisclosure,
  Spacer,
  HStack,
} from '@chakra-ui/react';
import { appValues } from '../../config/constants';
import { uploadFile } from '../../config/fileupload';
import { isSome } from '../../config/Maybe';
import { InputCustomizationsParams } from '../../objects/Customizations';
import {
  useGetCustomizationsByProductGroupIdQuery,
  useCreateOrUpdateCustomizationsMutation,
} from '../../services/customizationsEndpoints';
import { ColorAppearance } from '../../sharedComponents/customizations/ColorAppearance';
import { HeaderSection } from '../../sharedComponents/customizations/HeaderSection';
import { HelpMessaging } from '../../sharedComponents/customizations/HelpMessaging';
import { SideNavBar } from '../../sharedComponents/sideNavBar/SideNavBar';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { selectCurrentOrg } from '../../store/organizationSlice';
import { ProfilePopup } from '../../sharedComponents/modal/ProfilePopup';
import { UserIcon } from '../../config/icons';
import { firebaseApp } from '../../config/firebase.config';

export const CustomizationsPage = (): JSX.Element => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const selectedOrg = useAppSelector(selectCurrentOrg);
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const { data: currentCustomizations } =
    useGetCustomizationsByProductGroupIdQuery(selectedProductGroup?.id ?? '', {
      skip: !isSome(selectedProductGroup),
    });
  const [createOrUpdateCustomizations] =
    useCreateOrUpdateCustomizationsMutation();

  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const logoImageRef = useRef<HTMLInputElement>(null);
  const coverImageRef = useRef<HTMLInputElement>(null);
  const bannerImageRef = useRef<HTMLInputElement>(null);
  const helpImageRef = useRef<HTMLInputElement>(null);

  const onSubmit = async (
    customizations: InputCustomizationsParams
  ): Promise<void> => {
    setIsLoading(true);
    const logoImageFile = logoImageRef?.current?.files?.item(0);
    if (isSome(logoImageFile)) {
      customizations.logoImageName = logoImageFile.name;
      await uploadFile(
        firebaseApp.auth().currentUser,
        selectedProductGroup,
        logoImageFile
      );
    } else {
      customizations.logoImageName = currentCustomizations?.logoImageName ?? '';
    }
    const coverImageFile = coverImageRef?.current?.files?.item(0);
    if (isSome(coverImageFile)) {
      customizations.coverImageName = coverImageFile.name;
      await uploadFile(
        firebaseApp.auth().currentUser,
        selectedProductGroup,
        coverImageFile
      );
    } else {
      customizations.coverImageName =
        currentCustomizations?.coverImageName ?? '';
    }
    const bannerImageFile = bannerImageRef?.current?.files?.item(0);
    if (isSome(bannerImageFile)) {
      customizations.bannerImageName = bannerImageFile.name;
      await uploadFile(
        firebaseApp.auth().currentUser,
        selectedProductGroup,
        bannerImageFile
      );
    } else {
      customizations.bannerImageName =
        currentCustomizations?.bannerImageName ?? '';
    }
    const helpImageFile = helpImageRef?.current?.files?.item(0);
    if (isSome(helpImageFile)) {
      customizations.helpImageName = helpImageFile.name;
      await uploadFile(
        firebaseApp.auth().currentUser,
        selectedProductGroup,
        helpImageFile
      );
    } else {
      customizations.helpImageName = currentCustomizations?.helpImageName ?? '';
    }
    customizations.productGroupId = selectedProductGroup?.id ?? '';
    if (currentCustomizations !== undefined) {
      customizations.id = currentCustomizations.id;
    }

    await createOrUpdateCustomizations(customizations);

    setIsLoading(false);
  };

  return (
    <Flex>
      <SideNavBar />
      <Flex className='main' ml={appValues.SIDEBAR_WIDTH}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={8}>
            <HStack w='100%' justifyContent='flex-end'>
              <Heading as='h1' size='lg'>
                Customizations
              </Heading>
              <Spacer />
              <IconButton
                aria-label='Profile'
                border='none'
                icon={<UserIcon className='icon' w={8} h={8} />}
                onClick={onOpenProfilePopup}
              />
              <ProfilePopup
                isOpen={isOpenProfilePopup}
                onClose={onCloseProfilePopup}
              />
            </HStack>
            <Text>
              Enhance your end users’ experience by customizing the visual
              presentation of the application.
            </Text>
            <Box>
              <Text variant='label'>Custom Viduals URL extension</Text>
              <Text color='gray.500'>
                https://viduals.com/{selectedOrg?.urlExtension}/
                {isSome(selectedProductGroup) &&
                  selectedProductGroup.urlExtension}
              </Text>
            </Box>
            <ColorAppearance
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              logoImageRef={logoImageRef}
              coverImageRef={coverImageRef}
              bannerImageRef={bannerImageRef}
              customizations={currentCustomizations}
              prodGroup={selectedProductGroup}
            />
            <HeaderSection
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              customizations={currentCustomizations}
            />
            <HelpMessaging
              register={register}
              watch={watch}
              setValue={setValue}
              helpImageRef={helpImageRef}
              customizations={currentCustomizations}
              prodGroup={selectedProductGroup}
              errors={errors}
            />
            <Button isLoading={isLoading} w={144} variant='fill' type='submit'>
              Save changes
            </Button>
          </Stack>
        </form>
      </Flex>
    </Flex>
  );
};
