import { Box, Heading, Text } from '@chakra-ui/react';

export const ProdGroupsInitial = (): JSX.Element => {
  return (
    <Box>
      <Heading as='h1' fontSize='3xl' color='gray.900' pt={5} pb={5}>
        Product Groups
      </Heading>
      <Box color='gray.700'>
        <Text>
          Set up a product group with its own set of content and tags.
        </Text>
        <Text>
          Product groups can be configured as a{' '}
          <span style={{ fontWeight: 700 }}>standard</span> series of
          pre-configurations, or a{' '}
          <span style={{ fontWeight: 700 }}>questionnaire</span> database.
        </Text>
      </Box>
    </Box>
  );
};
