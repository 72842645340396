import { useEffect, useState } from 'react';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import SortableTree, {
  changeNodeAtPath,
  ExtendedNodeData,
  find,
  getVisibleNodeCount,
  TreeItem,
} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { Content } from '../../../objects/Content';
import {
  FileTreeItem,
  FileTreeItemTypeSchema,
} from '../../../objects/FileTreeItem';
import { isSome } from '../../../config/Maybe';
import { Folder } from '../../../objects/Folder';
import { useDebounce } from '../../../utilities/hooks';
import { Customizations } from '../../../objects/Customizations';
import Fuse from 'fuse.js';
import { QSystem } from '../../../objects/System';
import { useAppSelector } from '../../../store/hooks';
import {
  selectContentsInTree,
  selectFoldersInTree,
} from '../../../store/treeItemSlice';
import {
  FoldersOrContentWithTags,
  ItemType,
} from '../../../objects/FolderOrContentItem';
import { FolderWithTags } from '../../../objects/FolderWithTags';
import { ContentWithTags } from '../../../objects/ContentWithTags';
import {
  selectContentsWithTags,
  selectFoldersWithTags,
} from '../../../store/foldersAndContentsWithTagsSlice';
import { SystemFolderItem } from '../../../sharedComponents/endUserSystemsView/SystemFolderItem';
import { VideoItem } from '../../../sharedComponents/endUserSystemsView/VideoItem';
import { PdfItem } from '../../../sharedComponents/endUserSystemsView/PdfItem';
import { SearchResults } from '../../../sharedComponents/endUserSystemsView/SearchResults';
import {
  ContentVersion,
  versionTagsMatchCheckedSelection,
} from '../../../objects/ContentVersion';
import { selectVersionToDisplay } from '../../../store/endUserSlice';

interface TreeItemChildren {
  items: TreeItem[];
  containsUsedVersion: boolean;
}

interface QSystemFileListProps {
  qSystem: QSystem;
  customizations?: Customizations;
}

export const QSystemFileList = ({
  qSystem,
  customizations,
}: QSystemFileListProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<TreeItem[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  const allFolders: Folder[] = useAppSelector(selectFoldersInTree);
  const allContents: Content[] = useAppSelector(selectContentsInTree);
  const currentSelectedVersion: ContentVersion | undefined = useAppSelector(
    selectVersionToDisplay
  );

  const foldersWithTags: FolderWithTags[] = useAppSelector(
    selectFoldersWithTags
  );
  const contentsWithTags: ContentWithTags[] = useAppSelector(
    selectContentsWithTags
  );

  const fileTreeItems: FileTreeItem[] = [
    ...allFolders.map((folder): FileTreeItem => {
      return { ...folder, type: FileTreeItemTypeSchema.Enum.FOLDER };
    }),
    ...allContents.map((content): FileTreeItem => {
      return { ...content, type: FileTreeItemTypeSchema.Enum.CONTENT };
    }),
  ];

  useEffect(() => {
    setData();
  }, [allFolders, allContents, foldersWithTags, contentsWithTags, qSystem]);

  const shouldBeExpanded = (
    folderOrContentId: string,
    currentTree: TreeItem[]
  ): boolean => {
    const { matches } = find({
      treeData: currentTree,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node: searchNode }) => {
        return searchNode.id === folderOrContentId;
      },
    });
    if (matches.length > 0) {
      return matches[0].node.expanded ?? false;
    }
    return false;
  };

  const setData = (): void => {
    const allRootItems: FoldersOrContentWithTags[] = [
      ...foldersWithTags
        .filter((folder) => qSystem.checkedFolderIds.includes(folder.id))
        .map((folder): FoldersOrContentWithTags => {
          const match = allFolders.find((f) => f.id === folder.id);
          return {
            item: {
              ...folder,
              indexWithinParent: isSome(match)
                ? match.indexWithinParent
                : folder.indexWithinParent,
            },
            type: ItemType.Folder,
          };
        }),
      ...contentsWithTags
        .filter((content) => qSystem.checkedContentIds.includes(content.id))
        .map((content): FoldersOrContentWithTags => {
          const match = allContents.find((c) => c.id === content.id);
          return {
            item: {
              ...content,
              indexWithinParent: isSome(match)
                ? match.indexWithinParent
                : content.indexWithinParent,
            },
            type: ItemType.Content,
          };
        }),
    ].sort((a, b) => {
      return a.item.indexWithinParent - b.item.indexWithinParent;
    });

    const finalData: TreeItem[] = [];

    allRootItems.map((folderOrContent) => {
      if (folderOrContent.type === ItemType.Folder) {
        const folderChildren = setTreeDataRecursive(
          folderOrContent.item.id,
          folderOrContent.item.name
        );
        if (folderChildren.containsUsedVersion) {
          finalData.push({
            title: (
              <SystemFolderItem
                onClick={() => {
                  return true;
                }}
                folder={folderOrContent.item as Folder}
                color={customizations?.secondaryTextColor}
              />
            ),
            id: folderOrContent.item.id,
            fileTreeItem: folderOrContent.item as Folder,
            type: FileTreeItemTypeSchema.Enum.FOLDER,
            childAllowed: true,
            children: folderChildren.items,
            used: folderChildren.containsUsedVersion,
            expanded: shouldBeExpanded(folderOrContent.item.id, treeData),
            indexWithinParent: folderOrContent.item.indexWithinParent,
          });
        }
      } else {
        const rootContent = folderOrContent.item as Content;
        const versionItems = contentVersionsTreeItems(
          rootContent,
          '',
          rootContent.requiredUngroupedTags ?? []
        );
        if (versionItems.containsUsedVersion) {
          finalData.push(...versionItems.items);
        }
      }
      return folderOrContent;
    });

    finalData.sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });

    setTreeData(finalData);
  };

  const setTreeDataRecursive = (
    parentFolderId: string,
    parentPathName: string
  ): TreeItemChildren => {
    const firstChildren = fileTreeItems.filter(
      (item) => item.parentFolderId === parentFolderId
    );
    let containsUsedVersion = false;

    const treeChildren: TreeItem[] = [];

    firstChildren.map((child) => {
      if (child.type === FileTreeItemTypeSchema.Enum.FOLDER) {
        const folderChildItems = setTreeDataRecursive(
          child.id,
          parentPathName + ' > ' + child.name
        );
        if (folderChildItems.containsUsedVersion) {
          containsUsedVersion = true;
        }
        treeChildren.push({
          title: (
            <SystemFolderItem
              onClick={() => {
                return true;
              }}
              folder={child as Folder}
              color={customizations?.secondaryTextColor}
            />
          ),
          id: child.id,
          expanded: shouldBeExpanded(child.id, treeData),
          fileTreeItem: child as Folder,
          type: FileTreeItemTypeSchema.Enum.FOLDER,
          childAllowed: true,
          children: folderChildItems.items,
          used: folderChildItems.containsUsedVersion,
          indexWithinParent: child.indexWithinParent,
        });
        return child;
      } else if (child.type === FileTreeItemTypeSchema.Enum.CONTENT) {
        const childContent: Content = child as Content;

        const versionTreeItems = contentVersionsTreeItems(
          childContent,
          parentPathName,
          childContent.requiredUngroupedTags ?? []
        );

        if (versionTreeItems.containsUsedVersion) {
          containsUsedVersion = true;
          treeChildren.push(...versionTreeItems.items);
        }
        return child;
      } else {
        return child;
      }
    });

    treeChildren.sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });

    return {
      items: treeChildren,
      containsUsedVersion,
    };
  };

  const contentVersionsTreeItems = (
    content: Content,
    parentPathName: string,
    requiredUngroupedTags: string[]
  ): TreeItemChildren => {
    const contentVersionsItems: TreeItem[] = [];
    if (isSome(content.contentVersions)) {
      content.contentVersions?.forEach((contentVersion) => {
        const used = versionTagsMatchCheckedSelection(
          contentVersion,
          requiredUngroupedTags,
          qSystem.checkedTagIds,
          true
        );
        if (used) {
          if (
            isSome(contentVersion.videoName) &&
            contentVersion.videoName !== ''
          ) {
            contentVersionsItems.push({
              title: (
                <VideoItem
                  contentVersion={contentVersion}
                  content={content}
                  color={customizations?.secondaryTextColor}
                />
              ),
              id: contentVersion.id,
              fileTreeItem: contentVersion,
              content: content,
              name: content.name,
              pathName: parentPathName,
              type: FileTreeItemTypeSchema.Enum.CONTENT_VERSION,
              childAllowed: false,
              children: [],
              indexWithinParent: content.indexWithinParent,
            });
          } else {
            if (
              isSome(contentVersion.pdfName) &&
              contentVersion.pdfName !== ''
            ) {
              contentVersionsItems.push({
                title: (
                  <PdfItem
                    contentVersion={contentVersion}
                    content={content}
                    color={customizations?.secondaryTextColor}
                  />
                ),
                id: contentVersion.id,
                fileTreeItem: contentVersion,
                content: content,
                name: content.name,
                pathName: parentPathName,
                type: FileTreeItemTypeSchema.Enum.CONTENT_VERSION,
                childAllowed: false,
                children: [],
                indexWithinParent: content.indexWithinParent,
              });
            }
          }
        }
      });
    }
    contentVersionsItems.sort((a, b) => {
      return a.indexWithinParent - b.indexWithinParent;
    });
    return { items: contentVersionsItems, containsUsedVersion: true };
  };

  const toggleNodeExpandIfFolder = (nodeData: ExtendedNodeData): void => {
    const { node, path } = nodeData;
    if (node.children && node.children.length > 0) {
      setTreeData(
        changeNodeAtPath({
          treeData,
          path,
          getNodeKey: (fileTreeNode) => fileTreeNode.treeIndex,
          newNode: { ...node, expanded: !node.expanded },
        })
      );
    }
  };

  const visibleCount = getVisibleNodeCount({ treeData });
  const fullHeightPixels = visibleCount * 42; // count times row height

  return (
    <Box>
      <InputGroup mb={3} ml={2} w='95%'>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon
            color={
              isSome(customizations?.secondaryTextColor)
                ? '#' + String(customizations?.secondaryTextColor)
                : 'gray.200'
            }
          />
        </InputLeftElement>
        <Input
          value={searchTerm}
          color={
            isSome(customizations?.secondaryTextColor)
              ? '#' + String(customizations?.secondaryTextColor)
              : 'gray.200'
          }
          borderWidth={0}
          focusBorderColor={
            isSome(customizations?.buttonColor)
              ? '#' + String(customizations?.buttonColor)
              : 'gray.200'
          }
          placeholder='Search for the name of a video or folder'
          _placeholder={{
            opacity: 0.7,
            color: isSome(customizations?.secondaryTextColor)
              ? '#' + String(customizations?.secondaryTextColor)
              : 'gray.200',
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Box h={fullHeightPixels} w='100%'>
        <SortableTree
          searchQuery={debouncedSearchTerm}
          searchMethod={({ searchQuery, node }) => {
            const fuse = new Fuse([node], {
              threshold: 0.5,
              keys: ['name'],
            });
            const searchResult = fuse.search(searchQuery);
            return searchResult.length > 0;
          }}
          searchFinishCallback={(matches) => {
            setSearchResults(matches.map((node) => node.node));
          }}
          treeData={treeData}
          canDrag={false}
          onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
          theme={FileExplorerTheme}
          rowHeight={42}
          reactVirtualizedListProps={{
            className: 'file-tree-list',
          }}
          style={{
            width: '100%',
            display: debouncedSearchTerm.length === 0 ? '' : 'none',
          }}
          generateNodeProps={(nodeData: ExtendedNodeData) => {
            const { node } = nodeData;
            return {
              className:
                node.id === currentSelectedVersion?.id ||
                node.containsSelected === true
                  ? classNames.currentRow
                  : '',
              onClick: () => toggleNodeExpandIfFolder(nodeData),
            };
          }}
        />
        {debouncedSearchTerm.length !== 0 && (
          <SearchResults
            matches={debouncedSearchTerm.length !== 0 ? searchResults : []}
            color={
              isSome(customizations?.secondaryTextColor)
                ? '#' + String(customizations?.secondaryTextColor)
                : 'gray.200'
            }
            resetSearchTerm={() => setSearchTerm('')}
          />
        )}
      </Box>
    </Box>
  );
};

const classNames = {
  currentRow: 'end-user-file-list-current-row',
};
