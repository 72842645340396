import { FormLabel, Stack, Input, InputGroup } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface LabelInputProps {
  label: string;
  placeholder: string;
  register: UseFormRegisterReturn;
  disabled?: boolean;
}

export const LabelInput = ({
  label,
  placeholder,
  register,
  disabled,
}: LabelInputProps): JSX.Element => {
  return (
    <Stack display='flex' w='100%'>
      <FormLabel>{label}</FormLabel>

      <InputGroup w='100%'>
        <Input
          {...register}
          w='100%'
          type='text'
          placeholder={placeholder}
          isDisabled={disabled ?? false}
        />
      </InputGroup>
    </Stack>
  );
};
