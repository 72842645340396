import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Stack,
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormLabel,
} from '@chakra-ui/react';
import { useAppSelector } from '../../../store/hooks';
import { isSome } from '../../../config/Maybe';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { InputTagGroupParams } from '../../../objects/TagGroup';
import { useCreateTagGroupMutation } from '../../../services/tagGroupEndpoints';

interface AddTagGroupPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddTagGroupPopup = ({
  isOpen,
  onClose,
}: AddTagGroupPopupProps): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputTagGroupParams>();

  const [createTagGroup] = useCreateTagGroupMutation();

  const onSubmit = async (tagToCreate: InputTagGroupParams): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      tagToCreate.productGroupId = selectedProductGroup.id;

      await createTagGroup(tagToCreate).unwrap();
    }

    reset();
    onClose();
  };

  watch();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent w='458px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <ModalHeader pt={15}>
              <Heading as='h1' size='lg' textAlign='center'>
                Name your tag group
              </Heading>
            </ModalHeader>
            <Stack pt={15}>
              <FormLabel>Name</FormLabel>
              <Input
                backgroundColor='#ffffff'
                w='100%'
                type={'text'}
                placeholder='Tag Group'
                {...register('name', {
                  required: {
                    value: true,
                    message: 'You must enter a name',
                  },
                })}
              />
              {isSome(errors.name) && errors.name.type === 'required' && (
                <Text variant={'error'}>
                  You must enter a tag group name. Please try again.
                </Text>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack className='space-between width100' pt={30}>
              <Button w='45%' onClick={onClose}>
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='45%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
