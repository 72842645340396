import './QuestionnairePage.scss';
import {
  Box,
  HStack,
  Button,
  Image,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import Templates from '../../images/buttonIcons/templates.svg';
import { PreviewIcon } from '../../config/icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { PositiveConfirmModal } from '../../sharedComponents/modal/PositiveConfirmModal';
import { isSome } from '../../config/Maybe';
import { selectCurrentOrg } from '../../store/organizationSlice';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import {
  selectQuestionnaireToConfigure,
  selectAllRootQuestionsToConfigure,
  setRootQuestionsToConfigure,
  selectAllTagGroupQuestionsToConfigure,
  selectAllUngroupedTagQuestionsToConfigure,
  setOptionToConfigure,
  setTagGroupQuestionsToConfigure,
  setUngroupedTagQuestionsToConfigure,
  selectQuestionnaireSaveLoading,
  setQuestionnaireSaveLoading,
} from '../../store/questionnaireConfigurationSlice';
import { InputQuestionnaire } from '../../objects/Questions/Questionnaire';
import {
  useEditQuestionnaireMutation,
  useUpsertRootQuestionsForTemplateOptionMutation,
  useUpsertGroupQuestionsForTemplateOptionMutation,
  useUpsertUngroupedQuestionsForTemplateOptionMutation,
} from '../../services/questionnaireEndpoints';
import { OptionalProdGroup } from '../../objects/ProdGroup';
import { getBaseUrl } from '../../services/vidualsApi';
import axios from 'axios';
import {
  addFailedFileToUpload,
  addFileToUpload,
  addSucceededFileUpload,
  removeFileToUpload,
} from '../../store/toastSlice';
import { FolderOrContentQuestion } from '../../objects/Questions/FolderOrContentQuestion';
import { ITagGroupQuestion } from '../../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../../objects/Questions/UngroupedTagQuestion';
import firebase from 'firebase/app';
import { firebaseApp } from '../../config/firebase.config';
import { SelectAddTemplate } from '../../sharedComponents/modal/Templates/SelectAddTemplate';

export const QuestionnairePageHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editQuestionnaire] = useEditQuestionnaireMutation();
  const [upsertRootQuestions] =
    useUpsertRootQuestionsForTemplateOptionMutation();
  const [upsertGroupQuestions] =
    useUpsertGroupQuestionsForTemplateOptionMutation();
  const [upsertUngroupedQuestions] =
    useUpsertUngroupedQuestionsForTemplateOptionMutation();
  const selectedOrganization = useAppSelector(selectCurrentOrg);
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const questionnaireToConfigure = useAppSelector(
    selectQuestionnaireToConfigure
  );
  const rootQuestionsToConfigure = useAppSelector(
    selectAllRootQuestionsToConfigure
  );
  const tagGroupQuestions = useAppSelector(
    selectAllTagGroupQuestionsToConfigure
  );
  const ungroupedTagQuestions = useAppSelector(
    selectAllUngroupedTagQuestionsToConfigure
  );
  const loading = useAppSelector(selectQuestionnaireSaveLoading);

  const {
    isOpen: isOpenConfirmUrlPopup,
    onOpen: onOpenConfirmUrlPopup,
    onClose: onCloseConfirmUrlPopup,
  } = useDisclosure();

  const openPreviewInNewTab = async (): Promise<void> => {
    if (isSome(selectedOrganization) && isSome(selectedProductGroup)) {
      window.open(
        `${window.location.origin.toString()}/${
          selectedOrganization.urlExtension
        }/${selectedProductGroup.urlExtension}/`,
        '_blank'
      );
    }
  };

  const getUpdatedTemplateOptionIds = (
    questions: Array<
      FolderOrContentQuestion | ITagGroupQuestion | IUngroupedTagQuestion
    >
  ): string[] => {
    const allIds = questions.map((rq) => rq.templateQuestionnaireOptionId);
    return [...new Set(allIds)];
  };

  const getRootQuestionsWithFiles = async (
    rootQuestions: FolderOrContentQuestion[]
  ): Promise<FolderOrContentQuestion[]> => {
    return await Promise.all(
      rootQuestions
        .filter((rq) => rq.mandatory !== true)
        .map(async (rq) => {
          let qImageName: string | undefined = '';

          if (isSome(rq.imageFileObjectUrl) && isSome(rq.imageFileObjectName)) {
            const blob = await fetch(rq.imageFileObjectUrl);
            const blobFile = await blob.blob();
            const file = new File([blobFile], rq.imageFileObjectName);
            try {
              dispatch(addFileToUpload(file.name));
              await uploadFile(
                firebaseApp.auth().currentUser,
                selectedProductGroup,
                file
              );
              qImageName = file.name;
              dispatch(addSucceededFileUpload(file.name));
            } catch (err) {
              console.log(err);
              dispatch(addFailedFileToUpload(file.name));
            } finally {
              dispatch(removeFileToUpload(file.name));
            }
          } else if (rq.imageUrl === '') {
            qImageName = undefined;
          } else {
            qImageName = rq.imageUrl ?? '';
          }

          return {
            id: rq.id,
            text: rq.text,
            helpText: rq.helpText,
            imageUrl: qImageName,
            templateQuestionnaireOptionId: rq.templateQuestionnaireOptionId,
            folderOrContentId: rq.folderOrContentId,
            type: rq.type,
          };
        })
    );
  };

  const getGroupQuestionsWithFiles = async (
    groupQuestions: ITagGroupQuestion[]
  ): Promise<ITagGroupQuestion[]> => {
    return await Promise.all(
      groupQuestions
        .filter((tgq) => tgq.mandatory !== true)
        .map(async (tgq) => {
          let qImageName: string | undefined = '';

          if (
            isSome(tgq.imageFileObjectUrl) &&
            isSome(tgq.imageFileObjectName)
          ) {
            const blob = await fetch(tgq.imageFileObjectUrl);
            const blobFile = await blob.blob();
            const file = new File([blobFile], tgq.imageFileObjectName);
            try {
              dispatch(addFileToUpload(file.name));
              await uploadFile(
                firebaseApp.auth().currentUser,
                selectedProductGroup,
                file
              );
              qImageName = file.name;
              dispatch(addSucceededFileUpload(file.name));
            } catch (err) {
              console.log(err);
              dispatch(addFailedFileToUpload(file.name));
            } finally {
              dispatch(removeFileToUpload(file.name));
            }
          } else if (tgq.imageUrl === '') {
            qImageName = undefined;
          } else {
            qImageName = tgq.imageUrl ?? '';
          }

          return {
            id: tgq.id,
            text: tgq.text,
            helpText: tgq.helpText,
            imageUrl: qImageName,
            templateQuestionnaireOptionId: tgq.templateQuestionnaireOptionId,
            tagGroupId: tgq.tagGroupId,
          };
        })
    );
  };

  const getUnGroupedQuestionsWithFiles = async (
    ungroupedQuestions: IUngroupedTagQuestion[]
  ): Promise<IUngroupedTagQuestion[]> => {
    return await Promise.all(
      ungroupedQuestions
        .filter((utq) => utq.mandatory !== true)
        .map(async (utq) => {
          let qImageName: string | undefined = '';

          if (
            isSome(utq.imageFileObjectUrl) &&
            isSome(utq.imageFileObjectName)
          ) {
            const blob = await fetch(utq.imageFileObjectUrl);
            const blobFile = await blob.blob();
            const file = new File([blobFile], utq.imageFileObjectName);
            try {
              dispatch(addFileToUpload(file.name));
              await uploadFile(
                firebaseApp.auth().currentUser,
                selectedProductGroup,
                file
              );
              qImageName = file.name;
              dispatch(addSucceededFileUpload(file.name));
            } catch (err) {
              console.log(err);
              dispatch(addFailedFileToUpload(file.name));
            } finally {
              dispatch(removeFileToUpload(file.name));
            }
          } else if (utq.imageUrl === '') {
            qImageName = undefined;
          } else {
            qImageName = utq.imageUrl ?? '';
          }

          return {
            id: utq.id,
            text: utq.text,
            helpText: utq.helpText,
            imageUrl: qImageName,
            templateQuestionnaireOptionId: utq.templateQuestionnaireOptionId,
            tagId: utq.tagId,
          };
        })
    );
  };

  const saveQuestionnaireChanges = async (): Promise<void> => {
    dispatch(setQuestionnaireSaveLoading(true));
    if (isSome(questionnaireToConfigure) && isSome(selectedProductGroup)) {
      await upsertRootQuestions({
        productGroupId: selectedProductGroup.id,
        inputQuestions: await getRootQuestionsWithFiles(
          rootQuestionsToConfigure
        ),
        updatedTemplateOptionIds: getUpdatedTemplateOptionIds(
          rootQuestionsToConfigure
        ),
      });
      await upsertGroupQuestions({
        productGroupId: selectedProductGroup.id,
        inputQuestions: await getGroupQuestionsWithFiles(tagGroupQuestions),
        updatedTemplateOptionIds:
          getUpdatedTemplateOptionIds(tagGroupQuestions),
      });
      await upsertUngroupedQuestions({
        productGroupId: selectedProductGroup.id,
        inputQuestions: await getUnGroupedQuestionsWithFiles(
          ungroupedTagQuestions
        ),
        updatedTemplateOptionIds: getUpdatedTemplateOptionIds(
          ungroupedTagQuestions
        ),
      });

      let qImageName: string | undefined = '';

      if (
        isSome(questionnaireToConfigure.imageFileObjectUrl) &&
        isSome(questionnaireToConfigure.imageFileObjectName)
      ) {
        const blob = await fetch(questionnaireToConfigure.imageFileObjectUrl);
        const blobFile = await blob.blob();
        const file = new File(
          [blobFile],
          questionnaireToConfigure.imageFileObjectName
        );
        try {
          dispatch(addFileToUpload(file.name));
          await uploadFile(
            firebaseApp.auth().currentUser,
            selectedProductGroup,
            file
          );
          qImageName = file.name;
          dispatch(addSucceededFileUpload(file.name));
        } catch (err) {
          console.log(err);
          dispatch(addFailedFileToUpload(file.name));
        } finally {
          dispatch(removeFileToUpload(file.name));
        }
      } else if (questionnaireToConfigure.imageUrl === '') {
        qImageName = undefined;
      } else {
        qImageName = questionnaireToConfigure.imageUrl ?? '';
      }

      const questionnaireToSave: InputQuestionnaire = {
        id: questionnaireToConfigure.id,
        text: questionnaireToConfigure.text,
        helpText: questionnaireToConfigure.helpText,
        imageUrl: qImageName,
        inputQuestionnaireTemplateOptions:
          questionnaireToConfigure.questionnaireTemplateOptions.map(
            (iQTOption) => {
              return {
                id: iQTOption.id,
                questionnaireId: iQTOption.questionnaireId,
                questionnaireTemplateId: iQTOption.questionnaireTemplateId,
                available: iQTOption.available,
              };
            }
          ),
      };
      await editQuestionnaire(questionnaireToSave).unwrap(); // save this last as it should trigger a refresh of some of the data
    }
    dispatch(setOptionToConfigure(undefined));
    dispatch(setRootQuestionsToConfigure([]));
    dispatch(setTagGroupQuestionsToConfigure([]));
    dispatch(setUngroupedTagQuestionsToConfigure([]));
    dispatch(setQuestionnaireSaveLoading(false));
  };

  const {
    isOpen: isOpenTemplatePopup,
    onOpen: onOpenTemplatePopup,
    onClose: onCloseTemplatePopup,
  } = useDisclosure();

  return (
    <Box>
      {isOpenConfirmUrlPopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmUrlPopup}
          onClose={onCloseConfirmUrlPopup}
          title={`Please make sure you've saved your changes before viewing`}
          onCancel={onCloseConfirmUrlPopup}
          onConfirm={openPreviewInNewTab}
        />
      )}
      <HStack>
        <Button
          onClick={onOpenTemplatePopup}
          disabled={!isSome(selectedProductGroup)}
        >
          <Image className={classNames.icon} src={Templates} />
          Templates
        </Button>
        <Button onClick={onOpenConfirmUrlPopup}>
          <PreviewIcon className='icon' w={8} h={5} />
          Preview
        </Button>
        <Button
          onClick={saveQuestionnaireChanges}
          variant={'fill'}
          disabled={loading}
        >
          {loading ? <Spinner /> : 'Save'}
        </Button>
      </HStack>
      {isOpenTemplatePopup && (
        <SelectAddTemplate
          isOpen={isOpenTemplatePopup}
          onClose={onCloseTemplatePopup}
        />
      )}
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};

async function getPresignedUrl(
  currentUser: firebase.User | null,
  selectedProductGroup: OptionalProdGroup,
  file: File
): Promise<string> {
  const token = await currentUser?.getIdTokenResult();

  if (isSome(token?.token) && isSome(selectedProductGroup)) {
    const headers = {
      Authorization: `Bearer ${token?.token ?? ''}`,
    };
    const url = `${getBaseUrl()}/presign/generate-put`;
    // grab a string to add a key prefix for aws
    // see https://docs.aws.amazon.com/AmazonS3/latest/userguide/optimizing-performance.html
    const prefix = file.name;
    const putUrl = await axios.post(
      url,
      {
        key: `${selectedProductGroup.id}/questionImage/${prefix}/${file.name}`,
        contentType: file.type,
      },
      { headers }
    );
    return putUrl.data;
  } else throw new Error('Error in generating upload URL');
}

async function uploadFile(
  currentUser: firebase.User | null,
  selectedProductGroup: OptionalProdGroup,
  file: File
): Promise<void> {
  const putUrl = await getPresignedUrl(currentUser, selectedProductGroup, file);

  await axios.put(putUrl, file, { headers: { 'Content-Type': file.type } });
}
