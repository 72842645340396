import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionalProdGroup, ProdGroup } from '../objects/ProdGroup';
import { RootState } from './store';

export const productGroupSlice = createSlice({
  name: 'productGroups',
  initialState: {
    productGroups: [] as ProdGroup[],
    currentProductGroup: null as OptionalProdGroup,
  },
  reducers: {
    setCurrentProductGroup: (
      state,
      { payload: productGroup }: PayloadAction<OptionalProdGroup>
    ) => {
      state.currentProductGroup = productGroup;
    },
    setProductGroups: (
      state,
      { payload: productGroups }: PayloadAction<ProdGroup[]>
    ) => {
      state.productGroups = productGroups;
    },
    updateProductGroup: (
      state,
      { payload: updatedProductGroup }: PayloadAction<ProdGroup>
    ) => {
      state.productGroups = state.productGroups.map((productGroup) => {
        if (productGroup.id === updatedProductGroup.id) {
          return updatedProductGroup;
        }
        return productGroup;
      });
    },
  },
});
export const selectCurrentProductGroup = (
  state: RootState
): OptionalProdGroup => state.productGroups.currentProductGroup;
export const selectProductGroups = (state: RootState): ProdGroup[] =>
  state.productGroups.productGroups;

export const { setCurrentProductGroup, setProductGroups, updateProductGroup } =
  productGroupSlice.actions;

export default productGroupSlice.reducer;
