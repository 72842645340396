/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { firebaseApp } from '../config/firebase.config';
import { isSome } from '../config/Maybe';

export const getBaseUrl = (): string => {
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'local';
  let baseUrl: string;
  switch (ENVIRONMENT) {
    case 'dev':
    case 'stage':
      baseUrl = `https://api.${ENVIRONMENT}.vidualsplatform.com`;
      break;
    case 'prod':
      baseUrl = `https://api.vidualsplatform.com`;
      break;
    default:
      baseUrl = 'http://localhost:5000';
      break;
  }
  console.debug('API url', baseUrl);
  return baseUrl;
};

// Don't ask why 'as const' is required here because I have no idea
export const OrgCacheKey = 'Org' as const;
export const OrgUserCacheKey = 'OrgUsers' as const;
export const OrgListCacheId = 'OrgList' as const;
export const ContentListCacheId = 'ContentList' as const;
export const ContentVersionListCacheId = 'ContentVersionList' as const;
export const ProdGroupListCacheId = 'ProdGroupList' as const;
export const FoldersWithTagsList = 'FoldersWithTagsList' as const;
export const ContentsWithTagsList = 'ContentsWithTagsList' as const;
export const CustomizationsCacheKey = 'Customizations' as const;
export const QuestionnaireKey = 'QuestionnaireKey' as const;
export const QuestionnaireImagesKey = 'QuestionnaireImagesKey' as const;
export const QTemplateOptionRootQKey = 'QTemplateOptionRootQKey' as const;
export const QTemplateOptionGroupQKey = 'QTemplateOptionGroupQKey' as const;
export const QTemplateOptionUngroupedQKey =
  'QTemplateOptionUngroupedQKey' as const;

export interface IOnQueryAPI {
  dispatch: any;
  queryFulfilled: Promise<any>;
}

export const vidualsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: async (headers) => {
      const user = firebaseApp.auth().currentUser;
      const token = await user?.getIdTokenResult();
      if (isSome(token?.token)) {
        headers.set('Authorization', `Bearer ${token?.token ?? ''}`);
      } else {
        console.debug('Token not set for current user');
      }
      return headers;
    },
  }),
  tagTypes: [
    OrgCacheKey,
    OrgUserCacheKey,
    FoldersWithTagsList,
    ContentsWithTagsList,
    CustomizationsCacheKey,
    ProdGroupListCacheId,
    QuestionnaireKey,
    QuestionnaireImagesKey,
    QTemplateOptionRootQKey,
    QTemplateOptionGroupQKey,
    QTemplateOptionUngroupedQKey,
  ],
  endpoints: () => ({}),
});

// COPY PASTE THIS INTO YOUR QUERIES/MUTATIONS TO SEE DETAILS
/*
async onQueryStarted(_arg, { requestId, queryFulfilled }) {
  try {
    const { data, meta } = await queryFulfilled;
    console.debug('Request', { [requestId]: { data, meta } });
  } catch (err) {
    console.trace(err);
  }
},
*/
