import {
  Container,
  Text,
  Link,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useLazyGetSystemBySerialNumberQuery } from '../../services/systemEndpoints';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Customizations } from '../../objects/Customizations';
import { isSome } from '../../config/Maybe';
import { FindingSerialNumberPopup } from '../modal/endUserView/FindingSerialNumberPopup';
import { useEndUserContextContext } from '../../contexts/EndUserContext';

interface RouteParams {
  org: string;
  prodGroup: string;
}

interface SystemSearchProps {
  customizations: Customizations | null | undefined;
}

export const SystemSearch = ({
  customizations,
}: SystemSearchProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  const history = useHistory();
  const [searchSystems, result] = useLazyGetSystemBySerialNumberQuery();
  const params = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>('');

  useEffect(() => {
    const { data, error } = result;
    if (result.isLoading) {
      setIsLoading(true);
    } else if (error !== undefined) {
      if ('status' in error && error.status === 404) {
        setFetchError(
          `Could not find your ${
            productGroup?.configurationName ?? 'Configuration'
          }, are you sure you input the correct ${
            productGroup?.configurationIdLabel ?? 'ID'
          }?`
        );
      } else {
        setFetchError('An error occurred, please try again');
      }
      setIsLoading(false);
    } else if (data !== undefined) {
      setIsLoading(false);
      history.push(`/${params.org}/${params.prodGroup}/${data.urlExtension}`);
    }
  }, [result]);

  const onSubmit = async (serialNumber: string): Promise<void> => {
    if (productGroup) {
      searchSystems({
        serialNumber,
        pgId: productGroup.id,
      });
    }
  };

  const {
    isOpen: isOpenHelpPopup,
    onOpen: onOpenHelpPopup,
    onClose: onCloseHelpPopup,
  } = useDisclosure();

  return (
    <Container
      justifySelf='center'
      color={
        isSome(customizations?.primaryTextColor)
          ? '#' + String(customizations?.primaryTextColor)
          : 'white'
      }
      pt={150}
    >
      <Text fontWeight={700} fontSize={24} pb={8}>
        Enter your {productGroup?.configurationName ?? 'Configuration'}&apos;s{' '}
        {productGroup?.configurationIdLabel ?? 'ID'} to get started.
      </Text>
      {fetchError !== '' && <Text color={'red'}>{fetchError}</Text>}
      <InputGroup mb={3}>
        <InputLeftElement pointerEvents='none'>
          {isLoading ? (
            <Spinner justifySelf='center' />
          ) : (
            <SearchIcon color='gray.300' />
          )}
        </InputLeftElement>
        <Input
          disabled={isLoading}
          bg={
            isSome(customizations?.primaryTextColor)
              ? '#' + String(customizations?.primaryTextColor)
              : 'white'
          }
          color={
            isSome(customizations?.primaryColor)
              ? '#' + String(customizations?.primaryColor)
              : 'black'
          }
          placeholder={`Search for a ${
            productGroup?.configurationName ?? 'configuration'
          } ${productGroup?.configurationIdLabel ?? 'ID'}`}
          onKeyPress={async (e): Promise<void> => {
            if (e.key === 'Enter') {
              await onSubmit(e.currentTarget.value);
            }
          }}
        />
      </InputGroup>
      <Text>
        Need help{' '}
        <Link
          fontWeight={700}
          textDecoration='underline'
          onClick={onOpenHelpPopup}
        >
          finding your {productGroup?.configurationName ?? 'Configuration'} or{' '}
          {productGroup?.configurationIdLabel ?? 'ID'}?
        </Link>
      </Text>
      <FindingSerialNumberPopup
        customizations={customizations}
        isOpen={isOpenHelpPopup}
        onClose={onCloseHelpPopup}
      />
    </Container>
  );
};
