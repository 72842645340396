import './TagsPage.scss';
import NewFolder from '../../images/buttonIcons/newFolder.svg';
import { Box, HStack, Button, Image, useDisclosure } from '@chakra-ui/react';
import { AddTagPopup } from '../../sharedComponents/modal/Tags/AddTagPopup';
import { AddTagGroupPopup } from '../../sharedComponents/modal/TagGroups/AddTagGroupPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { appColors } from '../../config/constants';

export const TagsPageHeader = (): JSX.Element => {
  const {
    isOpen: isOpenAddTagPopup,
    onOpen: onOpenAddTagPopup,
    onClose: onCloseAddTagPopup,
  } = useDisclosure();

  const {
    isOpen: isOpenAddTagGroupPopup,
    onOpen: onOpenAddTagGroupPopup,
    onClose: onCloseAddTagGroupPopup,
  } = useDisclosure();

  return (
    <Box>
      <HStack>
        <Button onClick={onOpenAddTagGroupPopup}>
          <Image className={classNames.icon} src={NewFolder} />
          New Tag Group
        </Button>
        <Button
          color='white'
          backgroundColor={appColors.GREEN_DARKER}
          onClick={onOpenAddTagPopup}
        >
          <FontAwesomeIcon
            className={(classNames.icon, classNames.faIcon)}
            icon={faTag}
            color='white'
          />
          <span style={{ marginLeft: '10px' }}>New Tag</span>
        </Button>
      </HStack>
      <AddTagPopup isOpen={isOpenAddTagPopup} onClose={onCloseAddTagPopup} />
      <AddTagGroupPopup
        isOpen={isOpenAddTagGroupPopup}
        onClose={onCloseAddTagGroupPopup}
      />
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
