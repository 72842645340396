import {
  Button,
  Modal,
  Center,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
} from '@chakra-ui/react';
import { AddVideoIconNoColor } from '../../../config/icons';
import { isSome } from '../../../config/Maybe';
import { Customizations } from '../../../objects/Customizations';

interface HelpPopupProps {
  customizations: Customizations | null | undefined;

  isOpen: boolean;
  onClose: () => void;
}

export const HelpPopup = ({
  customizations,
  isOpen,
  onClose,
}: HelpPopupProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        w={315}
        bg={
          isSome(customizations)
            ? '#' + String(customizations.primaryColor)
            : 'black'
        }
        color={
          isSome(customizations)
            ? '#' + String(customizations.primaryTextColor)
            : 'white'
        }
      >
        <ModalHeader>Need help?</ModalHeader>
        <ModalCloseButton border='none' />
        <ModalBody>
          <Center>
            <Button
              bg={
                isSome(customizations)
                  ? '#' + String(customizations.buttonColor)
                  : 'black'
              }
              borderColor={
                isSome(customizations)
                  ? '#' + String(customizations.buttonColor)
                  : 'black'
              }
              color={
                isSome(customizations)
                  ? '#' + String(customizations.primaryTextColor)
                  : 'white'
              }
              _hover={{ background: '#80808080' }}
              w='100%'
              leftIcon={
                <AddVideoIconNoColor
                  stroke={
                    isSome(customizations)
                      ? '#' + String(customizations.primaryTextColor)
                      : 'white'
                  }
                />
              }
            >
              <Link>How to use the viewer</Link>
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
