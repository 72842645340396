/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { isSome } from '../config/Maybe';
import {
  InputCustomizationsParams,
  Customizations,
} from '../objects/Customizations';
import { CustomizationsCacheKey, IOnQueryAPI, vidualsApi } from './vidualsApi';

const updateCustomizationVars = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled } = api;
    const { data } = await queryFulfilled;
    const customizations = data as Customizations;

    const root = document.documentElement;
    root?.style.setProperty(
      '--vid-customizations-button-color',
      isSome(customizations?.buttonColor)
        ? '#' + String(customizations?.buttonColor)
        : '#fff'
    );
    // Set all custom colors this way?
    root?.style.setProperty(
      '--vid-customizations-primary-color',
      isSome(customizations?.primaryColor)
        ? '#' + String(customizations?.primaryColor)
        : '#fff'
    );
    // root?.style.setProperty(
    //   '--vid-customizations-p-text-color',
    //   isSome(customizations?.primaryTextColor)
    //     ? '#' + String(customizations?.primaryTextColor)
    //     : '#fff'
    // );
    root?.style.setProperty(
      '--vid-customizations-secondary-color',
      isSome(customizations?.secondaryColor)
        ? '#' + String(customizations?.secondaryColor)
        : 'gray.700'
    );
    // root?.style.setProperty(
    //   '--vid-customizations-s-text-color',
    //   isSome(customizations?.secondaryTextColor)
    //     ? '#' + String(customizations?.secondaryTextColor)
    //     : 'gray.200'
    // );
  } catch (err) {
    console.trace(err);
  }
};

export const customizationsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomizationsByProductGroupId: builder.query<Customizations, string>({
      query: (pgid) => `customizations/productGroup/${pgid}`,
      providesTags: [CustomizationsCacheKey],
      onQueryStarted: updateCustomizationVars,
    }),
    createOrUpdateCustomizations: builder.mutation<
      Customizations,
      InputCustomizationsParams
    >({
      query: (contentInput) => {
        return {
          url: `customizations`,
          method: 'PUT',
          body: contentInput,
        };
      },
      invalidatesTags: [CustomizationsCacheKey],
    }),
  }),
});

export const {
  useGetCustomizationsByProductGroupIdQuery,
  useCreateOrUpdateCustomizationsMutation,
} = customizationsApi;
