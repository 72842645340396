import { FoldersPage } from './pages/FoldersPage/FoldersPage';
import { ProdGroupsPage } from './pages/ProdGroupsPage';
import { SuperadminPage } from './pages/SuperadminPage';
import { LoginPage } from './pages/LoginSignupPage/LoginPage';
import { Redirect, Route, Switch } from 'react-router';
import 'firebase/app';
import 'firebase/auth';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './config/theme';
import { AuthenticatedPage } from './components/AuthenticatedPageHOC';
import { TagsPage } from './pages/TagsPage/TagsPage';
import { Routes } from './config/Routes';
import { EndUserSystemsFindPage } from './pages/EndUserSystemsFindPage/EndUserSystemsFindPage';
import { EndUserSystemsViewPage } from './pages/EndUserSystemsViewPage/EndUserSystemsViewPage';
import { SystemsPage } from './pages/SystemsPage/SystemsPage';
import { CustomizationsPage } from './pages/CustomizationsPage/CustomizationsPage';
import { QuestionnairePage } from './pages/QuestionnairePage/QuestionnairePage';
import { EndUserSystemFromQuestionnairePage } from './pages/EndUserSystemsViewPage/EndUserSystemFromQuestionnairePage';

const AuthenticatedRoute = AuthenticatedPage(Route);

const App = (): JSX.Element => {
  return (
    <ChakraProvider theme={theme}>
      <Switch>
        <AuthenticatedRoute
          exact
          path={Routes.FOLDERS_AND_CONTENT}
          render={() => <FoldersPage />}
        />
        <AuthenticatedRoute
          exact
          path={Routes.PRODUCT_GROUPS}
          render={() => <ProdGroupsPage />}
        />
        <AuthenticatedRoute
          exact
          path={Routes.CUSTOMIZATIONS}
          render={() => <CustomizationsPage />}
        />
        <AuthenticatedRoute
          exact
          path={Routes.SUPER_ADMIN}
          render={() => <SuperadminPage />}
        />
        <AuthenticatedRoute
          exact
          path={Routes.TAGS}
          render={() => <TagsPage />}
        />
        <AuthenticatedRoute
          exact
          path={Routes.SYSTEMS}
          render={() => <SystemsPage />}
        />
        <AuthenticatedRoute
          exact
          path={Routes.QUESTIONNAIRE}
          render={() => <QuestionnairePage />}
        />
        <Route path={Routes.LOGIN} render={() => <LoginPage />} />
        <Route
          path={Routes.QUESTIONNAIRE_RESULTS}
          render={() => <EndUserSystemFromQuestionnairePage />}
        />
        <Route
          path={'/:org/:prodGroup/:system'}
          render={() => <EndUserSystemsViewPage />}
        />
        <Route
          path={'/:org/:prodGroup'}
          render={() => <EndUserSystemsFindPage />}
        />
        <Route exact path={Routes.LOGIN} render={() => <LoginPage />} />
        <Redirect to={Routes.FOLDERS_AND_CONTENT} />
      </Switch>
    </ChakraProvider>
  );
};

export default App;
