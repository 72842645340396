import {
  Heading,
  Text,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useAppSelector } from '../../store/hooks';
import { useDeleteContentsByIdsMutation } from '../../services/contentsEndpoints';
import { selectContentToEdit } from '../../store/modalFormSlice';

interface DeleteContentPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseParent: () => void;
}

export const DeleteContentPopup = ({
  isOpen,
  onClose,
  onCloseParent,
}: DeleteContentPopupProps): JSX.Element => {
  const contentToEdit = useAppSelector(selectContentToEdit);

  const [deleteContentsApi] = useDeleteContentsByIdsMutation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='md'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>
          <Heading as='h1' textAlign='center' size='lg'>
            Deleting this content
          </Heading>
        </ModalHeader>
        {contentToEdit?.contentVersions !== undefined &&
        contentToEdit?.contentVersions.length > 1 ? (
          <ModalBody>
            <Text>You cannot delete content that contains variations</Text>
          </ModalBody>
        ) : (
          <ModalBody>
            <Text>
              Are you sure you want to delete this? This action cannot be
              reversed.
            </Text>
          </ModalBody>
        )}
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%'>
            <Button w='45%' onClick={onClose}>
              Go back
            </Button>
            {'\t'}
            <Button
              w='45%'
              variant='delete-confirm'
              onClick={async () => {
                await deleteContentsApi([contentToEdit?.id ?? '']);
                onCloseParent();
                onClose();
              }}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
