/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagGroup } from '../objects/TagGroup';
import { RootState } from './store';

export const tagGroupSlice = createSlice({
  name: 'tagGroups',
  initialState: {
    tagGroups: [] as TagGroup[],
  },
  reducers: {
    setTagGroups: (
      state,
      { payload: tagGroups }: PayloadAction<TagGroup[]>
    ) => {
      state.tagGroups = tagGroups;
    },
    addGroups: (state, { payload: groups }: PayloadAction<TagGroup[]>) => {
      return {
        ...state,
        tagGroups: [...state.tagGroups, ...groups],
      };
    },
    updateTagGroup: (
      state,
      { payload: updatedTagGroup }: PayloadAction<TagGroup>
    ) => {
      return {
        ...state,
        tagGroups: state.tagGroups.map((tagGroup) =>
          tagGroup.id === updatedTagGroup.id ? updatedTagGroup : tagGroup
        ),
      };
    },
    deleteTagGroups: (
      state,
      { payload: deletedIds }: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        tagGroups: state.tagGroups.filter(
          (group) => !deletedIds.includes(group.id)
        ),
      };
    },
    updateGroupOrder: (
      state,
      { payload: updatedGroups }: PayloadAction<TagGroup[]>
    ) => {
      return {
        ...state,
        tagGroups: state.tagGroups
          .map((tagGroup) => {
            const updatedGroup: TagGroup | undefined = updatedGroups.find(
              (group) => group.id === tagGroup.id
            );
            return updatedGroup !== undefined
              ? {
                  ...tagGroup,
                  indexWithinParent: updatedGroup.indexWithinParent,
                }
              : tagGroup;
          })
          .sort((a: TagGroup, b: TagGroup) => {
            return a.indexWithinParent - b.indexWithinParent;
          }),
      };
    },
  },
});

export const selectTagGroups = (state: RootState): TagGroup[] =>
  state.tagGroups.tagGroups;

export const selectGroupById = (
  state: RootState,
  groupId: string
): TagGroup | undefined =>
  state.tagGroups.tagGroups.find((group) => group.id === groupId);

export const {
  setTagGroups,
  addGroups,
  updateGroupOrder,
  updateTagGroup,
  deleteTagGroups,
} = tagGroupSlice.actions;

export default tagGroupSlice.reducer;
