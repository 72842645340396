import './SystemsPage.scss';
import {
  Box,
  HStack,
  Button,
  Image,
  useDisclosure,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Templates from '../../images/buttonIcons/templates.svg';
import { PreviewIcon } from '../../config/icons';
import { System } from '../../objects/System';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetSystemSlice } from '../../store/systemSlice';
import { SelectTemplateForSystem } from '../../sharedComponents/modal/Templates/SelectTemplateForSystem';
import { PositiveConfirmModal } from '../../sharedComponents/modal/PositiveConfirmModal';
import { isSome } from '../../config/Maybe';
import { selectCurrentOrg } from '../../store/organizationSlice';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

interface SystemConfigurationPageHeaderProps {
  system: System;
}

export const SystemConfigurationPageHeader = ({
  system,
}: SystemConfigurationPageHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedOrganization = useAppSelector(selectCurrentOrg);
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  const {
    isOpen: isOpenTemplatePopup,
    onOpen: onOpenTemplatePopup,
    onClose: onCloseTemplatePopup,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmUrlPopup,
    onOpen: onOpenConfirmUrlPopup,
    onClose: onCloseConfirmUrlPopup,
  } = useDisclosure();

  const openPreviewInNewTab = async (): Promise<void> => {
    if (isSome(selectedOrganization) && isSome(selectedProductGroup)) {
      window.open(
        `${window.location.origin.toString()}/${
          selectedOrganization.urlExtension
        }/${selectedProductGroup.urlExtension}/${system.urlExtension}`,
        '_blank'
      );
    }
  };

  return (
    <Box>
      {isOpenConfirmUrlPopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmUrlPopup}
          onClose={onCloseConfirmUrlPopup}
          title={`Please make sure you've saved your changes before viewing`}
          onCancel={onCloseConfirmUrlPopup}
          onConfirm={openPreviewInNewTab}
        />
      )}
      <HStack>
        <Button
          border='none'
          color='black'
          padding='0px'
          onClick={() => {
            dispatch(resetSystemSlice());
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Text mr='10px'>
          <Tooltip label='Configuration'>
            <Text as='span'>
              {selectedProductGroup?.configurationName ?? 'configuration'}
            </Text>
          </Tooltip>
          :{' '}
          <Tooltip label='Configuration Id'>
            <Text as='span'>{system.serialNumber}</Text>
          </Tooltip>
        </Text>
        <Button onClick={onOpenTemplatePopup}>
          <Image className={classNames.icon} src={Templates} />
          Templates
        </Button>
        <Button onClick={onOpenConfirmUrlPopup}>
          <PreviewIcon className='icon' w={8} h={5} />
          Preview
        </Button>
      </HStack>
      {isOpenTemplatePopup && (
        <SelectTemplateForSystem
          system={system}
          isOpen={isOpenTemplatePopup}
          onClose={onCloseTemplatePopup}
        />
      )}
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
