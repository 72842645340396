import { useState } from 'react';
import {
  Box,
  Button,
  Text,
  IconButton,
  Stack,
  HStack,
  Spacer,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Drawer,
  DrawerContent,
  Heading,
  DrawerOverlay,
  DrawerFooter,
  DrawerBody,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { TextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import { isSome } from '../../../config/Maybe';
import {
  useCreateOrgMutation,
  useAddUsersToOrgMutation,
} from '../../../services/orgEndpoints';
import { PositiveConfirmModal } from '../PositiveConfirmModal';

interface AddOrgPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddOrgPopup = ({
  isOpen,
  onClose,
}: AddOrgPopupProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>();
  const [createOrg] = useCreateOrgMutation();
  const [addUsersToOrg] = useAddUsersToOrgMutation();

  const {
    isOpen: isOpenConfirmUrlPopup,
    onOpen: onOpenConfirmUrlPopup,
    onClose: onCloseConfirmUrlPopup,
  } = useDisclosure();

  const orgNameRegister = register('orgName', {
    required: {
      value: true,
      message: 'You must enter a name',
    },
  });
  const orgNameText = watch('orgName');
  const orgURLRegister = register('orgURL', {
    required: {
      value: true,
      message: 'You must enter a url',
    },
  });
  const orgURLText = watch('orgURL');
  const orgNotesRegister = register('notes');
  const orgNotesText = watch('notes');

  const onSubmit = async (temporary: any): Promise<void> => {
    const orgValues = {
      name: temporary.orgName,
      urlExtension: encodeURIComponent(temporary.orgURL),
      notes: temporary.notes,
    };
    const createdOrg = await createOrg(orgValues).unwrap();

    if (isSome(temporary.userEmail) && isSome(temporary.userName)) {
      await addUsersToOrg({
        ...createdOrg,
        users: [{ name: temporary.userName, email: temporary.userEmail }],
      });
    }

    reset();
    onClose();
  };

  const allowOnlyValidUrlChars = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (encodeURIComponent(e.key) !== e.key) {
      e.preventDefault();
    }
  };

  const [showMemberInput, setShowMemberInput] = useState(false);
  const memberIssue =
    (isSome(errors.userEmail) && errors.userEmail.type === 'required') ||
    (isSome(errors.userName) && errors.userName.type === 'required');

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size='lg'
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      {isOpenConfirmUrlPopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmUrlPopup}
          onClose={onCloseConfirmUrlPopup}
          title={`Use this URL extension? This cannot be changed.`}
          message={encodeURIComponent(orgURLText)}
          onCancel={onCloseConfirmUrlPopup}
          onConfirm={async () => {
            onCloseConfirmUrlPopup();
            await handleSubmit(onSubmit)();
          }}
        />
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onOpenConfirmUrlPopup();
        }}
      >
        <DrawerContent py={5}>
          <DrawerBody>
            <Stack px={5} spacing={4}>
              <Heading as='h1' size='md' textAlign='center'>
                Add an organization
              </Heading>
              <Box>
                <Heading as='h2' size='md' mb={5}>
                  Info
                </Heading>
                <TextInput
                  placeholder='Organization name'
                  register={orgNameRegister}
                  text={orgNameText}
                />
                {isSome(errors.orgName) &&
                  errors.orgName.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter an organization name. Please try again.
                    </Text>
                  )}
                <TextInput
                  placeholder='Organization URL'
                  register={orgURLRegister}
                  text={orgURLText}
                  onKeyPress={allowOnlyValidUrlChars}
                />
                {isSome(errors.orgURL) && errors.orgURL.type === 'required' && (
                  <Text variant={'error'}>
                    You must enter a url. Please try again.
                  </Text>
                )}
                <TextInput
                  placeholder='Notes (optional; for internal purposes only)'
                  register={orgNotesRegister}
                  text={orgNotesText}
                />
              </Box>
              <Box>
                <HStack my={5}>
                  <Heading as='h2' size='md'>
                    Members
                  </Heading>
                  <Spacer />
                  <Button onClick={() => setShowMemberInput(true)}>
                    Add new member
                  </Button>
                </HStack>
                <Table variant='simple' size='sm'>
                  <Thead bg='gray.100'>
                    <Tr color='gray.300'>
                      <Th>Email</Th>
                      <Th>Name</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {showMemberInput && (
                      <Tr>
                        <Td>
                          <Input
                            {...register('userEmail', { required: true })}
                            type='text'
                          />
                        </Td>
                        <Td>
                          <Input
                            {...register('userName', { required: true })}
                            type='text'
                          />
                        </Td>
                        <Td>
                          <IconButton
                            aria-label='delete member'
                            textAlign='right'
                            color='red.700'
                            border='none'
                            onClick={() => setShowMemberInput(false)}
                            icon={
                              <FontAwesomeIcon
                                className={(classNames.icon, classNames.faIcon)}
                                icon={faTrashAlt}
                              />
                            }
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {showMemberInput && memberIssue && (
                  <Text variant={'error'}>
                    You must enter an email and a name to add a member. If you
                    do not wish to add a member, please delete the input.
                  </Text>
                )}
              </Box>
            </Stack>
          </DrawerBody>

          <DrawerFooter justifyContent='space-evenly'>
            <Button
              w='45%'
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Input
              type='submit'
              value='Save'
              w='45%'
              bg='viduals.greenDarker'
              color='white'
              fontWeight={500}
            />
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
