import '../TagsPage.scss';
import { Box, HStack } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import closedFolder from '../../../images/fileIcons/closedFolder.svg';
import SortableTree, {
  TreeItem,
  NodeData,
  FullTree,
  OnMovePreviousAndNextLocation,
  find,
  getTreeFromFlatData,
} from 'react-sortable-tree';
import { useState, useEffect } from 'react';
import 'react-sortable-tree/style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { isSome } from '../../../config/Maybe';
import { TagItem } from '../Tags/TagItem';
import { Tag, UpdateTagParams } from '../../../objects/Tag';
import { useUpdateTagMutation } from '../../../services/tagEndpoints';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { setTagGroupToEdit, setTagToEdit } from '../../../store/modalFormSlice';
import { TagTreeType } from '../../../objects/TagOrTagGroup';
import { TagGroup } from '../../../objects/TagGroup';
import {
  useSetTagGroupOrderInProductGroupMutation,
  useSetTagOrderInGroupMutation,
} from '../../../services/tagGroupEndpoints';
import { selectGroupedTags } from '../../../store/tagSlice';
import { selectTagGroups } from '../../../store/tagGroupSlice';
import { TagGroupItem } from './TagGroupItem';

export const ROOT_TREE_ID = 'root';

export const TagGroupList = (): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const groupedTags: Tag[] = useAppSelector(selectGroupedTags);
  const tagGroups: TagGroup[] = useAppSelector(selectTagGroups);
  const dispatch = useAppDispatch();

  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  const [updateTag] = useUpdateTagMutation();
  const [setTagOrderInGroup] = useSetTagOrderInGroupMutation();
  const [setTagGroupOrder] = useSetTagGroupOrderInProductGroupMutation();

  useEffect(() => {
    const treeItems = getTreeItemsFromFlatItems(
      groupedTags,
      tagGroups,
      treeData
    );
    const treeStruct = getTreeFromFlatData({
      flatData: treeItems,
      getKey: (fileTreeNode) => fileTreeNode.id,
      getParentKey: (fileTreeNode) => fileTreeNode.parentId,
      rootKey: ROOT_TREE_ID,
    });

    setTreeData(treeStruct);
  }, [groupedTags, tagGroups]);

  const onMoveNode = async (
    data: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode, nextParentNode, nextPath } = data;
    if (nextPath === null) {
      // node moved outside of tree, handle elsewhere
      return;
    }

    let confirm = false;
    if (isSome(movedNode.tag) && isSome(selectedProductGroup)) {
      const updatedTag: UpdateTagParams = {
        id: movedNode.tag.id,
        name: movedNode.tag.name,
        description: movedNode.tag.description,
        productGroupId: selectedProductGroup.id,
        thumbnailUrl: movedNode.tag.thumnailUrl,
      };

      if (isSome(nextParentNode) && isSome(nextParentNode.tagGroup)) {
        if (movedNode.tag.tagGroupId === nextParentNode.tagGroup.id) {
          confirm = true;
        } else {
          confirm = window.confirm(
            `Move ${String(movedNode.tag.name)} to ${String(
              nextParentNode.tagGroup.name
            )}?`
          );
        }
        updatedTag.tagGroupId = nextParentNode.tagGroup.id;
      } else {
        confirm = window.confirm(
          `Move ${String(movedNode.tag.name)} to Ungrouped Tags?`
        );
      }
      if (!confirm) {
        return;
      }

      await updateTag(updatedTag);
      if (updatedTag.tagGroupId !== undefined) {
        await saveTagOrderInGroup(data);
      }
    } else {
      await saveTagGroupOrderInProductGroup(data);
    }
  };

  const saveTagOrderInGroup = async (
    data: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode, nextParentNode } = data;
    if (!isSome(nextParentNode)) {
      // No tag group, handle this elsewhere
      return;
    }
    const { matches } = find({
      treeData: treeData,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node: searchNode }) => {
        return (
          searchNode.parentId === nextParentNode.id &&
          searchNode.id !== movedNode.id
        );
      },
    });

    const sorted = [...matches, data].sort((a, b) => a.treeIndex - b.treeIndex);
    const idsInOrder = sorted.map((data) => {
      return data.node.id as string;
    });

    await setTagOrderInGroup({
      groupId: nextParentNode.id,
      sortedTagIds: idsInOrder,
    });
  };

  const saveTagGroupOrderInProductGroup = async (
    data: NodeData & FullTree & OnMovePreviousAndNextLocation
  ): Promise<void> => {
    const { node: movedNode, nextParentNode } = data;
    if (isSome(nextParentNode) || movedNode.type === TagTreeType.Tag) {
      // groups should not be made children of others
      // in the wrong method for tags
      return;
    }
    const { matches } = find({
      treeData: treeData,
      getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
      searchMethod: ({ node: searchNode }) => {
        return (
          searchNode.type === TagTreeType.Group &&
          searchNode.id !== movedNode.id
        );
      },
    });

    const sorted = [...matches, data].sort((a, b) => a.treeIndex - b.treeIndex);
    const idsInOrder = sorted.map((data) => {
      return data.node.id as string;
    });

    if (isSome(selectedProductGroup)) {
      await setTagGroupOrder({
        pgid: selectedProductGroup.id,
        sortedTagGroupIds: idsInOrder,
      });
    }
  };

  const getTreeItemsFromFlatItems = (
    tags: Tag[],
    groups: TagGroup[],
    currentTree: TreeItem[]
  ): {
    [key: number]: TreeItem;
  } => {
    const treeItems: TreeItem[] = [];
    tags.forEach((tag) => {
      treeItems.push({
        id: tag.id,
        title: (
          <TagItem onClick={() => dispatch(setTagToEdit(tag))} tag={tag} />
        ),
        type: TagTreeType.Tag,
        tag: tag,
        parentId: tag.tagGroupId,
        childAllowed: false,
      });
    });
    groups.forEach((group) => {
      let expanded = false;
      const { matches } = find({
        treeData: currentTree,
        getNodeKey: (fileTreeNode) => fileTreeNode.node.id,
        searchMethod: ({ node: searchNode }) => {
          return searchNode.id === group.id;
        },
      });
      if (matches.length > 0) {
        expanded = matches[0].node.expanded ?? false;
      }
      treeItems.push({
        id: group.id,
        title: (
          <TagGroupItem
            onClick={() => dispatch(setTagGroupToEdit(group))}
            tagGroup={group}
          />
        ),
        type: TagTreeType.Group,
        tagGroup: group,
        expanded,
        childAllowed: true,
        parentId: ROOT_TREE_ID,
      });
    });
    return treeItems;
  };

  return (
    <Box className='checkableItems' w='50%' h='100%'>
      <HStack>
        <img className={classNames.icon} src={closedFolder} />
        <span style={{ fontWeight: 700 }} className={classNames.columnTitle}>
          Tag Groups
        </span>
      </HStack>
      <Box minH={100} h={'100%'} w='80%'>
        <SortableTree
          treeData={treeData}
          canDrop={(data) => {
            if (isSome(data.nextParent)) {
              return (
                data.nextParent.childAllowed === true &&
                data.node.type === TagTreeType.Tag
              );
            }
            return data.node.type === TagTreeType.Group;
          }}
          onChange={(updatedTreeData) => setTreeData(updatedTreeData)}
          getNodeKey={({ node }) => node.id}
          theme={FileExplorerTheme}
          rowHeight={30}
          rowDirection={'ltr'}
          reactVirtualizedListProps={{
            className: 'file-tree-list',
          }}
          onMoveNode={onMoveNode}
          dndType={'externalNodeType'}
          shouldCopyOnOutsideDrop={false}
        />
      </Box>
    </Box>
  );
};

const classNames = {
  columnTitle: 'tags-page-column-titles',
  icon: 'icon',
};
