import {
  Button,
  Text,
  Stack,
  Modal,
  ModalContent,
  Heading,
  ModalFooter,
  ModalBody,
  HStack,
  Image,
  InputGroup,
  Input,
  InputRightElement,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import Templates from '../../../images/buttonIcons/templates.svg';
import { appColors } from '../../../config/constants';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCreateTemplateMutation } from '../../../services/templateEndpoints';
import { isSome } from '../../../config/Maybe';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import { selectTemplates } from '../../../store/templateSlice';
import { TemplateOption } from './TemplateOption';
import { Template } from '../../../objects/Template';
import '../Popup.scss';
import {
  selectQTemplateToEdit,
  selectTemplateToEdit,
  setQTemplateToEdit,
  setTemplateToEdit,
} from '../../../store/modalFormSlice';
import { TemplateSettingsModalRedesign } from './TemplateSettingsModalRedesign';
import { selectQTemplates } from '../../../store/qTemplateSlice';
import { ProductGroupType } from '../../../objects/ProdGroup';
import { useCreateQTemplateMutation } from '../../../services/qTemplateEndpoints';
import { QTemplateOption } from '../QTemplates/QTemplateOption';
import { QTemplate } from '../../../objects/QuestionnaireTemplate';
import { QTemplateSettingsModal } from '../QTemplates/QTemplateSettingsModal';

interface SelectAddTemplateProps {
  isOpen: boolean;
  onClose: () => void;
}

const nameInputName = 'name';

export const SelectAddTemplate = ({
  isOpen,
  onClose,
}: SelectAddTemplateProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { register, watch, reset, getValues } = useForm<{ name: string }>();
  const [showNewInput, setShowNewInput] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    Template | undefined
  >(undefined);
  const [selectedQTemplate, setSelectedQTemplate] = useState<
    QTemplate | undefined
  >(undefined);
  const [createTemplate] = useCreateTemplateMutation();
  const [createQTemplate] = useCreateQTemplateMutation();
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const templateToEdit = useAppSelector(selectTemplateToEdit);
  const qTemplateToEdit = useAppSelector(selectQTemplateToEdit);
  const templates = useAppSelector(selectTemplates);
  const qTemplates = useAppSelector(selectQTemplates);

  const name = getValues(nameInputName);

  const onSubmitTemplate = async (): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      if (selectedProductGroup.type === ProductGroupType.System) {
        await createTemplate({ name, productGroupId: selectedProductGroup.id });
      } else {
        await createQTemplate({
          name,
          productGroupId: selectedProductGroup.id,
        });
      }
      reset();
      setShowNewInput(false);
    }
  };

  const onClickConfigure = (): void => {
    if (isSome(selectedProductGroup)) {
      if (selectedProductGroup.type === ProductGroupType.System) {
        dispatch(setTemplateToEdit(selectedTemplate));
        setSelectedTemplate(undefined);
      } else {
        dispatch(setQTemplateToEdit(selectedQTemplate));
        setSelectedQTemplate(undefined);
      }
    }
  };

  watch();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      {isSome(templateToEdit) && (
        <TemplateSettingsModalRedesign template={templateToEdit} />
      )}
      {isSome(qTemplateToEdit) && (
        <QTemplateSettingsModal qTemplate={qTemplateToEdit} />
      )}
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Stack spacing={30} pt={15}>
            <Stack>
              <Heading as='h1' size='md'>
                Templates
              </Heading>
              <Text
                color='gray.500'
                borderBottom={'1px'}
                borderColor={appColors.BORDERGRAY}
              >
                Templates are pre-selections of folders, which can be used as a
                starting point when configuring a
                <Tooltip label='configuration'>
                  <Text as='span'>
                    &nbsp;
                    {selectedProductGroup?.configurationName ?? 'configuration'}
                  </Text>
                </Tooltip>
                .
              </Text>
            </Stack>
            <Stack>
              {templates.map((template) => (
                <TemplateOption
                  selected={selectedTemplate?.id === template.id}
                  setSelected={setSelectedTemplate}
                  key={`select template modal option:${template.id}`}
                  template={template}
                />
              ))}
              {qTemplates.map((qTemplate) => (
                <QTemplateOption
                  selected={selectedQTemplate?.id === qTemplate.id}
                  setSelected={setSelectedQTemplate}
                  key={`select qtemplate modal option:${qTemplate.id}`}
                  qTemplate={qTemplate}
                />
              ))}
              {showNewInput && (
                <form
                  style={{ display: 'flex' }}
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await onSubmitTemplate();
                  }}
                >
                  <InputGroup w='100%'>
                    <Input
                      w='100%'
                      type='text'
                      {...register(nameInputName, {
                        required: true,
                        value: '',
                      })}
                    />

                    <InputRightElement width='4.5rem'>
                      <Button
                        border='none'
                        onClick={() => {
                          reset();
                          setShowNewInput(false);
                        }}
                      >
                        <FontAwesomeIcon
                          height={'100%'}
                          color={appColors.GRAY500}
                          className={(classNames.icon, classNames.faIcon)}
                          icon={faTimes}
                        />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Input ml={10} w='25%' type='submit' value='Create' />
                </form>
              )}
              <Button
                border='none'
                outline='none'
                justifyContent='left'
                onClick={() => setShowNewInput(true)}
              >
                <Image className={classNames.icon} src={Templates} />
                New template
              </Button>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent='left'>
          <HStack className='space-between width100'>
            <Button w='45%' onClick={onClose}>
              Cancel
            </Button>
            <Button
              backgroundColor={appColors.GREEN_DARKER}
              color={'white'}
              w='45%'
              disabled={
                selectedTemplate === undefined &&
                selectedQTemplate === undefined
              }
              onClick={onClickConfigure}
            >
              Configure
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
