import {
  Heading,
  Text,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UnorderedList,
  ListItem,
  Tooltip,
} from '@chakra-ui/react';
import { System } from '../../objects/System';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

interface DeleteVersionPopupProps {
  isOpen: boolean;
  onClose: () => void;
  systemsUsed: System[];
  onConfirmDelete: () => void;
}

export const DeleteVersionPopup = ({
  isOpen,
  onClose,
  systemsUsed,
  onConfirmDelete,
}: DeleteVersionPopupProps): JSX.Element => {
  const pGroup = useAppSelector(selectCurrentProductGroup);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='md'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>
          <Heading as='h1' textAlign='center' size='lg'>
            Remove this variation?
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text>
            Are you sure you want to delete? This cannot be reversed, and will
            be made permanent after submission.
          </Text>
          {systemsUsed.length > 0 && (
            <>
              <Text my='20px'>
                This variation is used in {systemsUsed.length} system
                {systemsUsed.length > 1 && 's'}. If deleted, this will no longer
                appear in the following
                <Tooltip label='configuration'>
                  <Text as='span'>
                    &nbsp;
                    {pGroup?.configurationName ?? 'configuration'}(s)
                  </Text>
                </Tooltip>
              </Text>
              <UnorderedList>
                {systemsUsed.map((system) => {
                  return (
                    <ListItem key={`delete version used system:${system.id}`}>
                      {system.serialNumber}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent='space-between' w='100%'>
            <Button w='45%' onClick={onClose}>
              Go back
            </Button>
            <Button
              w='45%'
              variant='delete-confirm'
              onClick={() => {
                onConfirmDelete();
                onClose();
              }}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
