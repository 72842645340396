import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionalOrganization, Organization } from '../objects/Organization';
import { RootState } from './store';

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    userOrgs: [] as Organization[],
    currentOrg: null as OptionalOrganization,
  },
  reducers: {
    setCurrentOrganization: (
      state,
      { payload: organization }: PayloadAction<OptionalOrganization>
    ) => {
      state.currentOrg = organization;
    },
    setUserOrganizations: (
      state,
      { payload: organizations }: PayloadAction<Organization[]>
    ) => {
      state.userOrgs = organizations;
    },
  },
});
export const selectCurrentOrg = (state: RootState): OptionalOrganization =>
  state.organizations.currentOrg;
export const selectCurrentUserOrgs = (state: RootState): Organization[] =>
  state.organizations.userOrgs;

export const { setCurrentOrganization, setUserOrganizations } =
  organizationSlice.actions;

export default organizationSlice.reducer;
