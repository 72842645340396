import { Box, Button, Stack, Text, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { isSome } from '../../../config/Maybe';
import {
  buildFileGetQuestionImageUrlByProdGroup,
  buildFileGetTagImageUrlByProdGroup,
} from '../../../config/S3Client';
import { Customizations } from '../../../objects/Customizations';
import { FolderOrContentQuestion } from '../../../objects/Questions/FolderOrContentQuestion';
import { ITagGroupQuestion } from '../../../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../../../objects/Questions/UngroupedTagQuestion';
import { Tag } from '../../../objects/Tag';
import { useAppSelector } from '../../../store/hooks';
import { selectUngroupedTags } from '../../../store/tagSlice';

interface QuestionAnswerFormProps {
  customizations?: Customizations;
  question: FolderOrContentQuestion | ITagGroupQuestion | IUngroupedTagQuestion;
  answerQuestion: (answer: boolean | string) => void;
  pGroupId: string;
}

export const QuestionAnswerForm = ({
  question,
  answerQuestion,
  pGroupId,
}: QuestionAnswerFormProps): JSX.Element => {
  const unGroupedTags = useAppSelector(selectUngroupedTags);
  const [groupQuestion, setGroupQuestion] = useState<ITagGroupQuestion | null>(
    null
  );
  const [unGroupedTag, setUngroupedTag] = useState<Tag | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    const groupQuestionCheck = question as ITagGroupQuestion;
    if (isSome(groupQuestionCheck.tagOptions)) {
      setGroupQuestion(groupQuestionCheck);
    } else {
      setGroupQuestion(null);
    }
    const questionAsUngrouped = question as IUngroupedTagQuestion;
    if (isSome(questionAsUngrouped.tagId)) {
      const tag =
        unGroupedTags.find((tag) => tag.id === questionAsUngrouped.tagId) ??
        null;
      setUngroupedTag(tag);
    } else {
      setUngroupedTag(null);
    }
    if (question.imageUrl) {
      setImageUrl(
        buildFileGetQuestionImageUrlByProdGroup(pGroupId, question.imageUrl)
      );
    } else {
      setImageUrl('');
    }
  }, [question]);

  const renderQuestionImage = (imageUrl: string): JSX.Element => {
    return (
      <Image
        src={imageUrl}
        fit='contain'
        maxHeight={'200px'}
        justifySelf={'center'}
      />
    );
  };

  const renderGroupQuestion = (gQuestion: ITagGroupQuestion): JSX.Element => {
    return (
      <>
        {gQuestion.tagOptions?.map((option): JSX.Element => {
          let imageUrl = '';
          if (option.thumbnailUrl) {
            imageUrl = buildFileGetTagImageUrlByProdGroup(
              option.productGroupId,
              option.thumbnailUrl
            );
          }
          return (
            <Button
              height={'80px'}
              padding={'0px'}
              borderColor={'var(--vid-customizations-button-color)'}
              color={'var(--vid-customizations-button-color)'}
              key={`tag option answer:${option.id}`}
              onClick={() => answerQuestion(option.id)}
            >
              {imageUrl && (
                <Image src={imageUrl} h='100%' w='20%' fit='contain' />
              )}
              <Text textAlign={'start'} w='80%'>
                {option.name}
              </Text>
            </Button>
          );
        })}
      </>
    );
  };

  const renderUngroupedQuestion = (tag: Tag): JSX.Element => {
    let imageUrl = '';
    if (tag.thumbnailUrl) {
      imageUrl = buildFileGetTagImageUrlByProdGroup(
        tag.productGroupId,
        tag.thumbnailUrl
      );
    }
    return (
      <Box w='100%' display={'flex'} justifyContent={'space-between'}>
        {imageUrl && <Image src={imageUrl} h='100%' w='20%' fit='contain' />}
        <Stack w={imageUrl ? '70%' : '100%'}>
          <Button
            borderColor={'var(--vid-customizations-button-color)'}
            color={'var(--vid-customizations-button-color)'}
            onClick={() => answerQuestion(true)}
          >
            Yes
          </Button>
          <Button
            borderColor={'var(--vid-customizations-button-color)'}
            color={'var(--vid-customizations-button-color)'}
            onClick={() => answerQuestion(false)}
          >
            No
          </Button>
        </Stack>
      </Box>
    );
  };

  const renderFolderContentQuestion = (): JSX.Element => {
    return (
      <>
        <Button
          borderColor={'var(--vid-customizations-button-color)'}
          color={'var(--vid-customizations-button-color)'}
          onClick={() => answerQuestion(true)}
        >
          Yes
        </Button>
        <Button
          borderColor={'var(--vid-customizations-button-color)'}
          color={'var(--vid-customizations-button-color)'}
          onClick={() => answerQuestion(false)}
        >
          No
        </Button>
      </>
    );
  };

  return (
    <>
      <Stack w='60%' minW={600}>
        <Text variant='end-user-questionnaire'>{question.text}</Text>
        {isSome(groupQuestion) && isSome(groupQuestion.tagOptions) ? (
          renderGroupQuestion(groupQuestion)
        ) : (
          <>
            {isSome(unGroupedTag)
              ? renderUngroupedQuestion(unGroupedTag)
              : renderFolderContentQuestion()}
          </>
        )}
      </Stack>
      <Box w={'40%'} display={'flex'} flexDirection={'column'}>
        {imageUrl && renderQuestionImage(imageUrl)}
        <Text>{question.helpText}</Text>
      </Box>
    </>
  );
};
