export enum QTemplateOptions {
  Mandatory = 'Mandatory',
  Optional = 'Optional',
  Unavailable = 'Unavailable',
}

export interface QTemplateFolder {
  selection: QTemplateOptions;
  folderId: string;
}

export interface QTemplateContent {
  selection: QTemplateOptions;
  contentId: string;
}

export interface QTemplateTag {
  selection: QTemplateOptions;
  tagId: string;
}

export interface QTemplate {
  id: string;
  name: string;
  imageUrl?: string;
  qTemplateTags: QTemplateTag[];
  qTemplateFolders: QTemplateFolder[];
  qTemplateContents: QTemplateContent[];
}

export interface InputQTemplateParams {
  name: string;
  productGroupId: string;
  imageUrl?: string;
}

export interface UpdateQTemplateParams extends InputQTemplateParams {
  id: string;
}

export interface IQTemplateWithFoldersContentsAndTags {
  id: string;
  folders: QTemplateFolder[];
  contents: QTemplateContent[];
  tags: QTemplateTag[];
}
