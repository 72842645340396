import React, { ReactNode } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const FirebaseContext = React.createContext<firebase.app.App | undefined>(
  undefined
);

const FirebaseProvider = ({
  app,
  children,
}: {
  app: firebase.app.App;
  children: ReactNode;
}): JSX.Element => {
  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  );
};

const useFirebase = (): firebase.app.App => {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};

export { FirebaseProvider, useFirebase };
