import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { LabelInput } from '../../components/LabelInput';
import { isSome } from '../../config/Maybe';
import { Customizations } from '../../objects/Customizations';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

export interface HeaderSectionProps {
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  errors: DeepMap<any, FieldError>;
  customizations: Customizations | null | undefined;
}
export const HeaderSection = ({
  register,
  watch,
  setValue,
  errors,
  customizations,
}: HeaderSectionProps): JSX.Element => {
  const pGroup = useAppSelector(selectCurrentProductGroup);
  const customTitleRegister = register(CUSTOM_TITLE_KEY, {
    required: true,
  });
  const customTitleText = watch(CUSTOM_TITLE_KEY);

  const [inclClientName, setInclClientName] = useState<boolean>(true);
  const [showClientNameWarning, setShowClientNameWarning] =
    useState<boolean>(false);
  const inclClientNameRegister = register('inclClientName');
  const clientNameRegister = register(CLIENT_NAME_LABEL_KEY);
  const clientNameLabelText = watch(CLIENT_NAME_LABEL_KEY);

  const [inclSerialNumber, setInclSerialNumber] = useState<boolean>(true);
  const inclSerialNumberRegister = register('inclSerialNumber');
  const serialNumberLabelRegister = register(SERIAL_NUMBER_LABEL_KEY);
  const serialNumberLabelText = watch(SERIAL_NUMBER_LABEL_KEY);

  const [inclTemplateName, setInclTemplateName] = useState<boolean>(true);
  const inclTemplateNameRegister = register('inclTemplateName');
  const templateNameLabelRegister = register(TEMPLATE_NAME_LABEL_KEY);
  const templateNameLabelText = watch(TEMPLATE_NAME_LABEL_KEY);

  useEffect(() => {
    setValue(CUSTOM_TITLE_KEY, customizations?.customTitle ?? '');
    setValue(CLIENT_NAME_LABEL_KEY, customizations?.clientNameLabel ?? '');
    setValue(SERIAL_NUMBER_LABEL_KEY, customizations?.serialNumberLabel ?? '');
    setValue(TEMPLATE_NAME_LABEL_KEY, customizations?.templateNameLabel ?? '');

    setValue('inclClientName', customizations?.inclClientName ?? true);
    setInclClientName(customizations?.inclClientName ?? true);
    setValue('inclSerialNumber', customizations?.inclSerialNumber ?? true);
    setInclSerialNumber(customizations?.inclSerialNumber ?? true);
    setValue('inclTemplateName', customizations?.inclTemplateName ?? true);
    setInclTemplateName(customizations?.inclTemplateName ?? true);
  }, [customizations]);
  return (
    <Box>
      <Heading as='h2' size='md' pb={2}>
        Header section
      </Heading>
      <HStack w='100%' alignItems='flex-start' spacing='5%'>
        <Stack w='50%' spacing={6}>
          <Box>
            <LabelInput
              label='Custom title (required)'
              placeholder='Customized Training For:'
              register={customTitleRegister}
            />
            {isSome(errors.customTitle) && (
              <Text variant={'error'}>
                You must enter a custom title. Please try again.
              </Text>
            )}
          </Box>
          <Box>
            <Checkbox
              {...inclClientNameRegister}
              onChange={(event) => {
                setShowClientNameWarning(event.target.checked);

                setInclClientName(!inclClientName);
              }}
              fontWeight={400}
              isChecked={inclClientName}
            >
              Include client name
            </Checkbox>
            {showClientNameWarning && (
              <Text variant={'error'}>
                Setting this may display an empty string if any&nbsp;
                <Tooltip label='configurations'>
                  <Text as='span'>
                    &nbsp;
                    {pGroup?.configurationName ?? 'configuration'}s
                  </Text>
                </Tooltip>
                &nbsp; have been created without a client name. This is
                generally not an issue if the label is also empty.
              </Text>
            )}
            <LabelInput
              label='Label as (optional)'
              placeholder='Client name:'
              register={clientNameRegister}
            />
          </Box>
          <Box>
            <Checkbox
              {...inclSerialNumberRegister}
              onChange={() => {
                setInclSerialNumber(!inclSerialNumber);
              }}
              fontWeight={400}
              isChecked={inclSerialNumber}
            >
              Include&nbsp;
              <Tooltip label='configuration id'>
                <Text as='span'>{pGroup?.configurationIdLabel ?? 'ID'}</Text>
              </Tooltip>
            </Checkbox>
            <LabelInput
              label='Label as (optional)'
              placeholder={`${pGroup?.configurationIdLabel ?? 'ID'}:`}
              register={serialNumberLabelRegister}
            />
          </Box>
          <Box>
            <Checkbox
              {...inclTemplateNameRegister}
              onChange={() => {
                setInclTemplateName(!inclTemplateName);
              }}
              fontWeight={400}
              isChecked={inclTemplateName}
            >
              Include template name
            </Checkbox>
            <LabelInput
              label='Label as (optional)'
              placeholder='Template name:'
              register={templateNameLabelRegister}
            />
          </Box>
        </Stack>
        <Stack w='50%'>
          <Heading as='h3' size='xs' color='gray.500' fontWeight={400}>
            Header section preview
          </Heading>
          <Box
            h={280}
            padding={10}
            bg={
              isSome(customizations)
                ? '#' + customizations.primaryColor
                : 'gray.400'
            }
            borderRadius={8}
          >
            <Stack
              spacing={5}
              color={
                isSome(customizations)
                  ? '#' + customizations.primaryTextColor
                  : 'black'
              }
            >
              <Heading size='md'>
                {isSome(customTitleText)
                  ? customTitleText
                  : String(customizations?.customTitle)}
              </Heading>
              {inclClientName && (
                <Text>
                  {isSome(clientNameLabelText)
                    ? clientNameLabelText
                    : String(customizations?.clientNameLabel)}
                  Client Name
                </Text>
              )}
              {inclSerialNumber && (
                <Text>
                  {isSome(serialNumberLabelText)
                    ? serialNumberLabelText
                    : String(customizations?.serialNumberLabel)}
                  123456789
                </Text>
              )}
              {inclTemplateName && (
                <Text>
                  {isSome(templateNameLabelText)
                    ? templateNameLabelText
                    : String(customizations?.templateNameLabel)}
                  Template Name
                </Text>
              )}
            </Stack>
          </Box>
        </Stack>
      </HStack>
    </Box>
  );
};

const CUSTOM_TITLE_KEY = 'customTitle';
const CLIENT_NAME_LABEL_KEY = 'clientNameLabel';
const SERIAL_NUMBER_LABEL_KEY = 'serialNumberLabel';
const TEMPLATE_NAME_LABEL_KEY = 'templateNameLabel';
