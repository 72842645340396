import {
  HStack,
  Text,
  Checkbox,
  Heading,
  RadioGroup,
  Radio,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import '../Popup.scss';
import { Tag } from '../../../objects/Tag';
import { TagGroup } from '../../../objects/TagGroup';
import { isSome } from '../../../config/Maybe';
import { appColors } from '../../../config/constants';

interface SystemAvailableTagListProps {
  tags: Tag[];
  checkedTagIds: string[];
  toggleTagId: (folderId: string) => void;
  selectGroupedTag: (tag: Tag) => void;
}

export const SystemAvailableTagList = ({
  checkedTagIds,
  toggleTagId,
  tags,
  selectGroupedTag,
}: SystemAvailableTagListProps): JSX.Element => {
  const availableTagGroups: TagGroup[] = [];
  const ungroupedTags: Tag[] = [];
  tags.forEach((tag) => {
    if (isSome(tag.tagGroup)) {
      const exists = availableTagGroups.some(
        (group) => group.id === tag.tagGroup?.id
      );
      if (!exists) {
        availableTagGroups.push({
          ...tag.tagGroup,
          tags: tags.filter((t) => t.tagGroup?.id === tag.tagGroup?.id),
        });
      }
    } else {
      ungroupedTags.push(tag);
    }
  });

  availableTagGroups.sort((a, b) => a.indexWithinParent - b.indexWithinParent);

  return (
    <>
      <Heading as='h4' size='md' alignSelf='flex-start' px='10px'>
        Tags
      </Heading>
      {availableTagGroups.map((group) => {
        const tagIdsInGroup: string[] = tags
          .filter((tag) => tag.tagGroup?.id === group.id)
          .map((tag) => {
            return tag.id;
          });
        const selectedInGroup = checkedTagIds.filter((id) =>
          tagIdsInGroup.includes(id)
        );
        return (
          <Accordion
            allowToggle
            w='100%'
            key={`template tagGroup option: ${group.id}`}
            spacing={0}
            // This defaults the first (only) element to open
            defaultIndex={[0]}
          >
            <AccordionItem w='100%'>
              <AccordionButton
                w='100%'
                h='52px'
                backgroundColor={appColors.GRAY_LIGHT}
                px='10px'
                alignContent='center'
                justifyContent='space-between'
                display='flex'
                color={appColors.GRAY500}
                border='none'
              >
                <Text>{group.name}</Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w='100%' padding='0px'>
                <RadioGroup w='100%' value={selectedInGroup[0] ?? ''}>
                  {group.tags
                    .sort((a, b) => a.indexWithinParent - b.indexWithinParent)
                    .map((tag) => {
                      const checked = checkedTagIds.some((id) => id === tag.id);
                      return (
                        <HStack
                          borderTop={'1px solid #d4d4d8'}
                          key={`template tag option: ${tag.id}`}
                          w='100%'
                          h='52px'
                          px='10px'
                          layerStyle={checked ? 'selected-row' : ''}
                          justifyContent='space-between'
                        >
                          <HStack>
                            <Radio
                              colorScheme='green'
                              value={tag.id}
                              onClick={() => selectGroupedTag(tag)}
                            />
                            <Text>{tag.name}</Text>
                          </HStack>
                        </HStack>
                      );
                    })}
                </RadioGroup>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}
      {ungroupedTags.length > 0 && (
        <Accordion
          allowToggle
          w='100%'
          spacing={0}
          // This defaults the first (only) element to open
          defaultIndex={[0]}
        >
          <AccordionItem w='100%'>
            <AccordionButton
              w='100%'
              h='52px'
              backgroundColor={appColors.GRAY_LIGHT}
              px='10px'
              alignContent='center'
              justifyContent='space-between'
              display='flex'
              color={appColors.GRAY500}
              border='none'
            >
              <Text>Ungrouped Tags</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel w='100%' padding='0px'>
              {ungroupedTags
                .sort((a, b) => a.indexWithinParent - b.indexWithinParent)
                .map((tag) => {
                  const checked = checkedTagIds.some((id) => id === tag.id);
                  return (
                    <HStack
                      borderTop={'1px solid #d4d4d8'}
                      key={`template ungrouped tag option: ${tag.id}`}
                      w='100%'
                      h='52px'
                      px='10px'
                      layerStyle={checked ? 'selected-row' : ''}
                      justifyContent='space-between'
                    >
                      <HStack>
                        <Checkbox
                          colorScheme='green'
                          onChange={() => toggleTagId(tag.id)}
                          isChecked={checked}
                        />
                        <Text>{tag.name}</Text>
                      </HStack>
                    </HStack>
                  );
                })}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
