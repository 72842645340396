import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserData } from '../objects/User';
import { RootState } from './store';

const initialUserData: IUserData = {
  displayName: '',
  email: '',
  uid: '',
  superAdmin: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUserData: initialUserData,
  },
  reducers: {
    setCurrentUserData: (
      state,
      { payload: userData }: PayloadAction<IUserData>
    ) => {
      state.currentUserData = userData;
    },
  },
});
export const selectCurrentUserData = (state: RootState): IUserData =>
  state.auth.currentUserData;

// Action creators are generated for each case reducer function
export const { setCurrentUserData } = authSlice.actions;

export default authSlice.reducer;
