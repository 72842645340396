import './SystemsPage.scss';
import {
  IconButton,
  Stack,
  Spacer,
  HStack,
  useDisclosure,
  Box,
  Button,
  Spinner,
  Text,
  Switch,
  VStack,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import { UserIcon } from '../../config/icons';
import { ProfilePopup } from '../../sharedComponents/modal/ProfilePopup';
import { SystemConfigurationPageHeader } from './SystemConfigurationPageHeader';
import { SelectionCheckableOptions, System } from '../../objects/System';
import { useAppSelector } from '../../store/hooks';
import { useSetSystemSelectionsMutation } from '../../services/systemEndpoints';
import { useState, useEffect } from 'react';
import { Tag } from '../../objects/Tag';
import { SystemAvailableFileTree } from '../../sharedComponents/modal/Systems/SystemAvailableFileTree';
import { SystemAvailableTagList } from '../../sharedComponents/modal/Systems/SystemAvailableTagList';
import { isSome } from '../../config/Maybe';
import { selectTags } from '../../store/tagSlice';
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  selectContentsWithTags,
  selectFoldersWithTags,
} from '../../store/foldersAndContentsWithTagsSlice';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

interface SystemConfigurationPageProps {
  system: System;
}

export const SystemConfigurationPage = ({
  system,
}: SystemConfigurationPageProps): JSX.Element => {
  const pGroup = useAppSelector(selectCurrentProductGroup);
  const allAvailableTags = useAppSelector(selectTags);
  const [updateSystemSelections] = useSetSystemSelectionsMutation();
  const foldersWithTags = useAppSelector(selectFoldersWithTags);
  const contentsWithTags = useAppSelector(selectContentsWithTags);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [treeContainsConflict, setTreeContainsConflict] =
    useState<boolean>(false);
  const [hideUnselected, setHideUnselected] = useState<boolean>(false);
  const [checkedFolderIds, setCheckedFolderIds] = useState<string[]>([]);
  const [checkedContentIds, setCheckedContentIds] = useState<string[]>([]);
  const [checkedTagIds, setCheckedTagIds] = useState<string[]>([]);
  const [tagsToShow, setTagsToShow] = useState<Tag[]>([]);

  const onConfirm = async (): Promise<void> => {
    setIsLoading(true);
    try {
      // convert checked tags and folders to SystemFolder/SystemTag
      await updateSystemSelections({
        id: system.id,
        folders: foldersWithTags.map((f) => {
          return { selection: getFolderSelection(f.id), folderId: f.id };
        }),
        tags: allAvailableTags.map((tag) => {
          return { selection: getTagSelection(tag.id), tagId: tag.id };
        }),
        contents: contentsWithTags.map((c) => {
          return { selection: getContentSelection(c.id), contentId: c.id };
        }),
      });
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };

  const getFolderSelection = (folderId: string): SelectionCheckableOptions => {
    const previousSF = system.systemFolders.find(
      (sf) => sf.folderId === folderId
    );

    if (
      isSome(previousSF) &&
      previousSF.selection === SelectionCheckableOptions.Unavailable
    ) {
      return SelectionCheckableOptions.Unavailable;
    } else if (checkedFolderIds.includes(folderId)) {
      return SelectionCheckableOptions.Checked;
    }
    return SelectionCheckableOptions.UnChecked;
  };

  const getContentSelection = (
    contentId: string
  ): SelectionCheckableOptions => {
    const previousSC = system.systemContents.find(
      (sc) => sc.contentId === contentId
    );

    if (
      isSome(previousSC) &&
      previousSC.selection === SelectionCheckableOptions.Unavailable
    ) {
      return SelectionCheckableOptions.Unavailable;
    } else if (checkedContentIds.includes(contentId)) {
      return SelectionCheckableOptions.Checked;
    }
    return SelectionCheckableOptions.UnChecked;
  };

  const getTagSelection = (tagId: string): SelectionCheckableOptions => {
    const previousST = system.systemTags.find((st) => st.tagId === tagId);

    if (
      isSome(previousST) &&
      previousST.selection === SelectionCheckableOptions.Unavailable
    ) {
      return SelectionCheckableOptions.Unavailable;
    } else if (
      checkedTagIds.includes(tagId) &&
      tagsToShow.map((tag) => tag.id).includes(tagId)
    ) {
      // if the tag is checked, and displayed (tags not shown should not be checkable)
      return SelectionCheckableOptions.Checked;
    }
    return SelectionCheckableOptions.UnChecked;
  };

  const toggleFolderChecked = (folderId: string): void => {
    const exists = checkedFolderIds.some((id) => id === folderId);
    if (exists) {
      setCheckedFolderIds((current) => current.filter((id) => id !== folderId));
    } else {
      setCheckedFolderIds((current) => [...current, folderId]);
    }
  };

  const toggleContentChecked = (contentId: string): void => {
    const exists = checkedContentIds.some((id) => id === contentId);
    if (exists) {
      setCheckedContentIds((current) =>
        current.filter((id) => id !== contentId)
      );
    } else {
      setCheckedContentIds((current) => [...current, contentId]);
    }
  };

  const toggleTagChecked = (tagId: string): void => {
    const exists = checkedTagIds.some((id) => id === tagId);
    if (exists) {
      setCheckedTagIds((current) => current.filter((id) => id !== tagId));
    } else {
      setCheckedTagIds((current) => [...current, tagId]);
    }
  };

  const checkTagInGroup = (clickedTag: Tag): void => {
    const exists = checkedTagIds.some((id) => id === clickedTag.id);
    // if already chosen, deselect
    if (exists) {
      setCheckedTagIds((current) =>
        current.filter((id) => id !== clickedTag.id)
      );
    } else {
      // otherwise, remove other tags from group and add selection
      const tagIdsInGroup: string[] = allAvailableTags
        .filter((tag) => tag.tagGroup?.id === clickedTag.tagGroup?.id)
        .map((tag) => {
          return tag.id;
        });

      setCheckedTagIds((current) => [
        ...current.filter((id) => !tagIdsInGroup.includes(id)),
        clickedTag.id,
      ]);
    }
  };

  useEffect(() => {
    const newTagsToShow: Tag[] = [];
    foldersWithTags.forEach((folder) => {
      if (checkedFolderIds.includes(folder.id)) {
        newTagsToShow.push(...folder.availableTags);
      }
    });
    contentsWithTags.forEach((content) => {
      if (checkedContentIds.includes(content.id)) {
        newTagsToShow.push(...content.availableTags);
      }
    });
    // remove duplicates
    const ids = newTagsToShow.map((tag) => tag.id);
    const uniqueTags = newTagsToShow.filter(
      (tag, index) => !ids.includes(tag.id, index + 1)
    );
    setTagsToShow(uniqueTags);
  }, [checkedFolderIds, foldersWithTags, contentsWithTags, checkedContentIds]);

  useEffect(() => {
    const newSelectedFolderIds: string[] = [];
    system.systemFolders.forEach((sFolder) => {
      if (sFolder.selection === SelectionCheckableOptions.Checked) {
        newSelectedFolderIds.push(sFolder.folderId);
      }
    });
    setCheckedFolderIds(newSelectedFolderIds);

    const newSelectedContentIds: string[] = [];
    system.systemContents.forEach((sContent) => {
      if (sContent.selection === SelectionCheckableOptions.Checked) {
        newSelectedContentIds.push(sContent.contentId);
      }
    });
    setCheckedContentIds(newSelectedContentIds);

    const newSelectedTagIds: string[] = [];
    system.systemTags.forEach((sTag) => {
      if (sTag.selection === SelectionCheckableOptions.Checked) {
        newSelectedTagIds.push(sTag.tagId);
      }
    });
    setCheckedTagIds(newSelectedTagIds);
  }, [system]);

  const {
    isOpen: isOpenProfilePopup,
    onOpen: onOpenProfilePopup,
    onClose: onCloseProfilePopup,
  } = useDisclosure();

  const unavailableFolderIds = system.systemFolders
    .filter((sf) => sf.selection === SelectionCheckableOptions.Unavailable)
    .map((sf) => sf.folderId);
  const unavailableContentIds = system.systemContents
    .filter((sc) => sc.selection === SelectionCheckableOptions.Unavailable)
    .map((sc) => sc.contentId);
  const unavailableTagIds = system.systemTags
    .filter((st) => st.selection === SelectionCheckableOptions.Unavailable)
    .map((st) => st.tagId);

  const availableFolders = foldersWithTags.filter(
    (folder) => !unavailableFolderIds.includes(folder.id)
  );

  const availableContents = contentsWithTags.filter(
    (content) => !unavailableContentIds.includes(content.id)
  );

  const availableTags = tagsToShow.filter(
    (tag) => !unavailableTagIds.includes(tag.id)
  );

  return (
    <Stack w='100%' h='calc(100vh - 60px)'>
      <HStack w='100%'>
        <SystemConfigurationPageHeader system={system} />
        <Button onClick={onConfirm} disabled={isLoading}>
          {isLoading ? <Spinner /> : 'Save'}
        </Button>
        <Spacer />
        <IconButton
          aria-label='Profile'
          border='none'
          icon={<UserIcon className='icon' w={8} h={8} />}
          onClick={onOpenProfilePopup}
        />
        <ProfilePopup
          isOpen={isOpenProfilePopup}
          onClose={onCloseProfilePopup}
        />
      </HStack>
      <Heading as='h1' fontSize='3xl' color='gray.900' pt={5} pb={5}>
        <Tooltip label='configuration'>
          <Text as='span'>{pGroup?.configurationName ?? 'configuration'}</Text>
        </Tooltip>
        :{' '}
        <Tooltip label='Configuration Id'>
          <Text as='span'>{system.serialNumber}</Text>
        </Tooltip>
      </Heading>
      <HStack
        alignContent='center'
        h='61px'
        my='5px'
        justifyContent='space-between'
      >
        <HStack>
          <Text fontWeight={500} fontSize={14} color='gray.600' mb={1}>
            Template: {system.lastAppliedTemplate ?? 'No template Applied'}
          </Text>
          <HStack px={3} mb={8}>
            <Text color='gray.700' fontWeight={500} fontSize={14} mb={1}>
              Hide Unselected
            </Text>
            <Switch
              isChecked={hideUnselected}
              colorScheme='green'
              id='hide-unselected'
              onChange={() => setHideUnselected(!hideUnselected)}
            />
          </HStack>
        </HStack>
        {treeContainsConflict && (
          <HStack layerStyle='warning-box' h='100%' px='15px' spacing='10px'>
            <WarningTwoIcon />{' '}
            <VStack spacing={0} justifyContent='flex-start'>
              <Text w='100%'>Variation Conflict Detected</Text>
              <Text w='100%'>
                Multiple variations of the same content are showing
              </Text>
            </VStack>
          </HStack>
        )}
      </HStack>
      <HStack
        w='100%'
        alignItems='flex-start'
        spacing='20px'
        h={'calc(100% - 65px)'}
      >
        <Box w='60%' mb='30px'>
          <SystemAvailableFileTree
            foldersWithTags={availableFolders}
            checkedFolderIds={checkedFolderIds}
            checkedTagIds={checkedTagIds}
            toggleFolderId={toggleFolderChecked}
            contentsWithTags={availableContents}
            checkedContentIds={checkedContentIds}
            toggleContentId={toggleContentChecked}
            setTreeContainsConflict={setTreeContainsConflict}
            hideUnselected={hideUnselected}
          />
        </Box>
        <Box w='40%' h='calc(100vh - 180px)' overflowY={'auto'}>
          <SystemAvailableTagList
            tags={availableTags}
            checkedTagIds={checkedTagIds}
            toggleTagId={toggleTagChecked}
            selectGroupedTag={checkTagInGroup}
          />
        </Box>
      </HStack>
    </Stack>
  );
};
