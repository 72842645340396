import { z } from 'zod';
import { BaseSchema } from './Base';
import { ContentVersionSchema } from './ContentVersion';
import { FileTreeItemSchema, FileTreeItemTypeSchema } from './FileTreeItem';

// The folder type that the backend/database knows about
export const FolderEntitySchema = BaseSchema.extend({
  name: z.string(),
  isRoot: z.boolean(),
  isOpen: z.boolean(),
  indexWithinParent: z.number(),
  parentFolderId: z.string().optional(),
  parent: BaseSchema.extend({
    name: z.string(),
    indexWithinParent: z.number(),
    isRoot: z.boolean(),
    productGroupId: z.string(),
  }),
  contents: z
    .array(
      BaseSchema.extend({
        name: z.string(),
        description: z.string().optional(),
        indexWithinParent: z.number(),
        parentFolderId: z.string().optional(),
        contentVersions: z.array(ContentVersionSchema).optional(),
        productGroupId: z.string(),
      })
    )
    .optional(),
  childrenFolderIds: z.array(z.string()).optional(),
  productGroupId: z.string(),
});

export type FolderEntity = z.infer<typeof FolderEntitySchema>;

// Frontend-only information for folders
export const FolderSchema = FileTreeItemSchema.merge(FolderEntitySchema).extend(
  {
    type: z.literal(FileTreeItemTypeSchema.Enum.FOLDER),
  }
);
export type Folder = z.infer<typeof FolderSchema>;

export const InputFolderParamsSchema = FolderSchema.pick({
  name: true,
  isOpen: true,
  indexWithinParent: true,
  parentFolderId: true,
  productGroupId: true,
});

// Information needed to update folder
export const UpdateFolderParamsSchema = FolderSchema.pick({
  name: true,
  indexWithinParent: true,
  parentFolderId: true,
});

export type InputFolderParams = z.infer<typeof InputFolderParamsSchema>;
export type UpdateFolderParams = z.infer<typeof UpdateFolderParamsSchema>;

export const DeleteFoldersResultSchema = z.object({
  folderId: z.string(),
});

export type DeleteFoldersResult = z.infer<typeof DeleteFoldersResultSchema>;

export const folderListToMap = (folders: Folder[]): Record<string, Folder> => {
  return folders.reduce((map, folder) => {
    map[folder.id] = folder;
    return map;
  }, {});
};
