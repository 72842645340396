import { Content } from './Content';
import { ContentVersion } from './ContentVersion';
import { ContentWithTags } from './ContentWithTags';
import { Folder } from './Folder';
import { FolderWithTags } from './FolderWithTags';

export enum ItemType {
  Content = 'content',
  Folder = 'folder',
  Version = 'version',
}

export interface FolderOrContentItem {
  item: Folder | Content;
  type: ItemType;
}

export interface FolderContentOrVersion {
  item: Folder | Content | ContentVersion;
  type: ItemType;
}

export interface FoldersOrContentWithTags {
  item: FolderWithTags | ContentWithTags;
  type: ItemType;
}
