/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FoldersOrContentWithTags } from '../objects/FolderOrContentItem';
import { FolderOrContentQuestion } from '../objects/Questions/FolderOrContentQuestion';
import { Questionnaire } from '../objects/Questions/Questionnaire';
import { QuestionnaireTemplateOption } from '../objects/Questions/QuestionnaireTemplateOption';
import { ITagGroupQuestion } from '../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../objects/Questions/UngroupedTagQuestion';
import { RootState } from './store';

const initialState = {
  templateOptionToConfigure: undefined as
    | QuestionnaireTemplateOption
    | undefined,
  questionnaireToConfigure: undefined as Questionnaire | undefined,
  currentRootContent: undefined as FoldersOrContentWithTags | undefined,
  rootQuestionsToConfigure: [] as FolderOrContentQuestion[],
  tagGroupQuestionsToConfigure: [] as ITagGroupQuestion[],
  ungroupedTagQuestionsToConfigure: [] as IUngroupedTagQuestion[],
  questionnaireSaveLoading: false as boolean,
};

export const questionnaireConfiguration = createSlice({
  name: 'questionnaireConfiguration',
  initialState,
  reducers: {
    setOptionToConfigure: (
      state,
      {
        payload: option,
      }: PayloadAction<QuestionnaireTemplateOption | undefined>
    ) => {
      state.templateOptionToConfigure = option;
    },
    setQuestionnaireToConfigure: (
      state,
      { payload: questionnaire }: PayloadAction<Questionnaire | undefined>
    ) => {
      state.questionnaireToConfigure = questionnaire;
    },
    setQuestionnaireSaveLoading: (
      state,
      { payload: isLoading }: PayloadAction<boolean>
    ) => {
      state.questionnaireSaveLoading = isLoading;
    },
    setCurrentQuestionnaireRootContent: (
      state,
      {
        payload: folderOrContentWithTags,
      }: PayloadAction<FoldersOrContentWithTags | undefined>
    ) => {
      state.currentRootContent = folderOrContentWithTags;
    },
    setRootQuestionsToConfigure: (
      state,
      { payload: questions }: PayloadAction<FolderOrContentQuestion[]>
    ) => {
      state.rootQuestionsToConfigure = questions;
    },
    addRootQuestionsToConfigure: (
      state,
      { payload: questions }: PayloadAction<FolderOrContentQuestion[]>
    ) => {
      state.rootQuestionsToConfigure.push(...questions);
    },
    setTagGroupQuestionsToConfigure: (
      state,
      { payload: questions }: PayloadAction<ITagGroupQuestion[]>
    ) => {
      state.tagGroupQuestionsToConfigure = questions;
    },
    addTagGroupQuestionsToConfigure: (
      state,
      { payload: questions }: PayloadAction<ITagGroupQuestion[]>
    ) => {
      state.tagGroupQuestionsToConfigure.push(...questions);
    },
    setUngroupedTagQuestionsToConfigure: (
      state,
      { payload: questions }: PayloadAction<IUngroupedTagQuestion[]>
    ) => {
      state.ungroupedTagQuestionsToConfigure = questions;
    },
    addUngroupedTagQuestionsToConfigure: (
      state,
      { payload: questions }: PayloadAction<IUngroupedTagQuestion[]>
    ) => {
      state.ungroupedTagQuestionsToConfigure.push(...questions);
    },
    updateRootQuestion: (
      state,
      { payload: updatedQuestion }: PayloadAction<FolderOrContentQuestion>
    ) => {
      // We key off tqoId and fcId since some of these could have undefined ids
      return {
        ...state,
        rootQuestionsToConfigure: state.rootQuestionsToConfigure.map(
          (question) =>
            question.folderOrContentId === updatedQuestion.folderOrContentId &&
            question.templateQuestionnaireOptionId ===
              updatedQuestion.templateQuestionnaireOptionId
              ? updatedQuestion
              : question
        ),
      };
    },
    updateGroupQuestion: (
      state,
      { payload: updatedQuestion }: PayloadAction<ITagGroupQuestion>
    ) => {
      // We key off tqoId and gId since some of these could have undefined ids
      return {
        ...state,
        tagGroupQuestionsToConfigure: state.tagGroupQuestionsToConfigure.map(
          (question) =>
            question.tagGroupId === updatedQuestion.tagGroupId &&
            question.templateQuestionnaireOptionId ===
              updatedQuestion.templateQuestionnaireOptionId
              ? updatedQuestion
              : question
        ),
      };
    },
    updateUngroupedQuestion: (
      state,
      { payload: updatedQuestion }: PayloadAction<IUngroupedTagQuestion>
    ) => {
      // We key off tqoId and tId since some of these could have undefined ids
      return {
        ...state,
        ungroupedTagQuestionsToConfigure:
          state.ungroupedTagQuestionsToConfigure.map((question) =>
            question.tagId === updatedQuestion.tagId &&
            question.templateQuestionnaireOptionId ===
              updatedQuestion.templateQuestionnaireOptionId
              ? updatedQuestion
              : question
          ),
      };
    },
    resetQCSlice: (
      _state,
      { payload: questionnaire }: PayloadAction<Questionnaire | undefined>
    ) => {
      return { ...initialState, questionnaireToConfigure: questionnaire };
    },
  },
});

export const selectQuestionnaireTemplateOptionToConfigure = (
  state: RootState
): QuestionnaireTemplateOption | undefined =>
  state.questionnaireConfiguration.templateOptionToConfigure;

export const selectCurrentQuestionnaireRootContent = (
  state: RootState
): FoldersOrContentWithTags | undefined =>
  state.questionnaireConfiguration.currentRootContent;

export const selectQuestionnaireToConfigure = (
  state: RootState
): Questionnaire | undefined =>
  state.questionnaireConfiguration.questionnaireToConfigure;

export const selectRootQuestionsToConfigureByTemplateOptionId = (
  state: RootState,
  templateOptionId: string
): FolderOrContentQuestion[] => {
  return state.questionnaireConfiguration.rootQuestionsToConfigure.filter(
    (question) => question.templateQuestionnaireOptionId === templateOptionId
  );
};

export const selectAllRootQuestionsToConfigure = (
  state: RootState
): FolderOrContentQuestion[] => {
  return state.questionnaireConfiguration.rootQuestionsToConfigure;
};

export const selectTagGroupQuestionsToConfigureByTemplateOptionId = (
  state: RootState,
  templateOptionId: string
): ITagGroupQuestion[] => {
  return state.questionnaireConfiguration.tagGroupQuestionsToConfigure.filter(
    (question) => question.templateQuestionnaireOptionId === templateOptionId
  );
};

export const selectAllTagGroupQuestionsToConfigure = (
  state: RootState
): ITagGroupQuestion[] => {
  return state.questionnaireConfiguration.tagGroupQuestionsToConfigure;
};

export const selectUngroupedTagQuestionsToConfigureByTemplateOptionId = (
  state: RootState,
  templateOptionId: string
): IUngroupedTagQuestion[] => {
  return state.questionnaireConfiguration.ungroupedTagQuestionsToConfigure.filter(
    (question) => question.templateQuestionnaireOptionId === templateOptionId
  );
};

export const selectAllUngroupedTagQuestionsToConfigure = (
  state: RootState
): IUngroupedTagQuestion[] => {
  return state.questionnaireConfiguration.ungroupedTagQuestionsToConfigure;
};

export const selectQuestionnaireSaveLoading = (state: RootState): boolean => {
  return state.questionnaireConfiguration.questionnaireSaveLoading;
};

export const {
  setCurrentQuestionnaireRootContent,
  setOptionToConfigure,
  setQuestionnaireToConfigure,
  setRootQuestionsToConfigure,
  addRootQuestionsToConfigure,
  setUngroupedTagQuestionsToConfigure,
  addUngroupedTagQuestionsToConfigure,
  setTagGroupQuestionsToConfigure,
  addTagGroupQuestionsToConfigure,
  updateRootQuestion,
  updateGroupQuestion,
  updateUngroupedQuestion,
  resetQCSlice,
  setQuestionnaireSaveLoading,
} = questionnaireConfiguration.actions;

export default questionnaireConfiguration.reducer;
