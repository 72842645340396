/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Content } from '../objects/Content';
import { RootState } from './store';
import {
  FolderContentOrVersion,
  FolderOrContentItem,
  ItemType,
} from '../objects/FolderOrContentItem';
import { ContentVersion } from '../objects/ContentVersion';
import { Folder } from '../objects/Folder';
import { isNone } from '../config/Maybe';

export const treeItemSlice = createSlice({
  name: 'treeItems',
  initialState: {
    foldersContentsOrVersions: [] as FolderContentOrVersion[],
  },
  reducers: {
    setTreeRootFoldersAndContent: (
      state,
      { payload: items }: PayloadAction<FolderOrContentItem[]>
    ) => {
      const folderContentOrVersion: FolderContentOrVersion[] = [];

      items.forEach((item) => {
        if (item.type === ItemType.Folder) {
          folderContentOrVersion.push(item);
        } else if (item.type === ItemType.Content) {
          const content = item.item as Content;
          folderContentOrVersion.push(item);
          content.contentVersions?.map((version) =>
            folderContentOrVersion.push({
              item: version,
              type: ItemType.Version,
            })
          );
        }
      });
      state.foldersContentsOrVersions = folderContentOrVersion;
    },
    addFolderOrContentToTree: (
      state,
      { payload: items }: PayloadAction<FolderOrContentItem[]>
    ) => {
      const folderContentOrVersion: FolderContentOrVersion[] = [];

      items.forEach((item) => {
        if (item.type === ItemType.Folder) {
          folderContentOrVersion.push(item);
        } else if (item.type === ItemType.Content) {
          // If item is content, also add the versions to the array
          const content = item.item as Content;
          folderContentOrVersion.push(item);
          content.contentVersions?.map((version) =>
            folderContentOrVersion.push({
              item: version,
              type: ItemType.Version,
            })
          );
        }
      });

      return {
        ...state,
        foldersContentsOrVersions: [
          ...state.foldersContentsOrVersions,
          ...folderContentOrVersion,
        ],
      };
    },
    addVersionToTree: (
      state,
      { payload: versions }: PayloadAction<ContentVersion[]>
    ) => {
      state.foldersContentsOrVersions.push(
        ...versions.map((version) => {
          return { item: version, type: ItemType.Version };
        })
      );
    },
    updateFolderContentOrVersion: (
      state,
      {
        payload: updatedFolderContentOrVersion,
      }: PayloadAction<FolderContentOrVersion>
    ) => {
      return {
        ...state,
        foldersContentsOrVersions: state.foldersContentsOrVersions.map(
          (folderContentOrVersion) =>
            folderContentOrVersion.item.id ===
            updatedFolderContentOrVersion.item.id
              ? updatedFolderContentOrVersion
              : folderContentOrVersion
        ),
      };
    },
    updateFolderOrContentIndexWithinParent: (
      state,
      { payload: foldersAndContents }: PayloadAction<Array<Folder | Content>>
    ) => {
      return {
        ...state,
        foldersContentsOrVersions: state.foldersContentsOrVersions
          .slice()
          .map((folderContentOrVersion) => {
            const updatedFolderOrContent = foldersAndContents.find(
              (folderOrContent) =>
                folderOrContent.id === folderContentOrVersion.item.id
            );
            return updatedFolderOrContent !== undefined
              ? {
                  type: folderContentOrVersion.type,
                  item: {
                    ...folderContentOrVersion.item,
                    indexWithinParent: updatedFolderOrContent.indexWithinParent,
                  },
                }
              : folderContentOrVersion;
          })
          .sort((a: FolderContentOrVersion, b: FolderContentOrVersion) => {
            return a.item.indexWithinParent - b.item.indexWithinParent;
          }),
      };
    },
    updateVersions: (
      state,
      { payload: allVersionsForContent }: PayloadAction<ContentVersion[]>
    ) => {
      if (allVersionsForContent.length > 0) {
        const contentId = allVersionsForContent[0].contentId;
        const newFoldersContentsOrVersions =
          state.foldersContentsOrVersions.filter((folderContentOrVersion) => {
            if (folderContentOrVersion.type === ItemType.Version) {
              const version = folderContentOrVersion.item as ContentVersion;
              if (version.contentId === contentId) {
                return false;
              }
            }
            return true;
          });
        newFoldersContentsOrVersions.push(
          ...allVersionsForContent.map((version) => {
            return {
              item: version,
              type: ItemType.Version,
            };
          })
        );
        state.foldersContentsOrVersions = newFoldersContentsOrVersions.sort(
          (a: FolderContentOrVersion, b: FolderContentOrVersion) => {
            return a.item.indexWithinParent - b.item.indexWithinParent;
          }
        );
      }
    },
    deleteFolderContentOrVersion: (
      state,
      { payload: deletedIds }: PayloadAction<string[]>
    ) => {
      // need to delete versions as well if content is deleted
      const versions = state.foldersContentsOrVersions
        .filter(
          (folderContentOrVersion) =>
            folderContentOrVersion.type === ItemType.Version
        )
        .map((version) => version.item) as ContentVersion[];
      const versionIdsToDelete = versions
        .filter((version) => deletedIds.includes(version.contentId))
        .map((version) => version.id);

      const fullDeleteList = deletedIds.concat(versionIdsToDelete);
      return {
        ...state,
        foldersContentsOrVersions: state.foldersContentsOrVersions.filter(
          (folderContentOrVersion) =>
            !fullDeleteList.includes(folderContentOrVersion.item.id)
        ),
      };
    },
  },
});

export const selectFoldersContentsOrVersions = (
  state: RootState
): FolderContentOrVersion[] => state.treeItems.foldersContentsOrVersions;

export const selectContentsInTree = (state: RootState): Content[] =>
  state.treeItems.foldersContentsOrVersions
    .filter(
      (folderContentOrVersion) =>
        folderContentOrVersion.type === ItemType.Content
    )
    .map((treeItem) => {
      const versions: ContentVersion[] =
        state.treeItems.foldersContentsOrVersions
          .filter((folderContentOrVersion) => {
            if (folderContentOrVersion.type === ItemType.Version) {
              const version = folderContentOrVersion.item as ContentVersion;
              return version.contentId === treeItem.item.id;
            }
            return false;
          })
          .map((content) => content.item) as ContentVersion[];
      const requiredUngroupedTagIds: string[] = [];
      for (const version of versions) {
        if (version.tags) {
          for (const tag of version.tags) {
            if (isNone(tag.tagGroupId)) {
              requiredUngroupedTagIds.push(tag.id);
            }
          }
        }
      }
      return {
        ...treeItem.item,
        requiredUngroupedTags: requiredUngroupedTagIds,
      };
    }) as Content[];

export const selectFoldersInTree = (state: RootState): Folder[] =>
  state.treeItems.foldersContentsOrVersions
    .filter(
      (folderContentOrVersion) =>
        folderContentOrVersion.type === ItemType.Folder
    )
    .map((folder) => folder.item) as Folder[];

export const selectVersionsInTree = (state: RootState): ContentVersion[] =>
  state.treeItems.foldersContentsOrVersions
    .filter(
      (folderContentOrVersion) =>
        folderContentOrVersion.type === ItemType.Version
    )
    .map((content) => content.item) as ContentVersion[];

export const selectVersionsForContentInTree = (
  state: RootState,
  contentId: string
): ContentVersion[] => {
  if (contentId === '') return [];
  return state.treeItems.foldersContentsOrVersions
    .filter((folderContentOrVersion) => {
      if (folderContentOrVersion.type === ItemType.Version) {
        const version = folderContentOrVersion.item as ContentVersion;
        return version.contentId === contentId;
      }
      return false;
    })
    .map((content) => content.item) as ContentVersion[];
};

// Action creators are generated for each case reducer function
export const {
  setTreeRootFoldersAndContent,
  addFolderOrContentToTree,
  updateFolderContentOrVersion,
  deleteFolderContentOrVersion,
  addVersionToTree,
  updateVersions,
  updateFolderOrContentIndexWithinParent,
} = treeItemSlice.actions;

export default treeItemSlice.reducer;
