import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react';
import { isSome } from '../../../config/Maybe';
import { buildFileGetUrlByProdGroup } from '../../../config/S3Client';
import { useEndUserContextContext } from '../../../contexts/EndUserContext';
import { Customizations } from '../../../objects/Customizations';

interface FindingSerialNumberPopupProps {
  customizations: Customizations | null | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export const FindingSerialNumberPopup = ({
  customizations,
  isOpen,
  onClose,
}: FindingSerialNumberPopupProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  const imageUrl = customizations
    ? buildFileGetUrlByProdGroup(
        productGroup,
        customizations.helpImageName ?? ''
      )
    : '';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        pb='20px'
        boxShadow={`1px 1px 5px var(--vid-customizations-secondary-color), -1px -1px 5px var(--vid-customizations-primary-color)`}
        bg={
          isSome(customizations)
            ? '#' + String(customizations.primaryColor)
            : 'black'
        }
        color={
          isSome(customizations)
            ? '#' + String(customizations.primaryTextColor)
            : 'white'
        }
      >
        <ModalHeader>Need help?</ModalHeader>
        <ModalCloseButton border='none' />
        {isSome(customizations) ? (
          <ModalBody>
            <Box
              h={300}
              padding={10}
              borderRadius={8}
              bg={
                isSome(customizations)
                  ? '#' + customizations.primaryColor
                  : 'gray.400'
              }
              color={
                isSome(customizations)
                  ? '#' + customizations.primaryTextColor
                  : 'black'
              }
            >
              <Stack spacing={6}>
                {imageUrl && <Image src={imageUrl} h={200} fit='contain' />}
                {isSome(customizations.helpDescription) && (
                  <Text>{customizations.helpDescription}</Text>
                )}
              </Stack>
            </Box>
          </ModalBody>
        ) : (
          <Text>No help available for this product group</Text>
        )}
      </ModalContent>
    </Modal>
  );
};
