export interface ProdGroup {
  id: string;
  name: string;
  configurationName: string;
  configurationIdLabel: string;
  type: ProductGroupType;
  numSystems: number;
  createdDate: string;
  updatedDate: string;
  urlExtension: string;
  description: string;
}

export type OptionalProdGroup = ProdGroup | null;

export enum ProductGroupType {
  System = 'system',
  Questionnaire = 'questionnaire',
}

export interface NewProdGroupParams {
  name: string;
  configurationName: string;
  configurationIdLabel: string;
  type: ProductGroupType;
  description: string;
  urlExtension: string;
  organizationId: string;
}

export interface UpdateProdGroupParams {
  id?: string;
  name: string;
  configurationName: string;
  configurationIdLabel: string;
  description: string;
}
