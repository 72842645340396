/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProdGroup } from '../objects/ProdGroup';
import { QSystem } from '../objects/System';
import { RootState } from './store';

export const qSystemSlice = createSlice({
  name: 'qSystem',
  initialState: {
    qSystem: undefined as QSystem | undefined,
    qPGroup: undefined as ProdGroup | undefined,
  },
  reducers: {
    setQSystem: (
      state,
      { payload: qSystem }: PayloadAction<QSystem | undefined>
    ) => {
      state.qSystem = qSystem;
    },
    setQPGroup: (
      state,
      { payload: qPGroup }: PayloadAction<ProdGroup | undefined>
    ) => {
      state.qPGroup = qPGroup;
    },
  },
});

export const selectQSystem = (state: RootState): QSystem | undefined =>
  state.qSystem.qSystem;
export const selectQPGroup = (state: RootState): ProdGroup | undefined =>
  state.qSystem.qPGroup;

export const { setQSystem, setQPGroup } = qSystemSlice.actions;

export default qSystemSlice.reducer;
