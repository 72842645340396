import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export const toastSlice = createSlice({
  name: 'toastSlice',
  initialState: {
    filesUploading: [] as string[],
    failedFileUploads: [] as string[],
    succeededFileUploads: [] as string[],
    generalErrors: [] as string[],
  },
  reducers: {
    addFileToUpload: (state, { payload: fileName }: PayloadAction<string>) => {
      state.filesUploading = [...state.filesUploading, fileName];
    },
    removeFileToUpload: (
      state,
      { payload: fileName }: PayloadAction<string>
    ) => {
      state.filesUploading = state.filesUploading.filter(
        (file) => file !== fileName
      );
    },
    addFailedFileToUpload: (
      state,
      { payload: fileName }: PayloadAction<string>
    ) => {
      state.failedFileUploads = [...state.failedFileUploads, fileName];
    },
    removeFailedFileToUpload: (
      state,
      { payload: fileName }: PayloadAction<string>
    ) => {
      state.failedFileUploads = state.failedFileUploads.filter(
        (file) => file !== fileName
      );
    },
    addGeneralError: (state, { payload: errorName }: PayloadAction<string>) => {
      state.generalErrors = [...state.generalErrors, errorName];
    },
    removeGeneralError: (
      state,
      { payload: errorName }: PayloadAction<string>
    ) => {
      state.generalErrors = state.generalErrors.filter(
        (file) => file !== errorName
      );
    },
    addSucceededFileUpload: (
      state,
      { payload: fileName }: PayloadAction<string>
    ) => {
      state.succeededFileUploads = [...state.succeededFileUploads, fileName];
    },
    removeSucceededFileUpload: (
      state,
      { payload: fileName }: PayloadAction<string>
    ) => {
      state.succeededFileUploads = state.succeededFileUploads.filter(
        (file) => file !== fileName
      );
    },
  },
});

export const selectUploadingFiles = (state: RootState): string[] =>
  state.toast.filesUploading;

export const selectFailedFileUploads = (state: RootState): string[] =>
  state.toast.failedFileUploads;

export const selectGeneralErrors = (state: RootState): string[] =>
  state.toast.generalErrors;

export const selectSucceededFileUploads = (state: RootState): string[] =>
  state.toast.succeededFileUploads;

export const {
  addFileToUpload,
  removeFileToUpload,
  addFailedFileToUpload,
  removeFailedFileToUpload,
  addSucceededFileUpload,
  removeSucceededFileUpload,
  addGeneralError,
  removeGeneralError,
} = toastSlice.actions;

export default toastSlice.reducer;
