import {
  Box,
  Button,
  HStack,
  Spacer,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { AddOrgPopup } from '../modal/superadmin/AddOrgPopup';
import { EditOrgPopup } from '../modal/superadmin/EditOrgPopup';
import { OrgItem } from '../orgs/OrgItem';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setUserToEdit } from '../../store/modalFormSlice';
import { User } from '../../objects/User';
import { selectCurrentUserOrgs } from '../../store/organizationSlice';

export const SuperadminTable = (): JSX.Element => {
  const userOrgs = useAppSelector(selectCurrentUserOrgs);
  const dispatch = useAppDispatch();

  const {
    isOpen: isOpenAddOrgPopup,
    onOpen: onOpenAddOrgPopup,
    onClose: onCloseAddOrgPopup,
  } = useDisclosure();
  const {
    isOpen: isOpenEditOrgPopup,
    onOpen: onOpenEditOrgPopup,
    onClose: onCloseEditOrgPopup,
  } = useDisclosure();

  const onCloseClearEditOrgPopup = (): void => {
    dispatch(setUserToEdit(undefined as User | undefined));
    onCloseEditOrgPopup();
  };

  return (
    <Box w='100%'>
      <HStack>
        <Heading as='h1' fontSize='lg' color='gray.900' pt={5} pb={5}>
          Organizations
        </Heading>
        <Spacer />
        <Button
          onClick={onOpenAddOrgPopup}
          variant='fill'
          leftIcon={<AddIcon />}
        >
          New organization
        </Button>
      </HStack>
      <Table variant='simple' size='sm'>
        <Thead bg='gray.100'>
          <Tr color='gray.300'>
            <Th>Organization Name</Th>
            <Th>Url Extension</Th>
            <Th>Members</Th>
            <Th>Notes</Th>
            <Th>Select</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userOrgs.map((org) => {
            return (
              <OrgItem key={org.id} org={org} onClick={onOpenEditOrgPopup} />
            );
          })}
        </Tbody>
      </Table>
      <AddOrgPopup isOpen={isOpenAddOrgPopup} onClose={onCloseAddOrgPopup} />
      <EditOrgPopup
        isOpen={isOpenEditOrgPopup}
        onClose={onCloseClearEditOrgPopup}
      />
    </Box>
  );
};
