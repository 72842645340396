import './QuestionnairePage.scss';
import { Box, VStack } from '@chakra-ui/react';
import { QuestionnaireTemplateOption } from '../../objects/Questions/QuestionnaireTemplateOption';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectQTemplateById } from '../../store/qTemplateSlice';
import { useEffect } from 'react';
import { isSome } from '../../config/Maybe';
import {
  useGetTagGroupQuestionsForTemplateOptionQuery,
  useGetUngroupedTagQuestionsForTemplateOptionQuery,
} from '../../services/questionnaireEndpoints';
import { QTemplateOptions } from '../../objects/QuestionnaireTemplate';
import { FoldersOrContentWithTags } from '../../objects/FolderOrContentItem';
import {
  addTagGroupQuestionsToConfigure,
  addUngroupedTagQuestionsToConfigure,
  selectTagGroupQuestionsToConfigureByTemplateOptionId,
  selectUngroupedTagQuestionsToConfigureByTemplateOptionId,
} from '../../store/questionnaireConfigurationSlice';
import { TagOptionForQuestion } from '../../objects/Tag';
import { selectTags } from '../../store/tagSlice';
import { selectTagGroups } from '../../store/tagGroupSlice';
import { ITagGroupQuestion } from '../../objects/Questions/TagGroupQuestion';
import { IUngroupedTagQuestion } from '../../objects/Questions/UngroupedTagQuestion';
import { TagGroupQuestionCard } from './QuestionCards/TagGroupQuestionCard';
import { UngroupedTagQuestionCard } from './QuestionCards/UngroupedTagQuestionCard';

interface TagQuestionCardListProps {
  templateOption: QuestionnaireTemplateOption;
  activeRootItem: FoldersOrContentWithTags;
}

export const TagQuestionCardList = ({
  templateOption,
  activeRootItem,
}: TagQuestionCardListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const groupQuestionsOnSlice = useAppSelector((state) =>
    selectTagGroupQuestionsToConfigureByTemplateOptionId(
      state,
      templateOption.id
    )
  );
  const ungroupedQuestionsOnSlice = useAppSelector((state) =>
    selectUngroupedTagQuestionsToConfigureByTemplateOptionId(
      state,
      templateOption.id
    )
  );

  const allTags = useAppSelector(selectTags);
  const allTagGroups = useAppSelector(selectTagGroups);

  const { data: savedGroupQuestions, isLoading: savedGroupQuestionsLoading } =
    useGetTagGroupQuestionsForTemplateOptionQuery(templateOption.id);
  const { data: savedTagQuestions, isLoading: savedTagQuestionsLoading } =
    useGetUngroupedTagQuestionsForTemplateOptionQuery(templateOption.id);
  const qTemplate = useAppSelector((state) =>
    selectQTemplateById(state, templateOption.questionnaireTemplateId)
  );

  useEffect(() => {
    if (
      isSome(qTemplate) &&
      isSome(savedGroupQuestions) &&
      isSome(savedTagQuestions) &&
      !savedGroupQuestionsLoading &&
      !savedTagQuestionsLoading
    ) {
      const usedGroupIds = activeRootItem.item.availableTags
        .map((tag) => {
          return tag.tagGroupId;
        })
        .filter((groupId) => isSome(groupId)) as string[];

      const usedUngroupedTagIds = activeRootItem.item.availableTags
        .filter((tag) => !isSome(tag.tagGroupId))
        .map((tag) => tag.id);

      const necessaryTags: TagOptionForQuestion[] = qTemplate.qTemplateTags
        .filter(
          (qtTag) =>
            qtTag.selection === QTemplateOptions.Optional ||
            qtTag.selection === QTemplateOptions.Mandatory
        )
        .map((qtTag): TagOptionForQuestion | undefined => {
          const foundTag = allTags.find((t) => t.id === qtTag.tagId);
          if (isSome(foundTag)) {
            return {
              ...foundTag,
              mandatory: qtTag.selection === QTemplateOptions.Mandatory,
            };
          }
          return undefined;
        })
        .filter((tagOption) => isSome(tagOption)) as TagOptionForQuestion[];

      const ungroupedTags = necessaryTags
        .filter((tag) => !isSome(tag.tagGroupId))
        .filter((t) => usedUngroupedTagIds.some((tagId) => tagId === t.id))
        .sort(
          (a, b) => (a.indexWithinParent ?? 0) - (b.indexWithinParent ?? 0)
        );

      const allNecessaryTagGroupIds = necessaryTags
        .filter((tag) => isSome(tag.tagGroupId))
        .map((tag) => tag.tagGroupId) as string[];
      const uniqueGroupIds = [...new Set(allNecessaryTagGroupIds)];

      const necessaryGroups = allTagGroups
        .filter((tg) => uniqueGroupIds.some((tgId) => tgId === tg.id))
        .filter((tg) => usedGroupIds.some((groupId) => groupId === tg.id))
        .sort(
          (a, b) => (a.indexWithinParent ?? 0) - (b.indexWithinParent ?? 0)
        );

      const necessaryGroupQuestions = necessaryGroups.map(
        (group): ITagGroupQuestion => {
          const tagOptions = necessaryTags.filter(
            (to) => to.tagGroupId === group.id
          );
          return {
            text: `Which ${group.name} do you have?`,
            helpText: '',
            templateQuestionnaireOptionId: templateOption.id,
            tagGroupId: group.id,
            tagOptions,
            mandatory: tagOptions.some((to) => to.mandatory),
          };
        }
      );
      const necessaryUngroupedQuestions = ungroupedTags.map(
        (tag): IUngroupedTagQuestion => {
          return {
            text: `Do you have ${tag.name}`,
            helpText: '',
            templateQuestionnaireOptionId: templateOption.id,
            tagId: tag.id,
            mandatory: tag.mandatory,
          };
        }
      );

      const newAndExistingGroupQuestions = necessaryGroupQuestions.map(
        (question) => {
          const existingQuestion = savedGroupQuestions.find(
            (sQuestion) =>
              sQuestion.tagGroupId === question.tagGroupId &&
              sQuestion.templateQuestionnaireOptionId ===
                question.templateQuestionnaireOptionId
          );
          if (isSome(existingQuestion) && !question.mandatory) {
            return existingQuestion;
          }
          return question;
        }
      );
      const newAndExistingUngroupedQuestions = necessaryUngroupedQuestions.map(
        (question) => {
          const existingQuestion = savedTagQuestions.find(
            (sQuestion) =>
              sQuestion.tagId === question.tagId &&
              sQuestion.templateQuestionnaireOptionId ===
                question.templateQuestionnaireOptionId
          );
          if (isSome(existingQuestion) && !question.mandatory) {
            return existingQuestion;
          }
          return question;
        }
      );

      const newGroupedQuestions = newAndExistingGroupQuestions.filter(
        (newQuestion) =>
          !groupQuestionsOnSlice.some(
            (existingQuestion) =>
              newQuestion.tagGroupId === existingQuestion.tagGroupId &&
              newQuestion.templateQuestionnaireOptionId ===
                existingQuestion.templateQuestionnaireOptionId
          )
      );
      const newUngroupedQuestions = newAndExistingUngroupedQuestions.filter(
        (newQuestion) =>
          !ungroupedQuestionsOnSlice.some(
            (existingQuestion) =>
              newQuestion.tagId === existingQuestion.tagId &&
              newQuestion.templateQuestionnaireOptionId ===
                existingQuestion.templateQuestionnaireOptionId
          )
      );
      dispatch(addTagGroupQuestionsToConfigure(newGroupedQuestions));
      dispatch(addUngroupedTagQuestionsToConfigure(newUngroupedQuestions));
    }
  }, [
    savedGroupQuestions,
    savedTagQuestions,
    templateOption,
    qTemplate,
    activeRootItem,
  ]);

  return (
    <Box h='100%' mb={5} minW='400px' overflowY={'scroll'}>
      <VStack mb={5} w='400px'>
        {groupQuestionsOnSlice.map((question) => (
          <TagGroupQuestionCard
            question={question}
            groupQuestionsOnSlice={groupQuestionsOnSlice}
            key={`root question card ${question.tagGroupId}, ${question.templateQuestionnaireOptionId}`}
            activeRootItem={activeRootItem}
          />
        ))}
        {ungroupedQuestionsOnSlice.map((question) => (
          <UngroupedTagQuestionCard
            question={question}
            ungroupedQuestionsOnSlice={ungroupedQuestionsOnSlice}
            key={`root question card ${question.tagId}, ${question.templateQuestionnaireOptionId}`}
            activeRootItem={activeRootItem}
          />
        ))}
      </VStack>
    </Box>
  );
};
