import './SystemsPage.scss';
import {
  Box,
  HStack,
  Button,
  Image,
  useDisclosure,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { AddSystemPopup } from '../../sharedComponents/modal/Systems/AddSystemPopup';
import { SelectAddTemplate } from '../../sharedComponents/modal/Templates/SelectAddTemplate';
import Templates from '../../images/buttonIcons/templates.svg';
import { appColors } from '../../config/constants';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';

export const SystemsPageHeader = (): JSX.Element => {
  const productGroup = useAppSelector(selectCurrentProductGroup);
  const {
    isOpen: isOpenAddSystemPopup,
    onOpen: onOpenAddSystemPopup,
    onClose: onCloseAddSystemPopup,
  } = useDisclosure();

  const {
    isOpen: isOpenTemplatePopup,
    onOpen: onOpenTemplatePopup,
    onClose: onCloseTemplatePopup,
  } = useDisclosure();

  return (
    <Box>
      <HStack>
        <Button
          color='white'
          backgroundColor={appColors.GREEN_DARKER}
          onClick={onOpenAddSystemPopup}
          leftIcon={
            <FontAwesomeIcon
              className={(classNames.icon, classNames.faIcon)}
              icon={faTools}
            />
          }
        >
          New
          <Tooltip label='New configuration'>
            <Text as='span'>
              &nbsp;
              {productGroup?.configurationName ?? 'configuration'}
            </Text>
          </Tooltip>
        </Button>
        <Button onClick={onOpenTemplatePopup}>
          <Image className={classNames.icon} src={Templates} />
          Templates
        </Button>
      </HStack>
      {isOpenAddSystemPopup && (
        <AddSystemPopup
          isOpen={isOpenAddSystemPopup}
          onClose={onCloseAddSystemPopup}
        />
      )}
      {isOpenTemplatePopup && (
        <SelectAddTemplate
          isOpen={isOpenTemplatePopup}
          onClose={onCloseTemplatePopup}
        />
      )}
    </Box>
  );
};

const classNames = {
  icon: 'icon',
  faIcon: 'faIcon',
};
