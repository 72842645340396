import {
  Button,
  Stack,
  Modal,
  ModalContent,
  Heading,
  ModalFooter,
  ModalBody,
  HStack,
  ModalOverlay,
  Input,
  Text,
  FormLabel,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { TextInput } from '../../../components/TextInput';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCurrentProductGroup } from '../../../store/productGroupSlice';
import '../Popup.scss';
import { TextAreaInput } from '../../../components/TextAreaInput';
import { InputSystemParams } from '../../../objects/System';
import {
  useApplyTemplateToSystemMutation,
  useCreateSystemMutation,
} from '../../../services/systemEndpoints';
import { isSome } from '../../../config/Maybe';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import { PositiveConfirmModal } from '../PositiveConfirmModal';
import { selectTemplates } from '../../../store/templateSlice';
import Select from 'react-select';
import { addGeneralError } from '../../../store/toastSlice';
import { selectCurrentOrg } from '../../../store/organizationSlice';
import { useGetCustomizationsByProductGroupIdQuery } from '../../../services/customizationsEndpoints';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface SelectInterface {
  value: string;
  label: string;
}

interface AddSystemPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddSystemPopup = ({
  isOpen,
  onClose,
}: AddSystemPopupProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [warrantyStart, setWarrantyStart] = useState<Date | null>(null);
  const [warrantyEnd, setWarrantyEnd] = useState<Date | null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<InputSystemParams>();

  const {
    isOpen: isOpenConfirmUrlPopup,
    onOpen: onOpenConfirmUrlPopup,
    onClose: onCloseConfirmUrlPopup,
  } = useDisclosure();
  const systemUrlText = watch('urlExtension');

  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);
  const selectedOrg = useAppSelector(selectCurrentOrg);
  const templates = useAppSelector(selectTemplates);
  const [templateOptions, setTemplateOptions] = useState<SelectInterface[]>([]);
  const [createSystem] = useCreateSystemMutation();
  const [applyTemplateToSystem] = useApplyTemplateToSystemMutation();

  const { data: currentCustomizations } =
    useGetCustomizationsByProductGroupIdQuery(
      selectedProductGroup?.id ?? skipToken
    );

  useEffect(() => {
    setTemplateOptions([
      ...templates.map((template) => {
        return { value: template.id, label: template.name };
      }),
    ]);
  }, [templates]);

  const handleFormSubmit = async (
    systemInfo: InputSystemParams
  ): Promise<void> => {
    if (isSome(selectedProductGroup)) {
      const warrantyStartString = isSome(warrantyStart)
        ? new Date(warrantyStart).toLocaleDateString()
        : '';
      const warrantyEndString = isSome(warrantyEnd)
        ? new Date(warrantyEnd).toLocaleDateString()
        : '';

      try {
        const newSystem = await createSystem({
          ...systemInfo,
          urlExtension: encodeURIComponent(systemInfo.urlExtension),
          warrantyStart: warrantyStartString,
          warrantyEnd: warrantyEndString,
          productGroupId: selectedProductGroup.id,
        }).unwrap();
        if (selectedTemplateId !== '') {
          await applyTemplateToSystem({
            systemId: newSystem.id,
            templateId: selectedTemplateId,
          });
        }
        onClose();
      } catch (err: any) {
        if (err.status === 409) {
          dispatch(
            addGeneralError(
              `Failed to create ${selectedProductGroup.configurationName}, are you sure your ${selectedProductGroup.configurationIdLabel} and url extension are unique?`
            )
          );
        } else {
          dispatch(
            addGeneralError(
              `Failed to create ${selectedProductGroup.configurationName}.`
            )
          );
        }
      }
    }
  };

  const allowOnlyValidUrlChars = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (encodeURIComponent(e.key) !== e.key) {
      e.preventDefault();
    }
  };

  const handleWarrantyStartChange = (date: Date | null): void => {
    if (isSome(date)) {
      setWarrantyStart(date);
    } else {
      setWarrantyStart(null);
    }
  };

  const handleWarrantyEndChange = (date: Date | null): void => {
    if (isSome(date)) {
      setWarrantyEnd(date);
    } else {
      setWarrantyEnd(null);
    }
  };

  const onSelectTemplate = async (
    option: SelectInterface | null
  ): Promise<void> => {
    if (isSome(option)) {
      setSelectedTemplateId(option.value);
    } else {
      setSelectedTemplateId('');
    }
  };

  const systemDescriptionRegister = register('description');
  const systemDescriptionText = watch('description');
  const systemSerialRegister = register('serialNumber', { required: true });
  const systemSerialText = watch('serialNumber');
  const systemClientRegister = register('clientName', {
    required: currentCustomizations?.inclClientName ?? true,
  });
  const systemClientText = watch('clientName');

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      {isOpenConfirmUrlPopup && (
        <PositiveConfirmModal
          isOpen={isOpenConfirmUrlPopup}
          onClose={onCloseConfirmUrlPopup}
          title={`Use this URL extension? This cannot be changed.`}
          message={encodeURIComponent(systemUrlText)}
          onCancel={onCloseConfirmUrlPopup}
          onConfirm={async () => {
            onCloseConfirmUrlPopup();
            await handleSubmit(handleFormSubmit)();
          }}
        />
      )}
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onOpenConfirmUrlPopup();
          }}
        >
          <ModalBody>
            <Stack spacing={30} pt={15}>
              <Heading as='h1' size='md'>
                <Tooltip label='configuration'>
                  <Text as='span'>
                    {selectedProductGroup?.configurationName ?? 'Configuration'}
                    &nbsp;
                  </Text>
                </Tooltip>
                information
              </Heading>

              <Stack>
                <FormLabel>URL</FormLabel>
                <HStack>
                  <Text color='gray.500'>
                    ...viduals.com/{selectedOrg?.urlExtension}/
                    {selectedProductGroup?.urlExtension}/
                  </Text>
                  <Input
                    {...register('urlExtension', { required: true })}
                    placeholder='URL'
                    type='text'
                    onKeyPress={allowOnlyValidUrlChars}
                  />
                </HStack>
                {isSome(errors.urlExtension) &&
                  errors.urlExtension.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter a url extension. Please try again.
                    </Text>
                  )}
                <TextAreaInput
                  placeholder='Description (optional)'
                  register={systemDescriptionRegister}
                  text={systemDescriptionText}
                />
                <TextInput
                  placeholder={`${
                    selectedProductGroup?.configurationIdLabel ?? 'ID'
                  }`}
                  register={systemSerialRegister}
                  text={systemSerialText}
                />
                {isSome(errors.serialNumber) &&
                  errors.serialNumber.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter a(n)&nbsp;
                      <Tooltip label='configuration id'>
                        <Text as='span'>
                          {selectedProductGroup?.configurationIdLabel ?? 'ID'}.
                        </Text>
                      </Tooltip>
                      Please try again.
                    </Text>
                  )}
                <TextInput
                  placeholder='Client Name'
                  register={systemClientRegister}
                  text={systemClientText}
                />
                {isSome(errors.clientName) &&
                  errors.clientName.type === 'required' && (
                    <Text variant={'error'}>
                      You must enter a client name. Please try again. If you do
                      not wish to inlcude the client name, navigate to the
                      customizations page, uncheck &quot;Include client
                      name&quot; and save the change.
                    </Text>
                  )}
                <Stack>
                  <Heading as='h2' size='sm'>
                    Template
                  </Heading>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    name='template'
                    options={templateOptions}
                    onChange={onSelectTemplate}
                  />
                </Stack>
                <FormLabel>Warranty</FormLabel>
                <HStack w='100%' spacing='5%'>
                  <HStack w='45%' boxSizing='border-box'>
                    <Text>Start:</Text>
                    <DatePicker
                      selected={warrantyStart}
                      onChange={handleWarrantyStartChange}
                    />
                  </HStack>
                  <HStack w='45%' boxSizing='border-box'>
                    <Text>End:</Text>
                    <DatePicker
                      selected={warrantyEnd}
                      onChange={handleWarrantyEndChange}
                    />
                  </HStack>
                </HStack>
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack className='space-between width100'>
              <Button w='45%' onClick={onClose}>
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='50%' />
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
