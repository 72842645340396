import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Heading,
  HStack,
  Button,
  Input,
  Text,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  CloseButton,
} from '@chakra-ui/react';
import { CreateContent, InputContentParams } from '../../objects/Content';
import { Folder } from '../../objects/Folder';
import { TextInput } from '../../components/TextInput';
import { isSome } from '../../config/Maybe';
import { InputContentVersionParams } from '../../objects/ContentVersion';
import { ContentVersionForm } from './content/ContentVersionForm';
import { FoldersList } from './FoldersList';

interface AddAvailabilityFormFields extends InputContentParams {
  versions: InputContentVersionParams[];
}

interface AddContentPopupProps {
  createContent: CreateContent;
  createVersions: (
    contentId: string,
    versions: InputContentVersionParams[]
  ) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}

let versionCounter = 1;

export const AddContentPopup = ({
  createContent,
  createVersions,
  isOpen,
  onClose,
}: AddContentPopupProps): JSX.Element => {
  const [parentFolder, setParentFolder] = useState<Folder>();

  const [versions, setVersions] = useState<InputContentVersionParams[]>([
    { contentId: '', tagIds: [], key: 0 },
  ]);
  const [pdfFilesToUpload, setPdfsToUpload] = useState<File[]>([]);
  const [videoFilesToUpload, setVideosToUpload] = useState<File[]>([]);
  const [fileChangesString, setFileUploadsModified] = useState<string>('');

  const duplicateVersion = (
    versionToDuplicate: InputContentVersionParams
  ): void => {
    setVersions((current) => [
      ...current,
      { ...versionToDuplicate, key: versionCounter },
    ]);
    versionCounter++;
  };

  const removeVersion = (index: number): void => {
    const newVersions = [
      ...versions.slice(0, index),
      ...versions.slice(index + 1),
    ];
    setVersions(newVersions);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (
    formContent: AddAvailabilityFormFields
  ): Promise<void> => {
    onClose();

    formContent.parentFolderId = parentFolder?.id;

    try {
      const createdContent = await createContent(formContent);
      createVersions(createdContent.id, versions).catch((err) =>
        console.log(err)
      );
      setVersions([{ contentId: '', tagIds: [], key: 0 }]);
      setParentFolder(undefined);
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  const handleClosePopup = (): void => {
    const close = window.confirm(
      'Are you sure you wish close this? all progress will be lost.'
    );

    if (close) {
      reset();
      onClose();
    }
  };

  const contentNameRegister = register('name', { required: true });
  const contentNameText = watch('name');
  const contentDescriptionRegister = register('description');
  const contentDescriptionText = watch('description');

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const pdfsBeingUploaded: File[] = versions
      .map((version) => version.pdfFile)
      .filter((file) => file !== undefined) as File[];
    setPdfsToUpload(pdfsBeingUploaded);

    const videosBeingUploaded: File[] = versions
      .map((version) => version.videoFile)
      .filter((file) => file !== undefined) as File[];
    setVideosToUpload(videosBeingUploaded);
  }, [versions, fileChangesString]);

  const modifyFileUploads = (file: File | undefined): void => {
    setFileUploadsModified((current) =>
      current.concat(file !== undefined ? file.name : 'undefined')
    );
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='lg'
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent width={400} pr={0}>
            <CloseButton
              alignSelf='flex-end'
              mt='10px'
              mr='10px'
              onClick={handleClosePopup}
            />
            <DrawerHeader align='center'>Create new content</DrawerHeader>
            <DrawerBody>
              <Stack>
                <Heading as='h1' size='md'>
                  Basic Information
                </Heading>
                <TextInput
                  placeholder='Content name'
                  register={contentNameRegister}
                  text={contentNameText}
                />
                {isSome(errors.name) && errors.name.type === 'required' && (
                  <Text variant={'error'}>
                    You must enter a content name. Please try again.
                  </Text>
                )}
                <TextInput
                  placeholder='Description (optional)'
                  register={contentDescriptionRegister}
                  text={contentDescriptionText}
                />
                <Heading as='h2' size='sm'>
                  Location
                </Heading>
                <FoldersList
                  selectedFolderId={parentFolder?.id}
                  setCurrentFolder={(folder: Folder) => setParentFolder(folder)}
                />
                <Heading as='h1' size='md'>
                  Variations
                </Heading>
                <Button
                  onClick={() => {
                    setVersions((current) => [
                      ...current,
                      { contentId: '', tagIds: [], key: versionCounter },
                    ]);
                    versionCounter++;
                  }}
                >
                  Add new variation
                </Button>
                <Text>
                  Every variation must have either a video or PDF (or both).
                </Text>
                {versions.map(
                  (version, index): JSX.Element => (
                    <React.Fragment key={`add version form frag:${index}`}>
                      {/* key is added just to get delete to show the right thing */}
                      {isSome(version.key) && (
                        <ContentVersionForm
                          key={`add version form: ${version.key}`}
                          version={version}
                          index={index}
                          removeVersion={removeVersion}
                          duplicateVersion={duplicateVersion}
                          pdfsBeingUploaded={pdfFilesToUpload}
                          videosBeingUploaded={videoFilesToUpload}
                          triggerModifiedFileUploads={modifyFileUploads}
                        />
                      )}
                    </React.Fragment>
                  )
                )}
                {isSome(errors.name) && errors.name.type === 'required' && (
                  <Text className='error'>You must enter a folder name.</Text>
                )}
                <HStack className='space-between width100'></HStack>
              </Stack>
            </DrawerBody>
            <DrawerFooter justifyContent='space-between'>
              <Button w='45%' onClick={handleClosePopup}>
                Cancel
              </Button>
              <Input className='modal-button' type='submit' w='50%' />
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </>
  );
};
