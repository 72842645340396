import { z } from 'zod';
import { BaseSchema } from './Base';
import { FileTreeItemSchema, FileTreeItemTypeSchema } from './FileTreeItem';
import { ContentVersionSchema } from './ContentVersion';
import { FolderSchema } from './Folder';

// Entity information is stuff the database knows about
export const ContentEntitySchema = BaseSchema.extend({
  name: z.string(),
  description: z.string().optional(),
  indexWithinParent: z.number(),
  parentFolderId: z.string().optional(),
  parent: FolderSchema,
  contentVersions: z.array(ContentVersionSchema).optional(),
  productGroupId: z.string(),
});
export type ContentEntity = z.infer<typeof ContentEntitySchema>;

// Union with FileTreeItem for display information
export const ContentSchema = FileTreeItemSchema.merge(
  ContentEntitySchema
).extend({
  type: z.literal(FileTreeItemTypeSchema.Enum.CONTENT),
  requiredUngroupedTags: z.array(z.string()).optional(), // keeps track of which ungrouped tags are used in any variation of content
});
export type Content = z.infer<typeof ContentSchema>;

// Information needed to create new content
export const InputContentParamsSchema = ContentSchema.omit({
  id: true,
  createdDate: true,
  updatedDate: true,
});
// Information needed to update content
export const UpdateContentParamsSchema = ContentSchema.pick({
  name: true,
  description: true,
  parentFolderId: true,
});
export type InputContentParams = z.infer<typeof InputContentParamsSchema>;
export type UpdateContentParams = z.infer<typeof UpdateContentParamsSchema>;

export type CreateContent = (content: InputContentParams) => Promise<Content>;

// Information received from backend when deleting content
export const DeleteContentResultSchema = z.object({
  contentId: z.string(),
});

export type DeleteContentResult = z.infer<typeof DeleteContentResultSchema>;

export const contentListToMap = (
  contents: Content[]
): Record<string, Content> => {
  return contents.reduce((map, content) => {
    map[content.id] = content;
    return map;
  }, {});
};
