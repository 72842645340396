/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Folder, folderListToMap } from '../objects/Folder';
import { RootState } from './store';
import values from 'lodash/values';

const initialFolders: Record<string, Folder> = {};

export const folderSlice = createSlice({
  name: 'folders',
  initialState: {
    foldersMap: initialFolders,
    rawFolders: [] as Folder[],
  },
  reducers: {
    setFolders: (state, { payload: folders }: PayloadAction<Folder[]>) => {
      state.foldersMap = folderListToMap(folders);
    },
    setRawFolders: (state, { payload: folders }: PayloadAction<Folder[]>) => {
      state.rawFolders = folders;
    },
  },
});

export const selectFoldersMap = (state: RootState): Record<string, Folder> => {
  const nonRootFolders = values(state.folders.foldersMap).filter((folder) => {
    return !folder.isRoot;
  });
  return folderListToMap(nonRootFolders);
};

export const selectRawFolders = (state: RootState): Folder[] =>
  state.folders.rawFolders;

// Action creators are generated for each case reducer function
export const { setFolders, setRawFolders } = folderSlice.actions;

export default folderSlice.reducer;
