import { VStack, Text, Button } from '@chakra-ui/react';
import { DefaultEndUserColors } from '../../../config/constants';
import { isSome } from '../../../config/Maybe';
import { useEndUserContextContext } from '../../../contexts/EndUserContext';
import { Customizations } from '../../../objects/Customizations';

interface StartQuestionnaireProps {
  customizations?: Customizations;
  onProceed: () => void;
}

export const StartQuestionnaire = ({
  customizations,
  onProceed,
}: StartQuestionnaireProps): JSX.Element => {
  const { productGroup } = useEndUserContextContext();
  return (
    <VStack
      justifySelf='center'
      alignItems='center'
      color={
        isSome(customizations?.primaryTextColor)
          ? '#' + String(customizations?.primaryTextColor)
          : DefaultEndUserColors.PRIM_TEXT_COLOR
      }
      pt={150}
    >
      <Text fontWeight={700} fontSize={24} pb={6}>
        Find your {productGroup?.configurationName ?? 'configuration'} by
        answering a few questions.
      </Text>
      <Button
        bg={
          isSome(customizations?.buttonColor)
            ? '#' + String(customizations?.buttonColor)
            : DefaultEndUserColors.BTN_COLOR
        }
        color={
          isSome(customizations?.primaryTextColor)
            ? '#' + String(customizations?.primaryTextColor)
            : DefaultEndUserColors.PRIM_TEXT_COLOR
        }
        borderColor='transparent'
        onClick={onProceed}
      >
        Start Questionnaire
      </Button>
    </VStack>
  );
};
