import './SideNavBar.scss';
import Logo from '../../images/logo.svg';
import Systems from '../../images/navIcons/systems.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faTag, faCog } from '@fortawesome/free-solid-svg-icons';
import {
  Flex,
  Stack,
  Box,
  Button,
  Text,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { history } from '../../store/store';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProductGroup } from '../../store/productGroupSlice';
import { isSome } from '../../config/Maybe';
import { Routes } from '../../config/Routes';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { appValues } from '../../config/constants';
import { ProductGroupType } from '../../objects/ProdGroup';

export const SideNavBar = (): JSX.Element => {
  const selectedProductGroup = useAppSelector(selectCurrentProductGroup);

  return (
    <Flex
      pos='fixed'
      left='0'
      h='100%'
      w={appValues.SIDEBAR_WIDTH}
      flexDir='column'
      justifyContent='space-between'
    >
      <nav className={classNames.container}>
        <h1>
          <img id='logo' src={Logo} alt='viduals logo' />
        </h1>

        <Stack className={classNames.navItems}>
          <Box px={3} mb={8}>
            <Text fontWeight={500} fontSize={14} color='gray.600' mb={1}>
              Product Group
            </Text>
            <Button
              onClick={() => history.push(Routes.PRODUCT_GROUPS)}
              borderColor='gray.400'
              color='gray.900'
              fontWeight={400}
              bg='white'
              w='100%'
              rightIcon={<ChevronRightIcon color='gray.500' w={7} h={7} />}
              fontSize={16}
              h='35px'
              justifyContent='space-between'
            >
              {isSome(selectedProductGroup)
                ? selectedProductGroup.name
                : 'Create a Product Group'}
            </Button>
          </Box>
          <Button
            onClick={() => history.push(Routes.FOLDERS_AND_CONTENT)}
            leftIcon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faVideo}
                style={{ width: '18px' }}
              />
            }
            variant={
              history.location.pathname === Routes.FOLDERS_AND_CONTENT
                ? 'side-nav-bar-active'
                : 'side-nav-bar'
            }
          >
            Content
          </Button>
          <Button
            onClick={() => history.push(Routes.TAGS)}
            leftIcon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faTag}
                style={{ width: '18px' }}
              />
            }
            variant={
              history.location.pathname === Routes.TAGS
                ? 'side-nav-bar-active'
                : 'side-nav-bar'
            }
          >
            Tags
          </Button>
          {isSome(selectedProductGroup) &&
          selectedProductGroup.type === ProductGroupType.System ? (
            <Button
              onClick={() => history.push(Routes.SYSTEMS)}
              leftIcon={<Image src={Systems} style={{ width: '18px' }} />}
              variant={
                history.location.pathname === Routes.SYSTEMS
                  ? 'side-nav-bar-active'
                  : 'side-nav-bar'
              }
            >
              <Tooltip label='configurations'>
                <Text as='span'>{selectedProductGroup.configurationName}s</Text>
              </Tooltip>
            </Button>
          ) : (
            <Button
              onClick={() => history.push(Routes.QUESTIONNAIRE)}
              leftIcon={<Image src={Systems} style={{ width: '18px' }} />}
              variant={
                history.location.pathname === Routes.QUESTIONNAIRE
                  ? 'side-nav-bar-active'
                  : 'side-nav-bar'
              }
            >
              Questionnaire
            </Button>
          )}
          <Button
            onClick={() => history.push(Routes.CUSTOMIZATIONS)}
            leftIcon={
              <FontAwesomeIcon
                className={(classNames.icon, classNames.faIcon)}
                icon={faCog}
                style={{ width: '18px', height: '18px' }}
              />
            }
            variant={
              history.location.pathname === Routes.CUSTOMIZATIONS
                ? 'side-nav-bar-active'
                : 'side-nav-bar'
            }
          >
            Customizations
          </Button>
        </Stack>
      </nav>
    </Flex>
  );
};

const classNames = {
  container: 'sideBar-container',
  navItems: 'sideBar-items',
  navItem: 'sideBar-item',
  navItemActive: 'sideBar-item-active',
  icon: 'icon',
  faIcon: 'faIcon',
};
