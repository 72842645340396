import {
  Text,
  Box,
  FormLabel,
  Input,
  Button,
  Heading,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { ImagePreview } from '../../../components/ImagePreview';
import {
  ImageIcon,
  PreviewIcon,
  TemplateSmallIcon,
} from '../../../config/icons';
import { isSome } from '../../../config/Maybe';
import { buildFileGetQuestionImageUrlByProdGroup } from '../../../config/S3Client';
import { Questionnaire } from '../../../objects/Questions/Questionnaire';
import { useGetUsedQuestionnaireImagesQuery } from '../../../services/questionnaireEndpoints';
import { QTemplateOptionCheckbox } from './QTemplateOptionCheckBox';
import Select from 'react-select';

interface SelectInterface {
  value: string;
  label: string;
}

interface QuestionnaireCardProps {
  questionnaire: Questionnaire;
  toggleTemplateChecked: (templateId: string) => void;
  updateInputQuestionnaire: (
    text: string,
    helpText: string,
    imageUrl: string | null | undefined,
    file: File | string | undefined
  ) => void;
}

export const QuestionnaireCard = ({
  questionnaire,
  toggleTemplateChecked,
  updateInputQuestionnaire,
}: QuestionnaireCardProps): JSX.Element => {
  const [showHelpInput, setShowHelpInput] = useState<boolean>(
    questionnaire.helpText !== ''
  );
  // useRef hook does not trigger a rerender, so we do it manually.
  const [imageFile, setImageFileState] = useState<File | undefined>(undefined);
  const [imageFileUrl, setImageFileUrl] = useState<string>('');

  useEffect(() => {
    if (isSome(imageFile)) {
      setImageFileUrl(URL.createObjectURL(imageFile));
    } else {
      setImageFileUrl('');
    }
  }, [imageFile]);

  const [selectedExistingFile, setSelectedExistingFile] = useState<string>(
    questionnaire.imageUrl ?? ''
  );
  const [fileOptions, setFileOptions] = useState<SelectInterface[]>([]);

  const { data: exsitingImages } = useGetUsedQuestionnaireImagesQuery(
    questionnaire.productGroupId
  );

  useEffect(() => {
    if (isSome(exsitingImages)) {
      setFileOptions(
        exsitingImages.map((name) => {
          return { value: name, label: name };
        })
      );
    } else {
      setFileOptions([]);
    }
  }, [exsitingImages]);

  const thumbnailImageRef = useRef<HTMLInputElement>(null);

  const setImageFile = (): void => {
    const file = thumbnailImageRef?.current?.files?.item(0) ?? undefined;
    if (isSome(file) && fileOptions.map((fo) => fo.value).includes(file.name)) {
      window.alert(
        `duplicate file name will overwrite existing file with name:${file.name}`
      );
    }
    updateInputQuestionnaire(
      questionnaire.text,
      questionnaire.helpText,
      questionnaire.imageUrl,
      file
    );

    setImageFileState(file);
  };

  const onSelectFile = async (
    option: SelectInterface | null
  ): Promise<void> => {
    if (isSome(option)) {
      setSelectedExistingFile(option.value);
      updateInputQuestionnaire(
        questionnaire.text,
        questionnaire.helpText,
        option.value,
        questionnaire.imageFileObjectUrl
      );
      removeImageFile();
    } else {
      updateInputQuestionnaire(
        questionnaire.text,
        questionnaire.helpText,
        '',
        questionnaire.imageFileObjectUrl
      );
      setSelectedExistingFile('');
    }
  };

  const removeImageFile = (): void => {
    if (isSome(thumbnailImageRef) && isSome(thumbnailImageRef.current)) {
      thumbnailImageRef.current.files = null;
      thumbnailImageRef.current.value = '';

      setImageFileState(undefined);
    }
    setImageFileUrl('');
  };

  const renderImagePreview = (): JSX.Element => {
    if (isSome(imageFile)) {
      return (
        <ImagePreview
          fileUrl={imageFileUrl}
          imageLabel='Help'
          imageName={imageFile.name}
          removeFunc={() => {
            updateInputQuestionnaire(
              questionnaire.text,
              questionnaire.helpText,
              questionnaire.imageUrl,
              undefined
            );
            removeImageFile();
          }}
        />
      );
    } else if (selectedExistingFile !== '') {
      const imageUrl = buildFileGetQuestionImageUrlByProdGroup(
        questionnaire.productGroupId,
        selectedExistingFile
      );
      return (
        <ImagePreview
          fileUrl={imageUrl}
          imageLabel='Help'
          imageName={selectedExistingFile}
          removeFunc={() => {
            updateInputQuestionnaire(
              questionnaire.text,
              questionnaire.helpText,
              '',
              questionnaire.imageFileObjectUrl
            );
            setSelectedExistingFile('');
          }}
        />
      );
    }
    return <Text>Image could not be rendered</Text>;
  };

  return (
    <Box layerStyle='questionnaire-card'>
      <Stack spacing={3}>
        <Heading as='h2' variant='questionnaire'>
          Question {'#'}
        </Heading>
        <Box>
          <FormLabel>
            <TemplateSmallIcon />
            Templates
          </FormLabel>

          <Input
            variant='questionnaire'
            w='100%'
            type='text'
            placeholder={''}
            value={questionnaire.text}
            onChange={(e) =>
              updateInputQuestionnaire(
                e.target.value,
                questionnaire.helpText,
                questionnaire.imageUrl,
                questionnaire.imageFileObjectUrl
              )
            }
          />
        </Box>
        <Stack justifyContent='space-between'>
          {isSome(imageFile) || selectedExistingFile !== '' ? (
            renderImagePreview()
          ) : (
            <>
              <Button
                onClick={() => {
                  thumbnailImageRef?.current?.click();
                }}
                leftIcon={<ImageIcon />}
              >
                Upload New Help Image
              </Button>
              <Box w='100%' my='20px'>
                <Text>Choose image from exsiting files</Text>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  name='fileName'
                  options={fileOptions}
                  onChange={onSelectFile}
                />
              </Box>
            </>
          )}
          <input
            onClick={(e) => (e.currentTarget.value = '')}
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            ref={thumbnailImageRef}
            onChange={setImageFile}
          />
          {!showHelpInput && (
            <Button
              w='48%'
              leftIcon={<PreviewIcon className='icon' w={8} h={5} />}
              onClick={() => setShowHelpInput(!showHelpInput)}
            >
              Add help text
            </Button>
          )}
        </Stack>
        {showHelpInput && (
          <>
            <FormLabel>Help text</FormLabel>
            <Textarea
              variant='questionnaire'
              placeholder={''}
              value={questionnaire.helpText}
              onChange={(e) =>
                updateInputQuestionnaire(
                  questionnaire.text,
                  e.target.value,
                  questionnaire.imageUrl,
                  questionnaire.imageFileObjectUrl
                )
              }
            />
          </>
        )}
        <Stack>
          <Text variant='questionnaire'>
            Select the options available to the end user
          </Text>

          {questionnaire.questionnaireTemplateOptions.map(
            (qTemplateOption, index) => (
              <QTemplateOptionCheckbox
                key={`questionnaire template option:${String(
                  qTemplateOption.id ?? index
                )}`}
                toggleTemplateChecked={toggleTemplateChecked}
                qTemplateOption={qTemplateOption}
              />
            )
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
