/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable  @typescript-eslint/restrict-template-expressions */
import { IUserData, User, UserParams } from '../objects/User';
import { setCurrentUserData } from '../store/authSlice';
import { vidualsApi, IOnQueryAPI } from './vidualsApi';

const updateUserState = async (_arg: any, api: IOnQueryAPI): Promise<void> => {
  try {
    const { dispatch, queryFulfilled } = api;
    const { data } = await queryFulfilled;
    dispatch(setCurrentUserData(data));
  } catch (err) {
    console.trace(err);
  }
};

export const userApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<User, UserParams>({
      query: (userInput) => {
        return {
          url: `users`,
          method: 'POST',
          body: userInput,
        };
      },
    }),
    updateUser: builder.mutation<User, UserParams>({
      query: (userInput) => {
        return {
          url: `users/${userInput.uid}`,
          method: 'PUT',
          body: userInput,
        };
      },
    }),
    getCurrentUser: builder.query<IUserData, void>({
      query: () => `users`,
      onQueryStarted: updateUserState,
    }),
    getUsersByIds: builder.query<User[], string[]>({
      query: (userIds) => {
        return {
          url: `users?id=${userIds.join('&id=')}`,
          method: 'GET',
        };
      },
    }),
    getUsersByOrgId: builder.query<User[], string>({
      query: (orgId) => {
        return {
          url: `users?id=${orgId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useLazyGetCurrentUserQuery,
  useGetUsersByIdsQuery,
} = userApi;
