/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { ContentVersion } from '../objects/ContentVersion';
import { InputTagParams, Tag, UpdateTagParams } from '../objects/Tag';
import { addTags, setTags, updateTag } from '../store/tagSlice';
import { removeTagFromTemplate } from '../store/templateSlice';
import { IOnQueryAPI, vidualsApi } from './vidualsApi';

const updateTagSlice = async (_arg: any, api: IOnQueryAPI): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(updateTag(data));
  } catch (err) {
    console.trace(err);
  }
};

const setTagsOnSlice = async (_arg: any, api: IOnQueryAPI): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(setTags(data));
  } catch (err) {
    console.trace(err);
  }
};

const addTagsToSlice = async (_arg: any, api: IOnQueryAPI): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    dispatch(addTags([data]));
  } catch (err) {
    console.trace(err);
  }
};

const removeTagFromTemplates = async (
  _arg: any,
  api: IOnQueryAPI
): Promise<void> => {
  try {
    const { queryFulfilled, dispatch } = api;
    const { data } = await queryFulfilled;

    if (typeof data === 'string') {
      dispatch(removeTagFromTemplate(data));
    }
  } catch (err) {
    console.trace(err);
  }
};

export const tagsApi = vidualsApi.injectEndpoints({
  endpoints: (builder) => ({
    getUngroupedTagsByProductGroupId: builder.query<Tag[], string>({
      query: (pgid) => `tags/productGroup/${pgid}/ungrouped`,
      onQueryStarted: setTagsOnSlice,
    }),
    createTag: builder.mutation<Tag, InputTagParams>({
      query: (tagInput) => {
        return {
          url: `tags`,
          method: 'POST',
          body: tagInput,
        };
      },
      onQueryStarted: addTagsToSlice,
    }),
    updateTag: builder.mutation<Tag, UpdateTagParams>({
      query: (tagInput) => {
        return {
          url: `tags`,
          method: 'PUT',
          body: tagInput,
        };
      },
      onQueryStarted: updateTagSlice,
    }),
    deleteTag: builder.mutation<string | ContentVersion[], UpdateTagParams>({
      query: (tagInput) => {
        return {
          url: `tags`,
          method: 'DELETE',
          body: tagInput,
        };
      },
      onQueryStarted: removeTagFromTemplates,
    }),
  }),
});

export const {
  useLazyGetUngroupedTagsByProductGroupIdQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagsApi;
