import { createIcon } from '@chakra-ui/react';
import { appColors } from './constants';

// New Content Icons
export const AddVideoIcon = createIcon({
  displayName: 'addVideoIcon',
  viewBox: '0 0 20 14',
  path: (
    <path
      d='M13 5L17.553 2.724C17.7054 2.64784 17.8748 2.61188 18.045 2.61955C18.2152 2.62721 18.3806 2.67825 18.5256 2.76781C18.6706 2.85736 18.7902 2.98248 18.8733 3.13127C18.9563 3.28007 18.9999 3.44761 19 3.618V10.382C18.9999 10.5524 18.9563 10.7199 18.8733 10.8687C18.7902 11.0175 18.6706 11.1426 18.5256 11.2322C18.3806 11.3218 18.2152 11.3728 18.045 11.3805C17.8748 11.3881 17.7054 11.3522 17.553 11.276L13 9V5ZM3 13H11C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11V3C13 2.46957 12.7893 1.96086 12.4142 1.58579C12.0391 1.21071 11.5304 1 11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13Z'
      fillOpacity='0'
      stroke={appColors.GREEN_DARKER}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
export const AddVideoIconNoColor = createIcon({
  displayName: 'addVideoIcon',
  viewBox: '0 0 20 14',
  path: (
    <path
      d='M13 5L17.553 2.724C17.7054 2.64784 17.8748 2.61188 18.045 2.61955C18.2152 2.62721 18.3806 2.67825 18.5256 2.76781C18.6706 2.85736 18.7902 2.98248 18.8733 3.13127C18.9563 3.28007 18.9999 3.44761 19 3.618V10.382C18.9999 10.5524 18.9563 10.7199 18.8733 10.8687C18.7902 11.0175 18.6706 11.1426 18.5256 11.2322C18.3806 11.3218 18.2152 11.3728 18.045 11.3805C17.8748 11.3881 17.7054 11.3522 17.553 11.276L13 9V5ZM3 13H11C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11V3C13 2.46957 12.7893 1.96086 12.4142 1.58579C12.0391 1.21071 11.5304 1 11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13Z'
      fillOpacity='0'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
export const PdfIcon = createIcon({
  displayName: 'pdfIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M13.4727 4.19531L10.5547 1.24219C10.2383 0.925781 9.78125 0.75 9.35938 0.75H2.1875C1.23828 0.75 0.5 1.52344 0.5 2.4375V17.0625C0.5 18.0117 1.23828 18.75 2.1875 18.75H12.3125C13.2266 18.75 14 18.0117 14 17.0625V5.39062C14 4.96875 13.7891 4.51172 13.4727 4.19531ZM12.1719 5.25H9.5V2.57812L12.1719 5.25ZM2.1875 17.0625V2.4375H7.8125V6.09375C7.8125 6.58594 8.16406 6.9375 8.65625 6.9375H12.3125V17.0625H2.1875ZM10.9766 12.0352C10.5547 11.6133 9.32422 11.7188 8.69141 11.7891C8.09375 11.4375 7.70703 10.9102 7.42578 10.1719C7.53125 9.60938 7.77734 8.73047 7.60156 8.20312C7.46094 7.28906 6.26562 7.35938 6.08984 7.99219C5.94922 8.55469 6.08984 9.36328 6.33594 10.3477C5.98438 11.1914 5.45703 12.3164 5.10547 12.9844C4.40234 13.3359 3.45312 13.8984 3.3125 14.6016C3.20703 15.1641 4.22656 16.5352 5.98438 13.5117C6.75781 13.2305 7.63672 12.9141 8.375 12.7734C9.04297 13.1602 9.81641 13.3711 10.3438 13.3711C11.2578 13.3711 11.3281 12.3867 10.9766 12.0352ZM4.01562 14.7773C4.19141 14.2852 4.85938 13.7227 5.07031 13.5469C4.40234 14.6016 4.01562 14.7773 4.01562 14.7773ZM6.86328 8.0625C7.14453 8.0625 7.10938 9.1875 6.93359 9.50391C6.79297 9.01172 6.79297 8.0625 6.86328 8.0625ZM6.01953 12.8789C6.37109 12.2812 6.65234 11.5781 6.89844 10.9453C7.17969 11.4727 7.53125 11.8945 7.95312 12.1758C7.21484 12.3516 6.58203 12.6328 6.01953 12.8789ZM10.625 12.7031C10.625 12.7031 10.4492 12.9141 9.32422 12.4219C10.5547 12.3164 10.7656 12.5977 10.625 12.7031Z'
      fillOpacity='1'
      fill={appColors.GREEN_DARKER}
      stroke={appColors.GREEN_DARKER}
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
export const PreviewIcon = createIcon({
  displayName: 'previewIcon',
  viewBox: '0 0 16 20',
  path: (
    <path
      d='M6 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V7.414C14.9999 7.1488 14.8946 6.89449 14.707 6.707L9.293 1.293C9.10551 1.10545 8.8512 1.00006 8.586 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V14M1 19L5.879 14.121M5.879 14.121C6.15671 14.4033 6.48757 14.6279 6.85249 14.7817C7.21742 14.9355 7.60919 15.0156 8.0052 15.0173C8.40122 15.0189 8.79365 14.9421 9.15985 14.7914C9.52605 14.6406 9.85878 14.4188 10.1388 14.1388C10.4189 13.8588 10.6408 13.5262 10.7916 13.16C10.9425 12.7938 11.0193 12.4014 11.0177 12.0054C11.0162 11.6094 10.9362 11.2176 10.7825 10.8526C10.6287 10.4877 10.4043 10.1568 10.122 9.879C9.55791 9.32389 8.79732 9.01417 8.00591 9.0173C7.2145 9.02043 6.45638 9.33615 5.8967 9.89571C5.33702 10.4553 5.02111 11.2133 5.0178 12.0047C5.01448 12.7961 5.32402 13.5568 5.879 14.121Z'
      stroke={appColors.GREEN_DARKER}
      fillOpacity='0'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
export const TemplateSmallIcon = createIcon({
  displayName: 'templateSmallIcon',
  viewBox: '0 0 18 18',
  path: (
    <>
      <path
        d='M0.861888 1.36225C0.736864 1.48728 0.666626 1.65685 0.666626 1.83366V3.16699C0.666626 3.3438 0.736864 3.51337 0.861888 3.6384C0.986912 3.76342 1.15648 3.83366 1.33329 3.83366H10.6666C10.8434 3.83366 11.013 3.76342 11.138 3.6384C11.2631 3.51337 11.3333 3.3438 11.3333 3.16699V1.83366C11.3333 1.65685 11.2631 1.48728 11.138 1.36225C11.013 1.23723 10.8434 1.16699 10.6666 1.16699H1.33329C1.15648 1.16699 0.986912 1.23723 0.861888 1.36225Z'
        stroke={appColors.GRAY500}
        fillOpacity='0'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.861888 6.69559C0.736864 6.82061 0.666626 6.99018 0.666626 7.16699V11.167C0.666626 11.3438 0.736864 11.5134 0.861888 11.6384C0.986912 11.7634 1.15648 11.8337 1.33329 11.8337H5.33329C5.5101 11.8337 5.67967 11.7634 5.8047 11.6384C5.92972 11.5134 5.99996 11.3438 5.99996 11.167V7.16699C5.99996 6.99018 5.92972 6.82061 5.8047 6.69559C5.67967 6.57056 5.5101 6.50033 5.33329 6.50033H1.33329C1.15648 6.50033 0.986912 6.57056 0.861888 6.69559Z'
        stroke={appColors.GRAY500}
        fillOpacity='0'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.86189 6.69559C8.73686 6.82061 8.66663 6.99018 8.66663 7.16699V11.167C8.66663 11.3438 8.73686 11.5134 8.86189 11.6384C8.98691 11.7634 9.15648 11.8337 9.33329 11.8337H10.6666C10.8434 11.8337 11.013 11.7634 11.138 11.6384C11.2631 11.5134 11.3333 11.3438 11.3333 11.167V7.16699C11.3333 6.99018 11.2631 6.82061 11.138 6.69559C11.013 6.57056 10.8434 6.50033 10.6666 6.50033H9.33329C9.15648 6.50033 8.98691 6.57056 8.86189 6.69559Z'
        stroke={appColors.GRAY500}
        fillOpacity='0'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});
export const TagVersionIcon = createIcon({
  displayName: 'tagVersionIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M5 5H5.01M5 1H10C10.512 1 11.024 1.195 11.414 1.586L18.414 8.586C18.7889 8.96106 18.9996 9.46967 18.9996 10C18.9996 10.5303 18.7889 11.0389 18.414 11.414L11.414 18.414C11.0389 18.7889 10.5303 18.9996 10 18.9996C9.46967 18.9996 8.96106 18.7889 8.586 18.414L1.586 11.414C1.4 11.2285 1.25249 11.0081 1.15192 10.7655C1.05136 10.5228 0.99973 10.2627 1 10V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1Z'
      fillOpacity='0'
      stroke={appColors.GREEN_DARKER}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
export const TagOutlineIcon = createIcon({
  displayName: 'tagOutlineIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M5 5H5.01M5 1H10C10.512 1 11.024 1.195 11.414 1.586L18.414 8.586C18.7889 8.96106 18.9996 9.46967 18.9996 10C18.9996 10.5303 18.7889 11.0389 18.414 11.414L11.414 18.414C11.0389 18.7889 10.5303 18.9996 10 18.9996C9.46967 18.9996 8.96106 18.7889 8.586 18.414L1.586 11.414C1.4 11.2285 1.25249 11.0081 1.15192 10.7655C1.05136 10.5228 0.99973 10.2627 1 10V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1Z'
      fillOpacity='0'
      stroke={appColors.GRAY500}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const MoveItemIcon = createIcon({
  displayName: 'moveItemIcon',
  viewBox: '0 0 7 13',
  path: (
    <path
      d='M6.14844 8.65625C6.03125 8.53906 5.84375 8.53906 5.75 8.65625L4.10938 10.3672V2.65625L5.75 4.36719C5.84375 4.48438 6.03125 4.48438 6.14844 4.36719L6.59375 3.89844C6.71094 3.80469 6.71094 3.61719 6.59375 3.5L3.6875 0.59375C3.57031 0.476562 3.40625 0.476562 3.28906 0.59375L0.382812 3.5C0.265625 3.61719 0.265625 3.80469 0.382812 3.89844L0.828125 4.36719C0.945312 4.48438 1.13281 4.48438 1.22656 4.36719L2.86719 2.65625V10.3672L1.22656 8.65625C1.13281 8.53906 0.945312 8.53906 0.828125 8.65625L0.382812 9.10156C0.265625 9.21875 0.265625 9.40625 0.382812 9.5L3.28906 12.4297C3.40625 12.5469 3.57031 12.5469 3.6875 12.4297L6.59375 9.5C6.71094 9.40625 6.71094 9.21875 6.59375 9.10156L6.14844 8.65625Z'
      fillOpacity='1'
      fill={appColors.GREEN_DARKER}
      strokeWidth='0'
    />
  ),
});

export const UserIcon = createIcon({
  displayName: 'userIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      d='M4.535 25.6733C8.03236 23.6965 11.9826 22.6606 16 22.6667C20.1667 22.6667 24.0783 23.7583 27.465 25.6733M21 12.6667C21 13.9927 20.4732 15.2645 19.5355 16.2022C18.5979 17.1399 17.3261 17.6667 16 17.6667C14.6739 17.6667 13.4021 17.1399 12.4645 16.2022C11.5268 15.2645 11 13.9927 11 12.6667C11 11.3406 11.5268 10.0688 12.4645 9.13113C13.4021 8.19345 14.6739 7.66667 16 7.66667C17.3261 7.66667 18.5979 8.19345 19.5355 9.13113C20.4732 10.0688 21 11.3406 21 12.6667ZM31 16C31 17.9698 30.612 19.9204 29.8582 21.7403C29.1044 23.5601 27.9995 25.2137 26.6066 26.6066C25.2137 27.9995 23.5601 29.1044 21.7403 29.8582C19.9204 30.612 17.9698 31 16 31C14.0302 31 12.0796 30.612 10.2597 29.8582C8.43986 29.1044 6.78628 27.9995 5.3934 26.6066C4.00052 25.2137 2.89563 23.5601 2.14181 21.7403C1.38799 19.9204 1 17.9698 1 16C1 12.0218 2.58035 8.20644 5.3934 5.3934C8.20644 2.58035 12.0218 1 16 1C19.9782 1 23.7936 2.58035 26.6066 5.3934C29.4196 8.20644 31 12.0218 31 16Z'
      fillOpacity='0'
      stroke={'#3F3F46'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const NewProdGroupIcon = createIcon({
  displayName: 'newProdGroupIcon',
  viewBox: '0 0 20 16',
  path: (
    <path
      d='M7 9H13M10 6V12M1 13V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H9L11 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13Z'
      fillOpacity='0'
      stroke={'white'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const EditIcon = createIcon({
  displayName: 'editIcon',
  viewBox: '0 0 21 21',
  path: (
    <path
      d='M13.732 3.73233L17.268 7.26833M15.232 2.23233C15.7009 1.76343 16.3369 1.5 17 1.5C17.6631 1.5 18.2991 1.76343 18.768 2.23233C19.2369 2.70123 19.5003 3.3372 19.5003 4.00033C19.5003 4.66346 19.2369 5.29943 18.768 5.76833L5 19.5363H1.5V15.9643L15.232 2.23233Z'
      fillOpacity='0'
      stroke={appColors.GREEN_DARKER}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const ImageIcon = createIcon({
  displayName: 'imageIcon',
  viewBox: '0 0 18 18',
  path: (
    <path
      d='M1 13L5.586 8.414C5.96106 8.03906 6.46967 7.82843 7 7.82843C7.53033 7.82843 8.03894 8.03906 8.414 8.414L13 13M11 11L12.586 9.414C12.9611 9.03906 13.4697 8.82843 14 8.82843C14.5303 8.82843 15.0389 9.03906 15.414 9.414L17 11M11 5H11.01M3 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17Z'
      fillOpacity='0'
      stroke={appColors.GREEN_DARKER}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const PictureIcon = createIcon({
  displayName: 'pictureIcon',
  viewBox: '0 0 18 18',
  path: (
    <path
      d='M1 13L5.586 8.414C5.96106 8.03906 6.46967 7.82843 7 7.82843C7.53033 7.82843 8.03894 8.03906 8.414 8.414L13 13M11 11L12.586 9.414C12.9611 9.03906 13.4697 8.82843 14 8.82843C14.5303 8.82843 15.0389 9.03906 15.414 9.414L17 11M11 5H11.01M3 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17Z'
      fillOpacity='0'
      stroke='viduals.greenDarker'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});
export const FolderIcon = createIcon({
  displayName: 'closedFolderIcon',
  viewBox: '0 0 20 16',
  path: (
    <path
      d='M1 13V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H9L11 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13Z'
      fillOpacity='0'
      stroke={appColors.GRAY500}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const FolderIconNoColor = createIcon({
  displayName: 'folderIcon',
  viewBox: '0 0 20 16',
  path: (
    <path
      d='M1 13V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H9L11 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13Z'
      fillOpacity='0'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
});

export const addContentIcons = {
  AddVideoIcon: <AddVideoIcon className='icon' />,
  TagVersionIcon: <TagVersionIcon className='icon' />,
};

export const homepageIcons = {
  MoveItemIcon: <MoveItemIcon className='icon' />,
};
